import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "./siteAboutInterfaces";
import { SchedulesTabEditMode } from "../SchedulesTabEditMode";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";
import { findNewScheduleIndex } from "./findNewScheduleIndex";
import { ScheduleGrid } from "../ScheduleGrid";
import { handleCreateInitialTimeSchedules } from "./handleCreateInitialTimeSchedules";
import { handleConvertSchedulesToBooleanArrays } from "./handleConvertSchedulesToBooleanArrays";
import { handleCreateDuskToDawn } from "./handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../../SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";

export const handleAddSchedule = (
  siteId: number,
  setSiteSchedules: Dispatcher<any>,
  setSchedulesBackdropLoadingScreenOn: Dispatcher<boolean>,
  visibleSchedules: any,
  setVisibleSchedules: Dispatcher<any>,
  setSchedulesIndex: Dispatcher<number>,
  setScheduleChangesMade: Dispatcher<boolean>,
  setClickedSchedulesTab: Dispatcher<string>,
  setActiveTab: Dispatcher<any>,
  setEditMode: Dispatcher<boolean>,
  setSiteInfo: Dispatcher<any>,
  siteInfo: any,
  setDeleteScheduleDialogOpen: Dispatcher<boolean>,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setEditModeTabSiteSchedules: Dispatcher<any>,
  setScheduleGrid: Dispatcher<any>,
  setSelectedDayInfo: Dispatcher<any>,
  setEditTimeScheduleDialogOpen: Dispatcher<boolean>,
  setTimeRanges: Dispatcher<any>,
  setGrid: Dispatcher<any>,
  setDuskToDawn: Dispatcher<any>,
  setDuskToDawnLocation: Dispatcher<any>,
  setMatchingSchedules: Dispatcher<any>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleAddSchedule(
      siteId,
      setSiteSchedules,
      setSchedulesBackdropLoadingScreenOn,
      visibleSchedules,
      setVisibleSchedules,
      setSchedulesIndex,
      setScheduleChangesMade,
      setClickedSchedulesTab,
      setActiveTab,
      setEditMode,
      setSiteInfo,
      siteInfo,
      setDeleteScheduleDialogOpen,
      setBackdropLoadingScreenOn,
      setEditModeTabSiteSchedules,
      setScheduleGrid,
      setSelectedDayInfo,
      setEditTimeScheduleDialogOpen,
      setTimeRanges,
      setGrid,
      setDuskToDawn,
      setDuskToDawnLocation,
      setMatchingSchedules,
      navigate
    );
  };

  AxiosUI.post(`/flex_schedule/add/${siteId}/`).then(
    (response: AxiosResponse) => {
      let previousSiteSchedules: any = [];
      setSiteSchedules((prev: any) => {
        previousSiteSchedules = prev;
      });
      setSiteSchedules(response.data);
      setEditModeTabSiteSchedules(response.data);

      const newScheduleIndex = findNewScheduleIndex(
        previousSiteSchedules,
        response.data
      );
      localStorage.setItem("backUpFlexSchedule", JSON.stringify(response.data));
      if (visibleSchedules.one && !visibleSchedules.two) {
        setVisibleSchedules({
          one: true,
          two: true,
          three: false,
        });
        localStorage.setItem(
          "visibleSchedules",
          JSON.stringify({
            one: true,
            two: true,
            three: false,
          })
        );
      }
      if (visibleSchedules.one && visibleSchedules.two) {
        setVisibleSchedules({
          one: true,
          two: true,
          three: true,
        });
        localStorage.setItem(
          "visibleSchedules",
          JSON.stringify({
            one: true,
            two: true,
            three: true,
          })
        );
      }
      if (newScheduleIndex === 0) {
        setSchedulesIndex(0);
        setClickedSchedulesTab("one");
        localStorage.setItem("clickedSchedulesTab", "one");
        localStorage.setItem("schedulesIndex", "0");
      }
      if (newScheduleIndex === 1) {
        setSchedulesIndex(1);
        setClickedSchedulesTab("two");
        localStorage.setItem("clickedSchedulesTab", "two");
        localStorage.setItem("schedulesIndex", "1");
      }
      if (newScheduleIndex === 2) {
        setSchedulesIndex(2);
        setClickedSchedulesTab("three");
        localStorage.setItem("clickedSchedulesTab", "three");
        localStorage.setItem("schedulesIndex", "2");
      }
      setActiveTab(
        <SchedulesTabEditMode
          siteSchedules={response.data}
          siteInfo={siteInfo}
          setScheduleChangesMade={setScheduleChangesMade}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setActiveTab={setActiveTab}
          setEditMode={setEditMode}
          setSiteSchedules={setSiteSchedules}
          setSiteInfo={setSiteInfo}
        />
      );
      setScheduleGrid(
        <ScheduleGrid
          editable={true}
          setSelectedDayInfo={setSelectedDayInfo}
          setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
          timeRanges={handleCreateInitialTimeSchedules(
            response.data[newScheduleIndex].schedule
          )}
          setTimeRanges={setTimeRanges}
          grid={handleConvertSchedulesToBooleanArrays(
            response.data[newScheduleIndex].schedule
          )}
          setGrid={setGrid}
          duskToDawn={handleCreateDuskToDawn(
            response.data[newScheduleIndex].schedule
          )}
          setDuskToDawn={setDuskToDawn}
          duskToDawnLocation={handleCreateDuskToDawnLocationPlusBuffer(
            response.data[0].schedule
          )}
          setDuskToDawnLocation={setDuskToDawnLocation}
          setMatchingSchedules={setMatchingSchedules}
          setScheduleChangesMade={setScheduleChangesMade}
          setSiteSchedules={setSiteSchedules}
          setScheduleGrid={setScheduleGrid}
          setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
        />
      );
      setSchedulesBackdropLoadingScreenOn(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Problem adding additional schedule.  Please try again.");
        setSchedulesBackdropLoadingScreenOn(false);
      });
    }
  );
};
