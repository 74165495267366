import { getIndividualGroup } from "./getIndividualGroup";

export const handleUnnestedLevelClick = async (
  setPage: any,
  setCurrentTitle: any,
  name: string,
  id: any,
  setRenderedRows: any,
  setPageLoading: any,
  navigate: any,
  setSiteBreadcrumbs: any,
  setSelected: any,
  cameraIconBoolean: boolean,
  setSelectedGroupListName: any,
  setEditableGroupSelected: any
) => {
  setSelectedGroupListName(name);
  setEditableGroupSelected(!cameraIconBoolean);

  localStorage.setItem("clickedId", id);
  setSiteBreadcrumbs("");
  setPage(0);
  setCurrentTitle(name);
  localStorage.setItem("getIndividualGroupId", id);
  getIndividualGroup(
    id,
    setRenderedRows,
    setPageLoading,
    setSiteBreadcrumbs,
    setSelected,
    setEditableGroupSelected,
    setSelectedGroupListName,
    navigate
  );
};
