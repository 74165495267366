import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleArmSite } from "./SiteAboutPageUtils/handleArmSite";
import { useNavigate } from "react-router-dom";
import { AlertState, SiteInfo } from "./SiteAboutPageUtils/siteAboutInterfaces";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { FormHelperText } from "@mui/material";
import { handleArmSiteFlexSchedules } from "./SiteAboutPageUtils/handleArmSiteFlexSchedules";
import { handleCreateSelectedSchedulesArrays } from "./SiteAboutPageUtils/handleCreateSelectedSchedulesArrays";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const ArmSiteFlexScheduleDialog = ({
  armSiteFlexScheduleDialogOpen,
  setArmSiteFlexScheduleDialogOpen,
  setSiteInfo,
  setBackdropLoadingScreenOn,
  setAlertState,
  setSyncNeeded,
  setArmedStatus,
  setArmButtonDisabled,
  siteSchedules,
  setToolTipTitle,
}: {
  armSiteFlexScheduleDialogOpen: boolean;
  setArmSiteFlexScheduleDialogOpen: Dispatcher<boolean>;
  setSiteInfo: Dispatcher<SiteInfo>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  setSyncNeeded: Dispatcher<boolean>;
  setArmedStatus: Dispatcher<boolean>;
  setArmButtonDisabled: any;
  siteSchedules: any;
  setToolTipTitle: any;
}) => {
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = React.useState({
    one: false,
    two: false,
    three: false,
  });

  const [duplicateProducts, setDuplicateProducts] = React.useState(false);

  const products = JSON.parse(localStorage.products);
  const productIds = new Map();

  products.forEach((product: any) => {
    productIds.set(product.id, product.name);
  });

  const handleCheckForSameProducts = () => {
    const selectedProducts: number[] = [];

    if (selectedValue.one) {
      siteSchedules[0].product.forEach((product: number) => {
        selectedProducts.push(product);
      });
    }
    if (selectedValue.two) {
      siteSchedules[1].product.forEach((product: number) => {
        selectedProducts.push(product);
      });
    }
    if (selectedValue.three) {
      siteSchedules[2].product.forEach((product: number) => {
        selectedProducts.push(product);
      });
    }

    //check selectedProducts for duplicates
    const duplicates = selectedProducts.filter(
      (item, index) => selectedProducts.indexOf(item) != index
    );

    if (duplicates.length > 0) {
      setDuplicateProducts(true);
    } else {
      setDuplicateProducts(false);
    }
  };

  React.useEffect(() => {
    handleCheckForSameProducts();
  }, [selectedValue]);

  return (
    <Dialog
      open={armSiteFlexScheduleDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ background: "#001943" }}>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Arm Site
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Flex scheduling is currently enabled. Please select which schedules
          you would like to arm. These schedules will disarm at their next
          scheduled disarm time.
        </DialogContentText>
        <FormGroup className="pt-[20px]">
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedValue.one}
                onChange={(event) =>
                  setSelectedValue({
                    ...selectedValue,
                    one: event.target.checked,
                  })
                }
              />
            }
            label={
              <div>
                {`${
                  siteSchedules[0].display_name
                } (Active Products: ${`${siteSchedules[0].product
                  .map((product: number) => {
                    return `${productIds.get(product)}`;
                  })
                  .join(", ")}`})`}
              </div>
            }
          />
          {siteSchedules[1] && siteSchedules[1].product.length > 0 && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedValue.two}
                  onChange={(event) =>
                    setSelectedValue({
                      ...selectedValue,
                      two: event.target.checked,
                    })
                  }
                />
              }
              label={
                <div>
                  {`${
                    siteSchedules[1].display_name
                  } (Active Products: ${`${siteSchedules[1].product
                    .map((product: number) => {
                      return `${productIds.get(product)}`;
                    })
                    .join(", ")}`})`}
                </div>
              }
            />
          )}
          {siteSchedules[2] && siteSchedules[2].product.length > 0 && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedValue.three}
                  onChange={(event) =>
                    setSelectedValue({
                      ...selectedValue,
                      three: event.target.checked,
                    })
                  }
                />
              }
              label={
                <div>
                  {`${
                    siteSchedules[2].display_name
                  } (Active Products: ${`${siteSchedules[2].product
                    .map((product: number) => {
                      return `${productIds.get(product)}`;
                    })
                    .join(", ")}`})`}
                </div>
              }
            />
          )}
        </FormGroup>
        {duplicateProducts && (
          <FormHelperText error>
            Schedules have been selected that contain the same products. Please
            note that this could result in duplicate alerts.
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setArmSiteFlexScheduleDialogOpen(false);
            setSiteInfo((previousState: SiteInfo) => {
              return { ...previousState, armed: false };
            });
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            handleArmSiteFlexSchedules(
              setBackdropLoadingScreenOn,
              setSiteInfo,
              setAlertState,
              setSyncNeeded,
              setArmedStatus,
              navigate,
              setArmButtonDisabled,
              handleCreateSelectedSchedulesArrays(selectedValue, siteSchedules),
              setToolTipTitle
            );

            setSiteInfo((previousState: SiteInfo) => {
              return { ...previousState, armed: true };
            });

            setArmSiteFlexScheduleDialogOpen(false);
          }}
          text="Arm Site"
          disabled={
            !selectedValue.one && !selectedValue.two && !selectedValue.three
          }
        />
      </DialogActions>
    </Dialog>
  );
};
