import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { GeneralInfoTab } from "../GeneralInfoTab";
import { VmsTab } from "../VmsTab";
import { SchedulesTab } from "../SchedulesTab";
import { getRefreshToken } from "../../../../utils/getRefreshToken";
import { getNumbersAfterAboutSite } from "../SiteAbout";
import { handleSaveChangesButtonClick } from "./handleSaveChangesButtonClick";
import { AlertState } from "./siteAboutInterfaces";
import { temporaryUpdateScheduleData } from "./temporaryUpdateScheduleData";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { SchedulesTabEditMode } from "../SchedulesTabEditMode";

export const submitScheduleChanges = async (
  siteSchedules: any,
  setAllSiteSchedules: any,
  setEditMode: any,
  setBackdropLoadingScreenOn: any,
  clickedTab: any,
  setActiveTab: any,
  siteInfo: any,
  vmsVariables: any,
  setDeleteScheduleDialogOpen: any,
  navigate: any,
  patchObject: any,
  setSiteInfo: any,
  setPatchObject: any,
  setAlertState: any,
  setSyncNeeded: any,
  editMode: any,
  setSchedulesTabActive: any,
  setClickedTab: any,
  siteInfoChangesMade: any,
  setSiteInfoChangesMade: any,
  scheduleChangesMade: any,
  setScheduleChangesMade: any,
  setSiteSchedules: any,
  setArmedStatus: any,
  setDeactivateSiteInsteadDialogOpen: any,
  setArmButtonDisabled: any,
  recordingServerChangesMade: any,
  recordingServerData: any,
  recordingServerPatchObject: any,
  setRecordingServerData: any,
  setRecordingServerPatchObject: any,
  setRecordingServerChangesMade: any,
  cameraHealthChangesMade: any,
  healthCheckData: any,
  setHealthCheckData: any,
  cameraHealthPatchObject: any,
  setCameraHealthPatchObject: any,
  setCameraHealthChangesMade: any,
  webhooksChangesMade: any,
  webhooksInfo: any,
  setWebhooksInfo: any,
  webhooksPatchObject: any,
  setWebhooksPatchObject: any,
  setWebhooksChangesMade: any,
  setActivateSiteWithScheduleDialogOpen: any
) => {
  const AxiosUI = createApi(``);
  const allSiteSchedulesBackup = JSON.parse(
    localStorage.allSiteSchedulesBackup
  );
  const returnFunction = () => {
    handleSaveChangesButtonClick(
      patchObject,
      setEditMode,
      setSiteInfo,
      setPatchObject,
      siteInfo,
      clickedTab,
      setActiveTab,
      vmsVariables,
      siteSchedules,
      setAllSiteSchedules,
      setBackdropLoadingScreenOn,
      setAlertState,
      setSyncNeeded,
      editMode,
      setSchedulesTabActive,
      setClickedTab,
      siteInfoChangesMade,
      setSiteInfoChangesMade,
      scheduleChangesMade,
      setScheduleChangesMade,
      setDeleteScheduleDialogOpen,
      navigate,
      setSiteSchedules,
      setArmButtonDisabled,
      recordingServerChangesMade,
      recordingServerData,
      recordingServerPatchObject,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerChangesMade,
      cameraHealthChangesMade,
      healthCheckData,
      setHealthCheckData,
      cameraHealthPatchObject,
      setCameraHealthPatchObject,
      setCameraHealthChangesMade,
      setArmedStatus,
      webhooksChangesMade,
      webhooksInfo,
      setWebhooksInfo,
      webhooksPatchObject,
      setWebhooksPatchObject,
      setWebhooksChangesMade,
      setDeactivateSiteInsteadDialogOpen,
      handleSaveChangesButtonClick
    );
  };

  const retryFunction = () => {
    submitScheduleChanges(
      siteSchedules,
      setAllSiteSchedules,
      setEditMode,
      setBackdropLoadingScreenOn,
      clickedTab,
      setActiveTab,
      siteInfo,
      vmsVariables,
      setDeleteScheduleDialogOpen,
      navigate,
      patchObject,
      setSiteInfo,
      setPatchObject,
      setAlertState,
      setSyncNeeded,
      editMode,
      setSchedulesTabActive,
      setClickedTab,
      siteInfoChangesMade,
      setSiteInfoChangesMade,
      scheduleChangesMade,
      setScheduleChangesMade,
      setSiteSchedules,
      setArmedStatus,
      setDeactivateSiteInsteadDialogOpen,
      setArmButtonDisabled,
      recordingServerChangesMade,
      recordingServerData,
      recordingServerPatchObject,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerChangesMade,
      cameraHealthChangesMade,
      healthCheckData,
      setHealthCheckData,
      cameraHealthPatchObject,
      setCameraHealthPatchObject,
      setCameraHealthChangesMade,
      webhooksChangesMade,
      webhooksInfo,
      setWebhooksInfo,
      webhooksPatchObject,
      setWebhooksPatchObject,
      setWebhooksChangesMade,
      setActivateSiteWithScheduleDialogOpen
    );
  };

  const readyAllSchedules = (schedules: any) => {
    const returnSchedules = schedules;

    returnSchedules.forEach((schedule: any, index: number) => {
      if (schedule.start_time !== null) {
        if (schedule.start_time === ":00" || schedule.start_time === " - :00") {
          returnSchedules[index].start_time = null;
        }
      }
      if (schedule.end_time !== null) {
        if (schedule.end_time === ":00" || schedule.end_time === " - :00") {
          returnSchedules[index].end_time = null;
        }
      }
    });

    //remove any schedule object that has a null start_time and end_time and false value for "always_on"
    for (let i = 0; i < returnSchedules.length; i++) {
      if (
        returnSchedules[i].start_time === null &&
        returnSchedules[i].end_time === null &&
        returnSchedules[i].always_on === false
      ) {
        returnSchedules.splice(i, 1);
      }
    }
    return returnSchedules;
  };

  if (localStorage.flexSchedulesOn === "false") {
    const submittedSchedules = readyAllSchedules(siteSchedules[0].schedule);

    if (!submittedSchedules[0]) {
      setDeactivateSiteInsteadDialogOpen(true);
    } else {
      await AxiosUI.post(`schedule/`, submittedSchedules).then(
        () => {
          localStorage.setItem("scheduleChangeError", "false");
          localStorage.setItem(
            "allSiteSchedulesBackup",
            JSON.stringify(allSiteSchedulesBackup)
          );
          setAlertState((previousState: AlertState) => {
            return { ...previousState, noSchedule: false };
          });
          AxiosUI.get(
            `schedule/by_customer/${getNumbersAfterAboutSite(location.href)}/`
          ).then(
            (response: AxiosResponse) => {
              localStorage.setItem("flexSchedulesOn", "false");
              setScheduleChangesMade(false);
              const temporaryUpdatedScheduleData = temporaryUpdateScheduleData(
                response.data
              );
              localStorage.setItem(
                "visibleSchedules",
                JSON.stringify({
                  one: true,
                  two: false,
                  three: false,
                })
              );
              localStorage.setItem(
                "siteSchedules",
                JSON.stringify(temporaryUpdatedScheduleData)
              );
              localStorage.setItem(
                "siteSchedule",
                JSON.stringify(temporaryUpdatedScheduleData)
              );
              setSiteSchedules(temporaryUpdatedScheduleData);
              setAllSiteSchedules(temporaryUpdatedScheduleData);
              localStorage.setItem(
                "allSiteSchedulesBackup",
                JSON.stringify(temporaryUpdatedScheduleData)
              );
              setEditMode(false);
              setBackdropLoadingScreenOn(false);
              setSiteInfo((prev: any) => {
                return {
                  ...prev,
                  has_flex_schedule: false,
                };
              });
              setAlertState((previousState: any) => {
                return {
                  ...previousState,
                  changeSuccess: false,
                  activateSuccess: false,
                  deactivateSuccess: false,
                  disarmSuccess: false,
                  armSuccess: false,
                  syncSuccess: false,
                  cameraDeleteSuccess: false,
                  armFail: false,
                };
              });
              if (!siteInfo.active) {
                setActivateSiteWithScheduleDialogOpen(true);
              }
              if (clickedTab === "one") {
                setActiveTab(
                  <GeneralInfoTab
                    siteInfo={siteInfo}
                    setSiteInfo={setSiteInfo}
                    setActiveTab={setActiveTab}
                  />
                );
              } else if (clickedTab === "two") {
                setActiveTab(
                  <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
                );
              } else if (clickedTab === "three") {
                if (localStorage.scheduleSubmitError === "true") {
                  localStorage.removeItem("scheduleSubmitError");
                  setActiveTab(
                    <SchedulesTab
                      siteSchedules={temporaryUpdatedScheduleData}
                      siteInfo={{ ...siteInfo, has_flex_schedule: false }}
                    />
                  );
                } else {
                  setActiveTab(
                    <SchedulesTab
                      siteSchedules={temporaryUpdatedScheduleData}
                      siteInfo={{ ...siteInfo, has_flex_schedule: false }}
                    />
                  );
                }
              }
            },
            (reason: any) => {
              if (
                reason.request.response.slice(11, 53) ===
                  "SocialToken matching query does not exist." ||
                reason.request.response.includes(
                  "Returned invalid token from cache"
                ) ||
                reason.request.response.slice(11, 53) === "Token is expired"
              ) {
                getRefreshToken(navigate, returnFunction);
              } else {
                setSiteInfo(JSON.parse(localStorage.siteInfo));
                setEditMode(false);
                if (clickedTab === "one") {
                  setActiveTab(
                    <GeneralInfoTab
                      siteInfo={siteInfo}
                      setSiteInfo={setSiteInfo}
                      setActiveTab={setActiveTab}
                    />
                  );
                } else if (clickedTab === "two") {
                  setActiveTab(
                    <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
                  );
                } else if (clickedTab === "three") {
                  setActiveTab(
                    <SchedulesTab
                      siteSchedules={siteSchedules}
                      siteInfo={siteInfo}
                    />
                  );
                }
              }
            }
          );
        },
        (reason: any) => {
          localStorage.setItem("scheduleChangeError", "true");
          localStorage.setItem("scheduleSubmitError", "true");
          setAllSiteSchedules(allSiteSchedulesBackup);
          localStorage.setItem(
            "allSiteSchedules",
            JSON.stringify(allSiteSchedulesBackup)
          );
          if (
            reason.request.response.includes(
              "overlaps with existing enabled schedule"
            )
          ) {
            alert(
              `Schedule Changes not accepted: One or more days on changed schedule overlaps with day(s) from another scheulde at this Site.`
            );
            setEditMode(false);
            setBackdropLoadingScreenOn(false);
            setAllSiteSchedules(allSiteSchedulesBackup);
            if (clickedTab === "one") {
              setActiveTab(
                <GeneralInfoTab
                  siteInfo={siteInfo}
                  setSiteInfo={setSiteInfo}
                  setActiveTab={setActiveTab}
                />
              );
            } else if (clickedTab === "two") {
              setActiveTab(
                <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
              );
            } else if (clickedTab === "three") {
              setActiveTab(
                <SchedulesTab
                  siteSchedules={siteSchedules}
                  siteInfo={siteInfo}
                />
              );
            }
          } else if (
            reason.request.response.slice(11, 53) ===
              "SocialToken matching query does not exist." ||
            reason.request.response.includes(
              "Returned invalid token from cache"
            )
          ) {
            getRefreshToken(navigate, returnFunction);
            setEditMode(false);
            if (clickedTab === "one") {
              setActiveTab(
                <GeneralInfoTab
                  siteInfo={siteInfo}
                  setSiteInfo={setSiteInfo}
                  setActiveTab={setActiveTab}
                />
              );
            } else if (clickedTab === "two") {
              setActiveTab(
                <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
              );
            } else if (clickedTab === "three") {
              setActiveTab(
                <SchedulesTab
                  siteSchedules={siteSchedules}
                  siteInfo={siteInfo}
                />
              );
            }
          } else {
            alert(`Schedule Changes not accepted, please try again`);
            setEditMode(false);
            setBackdropLoadingScreenOn(false);
            setAllSiteSchedules(allSiteSchedulesBackup);
            if (clickedTab === "one") {
              setActiveTab(
                <GeneralInfoTab
                  siteInfo={siteInfo}
                  setSiteInfo={setSiteInfo}
                  setActiveTab={setActiveTab}
                />
              );
            } else if (clickedTab === "two") {
              setActiveTab(
                <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
              );
            } else if (clickedTab === "three") {
              setActiveTab(
                <SchedulesTab
                  siteSchedules={siteSchedules}
                  siteInfo={siteInfo}
                />
              );
            }
          }
        }
      );
    }
  } else {
    const submittedSchedules = siteSchedules;

    siteSchedules.forEach((schedule: any, index: number) => {
      submittedSchedules[index].schedule = readyAllSchedules(schedule.schedule);
    });

    AxiosUI.patch(
      `/flex_schedule/by_customer/${getNumbersAfterAboutSite(location.href)}/`,
      submittedSchedules
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem("flexSchedulesOn", "true");
        localStorage.setItem(
          "backUpFlexSchedule",
          JSON.stringify(siteSchedules)
        );
        setEditMode(false);
        setScheduleChangesMade(false);
        setSiteSchedules(response.data);
        setSiteInfo((prev: any) => {
          return {
            ...prev,
            has_flex_schedule: true,
          };
        });
        setAlertState((previousState: any) => {
          return {
            ...previousState,
            changeSuccess: false,
            activateSuccess: false,
            deactivateSuccess: false,
            disarmSuccess: false,
            armSuccess: false,
            syncSuccess: false,
            cameraDeleteSuccess: false,
            armFail: false,
          };
        });
        setActiveTab(<></>);
        setActiveTab(
          <SchedulesTab
            siteSchedules={response.data}
            siteInfo={{ ...siteInfo, has_flex_schedule: true }}
          />
        );
        setBackdropLoadingScreenOn(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Problem submitting schedule changes.  Pleae try again.");
          setSiteSchedules(JSON.parse(localStorage.backUpFlexSchedule));
          setActiveTab(<></>);
          setTimeout(() => {
            setActiveTab(
              <SchedulesTabEditMode
                siteSchedules={JSON.parse(localStorage.backUpFlexSchedule)}
                siteInfo={siteInfo}
                setScheduleChangesMade={setScheduleChangesMade}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                setActiveTab={setActiveTab}
                setEditMode={setEditMode}
                setSiteSchedules={setSiteSchedules}
                setSiteInfo={setSiteInfo}
              />
            );
            setBackdropLoadingScreenOn(false);
          }, 10);
        });
      }
    );
  }
};
