import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";

export const createDetctionTypeIcon = (detection_type: string) => {
  if (detection_type === "true_threat") {
    return (
      <Tooltip
        title="True Threat"
        placement="top"
        sx={{ fontSize: "14px", fontFamily: "mulish" }}
      >
        <CheckCircleIcon sx={{ color: "#2E7D32" }} />
      </Tooltip>
    );
  } else if (detection_type === "true_non_threat") {
    return (
      <Tooltip
        title="True Non-Threat"
        placement="top"
        sx={{ fontSize: "14px", fontFamily: "mulish" }}
      >
        <CheckCircleIcon sx={{ color: "#FF8400" }} />
      </Tooltip>
    );
  } else if (detection_type === "false_positive") {
    return (
      <Tooltip
        title="False Positive"
        placement="top"
        sx={{ fontSize: "14px", fontFamily: "mulish" }}
      >
        <CancelIcon sx={{ color: "#D32F2F" }} />
      </Tooltip>
    );
  } else if (detection_type === "escalated_true_threat") {
    return (
      <Tooltip
        title="Escalated True Threat"
        placement="top"
        sx={{ fontSize: "14px", fontFamily: "mulish" }}
      >
        <ErrorIcon sx={{ color: "#7d2e72" }} />
      </Tooltip>
    );
  } else return "";
};
