import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AlertData, Order } from "../Alerts/AlertsUtils/alertsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import {
  getComparator,
  stableSort,
} from "../Alerts/AlertsUtils/alertsTableFunctions";
import { EnhancedAlertsTableHeadHM } from "./EnhancedAlertsTableHeadHM";
import { errorTypeCreator } from "./HealthMonitoringUtils/errorTypeCreator";
import { statusTypeCreator } from "./HealthMonitoringUtils/statusTypeCreator";
import { createFormatedData } from "./HealthMonitoringUtils/createFormatedData";
import { NavigateFunction } from "react-router-dom";
import { filterData } from "./HealthMonitoringUtils/filterData";
import { getImageURLS } from "./HealthMonitoringUtils/getImageURLS";
import { Autocomplete, Checkbox, TextField, Tooltip } from "@mui/material";
import { handleBulkResolve } from "./HealthMonitoringUtils/handleBulkResolve";
import { handleRerunHealthCheck } from "./HealthMonitoringUtils/handleRerunHealthCheck";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSearchParams } from "react-router-dom";
import { handleAddOpenAlertSearchParams } from "./HealthMonitoringUtils/handleAddOpenAlertSearchParams";
import { severityTypeIconCreator } from "./HealthMonitoringUtils/severityTypeCreator";

export const AlertsTableHealthMonitoring = ({
  alertsData,
  setAlertStatusDialogOpen,
  setSelectedRow,
  setSelectedRowIndex,
  setFectchingAllData,
  setAlertsData,
  setUnhealthyCamsData,
  unhealthyCamsData,
  clickedTab,
  setActiveTable,
  setSortedData,
  singleCellActivated,
  activeName,
  setUpdatingStatus,
  filters,
  alertFilter,
  setSceneChangeOutcomesDialogOpen,
  setBackgroundFrame,
  setDetectedFrame,
  setSelectedRows,
  selectedRows,
  setErrorsOverviewData,
  setCameraStatsData,
  setLoadingCameraStatsData,
  setBandwidthData,
  setLoadingBandwidthData,
  setShowSiteStats,
  cameraDropDownValue,
  setFilteredDataLengths,
  sceneChangeValueString,
  setBulkSceneChangeOutcomesDialogOpen,
  navigate,
}: {
  alertsData: any;
  setAlertStatusDialogOpen: any;
  setSelectedRow: any;
  setSelectedRowIndex: any;
  setFectchingAllData: Dispatcher<boolean>;
  setAlertsData: any;
  setUnhealthyCamsData: any;
  unhealthyCamsData: any;
  clickedTab: any;
  setActiveTable: any;
  setSortedData: any;
  singleCellActivated: boolean;
  activeName: any;
  setUpdatingStatus: Dispatcher<boolean>;
  filters: any;
  alertFilter: string;
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  setBackgroundFrame: Dispatcher<string>;
  setDetectedFrame: Dispatcher<string>;
  setSelectedRows: any;
  selectedRows: any;
  setErrorsOverviewData: any;
  setCameraStatsData: any;
  setLoadingCameraStatsData: any;
  setBandwidthData: any;
  setLoadingBandwidthData: any;
  setShowSiteStats: any;
  cameraDropDownValue: any;
  setFilteredDataLengths: any;
  sceneChangeValueString: string;
  setBulkSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  const filteredData: any = filterData(
    alertsData,
    activeName,
    alertFilter,
    singleCellActivated
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const activeData: any =
    singleCellActivated || alertFilter !== "all"
      ? filteredData[clickedTab]
      : alertsData[clickedTab];

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);

  const convertToMilitary = (inputString: string, aMPMString: string) => {
    if (aMPMString === "PM" && inputString !== "12") {
      return `${Number(inputString) + 12}`;
    }
    if (
      (aMPMString === "AM" && inputString !== "12") ||
      (aMPMString === "PM" && inputString === "12")
    ) {
      return inputString;
    }
    if (aMPMString === "AM" && inputString === "12") {
      return "00";
    }
  };

  const setIndex = (page: number, rowsPerPage: number, index: number) => {
    return rowsPerPage * page + index;
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AlertData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortingFunction =
    orderBy !== "formatted_start_date" && orderBy !== "formatted_end_date"
      ? stableSort(activeData, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : activeData
          .sort((a: any, b: any) => {
            if (orderBy === "formatted_start_date" && order === "asc") {
              return (
                Number(
                  `${b.formatted_start_date.slice(
                    6,
                    10
                  )}${b.formatted_start_date.slice(
                    3,
                    5
                  )}${b.formatted_start_date.slice(0, 2)}${convertToMilitary(
                    b.formatted_start_date.slice(11, 13),
                    b.formatted_start_date.slice(19, 21)
                  )}${b.formatted_start_date.slice(
                    14,
                    16
                  )}${b.formatted_start_date.slice(17, 19)}`
                ) -
                Number(
                  `${a.formatted_start_date.slice(
                    6,
                    10
                  )}${a.formatted_start_date.slice(
                    3,
                    5
                  )}${a.formatted_start_date.slice(0, 2)}${convertToMilitary(
                    a.formatted_start_date.slice(11, 13),
                    a.formatted_start_date.slice(19, 21)
                  )}${a.formatted_start_date.slice(
                    14,
                    16
                  )}${a.formatted_start_date.slice(17, 19)}`
                )
              );
            } else if (orderBy === "formatted_start_date" && order === "desc") {
              return (
                Number(
                  `${a.formatted_start_date.slice(
                    6,
                    10
                  )}${a.formatted_start_date.slice(
                    3,
                    5
                  )}${a.formatted_start_date.slice(0, 2)}${convertToMilitary(
                    a.formatted_start_date.slice(11, 13),
                    a.formatted_start_date.slice(19, 21)
                  )}${a.formatted_start_date.slice(
                    14,
                    16
                  )}${a.formatted_start_date.slice(17, 19)}`
                ) -
                Number(
                  `${b.formatted_start_date.slice(
                    6,
                    10
                  )}${b.formatted_start_date.slice(
                    3,
                    5
                  )}${b.formatted_start_date.slice(0, 2)}${convertToMilitary(
                    b.formatted_start_date.slice(11, 13),
                    b.formatted_start_date.slice(19, 21)
                  )}${b.formatted_start_date.slice(
                    14,
                    16
                  )}${b.formatted_start_date.slice(17, 19)}`
                )
              );
            } else if (orderBy === "formatted_end_date" && order === "asc") {
              if (b.formatted_end_date && a.formatted_end_date) {
                return (
                  Number(
                    `${b.formatted_end_date.slice(
                      6,
                      10
                    )}${b.formatted_end_date.slice(
                      3,
                      5
                    )}${b.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      b.formatted_end_date.slice(11, 13),
                      b.formatted_end_date.slice(19, 21)
                    )}${b.formatted_end_date.slice(
                      14,
                      16
                    )}${b.formatted_end_date.slice(17, 19)}`
                  ) -
                  Number(
                    `${a.formatted_end_date.slice(
                      6,
                      10
                    )}${a.formatted_end_date.slice(
                      3,
                      5
                    )}${a.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      a.formatted_end_date.slice(11, 13),
                      a.formatted_end_date.slice(19, 21)
                    )}${a.formatted_end_date.slice(
                      14,
                      16
                    )}${a.formatted_start_date.slice(17, 19)}`
                  )
                );
              } else if (b.formatted_end_date && !a.formatted_end_date) {
                return (
                  Number(
                    `${b.formatted_end_date.slice(
                      6,
                      10
                    )}${b.formatted_end_date.slice(
                      3,
                      5
                    )}${b.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      b.formatted_end_date.slice(11, 13),
                      b.formatted_end_date.slice(19, 21)
                    )}${b.formatted_end_date.slice(
                      14,
                      16
                    )}${b.formatted_end_date.slice(17, 19)}`
                  ) - Number(`10000000000000`)
                );
              } else if (!b.formatted_end_date && a.formatted_end_date) {
                return (
                  Number(`10000000000000`) -
                  Number(
                    `${a.formatted_end_date.slice(
                      6,
                      10
                    )}${a.formatted_end_date.slice(
                      3,
                      5
                    )}${a.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      a.formatted_end_date.slice(11, 13),
                      a.formatted_end_date.slice(19, 21)
                    )}${a.formatted_end_date.slice(
                      14,
                      16
                    )}${a.formatted_start_date.slice(17, 19)}`
                  )
                );
              } else {
                return Number(`10000000000000`) - Number(`10000000000000`);
              }
            } else if (orderBy === "formatted_end_date" && order === "desc") {
              if (a.formatted_end_date && b.formatted_end_date) {
                return (
                  Number(
                    `${a.formatted_end_date.slice(
                      6,
                      10
                    )}${a.formatted_end_date.slice(
                      3,
                      5
                    )}${a.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      a.formatted_end_date.slice(11, 13),
                      a.formatted_end_date.slice(19, 21)
                    )}${a.formatted_end_date.slice(
                      14,
                      16
                    )}${a.formatted_end_date.slice(17, 19)}`
                  ) -
                  Number(
                    `${b.formatted_end_date.slice(
                      6,
                      10
                    )}${b.formatted_end_date.slice(
                      3,
                      5
                    )}${b.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      b.formatted_end_date.slice(11, 13),
                      b.formatted_end_date.slice(19, 21)
                    )}${b.formatted_end_date.slice(
                      14,
                      16
                    )}${b.formatted_end_date.slice(17, 19)}`
                  )
                );
              } else if (!a.formatted_end_date && b.formatted_end_date) {
                return (
                  Number(`10000000000000`) -
                  Number(
                    `${b.formatted_end_date.slice(
                      6,
                      10
                    )}${b.formatted_end_date.slice(
                      3,
                      5
                    )}${b.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      b.formatted_end_date.slice(11, 13),
                      b.formatted_end_date.slice(19, 21)
                    )}${b.formatted_end_date.slice(
                      14,
                      16
                    )}${b.formatted_end_date.slice(17, 19)}`
                  )
                );
              } else if (a.formatted_end_date && !b.formatted_end_date) {
                return (
                  Number(
                    `${a.formatted_end_date.slice(
                      6,
                      10
                    )}${a.formatted_end_date.slice(
                      3,
                      5
                    )}${a.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      a.formatted_end_date.slice(11, 13),
                      a.formatted_end_date.slice(19, 21)
                    )}${a.formatted_end_date.slice(
                      14,
                      16
                    )}${a.formatted_end_date.slice(17, 19)}`
                  ) - Number(`10000000000000`)
                );
              } else {
                return Number(`10000000000000`) - Number(`10000000000000`);
              }
            }
          })
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  ``;
  const visibleRows = React.useMemo(
    () => sortingFunction,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage, alertsData]
  );

  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleSingleCheckboxClick = (
    event: React.MouseEvent<unknown>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    ``;
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const [selectedOption, setSelectedOption] = React.useState("");

  const [alertsArray, setAlertsArray] = React.useState<any>([]);

  const dropDown = (
    <div className="pt-4 pl-4">
      <Autocomplete
        size="small"
        disablePortal
        options={["Rerun Health Check", "Move to Pending", "Resolve"]}
        onChange={(event, value: any) => {
          setSelectedOption(value);
          if (!value) {
            setSelectedOption("");
          }
        }}
        sx={{ width: 200 }}
        renderInput={(params) => <TextField {...params} autoComplete="off" />}
      />
    </div>
  );

  const applyButton = (
    <div className="absolute left-[250px] top-[65px]">
      <button
        className={
          selectedOption !== ""
            ? "shadow-button bg-actuate-orange rounded font-mulish text-white text-base	p-1.5"
            : "shadow-button bg-actuate-grey rounded font-mulish text-white text-base	p-1.5"
        }
        onClick={() => {
          if (selectedOption === "Resolve") {
            let sceneChangeIncluded = false;
            alertsArray.forEach((alert: any) => {
              if (alert.error_type === "scene_change") {
                sceneChangeIncluded = true;
              }
            });
            if (sceneChangeIncluded) {
              setBulkSceneChangeOutcomesDialogOpen(true);
            } else {
              handleBulkResolve(
                alertsArray,
                setAlertsData,
                "resolved",
                alertsData,
                setFectchingAllData,
                setUnhealthyCamsData,
                unhealthyCamsData,
                clickedTab,
                setActiveTable,
                setAlertStatusDialogOpen,
                setSelectedRow,
                setSelectedRowIndex,
                setSortedData,
                singleCellActivated,
                activeName,
                setUpdatingStatus,
                filters,
                alertFilter,
                setSceneChangeOutcomesDialogOpen,
                setBackgroundFrame,
                setDetectedFrame,
                setSelectedRows,
                selectedRows,
                setErrorsOverviewData,
                setCameraStatsData,
                setLoadingCameraStatsData,
                setBandwidthData,
                setLoadingBandwidthData,
                setShowSiteStats,
                cameraDropDownValue,
                setFilteredDataLengths,
                sceneChangeValueString,
                setBulkSceneChangeOutcomesDialogOpen,
                navigate
              );
            }
          }
          if (selectedOption === "Move to Pending") {
            handleBulkResolve(
              alertsArray,
              setAlertsData,
              "pending",
              alertsData,
              setFectchingAllData,
              setUnhealthyCamsData,
              unhealthyCamsData,
              clickedTab,
              setActiveTable,
              setAlertStatusDialogOpen,
              setSelectedRow,
              setSelectedRowIndex,
              setSortedData,
              singleCellActivated,
              activeName,
              setUpdatingStatus,
              filters,
              alertFilter,
              setSceneChangeOutcomesDialogOpen,
              setBackgroundFrame,
              setDetectedFrame,
              setSelectedRows,
              selectedRows,
              setErrorsOverviewData,
              setCameraStatsData,
              setLoadingCameraStatsData,
              setBandwidthData,
              setLoadingBandwidthData,
              setShowSiteStats,
              cameraDropDownValue,
              setFilteredDataLengths,
              sceneChangeValueString,
              setBulkSceneChangeOutcomesDialogOpen,
              navigate
            );
          }
          if (selectedOption === "Rerun Health Check") {
            handleRerunHealthCheck(
              alertsArray,
              setAlertsData,
              setFectchingAllData,
              setUnhealthyCamsData,
              unhealthyCamsData,
              clickedTab,
              setActiveTable,
              setAlertStatusDialogOpen,
              setSelectedRow,
              setSelectedRowIndex,
              setSortedData,
              singleCellActivated,
              activeName,
              setUpdatingStatus,
              filters,
              alertFilter,
              setSceneChangeOutcomesDialogOpen,
              setBackgroundFrame,
              setDetectedFrame,
              setSelectedRows,
              selectedRows,
              setErrorsOverviewData,
              setCameraStatsData,
              setLoadingCameraStatsData,
              setBandwidthData,
              setLoadingBandwidthData,
              setShowSiteStats,
              cameraDropDownValue,
              navigate
            );
          }
        }}
        disabled={selectedOption === ""}
      >
        &nbsp; Apply &nbsp;
      </button>
    </div>
  );

  React.useEffect(() => {
    orderBy !== "formatted_start_date" && orderBy !== "formatted_end_date"
      ? setSortedData(stableSort(activeData, getComparator(order, orderBy)))
      : setSortedData(
          activeData.sort((a: any, b: any) => {
            if (orderBy === "formatted_start_date" && order === "asc") {
              return (
                Number(
                  `${b.formatted_start_date.slice(
                    6,
                    10
                  )}${b.formatted_start_date.slice(
                    3,
                    5
                  )}${b.formatted_start_date.slice(0, 2)}${convertToMilitary(
                    b.formatted_start_date.slice(11, 13),
                    b.formatted_start_date.slice(19, 21)
                  )}${b.formatted_start_date.slice(
                    14,
                    16
                  )}${b.formatted_start_date.slice(17, 19)}`
                ) -
                Number(
                  `${a.formatted_start_date.slice(
                    6,
                    10
                  )}${a.formatted_start_date.slice(
                    3,
                    5
                  )}${a.formatted_start_date.slice(0, 2)}${convertToMilitary(
                    a.formatted_start_date.slice(11, 13),
                    a.formatted_start_date.slice(19, 21)
                  )}${a.formatted_start_date.slice(
                    14,
                    16
                  )}${a.formatted_start_date.slice(17, 19)}`
                )
              );
            } else if (orderBy === "formatted_start_date" && order === "desc") {
              return (
                Number(
                  `${a.formatted_start_date.slice(
                    6,
                    10
                  )}${a.formatted_start_date.slice(
                    3,
                    5
                  )}${a.formatted_start_date.slice(0, 2)}${convertToMilitary(
                    a.formatted_start_date.slice(11, 13),
                    a.formatted_start_date.slice(19, 21)
                  )}${a.formatted_start_date.slice(
                    14,
                    16
                  )}${a.formatted_start_date.slice(17, 19)}`
                ) -
                Number(
                  `${b.formatted_start_date.slice(
                    6,
                    10
                  )}${b.formatted_start_date.slice(
                    3,
                    5
                  )}${b.formatted_start_date.slice(0, 2)}${convertToMilitary(
                    b.formatted_start_date.slice(11, 13),
                    b.formatted_start_date.slice(19, 21)
                  )}${b.formatted_start_date.slice(
                    14,
                    16
                  )}${b.formatted_start_date.slice(17, 19)}`
                )
              );
            } else if (orderBy === "formatted_end_date" && order === "asc") {
              if (b.formatted_end_date && a.formatted_end_date) {
                return (
                  Number(
                    `${b.formatted_end_date.slice(
                      6,
                      10
                    )}${b.formatted_end_date.slice(
                      3,
                      5
                    )}${b.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      b.formatted_end_date.slice(11, 13),
                      b.formatted_end_date.slice(19, 21)
                    )}${b.formatted_end_date.slice(
                      14,
                      16
                    )}${b.formatted_end_date.slice(17, 19)}`
                  ) -
                  Number(
                    `${a.formatted_end_date.slice(
                      6,
                      10
                    )}${a.formatted_end_date.slice(
                      3,
                      5
                    )}${a.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      a.formatted_end_date.slice(11, 13),
                      a.formatted_end_date.slice(19, 21)
                    )}${a.formatted_end_date.slice(
                      14,
                      16
                    )}${a.formatted_start_date.slice(17, 19)}`
                  )
                );
              } else if (b.formatted_end_date && !a.formatted_end_date) {
                return (
                  Number(
                    `${b.formatted_end_date.slice(
                      6,
                      10
                    )}${b.formatted_end_date.slice(
                      3,
                      5
                    )}${b.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      b.formatted_end_date.slice(11, 13),
                      b.formatted_end_date.slice(19, 21)
                    )}${b.formatted_end_date.slice(
                      14,
                      16
                    )}${b.formatted_end_date.slice(17, 19)}`
                  ) - Number(`10000000000000`)
                );
              } else if (!b.formatted_end_date && a.formatted_end_date) {
                return (
                  Number(`10000000000000`) -
                  Number(
                    `${a.formatted_end_date.slice(
                      6,
                      10
                    )}${a.formatted_end_date.slice(
                      3,
                      5
                    )}${a.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      a.formatted_end_date.slice(11, 13),
                      a.formatted_end_date.slice(19, 21)
                    )}${a.formatted_end_date.slice(
                      14,
                      16
                    )}${a.formatted_start_date.slice(17, 19)}`
                  )
                );
              } else {
                return Number(`10000000000000`) - Number(`10000000000000`);
              }
            } else if (orderBy === "formatted_end_date" && order === "desc") {
              if (a.formatted_end_date && b.formatted_end_date) {
                return (
                  Number(
                    `${a.formatted_end_date.slice(
                      6,
                      10
                    )}${a.formatted_end_date.slice(
                      3,
                      5
                    )}${a.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      a.formatted_end_date.slice(11, 13),
                      a.formatted_end_date.slice(19, 21)
                    )}${a.formatted_end_date.slice(
                      14,
                      16
                    )}${a.formatted_end_date.slice(17, 19)}`
                  ) -
                  Number(
                    `${b.formatted_end_date.slice(
                      6,
                      10
                    )}${b.formatted_end_date.slice(
                      3,
                      5
                    )}${b.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      b.formatted_end_date.slice(11, 13),
                      b.formatted_end_date.slice(19, 21)
                    )}${b.formatted_end_date.slice(
                      14,
                      16
                    )}${b.formatted_end_date.slice(17, 19)}`
                  )
                );
              } else if (!a.formatted_end_date && b.formatted_end_date) {
                return (
                  Number(`10000000000000`) -
                  Number(
                    `${b.formatted_end_date.slice(
                      6,
                      10
                    )}${b.formatted_end_date.slice(
                      3,
                      5
                    )}${b.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      b.formatted_end_date.slice(11, 13),
                      b.formatted_end_date.slice(19, 21)
                    )}${b.formatted_end_date.slice(
                      14,
                      16
                    )}${b.formatted_end_date.slice(17, 19)}`
                  )
                );
              } else if (a.formatted_end_date && !b.formatted_end_date) {
                return (
                  Number(
                    `${a.formatted_end_date.slice(
                      6,
                      10
                    )}${a.formatted_end_date.slice(
                      3,
                      5
                    )}${a.formatted_end_date.slice(0, 2)}${convertToMilitary(
                      a.formatted_end_date.slice(11, 13),
                      a.formatted_end_date.slice(19, 21)
                    )}${a.formatted_end_date.slice(
                      14,
                      16
                    )}${a.formatted_end_date.slice(17, 19)}`
                  ) - Number(`10000000000000`)
                );
              } else {
                return Number(`10000000000000`) - Number(`10000000000000`);
              }
            }
          })
        );
  }, [order, orderBy, page, rowsPerPage, alertsData]);

  React.useEffect(() => {
    localStorage.setItem("selectedCHMAlertsArray", JSON.stringify(alertsArray));
  }, [alertsArray]);

  return (
    <Paper square>
      {selected[0] && dropDown}
      {selected[0] && applyButton}
      <EnhancedAlertsTableHeadHM
        onRequestSort={handleRequestSort}
        orderBy={orderBy}
        order={order}
        activeData={activeData}
        setSelected={setSelected}
        setAlertsArray={setAlertsArray}
      />
      <TableContainer sx={{ height: 233 }}>
        <Table
          sx={{ minWidth: 700, fontFamily: "Mulish", overflow: "scroll" }}
          aria-labelledby="tableTitle"
        >
          <TableBody>
            {visibleRows.map((row: any, index: number) => {
              const isItemSelected = isSelected(row.start_timestamp);
              return (
                <TableRow hover key={index} tabIndex={-1}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onClick={(event: any) => {
                        handleSingleCheckboxClick(event, row.start_timestamp);
                        if (event.target.checked === true) {
                          const updatedAlertsArray = [...alertsArray, row];
                          setAlertsArray(updatedAlertsArray);
                          localStorage.setItem(
                            "selectedCHMAlertsArray",
                            JSON.stringify(updatedAlertsArray)
                          );
                        } else {
                          setAlertsArray(
                            alertsArray.filter(
                              (obj: any) =>
                                obj.start_timestamp !== row.start_timestamp
                            )
                          );
                          localStorage.setItem(
                            "selectedCHMAlertsArray",
                            JSON.stringify(
                              alertsArray.filter(
                                (obj: any) =>
                                  obj.start_timestamp !== row.start_timestamp
                              )
                            )
                          );
                        }
                      }}
                      // checked={selectedRows.includes(row.start_timestamp)}
                      // onClick={(event: any) => {
                      //   if (event.target.checked) {
                      //     setSelectedRows((previousState: any) => {
                      //       return [...previousState, Number(row.start_timestamp)];
                      //     });
                      //   } else {
                      //     setSelectedRows((previousState: any) => {
                      //       return removeNumberFromArray(
                      //         Number(row.start_timestamp),
                      //         previousState
                      //       );
                      //     });
                      //   }
                      // }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontFamily: "mulish",
                      width: 190,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (row.error_type === "scene_change") {
                        setBackgroundFrame("");
                        setDetectedFrame("");
                        getImageURLS(
                          row,
                          setBackgroundFrame,
                          setDetectedFrame,
                          navigate
                        );
                      }
                      setAlertStatusDialogOpen(true);
                      setSelectedRow(row);

                      handleAddOpenAlertSearchParams(
                        row,
                        searchParams,
                        setSearchParams
                      );

                      setSelectedRowIndex(setIndex(page, rowsPerPage, index));
                    }}
                  >
                    {row.site_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontFamily: "mulish",
                      width: 208,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (row.error_type === "scene_change") {
                        setBackgroundFrame("");
                        setDetectedFrame("");
                        getImageURLS(
                          row,
                          setBackgroundFrame,
                          setDetectedFrame,
                          navigate
                        );
                      }
                      setAlertStatusDialogOpen(true);
                      setSelectedRow(row);

                      handleAddOpenAlertSearchParams(
                        row,
                        searchParams,
                        setSearchParams
                      );

                      setSelectedRowIndex(setIndex(page, rowsPerPage, index));
                    }}
                  >
                    {row.camera_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontFamily: "mulish",
                      width: 208,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (row.error_type === "scene_change") {
                        setBackgroundFrame("");
                        setDetectedFrame("");
                        getImageURLS(
                          row,
                          setBackgroundFrame,
                          setDetectedFrame,
                          navigate
                        );
                      }
                      setAlertStatusDialogOpen(true);
                      setSelectedRow(row);

                      handleAddOpenAlertSearchParams(
                        row,
                        searchParams,
                        setSearchParams
                      );

                      setSelectedRowIndex(setIndex(page, rowsPerPage, index));
                    }}
                  >
                    <>
                      {createFormatedData(row.formatted_start_date)}
                      <Tooltip
                        title={row.timezone}
                        placement="top"
                        sx={{ fontSize: "15px", fontFamily: "mulish" }}
                      >
                        <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                      </Tooltip>
                    </>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontFamily: "mulish",
                      width: 208,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (row.error_type === "scene_change") {
                        setBackgroundFrame("");
                        setDetectedFrame("");
                        getImageURLS(
                          row,
                          setBackgroundFrame,
                          setDetectedFrame,
                          navigate
                        );
                      }
                      setAlertStatusDialogOpen(true);
                      setSelectedRow(row);
                      handleAddOpenAlertSearchParams(
                        row,
                        searchParams,
                        setSearchParams
                      );

                      setSelectedRowIndex(setIndex(page, rowsPerPage, index));
                    }}
                  >
                    {row.formatted_end_date ? (
                      <>
                        {createFormatedData(row.formatted_end_date)}

                        <Tooltip
                          title={row.timezone}
                          placement="top"
                          sx={{ fontSize: "15px", fontFamily: "mulish" }}
                        >
                          <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                        </Tooltip>
                      </>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontFamily: "mulish",
                      width: 208,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (row.error_type === "scene_change") {
                        setBackgroundFrame("");
                        setDetectedFrame("");
                        getImageURLS(
                          row,
                          setBackgroundFrame,
                          setDetectedFrame,
                          navigate
                        );
                      }
                      setAlertStatusDialogOpen(true);
                      setSelectedRow(row);
                      handleAddOpenAlertSearchParams(
                        row,
                        searchParams,
                        setSearchParams
                      );
                      setSelectedRowIndex(setIndex(page, rowsPerPage, index));
                    }}
                  >
                    {errorTypeCreator(row.error_type)}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontFamily: "mulish",
                      width: 208,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (row.error_type === "scene_change") {
                        setBackgroundFrame("");
                        setDetectedFrame("");
                        getImageURLS(
                          row,
                          setBackgroundFrame,
                          setDetectedFrame,
                          navigate
                        );
                      }
                      setAlertStatusDialogOpen(true);
                      setSelectedRow(row);
                      handleAddOpenAlertSearchParams(
                        row,
                        searchParams,
                        setSearchParams
                      );
                      setSelectedRowIndex(setIndex(page, rowsPerPage, index));
                    }}
                  >
                    {row.severity ? severityTypeIconCreator(row.severity) : ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontFamily: "mulish",
                      width: 208,
                    }}
                  >
                    {statusTypeCreator(
                      row.status,
                      row,
                      setFectchingAllData,
                      setAlertsData,
                      alertsData,
                      setUnhealthyCamsData,
                      unhealthyCamsData,
                      clickedTab,
                      setActiveTable,
                      setAlertStatusDialogOpen,
                      setSelectedRow,
                      setSelectedRowIndex,
                      setSortedData,
                      singleCellActivated,
                      activeName,
                      setUpdatingStatus,
                      filters,
                      alertFilter,
                      setSceneChangeOutcomesDialogOpen,
                      setBackgroundFrame,
                      setDetectedFrame,
                      setSelectedRows,
                      selectedRows,
                      setErrorsOverviewData,
                      setCameraStatsData,
                      setLoadingCameraStatsData,
                      setBandwidthData,
                      setLoadingBandwidthData,
                      setShowSiteStats,
                      cameraDropDownValue,
                      setFilteredDataLengths,
                      sceneChangeValueString,
                      setBulkSceneChangeOutcomesDialogOpen,
                      navigate
                    )}{" "}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={activeData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
