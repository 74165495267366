import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "./siteAboutInterfaces";
import { getRefreshToken } from "../../../../utils/getRefreshToken";
import { SchedulesTab } from "../SchedulesTab";
import { NavigateFunction } from "react-router-dom";
import { getNumbersAfterAboutSite } from "../SiteAbout";
import { temporaryUpdateScheduleData } from "./temporaryUpdateScheduleData";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";

export const handleSubmitDetailedScheduleChanges = (
  postObject: any,
  setActiveTab: any,
  setEditMode: Dispatcher<boolean>,
  setSiteSchedules: Dispatcher<any>,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  siteInfo: any,
  setDeleteScheduleDialogOpen: Dispatcher<boolean>,
  setSiteInfo: Dispatcher<any>,
  siteSchedules: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);
  const allSiteSchedulesBackup = JSON.parse(
    localStorage.allSiteSchedulesBackup
  );

  const returnFunction = () => {
    handleSubmitDetailedScheduleChanges(
      postObject,
      setActiveTab,
      setEditMode,
      setSiteSchedules,
      setBackdropLoadingScreenOn,
      siteInfo,
      setDeleteScheduleDialogOpen,
      setSiteInfo,
      siteSchedules,
      navigate
    );
  };

  setActiveTab(<></>);
  setEditMode(false);

  postObject.forEach((schedule: any, index: number) => {
    if (schedule.start_time === "") {
      postObject[index].start_time = null;
    }
    if (schedule.end_time === "") {
      postObject[index].end_time = null;
    }
  });

  if (localStorage.flexSchedulesOn === "false") {
    AxiosUI.post(`schedule/`, postObject).then(
      () => {
        localStorage.setItem("scheduleChangeError", "false");
        localStorage.setItem(
          "allSiteSchedulesBackup",
          JSON.stringify(allSiteSchedulesBackup)
        );
        AxiosUI.get(
          `schedule/by_customer/${getNumbersAfterAboutSite(location.href)}/`
        ).then(
          (response: AxiosResponse) => {
            localStorage.setItem("flexSchedulesOn", "false");
            localStorage.setItem(
              "visibleSchedules",
              JSON.stringify({
                one: true,
                two: false,
                three: false,
              })
            );
            const temporaryUpdatedScheduleData = temporaryUpdateScheduleData(
              response.data
            );
            localStorage.setItem(
              "siteSchedules",
              JSON.stringify(temporaryUpdatedScheduleData)
            );
            localStorage.setItem(
              "siteSchedule",
              JSON.stringify(temporaryUpdatedScheduleData)
            );
            setSiteSchedules(temporaryUpdatedScheduleData);
            localStorage.setItem(
              "allSiteSchedulesBackup",
              JSON.stringify(temporaryUpdatedScheduleData)
            );
            // setAllSiteSchedules(response.data);
            // localStorage.setItem(
            //   "allSiteSchedulesBackup",
            //   JSON.stringify(response.data)
            // );

            setBackdropLoadingScreenOn(false);
            if (localStorage.scheduleSubmitError === "true") {
              localStorage.removeItem("scheduleSubmitError");
              setActiveTab(
                <SchedulesTab
                  siteSchedules={temporaryUpdatedScheduleData}
                  siteInfo={siteInfo}
                />
              );
            } else {
              setActiveTab(
                <SchedulesTab
                  siteSchedules={temporaryUpdatedScheduleData}
                  siteInfo={siteInfo}
                />
              );
            }
          },
          (reason: AxiosError) => {
            if (
              reason.request.response.slice(11, 53) ===
                "SocialToken matching query does not exist." ||
              reason.request.response.includes(
                "Returned invalid token from cache"
              ) ||
              reason.request.response.slice(11, 53) === "Token is expired"
            ) {
              getRefreshToken(navigate, returnFunction);
            } else {
              setSiteInfo(JSON.parse(localStorage.siteInfo));
              setEditMode(false);
              setActiveTab(
                <SchedulesTab
                  siteSchedules={siteSchedules}
                  siteInfo={siteInfo}
                />
              );
            }
          }
        );
      },
      (reason: AxiosError) => {
        localStorage.setItem("scheduleChangeError", "true");
        localStorage.setItem("scheduleSubmitError", "true");
        // setAllSiteSchedules(allSiteSchedulesBackup);
        localStorage.setItem(
          "allSiteSchedules",
          JSON.stringify(allSiteSchedulesBackup)
        );
        if (
          reason.request.response.includes(
            "overlaps with existing enabled schedule"
          )
        ) {
          alert(
            `Schedule Changes not accepted: One or more days on changed schedule overlaps with day(s) from another scheulde at this Site.`
          );
          setEditMode(false);
          setBackdropLoadingScreenOn(false);
          // setAllSiteSchedules(allSiteSchedulesBackup);
          setActiveTab(
            <SchedulesTab siteSchedules={siteSchedules} siteInfo={siteInfo} />
          );
        } else if (
          reason.request.response.slice(11, 53) ===
            "SocialToken matching query does not exist." ||
          reason.request.response.includes(
            "Returned invalid token from cache"
          ) ||
          reason.request.response.includes === "Token not found"
        ) {
          getRefreshToken(navigate, returnFunction);
          setActiveTab(
            <SchedulesTab siteSchedules={siteSchedules} siteInfo={siteInfo} />
          );
        } else {
          alert(`Schedule Changes not accepted, please try again`);
          setEditMode(false);
          setBackdropLoadingScreenOn(false);
          // setAllSiteSchedules(allSiteSchedulesBackup);
          setActiveTab(
            <SchedulesTab siteSchedules={siteSchedules} siteInfo={siteInfo} />
          );
        }
      }
    );
  } else {
    const postObjectFlex = siteSchedules;

    postObjectFlex[Number(localStorage.schedulesIndex)].schedule = postObject;

    AxiosUI.patch(
      `/flex_schedule/by_customer/${getNumbersAfterAboutSite(location.href)}/`,
      postObjectFlex
    ).then(
      (response: AxiosResponse) => {
        setSiteSchedules(response.data);
        localStorage.setItem(
          "backUpFlexSchedule",
          JSON.stringify(response.data)
        );
        setActiveTab(
          <SchedulesTab siteSchedules={response.data} siteInfo={siteInfo} />
        );
        setBackdropLoadingScreenOn(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, returnFunction, () => {
          alert("Problem submitting schedule changes.  Pleae try again.");
          setActiveTab(
            <SchedulesTab
              siteSchedules={JSON.parse(localStorage.backUpFlexSchedule)}
              siteInfo={siteInfo}
            />
          );
          setBackdropLoadingScreenOn(false);
        });
      }
    );
  }
};
