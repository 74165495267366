import React from "react";

export const handleUpdateSiteSchedules = (
  timeRanges: any,
  duskToDawnLocation: any,
  matchingSchedules: any,
  setSiteSchedules: any,
  setEditModeTabSiteSchedules: any
) => {
  const previousSiteSchedules: any = [];

  setSiteSchedules((prev: any) => {
    previousSiteSchedules.push(...prev);
    return prev;
  });

  if (
    previousSiteSchedules.length === 0 &&
    !window.location.pathname.includes("addschedule")
  ) {
    return;
  }

  const schedulesIndex = Number(localStorage.schedulesIndex);

  const returnArray: any[] = [];

  const addCameraInfo = JSON.parse(localStorage.addCameraInfo);

  let duskToDawnStartTime: any;

  let duskToDawnEndTime: any;

  if (!window.location.pathname.includes("addschedule")) {
    const currentSchedule = previousSiteSchedules[schedulesIndex].schedule;

    currentSchedule.forEach((schedule: any) => {
      if (schedule.location_dusk_dawn) {
        duskToDawnStartTime = schedule.start_time;
        duskToDawnEndTime = schedule.end_time;
      }
    });
  }

  matchingSchedules.forEach((array: any) => {
    const alwaysOn = timeRanges[array[0]] === "Always On";
    const duskToDawn = duskToDawnLocation[array[0]][0];
    const bufferTime = duskToDawnLocation[array[0]][1];
    const endTime =
      timeRanges[array[0]] === "Always On"
        ? //   ||
          // timeRanges[array[0]] === "Dusk to Dawn"
          "23:59:59"
        : timeRanges[array[0]] === "Dusk to Dawn"
        ? duskToDawnEndTime
        : `${timeRanges[array[0]].slice(8, 13)}:00`;
    const startTime =
      timeRanges[array[0]] === "Always On"
        ? //   ||
          // timeRanges[array[0]] === "Dusk to Dawn"
          "00:00:00"
        : timeRanges[array[0]] === "Dusk to Dawn"
        ? duskToDawnStartTime
        : `${timeRanges[array[0]].slice(0, 5)}:00`;

    returnArray.push({
      always_on: alwaysOn,
      buffer_time: bufferTime ? bufferTime : 0,
      customer: localStorage.siteId,
      day_of_week: array,
      enabled: true,
      end_time: endTime === "24:00:00" ? "23:59:59" : endTime,
      is_overide: false,
      location_dusk_dawn: duskToDawn,
      start_time: startTime,
    });
  });

  window.location.pathname.includes("addschedule")
    ? setSiteSchedules(returnArray)
    : setSiteSchedules((prev: any) => {
        setEditModeTabSiteSchedules((prev: any) => {
          const newSchedules = [...prev];
          newSchedules[schedulesIndex].schedule = returnArray;
          if (newSchedules[schedulesIndex].product.length === 0) {
            newSchedules[schedulesIndex].product = [
              ...addCameraInfo.initial_data.products,
            ];
          }
          return newSchedules;
        });
        const newSchedules = [...prev];
        newSchedules[schedulesIndex].schedule = returnArray;
        if (newSchedules[schedulesIndex].product.length === 0) {
          newSchedules[schedulesIndex].product = [
            ...addCameraInfo.initial_data.products,
          ];
        }
        return newSchedules;
      });
};
