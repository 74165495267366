import { id } from "date-fns/locale";
import React from "react";

export const handleCreateSelectedSchedulesArrays = (
  selectedValueObject: any,
  siteSchedules: any
) => {
  const selectedSchedulesIds: number[] = [];

  const selectedSchedulesNames: string[] = [];

  if (selectedValueObject.one) {
    selectedSchedulesIds.push(siteSchedules[0].id);
    selectedSchedulesNames.push(siteSchedules[0].display_name);
  }

  if (selectedValueObject.two) {
    selectedSchedulesIds.push(siteSchedules[1].id);
    selectedSchedulesNames.push(siteSchedules[1].display_name);
  }

  if (selectedValueObject.three) {
    selectedSchedulesIds.push(siteSchedules[2].id);
    selectedSchedulesNames.push(siteSchedules[2].display_name);
  }

  return {
    ids: selectedSchedulesIds,
    names: selectedSchedulesNames,
  };
};
