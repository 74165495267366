// /* eslint-disable react-hooks/exhaustive-deps */
// import React from "react";
// import { NavBar } from "../../common/NavBar";
// import { ParentGroupsDropDown } from "./ParentGroupsDropDown";
// import { SiteDropDown } from "./SiteDropDown";
// import { CameraDropDown } from "./CameraDropDown";
// import { StartDateDropDown } from "./StartDateDropDown";
// import { EndDateDropDown } from "./EndDateDropDown";
// import { SVGLine } from "./SVGLine";
// import { DownloadButton } from "./DownloadButton";
// import Checkbox from "@mui/material/Checkbox";
// import { AlertsByDateGraph } from "./AlertsByDateGraph";
// import {
//   AllAlertsData,
//   ParentGroupsAlertData,
//   UserFilters,
// } from "./AnalyticsUtils/analyticsInterfaces";
// import { getAllAlertsData } from "./AnalyticsUtils/getAllAlertsData";
// import { useNavigate } from "react-router-dom";
// import { yyyymmddGenerator } from "./AnalyticsUtils/yyyymmddGenerator";
// import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
// import { getParentGroupsAlertData } from "./AnalyticsUtils/getParentGroupsAlertData";
// import { AlertsByParentGroupsGraph } from "./AlertsByParentGroupsGraph";
// import { FalsePositiveReductionGraphAnalytics } from "./FalsePositiveReductionGraphAnalytics";
// import { getFalsePositiveDataAnalyticsPage } from "./AnalyticsUtils/getFalsePositiveDataAnalyticsPage";
// import { PercentageofMotionFilteredGraph } from "./PercentageofMotionFilteredGraph";
// import { getPercentageMotionFilteredData } from "./AnalyticsUtils/getPercentageMotionFilteredData";
// import { handleNavigatedFromSitesPage } from "./AnalyticsUtils/handleNavigatedFromSitesPage";
// import { AnalyticsAlerts } from "./AnalyticsAlerts";
// import { getSitesByParentGroup } from "./AnalyticsUtils/getSitesByParentGroup";
// import { getCamerasBySite } from "./AnalyticsUtils/getCamerasBySite";
// import { getDatesFromDefault } from "./AnalyticsUtils/getDatesFromDefault";

// export const AnalyticsMirror = () => {
//   const [loadingData, setLoadingData] = React.useState({
//     allAlertsData: true,
//     parentGroupsData: true,
//     falsePositiveData: true,
//     percetageOfMotionData: true,
//   });

//   const navigate = useNavigate();

//   const startDate = new Date();
//   startDate.setDate(startDate.getDate() - 5);

//   const endDate = new Date();

//   const [siteDropDownValue, setSiteDropDownValue] = React.useState<any[]>(
//     localStorage.analyticsSiteName ? [localStorage.analyticsSiteName] : []
//   );

//   const [cameraDropDownValue, setCameraDropDownValue] = React.useState<any[]>(
//     []
//   );

//   const [allAlertsData, setAllAlertsData] = React.useState<AllAlertsData[]>([]);

//   const [siteOptions, setSiteOptions] = React.useState<any[]>([]);

//   const [cameraOptions, setCameraOptions] = React.useState<any[]>([]);

//   const [pageLoading, setPageLoading] = React.useState(false);

//   const [visibleArray, setVisibleArray] = React.useState<any[]>([]);

//   const [conversionArray, setConversionArray] = React.useState<any>([]);

//   const [showMoreVisible, setShowMoreVisible] = React.useState(false);

//   const [showMoreOpen, setShowMoreOpen] = React.useState(false);

//   const [parentGroupsAlertsData, setParentGroupsAlertsData] = React.useState<
//     ParentGroupsAlertData[]
//   >([]);

//   const [displayOnLeftBool, setDisplayOnLeftBool] = React.useState(false);

//   const [userFilters, setUserFilters] = React.useState<UserFilters>({
//     parent_group: "",
//     site: [],
//     camera: [],
//     start_date: startDate,
//     end_date: endDate,
//   });

//   const [alertGraphName, setAlertsGraphName] = React.useState("Parent Group");

//   const [falsePostiveData, setFalsePostiveData] = React.useState(
//     JSON.parse(localStorage.falsePostiveData)
//   );

//   const [percetageOfMotionData, setPercetageOfMotionData] = React.useState(
//     JSON.parse(localStorage.percetageOfMotionData)
//   );

//   const [averagePercentageOfMotion, setAveragePercentageOfMotion] =
//     React.useState<number>(NaN);

//   const [alertState, setAlertState] = React.useState({
//     dateTooFar: false,
//     startAfterEnd: false,
//     endBeforeStart: false,
//   });

//   const [filtersSavedClicked, setFiltersSavedClicked] = React.useState<boolean>(
//     localStorage.filtersSavedClicked === "true"
//   );

//   React.useEffect(() => {
//     if (userFilters.parent_group === "") {
//       setAlertsGraphName("Parent Group");
//     }
//     if (userFilters.parent_group) {
//       setAlertsGraphName("Site");
//     }
//     if (userFilters.site[0]) {
//       setAlertsGraphName("Camera");
//     }
//   }, [userFilters]);

//   React.useEffect(() => {
//     if (
//       localStorage.navigatedToAnalyticsFromSitesPage === "false" &&
//       localStorage.filtersSavedClicked === "false"
//     ) {
//       getAllAlertsData(
//         setAllAlertsData,
//         "",
//         yyyymmddGenerator(startDate),
//         yyyymmddGenerator(endDate),
//         navigate,
//         setLoadingData
//       );
//       getParentGroupsAlertData(
//         setParentGroupsAlertsData,
//         "",
//         yyyymmddGenerator(startDate),
//         yyyymmddGenerator(endDate),
//         [],
//         navigate,
//         setLoadingData,
//         setDisplayOnLeftBool,
//         setVisibleArray,
//         setConversionArray,
//         setShowMoreVisible,
//         setShowMoreOpen
//       );
//       getFalsePositiveDataAnalyticsPage(
//         setFalsePostiveData,
//         navigate,
//         setLoadingData,
//         yyyymmddGenerator(startDate),
//         yyyymmddGenerator(endDate),
//         [],
//         [],
//         userFilters.parent_group
//       );
//       getPercentageMotionFilteredData(
//         setPercetageOfMotionData,
//         setAveragePercentageOfMotion,
//         navigate,
//         setLoadingData,
//         yyyymmddGenerator(startDate),
//         yyyymmddGenerator(endDate),
//         [],
//         [],
//         userFilters.parent_group
//       );
//     } else if (localStorage.navigatedToAnalyticsFromSitesPage === "true") {
//       setAlertsGraphName("Camera");
//       handleNavigatedFromSitesPage(
//         setLoadingData,
//         navigate,
//         startDate,
//         endDate,
//         setAllAlertsData,
//         setSiteOptions,
//         setCameraOptions,
//         setPageLoading,
//         setParentGroupsAlertsData,
//         setUserFilters,
//         setFalsePostiveData,
//         setPercetageOfMotionData,
//         setAveragePercentageOfMotion,
//         localStorage.analyticsParentGroupId,
//         localStorage.analyticsSiteId,
//         setDisplayOnLeftBool,
//         setAlertsGraphName,
//         setVisibleArray,
//         setConversionArray,
//         setShowMoreVisible,
//         setShowMoreOpen
//       );
//     }
//   }, []);

//   // React.useEffect(
//   //   () => () => {
//   //     console.log("UNMOUNTING");
//   //     setUserFilters({
//   //       parent_group: "",
//   //       site: [],
//   //       camera: [],
//   //       start_date: startDate,
//   //       end_date: endDate,
//   //     })
//   //     localStorage.setItem("analyticsSiteName", "");
//   //     localStorage.setItem("analyticsParentGroupId", "");
//   //     localStorage.setItem("navigatedToAnalyticsFromSitesPage", "false");
//   //   },
//   //   []
//   // );

//   React.useEffect(() => {
//     localStorage.setItem("analyticsSiteName", "");
//   }, []);

//   React.useEffect(() => {
//     if (
//       localStorage.navigatedToAnalyticsFromSitesPage === "false" &&
//       localStorage.filtersSavedClicked === "true"
//     ) {
//       setSiteDropDownValue(JSON.parse(localStorage.siteDropDownValues));
//       setCameraDropDownValue(JSON.parse(localStorage.cameraDropDownValues));
//       setUserFilters(JSON.parse(localStorage.defaultUserFilters));
//       const tempUserFilters = JSON.parse(localStorage.defaultUserFilters);
//       const start_date = tempUserFilters.start_date.slice(0, 10);
//       const end_date = tempUserFilters.end_date.slice(0, 10);
//       getDatesFromDefault(start_date, setUserFilters, "start");
//       getDatesFromDefault(end_date, setUserFilters, "end");
//       setUserFilters((previousState: any) => {
//         return {
//           ...previousState,
//           parent_group: tempUserFilters.parent_group,
//           site: tempUserFilters.site,
//           camera: tempUserFilters.camera,
//         };
//       });
//       setPageLoading(true);
//       setLoadingData((previousState) => {
//         return {
//           ...previousState,
//           parentGroupsData: true,
//           falsePositiveData: true,
//           percetageOfMotionData: true,
//         };
//       });
//       getAllAlertsData(
//         setAllAlertsData,
//         "",
//         start_date,
//         end_date,
//         navigate,
//         setLoadingData
//       );
//       getSitesByParentGroup(
//         tempUserFilters.parent_group,
//         navigate,
//         setSiteOptions,
//         setPageLoading,
//         setAlertsGraphName
//       );
//       getCamerasBySite(
//         tempUserFilters.site,
//         navigate,
//         setCameraOptions,
//         setPageLoading
//       );
//       getParentGroupsAlertData(
//         setParentGroupsAlertsData,
//         tempUserFilters.parent_group,
//         start_date,
//         end_date,
//         tempUserFilters.site,
//         navigate,
//         setLoadingData,
//         setDisplayOnLeftBool,
//         setVisibleArray,
//         setConversionArray,
//         setShowMoreVisible,
//         setShowMoreOpen
//       );
//       getFalsePositiveDataAnalyticsPage(
//         setFalsePostiveData,
//         navigate,
//         setLoadingData,
//         start_date,
//         end_date,
//         tempUserFilters.site,
//         tempUserFilters.camera,
//         tempUserFilters.parent_group
//       );
//       getPercentageMotionFilteredData(
//         setPercetageOfMotionData,
//         setAveragePercentageOfMotion,
//         navigate,
//         setLoadingData,
//         start_date,
//         end_date,
//         tempUserFilters.site,
//         tempUserFilters.camera,
//         tempUserFilters.parent_group
//       );
//     }
//   }, []);

//   return (
//     <div>
//       <AnalyticsAlerts alertState={alertState} setAlertState={setAlertState} />
//       <BackdropLoadingScreen openState={pageLoading} />
//       <div className="bg-actuate-grey absolute w-full h-full overflow-scroll">
//         <div className="absolute left-[150px] right-[1px] h-full bg-white overflow-scroll">
//           <div className="font-mulish absolute left-[20px] top-[20px]">
//             <ParentGroupsDropDown
//               userFilters={userFilters}
//               setUserFilters={setUserFilters}
//               navigate={navigate}
//               setSiteOptions={setSiteOptions}
//               setPageLoading={setPageLoading}
//               setParentGroupsAlertsData={setParentGroupsAlertsData}
//               setLoadingData={setLoadingData}
//               setFalsePostiveData={setFalsePostiveData}
//               setPercetageOfMotionData={setPercetageOfMotionData}
//               setSiteDropDownValue={setSiteDropDownValue}
//               setCameraDropDownValue={setCameraDropDownValue}
//               setAveragePercentageOfMotion={setAveragePercentageOfMotion}
//               setAllAlertsData={setAllAlertsData}
//               setDisplayOnLeftBool={setDisplayOnLeftBool}
//               setAlertsGraphName={setAlertsGraphName}
//               setVisibleArray={setVisibleArray}
//               setConversionArray={setConversionArray}
//               setShowMoreVisible={setShowMoreVisible}
//               setShowMoreOpen={setShowMoreOpen}
//               setCameraOptions={setCameraOptions}
//             />
//           </div>
//           <div className="font-mulish relative left-[230px] top-[20px] max-w-[800px] max-h-full">
//             <div className="relative top-0 left-0 w-[722px] columns-2">
//               <div>
//                 {" "}
//                 <SiteDropDown
//                   setUserFilters={setUserFilters}
//                   siteOptions={siteOptions}
//                   setCameraOptions={setCameraOptions}
//                   setPageLoading={setPageLoading}
//                   navigate={navigate}
//                   setFalsePostiveData={setFalsePostiveData}
//                   setLoadingData={setLoadingData}
//                   userFilters={userFilters}
//                   setPercetageOfMotionData={setPercetageOfMotionData}
//                   siteDropDownValue={siteDropDownValue}
//                   setSiteDropDownValue={setSiteDropDownValue}
//                   setCameraDropDownValue={setCameraDropDownValue}
//                   setAveragePercentageOfMotion={setAveragePercentageOfMotion}
//                   setParentGroupsAlertsData={setParentGroupsAlertsData}
//                   setDisplayOnLeftBool={setDisplayOnLeftBool}
//                   setVisibleArray={setVisibleArray}
//                   setConversionArray={setConversionArray}
//                   setShowMoreVisible={setShowMoreVisible}
//                   setShowMoreOpen={setShowMoreOpen}
//                 />
//               </div>
//               <div>
//                 <CameraDropDown
//                   userFilters={userFilters}
//                   setUserFilters={setUserFilters}
//                   cameraOptions={cameraOptions}
//                   setLoadingData={setLoadingData}
//                   setFalsePostiveData={setFalsePostiveData}
//                   setPercetageOfMotionData={setPercetageOfMotionData}
//                   navigate={navigate}
//                   cameraDropDownValue={cameraDropDownValue}
//                   setCameraDropDownValue={setCameraDropDownValue}
//                   setAveragePercentageOfMotion={setAveragePercentageOfMotion}
//                   setShowMoreVisible={setShowMoreVisible}
//                   setShowMoreOpen={setShowMoreOpen}
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="font-mulish absolute left-[960px] top-[20px]">
//             <StartDateDropDown
//               userFilters={userFilters}
//               setUserFilters={setUserFilters}
//               setAllAlertsData={setAllAlertsData}
//               navigate={navigate}
//               setLoadingData={setLoadingData}
//               setParentGroupsAlertsData={setParentGroupsAlertsData}
//               setFalsePostiveData={setFalsePostiveData}
//               setPercetageOfMotionData={setPercetageOfMotionData}
//               setAveragePercentageOfMotion={setAveragePercentageOfMotion}
//               setAlertState={setAlertState}
//               setDisplayOnLeftBool={setDisplayOnLeftBool}
//               setVisibleArray={setVisibleArray}
//               setConversionArray={setConversionArray}
//               setShowMoreVisible={setShowMoreVisible}
//               setShowMoreOpen={setShowMoreOpen}
//             />
//           </div>
//           <div className="font-mulish absolute left-[1120px] top-[20px]">
//             <EndDateDropDown
//               userFilters={userFilters}
//               setUserFilters={setUserFilters}
//               setAllAlertsData={setAllAlertsData}
//               navigate={navigate}
//               setLoadingData={setLoadingData}
//               setParentGroupsAlertsData={setParentGroupsAlertsData}
//               setFalsePostiveData={setFalsePostiveData}
//               setPercetageOfMotionData={setPercetageOfMotionData}
//               setAveragePercentageOfMotion={setAveragePercentageOfMotion}
//               setAlertState={setAlertState}
//               setDisplayOnLeftBool={setDisplayOnLeftBool}
//               setVisibleArray={setVisibleArray}
//               setConversionArray={setConversionArray}
//               setShowMoreVisible={setShowMoreVisible}
//               setShowMoreOpen={setShowMoreOpen}
//             />
//           </div>

//           <div className="font-mulish relative left-[20px] top-[25px]">
//             <Checkbox
//               checked={filtersSavedClicked}
//               onChange={(event: any, result: boolean) => {
//                 if (result === true) {
//                   setFiltersSavedClicked(true);
//                   localStorage.setItem("filtersSavedClicked", "true");
//                   localStorage.setItem(
//                     "defaultUserFilters",
//                     JSON.stringify(userFilters)
//                   );
//                   localStorage.setItem(
//                     "siteDropDownValues",
//                     JSON.stringify(siteDropDownValue)
//                   );
//                   localStorage.setItem(
//                     "cameraDropDownValues",
//                     JSON.stringify(cameraDropDownValue)
//                   );
//                 } else {
//                   setFiltersSavedClicked(false);
//                   localStorage.setItem("filtersSavedClicked", "false");
//                 }
//               }}
//             />
//             &nbsp; Set these filters as default configuration.
//             <div className="font-mulish absolute left-[1140px] top-[40px]">
//             <DownloadButton
//               userFilters={userFilters}
//               navigate={navigate}
//               setPageLoading={setPageLoading}
//             />
//           </div>
//           </div>
//           <div className="relative">
//             <SVGLine />
//             <AlertsByDateGraph
//               allAlertsData={allAlertsData}
//               loadingData={loadingData}
//             />
//             <AlertsByParentGroupsGraph
//               parentGroupsAlertsData={parentGroupsAlertsData}
//               loadingData={loadingData}
//               setDisplayOnLeftBool={setDisplayOnLeftBool}
//               alertGraphName={alertGraphName}
//               setPageLoading={setPageLoading}
//               navigate={navigate}
//               visibleArray={visibleArray}
//               setVisibleArray={setVisibleArray}
//               conversionArray={conversionArray}
//               showMoreVisible={showMoreVisible}
//               showMoreOpen={showMoreOpen}
//               setShowMoreOpen={setShowMoreOpen}
//             />
//             <FalsePositiveReductionGraphAnalytics
//               falsePostiveData={falsePostiveData}
//               loadingData={loadingData}
//             />
//             <PercentageofMotionFilteredGraph
//               percetageOfMotionData={percetageOfMotionData}
//               loadingData={loadingData}
//               displayOnLeftBool={displayOnLeftBool}
//             />
//             {displayOnLeftBool ? (
//               <div className="absolute top-[1270px] left-[20px] h-10 text-actuate-blue font-mulish text-[14px] font-bold">
//                 {averagePercentageOfMotion
//                   ? `Average reduction in False Positives for selected range is ${Number(
//                       averagePercentageOfMotion.toFixed(2)
//                     )} %`
//                   : ``}
//               </div>
//             ) : (
//               <div className="absolute top-[870px] left-[660px] h-10  text-actuate-blue font-mulish text-[14px] font-bold">
//                 {averagePercentageOfMotion
//                   ? `Average reduction in False Positives for selected range is ${Number(
//                       averagePercentageOfMotion.toFixed(2)
//                     )} %`
//                   : ``}
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//       <div className="justify-start">
//         <NavBar />
//       </div>
//     </div>
//   );
// };
