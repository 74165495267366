import React from "react";

export const handleCreateSelectedProducts = (
  productsData: any,
  setSelectedGroups: any,
  selectedGroups: any,
  setUpdatingSelectedGroups: any
) => {
  const updatedSelectedGroups: any = selectedGroups;

  if (productsData.length !== 0) {
    productsData.forEach((product: any) => {
      const metrics: any = product.metrics;
      for (const key in metrics) {
        if (key === "intruder") {
          updatedSelectedGroups.intruder.intruder = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.intruder.selected = true;
          }
        }
        if (key === "bike") {
          updatedSelectedGroups.intruder.bike = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.intruder.selected = true;
          }
        }
        if (key === "vehicle") {
          updatedSelectedGroups.intruder.vehicle = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.intruder.selected = true;
          }
        }
        if (key === "intruder_plus") {
          updatedSelectedGroups.intruderPlus.intruder_plus =
            metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.intruderPlus.selected = true;
          }
        }
        if (key === "bike_plus") {
          updatedSelectedGroups.intruderPlus.bike_plus = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.intruderPlus.selected = true;
          }
        }
        if (key === "vehicle_plus") {
          updatedSelectedGroups.intruderPlus.vehicle_plus = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.intruderPlus.selected = true;
          }
        }
        if (key === "intruder_motion") {
          updatedSelectedGroups.motionPlus.intruder_motion =
            metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.motionPlus.selected = true;
          }
        }
        if (key === "bike_motion") {
          updatedSelectedGroups.motionPlus.bike_motion = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.motionPlus.selected = true;
          }
        }
        if (key === "vehicle_motion") {
          updatedSelectedGroups.motionPlus.vehicle_motion = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.motionPlus.selected = true;
          }
        }
        if (key === "gun") {
          updatedSelectedGroups.gun.gun = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.gun.selected = true;
          }
        }
        if (key === "pistol") {
          updatedSelectedGroups.gun.pistol = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.gun.selected = true;
          }
        }
        if (key === "crowd") {
          updatedSelectedGroups.crowd.crowd = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.crowd.selected = true;
          }
        }
        if (key === "loiterer") {
          updatedSelectedGroups.loitering.loiterer = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.loitering.selected = true;
          }
        }
        if (key === "vehicle_loiterer") {
          updatedSelectedGroups.loitering.vehicle_loiterer =
            metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.loitering.selected = true;
          }
        }
        if (key === "fall_person") {
          updatedSelectedGroups.fallPerson.fall_person = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.fallPerson.selected = true;
          }
        }
        if (key === "no_hard_hat") {
          updatedSelectedGroups.noHardHat.noHardHat = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.noHardHat.selected = true;
          }
        }
        if (key === "mask") {
          updatedSelectedGroups.mask.mask = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.mask.selected = true;
          }
        }
        if (key === "mask_compliance") {
          updatedSelectedGroups.mask.mask_compliance = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.mask.selected = true;
          }
        }
        if (key === "no_mask") {
          updatedSelectedGroups.mask.no_mask = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.mask.selected = true;
          }
        }
        if (key === "social_distancing") {
          updatedSelectedGroups.socialDistancing.social_distancing =
            metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.socialDistancing.selected = true;
          }
        }
        if (key === "left_object") {
          updatedSelectedGroups.leftObject.left_object = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.leftObject.selected = true;
          }
        }
        if (key === "package") {
          updatedSelectedGroups.package.package = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.package.selected = true;
          }
        }
        if (key === "fire") {
          updatedSelectedGroups.fire.fire = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.fire.selected = true;
          }
        }
        if (key === "smoke") {
          updatedSelectedGroups.fire.smoke = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.fire.selected = true;
          }
        }
        if (key === "people_flow") {
          updatedSelectedGroups.peopleFlow.people_flow = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.peopleFlow.selected = true;
          }
        }
        if (key === "fall") {
          updatedSelectedGroups.fall.fall = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.fall.selected = true;
          }
        }
        if (key === "amazon") {
          updatedSelectedGroups.vehicleLogoId.amazon = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.vehicleLogoId.selected = true;
          }
        }
        if (key === "dhl") {
          updatedSelectedGroups.vehicleLogoId.dhl = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.vehicleLogoId.selected = true;
          }
        }
        if (key === "fedex") {
          updatedSelectedGroups.vehicleLogoId.fedex = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.vehicleLogoId.selected = true;
          }
        }
        if (key === "school_bus") {
          updatedSelectedGroups.vehicleLogoId.school_bus = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.vehicleLogoId.selected = true;
          }
        }
        if (key === "ups") {
          updatedSelectedGroups.vehicleLogoId.ups = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.vehicleLogoId.selected = true;
          }
        }
        if (key === "usps") {
          updatedSelectedGroups.vehicleLogoId.usps = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.vehicleLogoId.selected = true;
          }
        }
        if (key === "envera") {
          updatedSelectedGroups.vehicleLogoId.envera = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.vehicleLogoId.selected = true;
          }
        }
        if (key === "fire_truck") {
          updatedSelectedGroups.vehicleLogoId.fire_truck = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.vehicleLogoId.selected = true;
          }
        }
        if (key === "non_ups") {
          updatedSelectedGroups.nonUps.non_ups = metrics[key].active;
          if (metrics[key].active) {
            updatedSelectedGroups.nonUps.selected = true;
          }
        }
      }
    });
  }

  setSelectedGroups(updatedSelectedGroups);
  if (setUpdatingSelectedGroups) {
    setUpdatingSelectedGroups(false);
  }
};
