import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import {
  AlertsDataLabel,
  AllAlertsData,
  LoadingData,
} from "./AnalyticsUtils/analyticsInterfaces";
import { CircularProgress } from "@mui/material";
import { colorsArray } from "./AnalyticsUtils/colorsArray";
import { siteGraphStringReturner } from "./AnalyticsUtils/siteGraphStringReturner";
import { createColor } from "./AnalyticsUtils/createColor";

export const AlertsByDateGraph = ({
  allAlertsData,
  loadingData,
  dateGraphName,
  navBarCollapsed,
}: {
  allAlertsData: AllAlertsData[];
  loadingData: LoadingData;
  dateGraphName: string;
  navBarCollapsed: boolean;
}) => {
  const labelNames: string[] = [];
  const labelsAndColorsArray: { label: AlertsDataLabel }[] = [];
  const labelDisplayNames: string[] = [];
  let labelStringLength = 0;

  allAlertsData.forEach((object: AllAlertsData) => {
    if (object.label && object.label.display_name) {
      if (!labelDisplayNames.includes(object.label.display_name)) {
        labelDisplayNames.push(object.label.display_name);
        labelStringLength += object.label.display_name.length;
      }
    }
    if (!labelsAndColorsArray[0]) {
      labelNames.push(object.label.name);
      labelsAndColorsArray.push({
        label: object.label,
      });
    }
    labelsAndColorsArray.forEach(() => {
      if (!labelNames.includes(object.label.name)) {
        labelsAndColorsArray.push({
          label: object.label,
        });
        labelNames.push(object.label.name);
      }
    });
  });

  let bottomLength = 36;

  if (labelStringLength > 47 && labelStringLength < 95) {
    bottomLength = 56;
  }

  if (labelStringLength > 94) {
    bottomLength = 76;
  }

  const conversionArray: {
    [x: string]: string | number;
    date: string;
  }[] = [];
  allAlertsData.forEach((object) => {
    if (!conversionArray[0]) {
      const pushedObject = {
        date: object.date,
        [object.label.name]: object.alert_volume,
      };
      conversionArray.push(pushedObject);
    }
    let dateExists = false;
    conversionArray.forEach(
      (
        obj: {
          [x: string]: string | number;
          date: string;
        },
        index: number
      ) => {
        if (object.date === obj.date) {
          dateExists = true;
          conversionArray[index][object.label.name] = object.alert_volume;
        }
        if (index === conversionArray.length - 1 && !dateExists) {
          conversionArray.push({
            date: object.date,
            [object.label.name]: object.alert_volume,
          });
        }
      }
    );
  });

  return (
    <div
      className={
        navBarCollapsed
          ? "absolute top-[50px] left-[20px] right-[1px] h-[425px] w-[652px] bg-[#BDBDBD]"
          : "absolute top-[50px] left-[20px] right-[1px] h-[425px] w-[610px] bg-[#BDBDBD]"
      }
    >
      <div
        className={
          navBarCollapsed
            ? "absolute top-[1px] left-[1px] right-[1px] h-[423px] w-[650px] bg-white"
            : "absolute top-[1px] left-[1px] right-[1px] h-[423px] w-[608px] bg-white"
        }
      >
        <p className="text-actuate-blue font-mulish text-[22px] font-bold absolute top-[8px] left-[40px]">
          Alerts by Date
        </p>
        <p className="text-actuate-blue font-mulish text-[14px] absolute top-[36px] left-[40px]">
          {siteGraphStringReturner(dateGraphName)}
        </p>
        {!loadingData.allAlertsData && (
          <div className="absolute top-[60px] left-[0px]">
            <BarChart
              width={!navBarCollapsed ? 610 : 652}
              height={365}
              {...(conversionArray.length < 4 && { barSize: 120 })}
              data={conversionArray}
              margin={{
                top: 5,
                right: 30,
                left: 5,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis allowDecimals={false} />
              {conversionArray[0] && <Tooltip />}
              <Legend height={bottomLength} />
              {labelsAndColorsArray.map((object, index: number) => {
                return (
                  <Bar
                    key={object.label.name}
                    dataKey={object.label.name}
                    stackId="a"
                    fill={createColor(object.label.name, object.label.color)}
                    //captialize the name
                    name={
                      object.label.display_name.charAt(0).toUpperCase() +
                      object.label.display_name.slice(1)
                    }
                  />
                );
              })}
            </BarChart>
          </div>
        )}

        {loadingData.allAlertsData && (
          <div className="absolute top-[170px] left-[230px]">
            <CircularProgress
              size={25}
              sx={{ position: "relative", left: 40 }}
            />
            <div className="font-mulish text-actuate-blue test-bold">
              Fetching Data...
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
