import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Tooltip } from "@mui/material";
import Link from "@mui/material/Link";

export const GroupBreadcrumbs = ({
  siteBreadcrumbs,
  setSelectedIndex,
  setPageLoading,
  getIndividualGroup,
  setRenderedRows,
  setSiteBreadcrumbs,
  setSelected,
  setEditableGroupSelected,
  setSelectedGroupListName,
  navigate,
}: {
  siteBreadcrumbs: any;
  setSelectedIndex: any;
  setPageLoading: any;
  getIndividualGroup: any;
  setRenderedRows: any;
  setSiteBreadcrumbs: any;
  setSelected: any;
  setEditableGroupSelected: any;
  setSelectedGroupListName: any;
  navigate: any;
}) => {
  return (
    <Stack spacing={2}>
      <div>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="medium" />}
          aria-label="breadcrumb"
        >
          {siteBreadcrumbs.map((object: any, index: any) => {
            if (index === 0 && siteBreadcrumbs.length > 1) {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  href="/sites"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                  onClick={(event: any) => {
                    event.preventDefault();
                    setSelectedIndex({
                      level1: [object.id],
                      level2: [],
                      level3: [],
                      level4: [],
                      level5: [],
                      level6: [],
                    });
                    setPageLoading(true);
                    localStorage.setItem("getIndividualGroupId", object.id);
                    getIndividualGroup(
                      object.id,
                      setRenderedRows,
                      setPageLoading,
                      setSiteBreadcrumbs,
                      setSelected,
                      setEditableGroupSelected,
                      setSelectedGroupListName,
                      navigate
                    );
                  }}
                >
                  {object.name}
                </Link>
              );
            } else if (index === 1 && siteBreadcrumbs.length > 2) {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  href="#"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                  onClick={() => {
                    setSelectedIndex({
                      level1: [siteBreadcrumbs[0].id],
                      level2: [object.id],
                      level3: [],
                      level4: [],
                      level5: [],
                      level6: [],
                    });
                    setPageLoading(true);
                    localStorage.setItem("getIndividualGroupId", object.id);
                    getIndividualGroup(
                      object.id,
                      setRenderedRows,
                      setPageLoading,
                      setSiteBreadcrumbs,
                      setSelected,
                      setEditableGroupSelected,
                      setSelectedGroupListName,
                      navigate
                    );
                  }}
                >
                  {object.name}
                </Link>
              );
            } else if (index === 2 && siteBreadcrumbs.length > 3) {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  href="#"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                  onClick={() => {
                    setSelectedIndex({
                      level1: [siteBreadcrumbs[0].id],
                      level2: [siteBreadcrumbs[1].id],
                      level3: [object.id],
                      level4: [],
                      level5: [],
                      level6: [],
                    });
                    setPageLoading(true);
                    localStorage.setItem("getIndividualGroupId", object.id);
                    getIndividualGroup(
                      object.id,
                      setRenderedRows,
                      setPageLoading,
                      setSiteBreadcrumbs,
                      setSelected,
                      setEditableGroupSelected,
                      setSelectedGroupListName,
                      navigate
                    );
                  }}
                >
                  {object.name}
                </Link>
              );
            } else if (index === 3 && siteBreadcrumbs.length > 4) {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  href="#"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                  onClick={() => {
                    setSelectedIndex({
                      level1: [siteBreadcrumbs[0].id],
                      level2: [siteBreadcrumbs[1].id],
                      level3: [siteBreadcrumbs[2].id],
                      level4: [object.id],
                      level5: [],
                      level6: [],
                    });
                    setPageLoading(true);
                    localStorage.setItem("getIndividualGroupId", object.id);
                    getIndividualGroup(
                      object.id,
                      setRenderedRows,
                      setPageLoading,
                      setSiteBreadcrumbs,
                      setSelected,
                      setEditableGroupSelected,
                      setSelectedGroupListName,
                      navigate
                    );
                  }}
                >
                  {object.name}
                </Link>
              );
            } else if (index === 4 && siteBreadcrumbs.length > 5) {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  href="#"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                  onClick={() => {
                    setSelectedIndex({
                      level1: [siteBreadcrumbs[0].id],
                      level2: [siteBreadcrumbs[1].id],
                      level3: [siteBreadcrumbs[2].id],
                      level4: [siteBreadcrumbs[3].id],
                      level5: [object.id],
                      level6: [],
                    });
                    setPageLoading(true);
                    localStorage.setItem("getIndividualGroupId", object.id);
                    getIndividualGroup(
                      object.id,
                      setRenderedRows,
                      setPageLoading,
                      setSiteBreadcrumbs,
                      setSelected,
                      setEditableGroupSelected,
                      setSelectedGroupListName,
                      navigate
                    );
                  }}
                >
                  {object.name}
                </Link>
              );
            } else if (index === 5 && siteBreadcrumbs.length > 6) {
              return (
                <Link
                  underline="hover"
                  key={index}
                  color="inherit"
                  href="#"
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Mulish",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                  onClick={() => {
                    setSelectedIndex({
                      level1: [siteBreadcrumbs[0].id],
                      level2: [siteBreadcrumbs[1].id],
                      level3: [siteBreadcrumbs[2].id],
                      level4: [siteBreadcrumbs[3].id],
                      level5: [siteBreadcrumbs[4].id],
                      level6: [object.id],
                    });
                    setPageLoading(true);
                    localStorage.setItem("getIndividualGroupId", object.id);
                    getIndividualGroup(
                      object.id,
                      setRenderedRows,
                      setPageLoading,
                      setSiteBreadcrumbs,
                      setSelected,
                      setEditableGroupSelected,
                      setSelectedGroupListName,
                      navigate
                    );
                  }}
                >
                  {object.name}
                </Link>
              );
            } else
              return (
                <Typography
                  key={index}
                  color="text.primary"
                  fontSize="14px"
                  fontFamily="Mulish"
                  fontWeight="bold"
                >
                  {object.name}
                </Typography>
              );
          })}
        </Breadcrumbs>
      </div>
    </Stack>
  );
};
