import React from "react";
import { ParentGroupsDropDownHM } from "./ParentGroupsDropDownHM";
import { SiteDropDownHM } from "./SiteDropDownHM";
import { CameraDropDownHM } from "./CameraDropDownHM";
import { StartDateDropDownHM } from "./StartDateDropDownHM";
import { EndDateDropDownHM } from "./EndDateDropDownHM";
import { MetricsDropDown } from "./MetricsDropDown";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const FiltersHealthMonitoring = ({
  userFilters,
  setUserFilters,
  setSiteOptions,
  setSiteDropDownValue,
  setCameraOptions,
  setCameraDropDownValue,
  siteOptions,
  siteDropDownValue,
  loadingSites,
  setLoadingCameras,
  setLoadingSites,
  cameraOptions,
  cameraDropDownValue,
  loadingCameras,
  navBarCollapsed,
  metricsSelected,
  setMetricsSelected,
  navigate,
}: {
  userFilters: any;
  setUserFilters: any;
  setSiteOptions: any;
  setSiteDropDownValue: any;
  setCameraOptions: any;
  setCameraDropDownValue: any;
  siteOptions: any;
  siteDropDownValue: any;
  loadingSites: any;
  setLoadingCameras: any;
  setLoadingSites: any;
  cameraOptions: any;
  cameraDropDownValue: any;
  loadingCameras: boolean;
  navBarCollapsed: boolean;
  metricsSelected: string[];
  setMetricsSelected: Dispatcher<string[]>;
  navigate: any;
}) => {
  return (
    <div
      className={
        !navBarCollapsed ? "columns-5 w-[1090px]" : "columns-5 w-[1170px]"
      }
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto auto auto auto auto",
      }}
    >
      <div>
        <ParentGroupsDropDownHM
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          setSiteOptions={setSiteOptions}
          setSiteDropDownValue={setSiteDropDownValue}
          setCameraOptions={setCameraOptions}
          setCameraDropDownValue={setCameraDropDownValue}
          setLoadingSites={setLoadingSites}
          navBarCollapsed={navBarCollapsed}
          navigate={navigate}
        />
      </div>
      <div>
        <SiteDropDownHM
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          siteOptions={siteOptions}
          setCameraOptions={setCameraOptions}
          setCameraDropDownValue={setCameraDropDownValue}
          siteDropDownValue={siteDropDownValue}
          setSiteDropDownValue={setSiteDropDownValue}
          loadingSites={loadingSites}
          setLoadingCameras={setLoadingCameras}
          navBarCollapsed={navBarCollapsed}
          navigate={navigate}
        />
      </div>
      <div>
        <CameraDropDownHM
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          cameraOptions={cameraOptions}
          cameraDropDownValue={cameraDropDownValue}
          setCameraDropDownValue={setCameraDropDownValue}
          siteOptions={siteOptions}
          loadingCameras={loadingCameras}
          navBarCollapsed={navBarCollapsed}
        />
      </div>
      <div>
        <MetricsDropDown
          navBarCollapsed={navBarCollapsed}
          metricsSelected={metricsSelected}
          setMetricsSelected={setMetricsSelected}
        />
      </div>

      <div className="h-[50px]">
        <StartDateDropDownHM
          userFilters={userFilters}
          setUserFilters={setUserFilters}
        />
      </div>
      <div className="h-[50px]">
        <EndDateDropDownHM
          userFilters={userFilters}
          setUserFilters={setUserFilters}
        />
      </div>
    </div>
  );
};
