/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../common/TableHead";
import Switch from "@mui/material/Switch";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";
import { switchCreator } from "./EditCameraUtils/switchCreator";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleProductMasterSwitchEvent } from "./EditCameraUtils/handleProductMasterSwitchEvent";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleCheckForDisabledProducts } from "./EditCameraUtils/handleCheckForDisabledProducts";
import { handleCreateSelectedProducts } from "./EditCameraUtils/handleCreateSelectedProducts";
import Tooltip from "@mui/material/Tooltip";
import { createIntruderToggle } from "./EditCameraUtils/createIntruderToggle";

export const ProductsTabEditMode = ({
  activeProducts,
  setPatchObject,
  setGeneralInfoChangesMade,
  cameraGeneralInfo,
  productsData,
  setProductsData,
  allTabsOpen,
  setAllTabsOpen,
  setBackupProductsData,
}: {
  activeProducts: any;
  setPatchObject: any;
  setGeneralInfoChangesMade: any;
  cameraGeneralInfo: any;
  productsData: any;
  setProductsData: Dispatcher<any>;
  allTabsOpen: boolean;
  setAllTabsOpen: Dispatcher<boolean>;
  setBackupProductsData: any;
}) => {
  const handlePatchThresh = (
    value: any,
    groupLabelName: string,
    object: any,
    optionNames: any
  ) => {
    setLocalProductsData((previousState: any) => {
      const updatedProducts = [...previousState];
      const productIndex = updatedProducts.findIndex(
        (product: any) => product.label_name === groupLabelName
      );
      const updatedProduct = updatedProducts[productIndex];
      updatedProduct.metrics[object.label_name].option_id =
        optionNames.get(value);
      updatedProducts[productIndex] = updatedProduct;
      return updatedProducts;
    });
    setProductsData((previousState: any) => {
      const updatedProducts = [...previousState];
      const productIndex = updatedProducts.findIndex(
        (product: any) => product.label_name === groupLabelName
      );
      const updatedProduct = updatedProducts[productIndex];
      updatedProduct.metrics[object.label_name].option_id =
        optionNames.get(value);
      updatedProducts[productIndex] = updatedProduct;

      const streamMetricsPatchArray: any[] = [];

      updatedProducts.forEach((product: any) => {
        const metrics: any = product.metrics;
        for (const key in metrics) {
          streamMetricsPatchArray.push(metrics[key]);
        }
      });

      setPatchObject((previousState: any) => {
        const newPatchObject = { ...previousState };
        newPatchObject.streams[0].stream_metrics = streamMetricsPatchArray;
        return newPatchObject;
      });

      return updatedProducts;
    });
  };

  const handlePatchDistance = (
    value: any,
    groupLabelName: string,
    object: any,
    optionNames: any
  ) => {
    setLocalProductsData((previousState: any) => {
      const updatedProducts = [...previousState];
      const productIndex = updatedProducts.findIndex(
        (product: any) => product.label_name === groupLabelName
      );
      const updatedProduct = updatedProducts[productIndex];
      updatedProduct.metrics[object.label_name].distance =
        optionNames.get(value);
      updatedProducts[productIndex] = updatedProduct;
      return updatedProducts;
    });
    setProductsData((previousState: any) => {
      const updatedProducts = [...previousState];
      const productIndex = updatedProducts.findIndex(
        (product: any) => product.label_name === groupLabelName
      );
      const updatedProduct = updatedProducts[productIndex];
      updatedProduct.metrics[object.label_name].distance =
        optionNames.get(value);
      updatedProducts[productIndex] = updatedProduct;

      const streamMetricsPatchArray: any[] = [];

      updatedProducts.forEach((product: any) => {
        const metrics: any = product.metrics;
        for (const key in metrics) {
          streamMetricsPatchArray.push(metrics[key]);
        }
      });

      setPatchObject((previousState: any) => {
        const newPatchObject = { ...previousState };
        newPatchObject.streams[0].stream_metrics = streamMetricsPatchArray;
        return newPatchObject;
      });

      return updatedProducts;
    });
  };

  const handleExtraFieldInput = (
    value: any,
    groupLabelName: string,
    object: any
  ) => {
    setLocalProductsData((previousState: any) => {
      const updatedProducts = [...previousState];
      const productIndex = updatedProducts.findIndex(
        (product: any) => product.label_name === groupLabelName
      );
      const updatedProduct = updatedProducts[productIndex];
      updatedProduct.metrics[object.label_name].user_input_value = value;
      updatedProducts[productIndex] = updatedProduct;
      return updatedProducts;
    });
    setProductsData((previousState: any) => {
      const updatedProducts = [...previousState];
      const productIndex = updatedProducts.findIndex(
        (product: any) => product.label_name === groupLabelName
      );
      const updatedProduct = updatedProducts[productIndex];
      updatedProduct.metrics[object.label_name].user_input_value = value;
      updatedProducts[productIndex] = updatedProduct;

      const streamMetricsPatchArray: any[] = [];

      updatedProducts.forEach((product: any) => {
        const metrics: any = product.metrics;
        for (const key in metrics) {
          streamMetricsPatchArray.push(metrics[key]);
        }
      });

      setPatchObject((previousState: any) => {
        const newPatchObject = { ...previousState };
        newPatchObject.streams[0].stream_metrics = streamMetricsPatchArray;
        return newPatchObject;
      });

      return updatedProducts;
    });
  };

  const [tabsOpen, setTabsOpen] = React.useState(allTabsOpen);

  const [localProductsData, setLocalProductsData] =
    React.useState(productsData);

  const [selectedGroups, setSelectedGroups] = React.useState<any>({
    intruder: {
      selected: false,
      disabled: false,
      intruder: false,
      bike: false,
      vehicle: false,
    },
    intruderPlus: {
      selected: false,
      disabled: false,
      intruder_plus: false,
      bike_plus: false,
      vehicle_plus: false,
    },
    motionPlus: {
      selected: false,
      intruder_motion: false,
      vehicle_motion: false,
      bike_motion: false,
    },
    gun: {
      selected: false,
      gun: false,
      pistol: false,
    },
    crowd: {
      selected: false,
      crowd: false,
    },
    loitering: {
      selected: false,
      loiterer: false,
      vehicle_loiterer: false,
    },
    fallPerson: {
      selected: false,
      fall_person: false,
    },
    noHardHat: {
      selected: false,
      no_hard_hat: false,
    },
    mask: {
      selected: false,
      mask: false,
      mask_compliance: false,
      no_mask: false,
    },
    socialDistancing: {
      selected: false,
      social_distancing: false,
    },
    leftObject: {
      selected: false,
      left_object: false,
    },
    package: {
      selected: false,
      package: false,
    },
    fire: {
      selected: false,
      fire: false,
      smoke: false,
    },
    peopleFlow: {
      selected: false,
      people_flow: false,
    },
    fall: {
      selected: false,
      fall: false,
    },
    vehicleLogoId: {
      selected: false,
      amazon: false,
      dhl: false,
      fedex: false,
      school_bus: false,
      ups: false,
      usps: false,
      envera: false,
      fire_truck: false,
    },
    nonUps: {
      selected: false,
      non_ups: false,
    },
  });

  const [updatingSelectedGroups, setUpdatingSelectedGroups] =
    React.useState(true);

  React.useEffect(() => {
    if (productsData.length !== 0) {
      handleCheckForDisabledProducts(productsData, setSelectedGroups);
    }
    localStorage.setItem("backupProductsData", JSON.stringify(productsData));
    handleCreateSelectedProducts(
      productsData,
      setSelectedGroups,
      selectedGroups,
      setUpdatingSelectedGroups
    );
    setPatchObject((previousState: any) => {
      const newPatchObject = { ...previousState };
      if (cameraGeneralInfo.streams.length > 0) {
        newPatchObject.streams = [cameraGeneralInfo.streams[0]];
      } else {
        newPatchObject.streams = [{}];
      }
      // if (newPatchObject.streams[0]) {
      newPatchObject.streams[0].stream_metrics = [];
      // }
      return newPatchObject;
    });
  }, []);

  return (
    <div>
      {!updatingSelectedGroups && (
        <div>
          <div
            className={"relative right-[1px] h-[38px] bg-white min-w-[1250px]"}
          >
            <div className="absolute right-[50px] top-[5px]">
              {!tabsOpen ? (
                <OrangeTextButton
                  onClick={() => {
                    const newTesterData = [...localProductsData];
                    newTesterData.forEach((object: any) => {
                      object.open = true;
                    });
                    setProductsData(newTesterData);
                    setLocalProductsData(newTesterData);
                    setAllTabsOpen(true);
                    setTabsOpen(true);
                  }}
                  text="Open All"
                  disabled={false}
                />
              ) : (
                <OrangeTextButton
                  onClick={() => {
                    const newTesterData = [...localProductsData];
                    newTesterData.forEach((object: any) => {
                      object.open = false;
                    });
                    setProductsData(newTesterData);
                    setLocalProductsData(newTesterData);
                    setAllTabsOpen(false);
                    setTabsOpen(false);
                  }}
                  text="Collapse All"
                  disabled={false}
                />
              )}
            </div>
          </div>
          {localProductsData.length !== 0 &&
            localProductsData.map(
              (productObject: any, productIndex: number) => {
                return (
                  <div
                    key={productObject.name}
                    className={productIndex === 0 ? "" : "pt-[1px]"}
                  >
                    <div
                      className={
                        "relative right-[1px] h-[58px] bg-white min-w-[1250px]"
                      }
                    >
                      <div className="absolute left-[20px]">
                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                          fontWeight="bold"
                          sx={{
                            fontFamily: "Mulish",
                            color: "#001943",
                            paddingTop: "10px",
                          }}
                        >
                          {productObject.name}
                        </Typography>
                      </div>
                      <div className="absolute left-[200px] top-[8px]">
                        {productObject.name !== "Intruder" &&
                          productObject.name !== "Intruder Plus" &&
                          productObject.name !== "Motion Plus" && (
                            <Switch
                              checked={
                                selectedGroups[productObject.label_name]
                                  .selected
                              }
                              color="warning"
                              disabled={false}
                              onChange={(event) => {
                                setSelectedGroups((previousState: any) => {
                                  const updatedState = { ...previousState };
                                  const updatedGroup =
                                    updatedState[productObject.label_name];
                                  for (const key in updatedGroup) {
                                    updatedGroup[key] = event.target.checked;
                                  }
                                  updatedState[productObject.label_name] =
                                    updatedGroup;
                                  return updatedState;
                                });
                                handleProductMasterSwitchEvent(
                                  productIndex,
                                  setLocalProductsData,
                                  setProductsData,
                                  setPatchObject,
                                  event
                                );
                                setGeneralInfoChangesMade(true);
                              }}
                            />
                          )}
                        {productObject.name === "Intruder" &&
                          createIntruderToggle(
                            selectedGroups,
                            setSelectedGroups,
                            productIndex,
                            setLocalProductsData,
                            setProductsData,
                            setPatchObject,
                            setGeneralInfoChangesMade
                          )}
                        {productObject.name === "Intruder Plus" &&
                          (selectedGroups.intruderPlus.disabled ? (
                            <Tooltip
                              title={`Intruder is already enabled. To enable Intruder Plus, please toggle off Intruder.`}
                              placement="top"
                            >
                              <div>
                                <Switch
                                  checked={selectedGroups.intruderPlus.selected}
                                  color="warning"
                                  disabled={true}
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Switch
                              checked={selectedGroups.intruderPlus.selected}
                              color="warning"
                              disabled={false}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  setSelectedGroups((previousState: any) => {
                                    return {
                                      ...previousState,
                                      intruder: {
                                        selected:
                                          previousState.intruder.selected,
                                        disabled:
                                          selectedGroups.intruder.selected,
                                        intruder:
                                          previousState.intruder.intruder,
                                        bike: previousState.intruder.bike,
                                        vehicle: previousState.intruder.vehicle,
                                      },
                                      intruderPlus: {
                                        selected: false,
                                        disabled:
                                          previousState.intruderPlus.disabled,
                                        intruder_plus: false,
                                        bike_plus: false,
                                        vehicle_plus: false,
                                      },
                                    };
                                  });
                                }
                                if (event.target.checked) {
                                  setSelectedGroups((previousState: any) => {
                                    return {
                                      ...previousState,
                                      intruder: {
                                        selected:
                                          previousState.intruder.selected,
                                        disabled:
                                          !selectedGroups.intruder.selected,
                                        intruder:
                                          previousState.intruder.intruder,
                                        bike: previousState.intruder.bike,
                                        vehicle: previousState.intruder.vehicle,
                                      },
                                      intruderPlus: {
                                        selected: true,
                                        disabled:
                                          previousState.intruderPlus.disabled,
                                        intruder_plus: true,
                                        bike_plus: true,
                                        vehicle_plus: true,
                                      },
                                    };
                                  });
                                }
                                handleProductMasterSwitchEvent(
                                  productIndex,
                                  setLocalProductsData,
                                  setProductsData,
                                  setPatchObject,
                                  event
                                );
                                setGeneralInfoChangesMade(true);
                              }}
                            />
                          ))}
                        {productObject.name === "Motion Plus" &&
                          (selectedGroups.intruderPlus.disabled ? (
                            <Tooltip
                              title={`Intruder and Motion Plus can not run together. To enable Motion Plus, please toggle off Intruder.`}
                              placement="top"
                            >
                              <div>
                                <Switch
                                  checked={selectedGroups.motionPlus.selected}
                                  color="warning"
                                  disabled={true}
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Switch
                              checked={selectedGroups.motionPlus.selected}
                              color="warning"
                              disabled={false}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  setSelectedGroups((previousState: any) => {
                                    return {
                                      ...previousState,
                                      intruder: {
                                        selected:
                                          previousState.intruder.selected,
                                        disabled:
                                          selectedGroups.intruder.selected,
                                        intruder:
                                          previousState.intruder.intruder,
                                        bike: previousState.intruder.bike,
                                        vehicle: previousState.intruder.vehicle,
                                      },
                                      motionPlus: {
                                        selected: false,
                                        vehicle_motion: false,
                                        bike_motion: false,
                                        intruder_motion: false,
                                      },
                                    };
                                  });
                                }
                                if (event.target.checked) {
                                  setSelectedGroups((previousState: any) => {
                                    return {
                                      ...previousState,
                                      intruder: {
                                        selected:
                                          previousState.intruder.selected,
                                        disabled:
                                          selectedGroups.intruder.selected,
                                        intruder:
                                          previousState.intruder.intruder,
                                        bike: previousState.intruder.bike,
                                        vehicle: previousState.intruder.vehicle,
                                      },
                                      motionPlus: {
                                        selected: true,
                                        vehicle_motion: true,
                                        bike_motion: true,
                                        intruder_motion: true,
                                      },
                                    };
                                  });
                                }
                                handleProductMasterSwitchEvent(
                                  productIndex,
                                  setLocalProductsData,
                                  setProductsData,
                                  setPatchObject,
                                  event
                                );
                                setGeneralInfoChangesMade(true);
                              }}
                            />
                          ))}
                      </div>
                      <div className="absolute right-[50px] top-[10px]">
                        {productObject.open ? (
                          <button>
                            <KeyboardArrowUpIcon
                              fontSize="large"
                              onClick={() => {
                                const newTesterData = [...localProductsData];
                                newTesterData[productIndex].open =
                                  !newTesterData[productIndex].open;
                                setProductsData(newTesterData);
                                setLocalProductsData(newTesterData);
                              }}
                            />
                          </button>
                        ) : (
                          <button>
                            <KeyboardArrowDownIcon
                              fontSize="large"
                              onClick={() => {
                                const newTesterData = [...localProductsData];
                                newTesterData[productIndex].open =
                                  !newTesterData[productIndex].open;
                                setProductsData(newTesterData);
                                setLocalProductsData(newTesterData);
                              }}
                            />
                          </button>
                        )}
                      </div>
                    </div>
                    {productObject.open && (
                      // create an outlined table that is padded with 10px inside the div
                      <div className="bg-white">
                        <div className="pt-[1px] pl-[40px] pr-[40px] pb-[10px]">
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ fontFamily: "Mulish" }}
                              aria-label="simple table"
                            >
                              {productObject.name === "Crowd" &&
                                productObject.metrics.crowd
                                  .display_distance && (
                                  <TableHeadComponent
                                    titlesArray={[
                                      "METRIC",
                                      "ACTIVE",
                                      "SENSITIVITY",
                                      "MIN CROWD SIZE",
                                      "DISTANCE",
                                    ]}
                                  />
                                )}

                              {productObject.display_name === "Crowd" &&
                                !productObject.metrics.crowd
                                  .display_distance && (
                                  <TableHeadComponent
                                    titlesArray={[
                                      "METRIC",
                                      "ACTIVE",
                                      "SENSITIVITY",
                                      "MIN CROWD SIZE",
                                      "",
                                    ]}
                                  />
                                )}
                              {productObject.name === "Social Distancing" &&
                                productObject.metrics.social_distancing
                                  .display_distance && (
                                  <TableHeadComponent
                                    titlesArray={[
                                      "METRIC",
                                      "ACTIVE",
                                      "SENSITIVITY",
                                      "DISTANCE",
                                      "",
                                    ]}
                                  />
                                )}
                              {productObject.name === "Social Distancing" &&
                                !productObject.metrics.social_distancing
                                  .display_distance && (
                                  <TableHeadComponent
                                    titlesArray={[
                                      "METRIC",
                                      "ACTIVE",
                                      "SENSITIVITY",
                                      "",
                                      "",
                                    ]}
                                  />
                                )}
                              {(productObject.name === "Left Object" ||
                                productObject.name === "Loitering") && (
                                <TableHeadComponent
                                  titlesArray={[
                                    "METRIC",
                                    "ACTIVE",
                                    "SENSITIVITY",
                                    "FRAME THRESH",
                                    "",
                                  ]}
                                />
                              )}
                              {productObject.name !== "Crowd" &&
                                productObject.name !== "Left Object" &&
                                productObject.name !== "Loitering" &&
                                productObject.name !== "Social Distancing" && (
                                  <TableHeadComponent
                                    titlesArray={[
                                      "METRIC",
                                      "ACTIVE",
                                      "SENSITIVITY",
                                      "",
                                      "",
                                    ]}
                                  />
                                )}
                              <TableBody>
                                {Object.entries(productObject.metrics).map(
                                  (
                                    [key, metricsObject]: [string, any],
                                    metricIndex: number
                                  ) => {
                                    const options = metricsObject.options;
                                    const optionsIds = new Map();
                                    const optionNames = new Map();
                                    const distanceOptions =
                                      metricsObject.distance_options;
                                    const distanceOptionsIds = new Map();
                                    const distanceOptionNames = new Map();
                                    if (metricsObject.options) {
                                      options.forEach((option: any) => {
                                        optionsIds.set(option[1], option[0]);
                                      });
                                      options.forEach((option: any) => {
                                        optionNames.set(option[0], option[1]);
                                      });
                                    }
                                    if (metricsObject.distance_options) {
                                      distanceOptions.forEach((option: any) => {
                                        distanceOptionsIds.set(
                                          option[1],
                                          option[0]
                                        );
                                      });
                                      distanceOptions.forEach((option: any) => {
                                        distanceOptionNames.set(
                                          option[0],
                                          option[1]
                                        );
                                      });
                                    }
                                    return (
                                      <TableRow key={key}>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            fontFamily: "Mulish",
                                            width: "200px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {metricsObject.display_name}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            fontFamily: "Mulish",
                                            width: "200px",
                                          }}
                                        >
                                          {switchCreator(
                                            metricsObject,
                                            selectedGroups,
                                            productObject.label_name,
                                            setSelectedGroups,
                                            setLocalProductsData,
                                            setProductsData,
                                            setPatchObject,
                                            setGeneralInfoChangesMade
                                          )}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          sx={{
                                            fontFamily: "Mulish",
                                            width: "40px",
                                          }}
                                        >
                                          <div key={"thresh_option"}>
                                            <Autocomplete
                                              disablePortal
                                              id={key}
                                              options={options.map(
                                                (option: string[]) => {
                                                  return option[0];
                                                }
                                              )}
                                              defaultValue={
                                                metricsObject.option_id
                                                  ? optionsIds.get(
                                                      metricsObject.option_id
                                                    )
                                                  : ""
                                              }
                                              onChange={(
                                                event: any,
                                                newInputValue: any
                                              ) => {
                                                handlePatchThresh(
                                                  newInputValue,
                                                  productObject.label_name,
                                                  metricsObject,
                                                  optionNames
                                                );
                                                setGeneralInfoChangesMade(true);
                                              }}
                                              sx={{ width: 250 }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  variant="standard"
                                                  label="Thresh"
                                                  autoComplete="off"
                                                  id={key}
                                                  name={key}
                                                />
                                              )}
                                            />
                                          </div>
                                        </TableCell>
                                        {productObject.name ===
                                          "Social Distancing" &&
                                        productObject.metrics.social_distancing
                                          .display_distance ? (
                                          <TableCell
                                            align="left"
                                            sx={{
                                              fontFamily: "Mulish",
                                              width: "200px",
                                            }}
                                          >
                                            <div key={"distance_option"}>
                                              <Autocomplete
                                                disablePortal
                                                id={key}
                                                options={distanceOptions.map(
                                                  (option: string[]) => {
                                                    return option[0];
                                                  }
                                                )}
                                                defaultValue={
                                                  productObject.metrics
                                                    .social_distancing.distance
                                                    ? distanceOptionsIds.get(
                                                        productObject.metrics
                                                          .social_distancing
                                                          .distance
                                                      )
                                                    : ""
                                                }
                                                onChange={(
                                                  event: any,
                                                  newInputValue: any
                                                ) => {
                                                  handlePatchDistance(
                                                    newInputValue,
                                                    productObject.label_name,
                                                    metricsObject,
                                                    distanceOptionNames
                                                  );
                                                  setGeneralInfoChangesMade(
                                                    true
                                                  );
                                                }}
                                                sx={{ width: 250 }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Distance"
                                                    autoComplete="off"
                                                    id={key}
                                                    name={key}
                                                  />
                                                )}
                                              />
                                            </div>
                                          </TableCell>
                                        ) : metricsObject.user_input_label ? (
                                          <TableCell
                                            align="left"
                                            sx={{
                                              fontFamily: "Mulish",
                                              width: "200px",
                                            }}
                                          >
                                            <div>
                                              <TextField
                                                defaultValue={
                                                  metricsObject.user_input_value
                                                }
                                                autoComplete="off"
                                                variant="standard"
                                                onChange={(event: any) => {
                                                  handleExtraFieldInput(
                                                    event.target.value,
                                                    productObject.label_name,
                                                    metricsObject
                                                  );
                                                  setGeneralInfoChangesMade(
                                                    true
                                                  );
                                                }}
                                                sx={{ width: 150 }}
                                              />
                                            </div>
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            align="left"
                                            sx={{
                                              fontFamily: "Mulish",
                                              width: "200px",
                                            }}
                                          ></TableCell>
                                        )}
                                        {productObject.name === "Crowd" &&
                                        productObject.metrics.crowd
                                          .display_distance ? (
                                          <TableCell
                                            align="left"
                                            sx={{
                                              fontFamily: "Mulish",
                                              width: "200px",
                                            }}
                                          >
                                            <div key={"distance_option"}>
                                              <Autocomplete
                                                disablePortal
                                                id={key}
                                                options={distanceOptions.map(
                                                  (option: string[]) => {
                                                    return option[0];
                                                  }
                                                )}
                                                defaultValue={
                                                  productObject.metrics.crowd
                                                    .distance
                                                    ? distanceOptionsIds.get(
                                                        productObject.metrics
                                                          .crowd.distance
                                                      )
                                                    : ""
                                                }
                                                onChange={(
                                                  event: any,
                                                  newInputValue: any
                                                ) => {
                                                  handlePatchDistance(
                                                    newInputValue,
                                                    productObject.label_name,
                                                    metricsObject,
                                                    distanceOptionNames
                                                  );
                                                  setGeneralInfoChangesMade(
                                                    true
                                                  );
                                                }}
                                                sx={{ width: 250 }}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Distance"
                                                    autoComplete="off"
                                                    id={key}
                                                    name={key}
                                                  />
                                                )}
                                              />
                                            </div>
                                          </TableCell>
                                        ) : (
                                          <TableCell
                                            align="left"
                                            sx={{
                                              fontFamily: "Mulish",
                                              width: "200px",
                                            }}
                                          ></TableCell>
                                        )}
                                      </TableRow>
                                    );
                                  }
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
            )}
        </div>
      )}
    </div>
  );
};
