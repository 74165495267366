import * as React from "react";
import { ScheduleGrid } from "./ScheduleGrid";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  SiteSchedule,
  SiteInfo,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { handleCreateInitialTimeSchedules } from "./SiteAboutPageUtils/handleCreateInitialTimeSchedules";
import { handleConvertSchedulesToBooleanArrays } from "./SiteAboutPageUtils/handleConvertSchedulesToBooleanArrays";
import { handleCreateDuskToDawn } from "./SiteAboutPageUtils/handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";
import { SchedulesFilterDropDown } from "./SchedulesFilterDropDown";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { SchedulesTabs } from "./SchedulesTabs";

export const SchedulesTab = ({
  siteSchedules,
  siteInfo,
}: {
  siteSchedules: any;
  siteInfo: SiteInfo;
}) => {
  const [schedulesIndex, setSchedulesIndex] = React.useState(
    Number(localStorage.schedulesIndex)
  );

  const [timeRanges, setTimeRanges] = React.useState(
    handleCreateInitialTimeSchedules(siteSchedules[schedulesIndex].schedule)
  );

  const [grid, setGrid] = React.useState(
    handleConvertSchedulesToBooleanArrays(
      siteSchedules[schedulesIndex].schedule
    )
  );

  const [duskToDawn, setDuskToDawn] = React.useState(
    handleCreateDuskToDawn(siteSchedules[schedulesIndex].schedule)
  );

  const [duskToDawnLocation, setDuskToDawnLocation] = React.useState(
    handleCreateDuskToDawnLocationPlusBuffer(
      siteSchedules[schedulesIndex].schedule
    )
  );

  const [clickedSchedulesTab, setClickedSchedulesTab] = React.useState(
    localStorage.clickedSchedulesTab
  );

  const [visibleSchedules, setVisibleSchedules] = React.useState(
    JSON.parse(localStorage.visibleSchedules)
  );

  const userInfo = JSON.parse(localStorage.userInfo);

  const permissions = userInfo.permission;

  const isSuperUser =
    userInfo.username.includes("@aegissystems") ||
    userInfo.username.includes("@actuate") ||
    userInfo.email.includes("@aegissystems") ||
    userInfo.email.includes("@actuate");

  const [scheudleGrid, setScheduleGrid] = React.useState(
    <ScheduleGrid
      editable={false}
      setSelectedDayInfo={() => {}}
      setEditTimeScheduleDialogOpen={() => {}}
      timeRanges={timeRanges}
      setTimeRanges={setTimeRanges}
      grid={grid}
      setGrid={setGrid}
      duskToDawn={duskToDawn}
      setDuskToDawn={setDuskToDawn}
      duskToDawnLocation={duskToDawnLocation}
      setDuskToDawnLocation={setDuskToDawnLocation}
      setMatchingSchedules={() => {}}
      setScheduleChangesMade={() => {}}
      setSiteSchedules={() => {}}
      setScheduleGrid={() => {}}
      setEditModeTabSiteSchedules={() => {}}
    />
  );
  const products = JSON.parse(localStorage.products);
  const productIds = new Map();

  products.forEach((product: any) => {
    productIds.set(product.id, product.name);
  });

  return (
    <div>
      <div className="border-t border-gray-200"></div>
      <div
        className={`relative top-[0px] ${
          !siteInfo.has_flex_schedule
            ? "h-[440px]"
            : visibleSchedules.two
            ? "h-[510px]"
            : "h-[480px]"
        } bg-white overflow-scroll`}
      >
        <div className="flex">
          <p className="font-black font-mulish text-[20px] p-3 indent-4">
            Schedule Overview &nbsp;
            <Tooltip
              title={`Times shows are in ${siteInfo.timezone} time. Scheduled arming time ranges are highlighted in green.`}
              placement="top"
              sx={{ fontSize: "15px", fontFamily: "mulish" }}
            >
              <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
            </Tooltip>
          </p>
          {permissions.flex_schedule && (
            <div className="pl-[30px] pt-[10px]">
              <FormControlLabel
                sx={{ fontFamily: "Mulish" }}
                control={
                  <Switch
                    color="warning"
                    checked={siteInfo.has_flex_schedule}
                    disabled={true}
                  />
                }
                label="Flex Scheduling"
              />
            </div>
          )}
        </div>
        <div className="pb-[10px]">
          {visibleSchedules.two && permissions.flex_schedule && (
            <SchedulesTabs
              clickedSchedulesTab={clickedSchedulesTab}
              setClickedSchedulesTab={setClickedSchedulesTab}
              siteSchedules={siteSchedules}
              visibleSchedules={visibleSchedules}
              setScheduleGrid={setScheduleGrid}
              setTimeRanges={setTimeRanges}
              setGrid={setGrid}
              setDuskToDawn={setDuskToDawn}
              setDuskToDawnLocation={setDuskToDawnLocation}
              setSelectedDayInfo={() => {}}
              setEditTimeScheduleDialogOpen={() => {}}
              setMatchingSchedules={() => {}}
              setScheduleChangesMade={() => {}}
              setSiteSchedules={() => {}}
              editMode={false}
              setSchedulesIndex={setSchedulesIndex}
              setEditModeTabSiteSchedules={() => {}}
            />
          )}
        </div>
        {siteInfo.has_flex_schedule && (
          <div className="flex">
            <div className="pl-[30px] pb-[10px] overflow-scroll flex">
              <p className="font-black font-mulish text-[20px] flex">
                {siteSchedules[schedulesIndex].display_name}
                {isSuperUser && (
                  <div className="pl-[10px] pb-[px]">
                    <Tooltip
                      title={`${siteSchedules[schedulesIndex].log_name}`}
                      placement="top"
                      sx={{ fontSize: "15px", fontFamily: "mulish" }}
                    >
                      <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                    </Tooltip>
                  </div>
                )}
              </p>
              <p className="font-black font-mulish text-[16px] pl-[30px] pt-[5px]">
                {`Active Products: `}
              </p>
              <div className="pl-[6px] text-[16px] pt-[5px]">
                {`${siteSchedules[schedulesIndex].product
                  .map((product: number) => {
                    return `${productIds.get(product)}`;
                  })
                  .join(", ")}`}
              </div>
            </div>
          </div>
        )}
        <div className="relative top-[0px] left-[15px] w-[1500px]">
          {scheudleGrid}
        </div>
      </div>
    </div>
  );
};
