import React from "react";
import { createApi } from "../../../../utils/createApi";
import { Dispatcher } from "../../SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";
import { object } from "yup";

export const handleBulkGroupAction = (
  actionString: string,
  selected: readonly string[],
  renderedRows: any,
  setSiteScreenLoading: Dispatcher<boolean>,
  groupId: string,
  setPageLoading: Dispatcher<boolean>,
  setSelected: Dispatcher<any>,
  setRenderedRows: any,
  setSiteBreadcrumbs: any,
  setSiteScreenAlerts: Dispatcher<any>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);
  setSiteScreenLoading(true);

  const retryFunction = () => {
    handleBulkGroupAction(
      actionString,
      selected,
      renderedRows,
      setSiteScreenLoading,
      groupId,
      setPageLoading,
      setSelected,
      setRenderedRows,
      setSiteBreadcrumbs,
      setSiteScreenAlerts,
      navigate
    );
  };

  const sitesWithFlexSchedules: string[] = [];

  const disabledSites: string[] = [];

  const findWordsBeforeIs = (string: string) => {
    const words = string.split(" ");
    const index = words.indexOf("is");
    return words.slice(0, index).join(" ");
  };

  const findWordsBeforeHas = (string: string) => {
    const words = string.split(" ");
    const index = words.indexOf("has");
    return words.slice(0, index).join(" ");
  };

  if (selected.length === renderedRows.length && actionString !== "deploy") {
    AxiosUI.post(`group/${groupId}/action/${actionString}/`).then(
      (resp: AxiosResponse) => {
        setSiteScreenLoading(false);
        setPageLoading(true);
        setSelected([]);
        if (actionString === "arm") {
          if (resp.data.length > 0) {
            resp.data.forEach((obj: any) => {
              if (obj?.message) {
                if (!obj.message.includes("Requested reboot")) {
                  if (obj.message.includes("flex schedule")) {
                    sitesWithFlexSchedules.push(
                      findWordsBeforeHas(obj.message)
                    );
                  } else if (obj.message.includes("disabled")) {
                    disabledSites.push(findWordsBeforeIs(obj.message));
                  } else {
                    alert(obj.message);
                  }
                }
              }
            });
            if (sitesWithFlexSchedules.length > 0) {
              alert(
                `The following sites have flex schedules and were not armed: ${sitesWithFlexSchedules.join(
                  ", "
                )}.  To arm, visit the site's about page and turn on the "Arm" toggle to select which schedules to arm.`
              );
            }
            if (disabledSites.length > 0) {
              alert(
                `The following sites are currently disabled and were not armed: ${disabledSites.join(
                  ", "
                )}.  To arm, visit the site's about page and turn on the "Active" toggle.`
              );
            }
          }
        }

        AxiosUI.get(`customer/by_group_id/${groupId}/?page_size=1000`).then(
          (response: AxiosResponse) => {
            const data = response.data.results;
            setRenderedRows(data);
            setPageLoading(false);
            if (actionString === "arm") {
              setSiteScreenAlerts((previousState: any) => {
                return { ...previousState, armGroupSuccess: true };
              });
            }
            if (actionString === "disarm") {
              setSiteScreenAlerts((previousState: any) => {
                return { ...previousState, disarmGroupSuccess: true };
              });
            }
            // if (actionString === "deploy") {
            //   setSiteScreenAlerts((previousState: any) => {
            //     return { ...previousState, deployGroupSuccess: true };
            //   });
            // }
            if (data) {
              const breadcrumbs = response.data.results[0].breadcrumbs;
              setSiteBreadcrumbs(breadcrumbs);
              localStorage.setItem(
                "currentSiteBreadcrumbs",
                JSON.stringify(breadcrumbs)
              );
              if (localStorage.navigatedFromSitesAboutPage === "true") {
                localStorage.setItem("navigatedFromSitesAboutPage", "false");
              }
            }
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              alert("Error Retrieving site data.");
              setRenderedRows([]);
              setPageLoading(false);
            });
          }
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert(`Error ${actionString}ing site. Please try again.`);
          setSiteScreenLoading(false);
        });
      }
    );
  } else {
    selected.forEach(async (cameraId, index) => {
      let action = actionString;
      if (actionString === "deploy") {
        action = "deploy_next";
      }
      await AxiosUI.post(`customer/${cameraId}/action/${action}/`).then(
        (response: AxiosResponse) => {
          if (response.data[0]?.message) {
            if (response.data[0].message.includes("flex schedule")) {
              sitesWithFlexSchedules.push(
                findWordsBeforeHas(response.data[0].message)
              );
            } else if (response.data[0].message.includes("disabled")) {
              disabledSites.push(findWordsBeforeIs(response.data[0].message));
            } else {
              alert(response.data[0].message);
            }
          }
          if (selected.length - 1 === index) {
            setSiteScreenLoading(false);
            setPageLoading(true);
            setSelected([]);
            AxiosUI.get(`customer/by_group_id/${groupId}/?page_size=1000`).then(
              (response: AxiosResponse) => {
                const data = response.data.results;
                setRenderedRows(data);
                setPageLoading(false);
                if (actionString === "arm") {
                  if (sitesWithFlexSchedules.length > 0) {
                    alert(
                      `The following sites have flex schedules and were not armed: ${sitesWithFlexSchedules.join(
                        ", "
                      )}.  To arm, visit the site's about page and turn on the "Arm" toggle to select which schedules to arm.`
                    );
                  }
                  if (disabledSites.length > 0) {
                    alert(
                      `The following sites are currently disabled and were not armed: ${disabledSites.join(
                        ", "
                      )}.  To arm, visit the site's about page and turn on the "Active" toggle.`
                    );
                  }
                  setSiteScreenAlerts((previousState: any) => {
                    return { ...previousState, armGroupSuccess: true };
                  });
                }
                if (actionString === "disarm") {
                  setSiteScreenAlerts((previousState: any) => {
                    return { ...previousState, disarmGroupSuccess: true };
                  });
                }
                if (actionString === "deploy") {
                  setSiteScreenAlerts((previousState: any) => {
                    return { ...previousState, deployGroupSuccess: true };
                  });
                }
                if (data) {
                  const breadcrumbs = response.data.results[0].breadcrumbs;
                  setSiteBreadcrumbs(breadcrumbs);
                  localStorage.setItem(
                    "currentSiteBreadcrumbs",
                    JSON.stringify(breadcrumbs)
                  );
                  if (localStorage.navigatedFromSitesAboutPage === "true") {
                    localStorage.setItem(
                      "navigatedFromSitesAboutPage",
                      "false"
                    );
                  }
                }
              },
              (reason: AxiosError) => {
                universalAPIErrorHandler(
                  reason,
                  navigate,
                  retryFunction,
                  () => {
                    alert("Error Retrieving site data.");
                    setRenderedRows([]);
                    setPageLoading(false);
                  }
                );
              }
            );
          }
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            alert(`Error ${actionString}ing site ${cameraId}.`);
            setSiteScreenLoading(false);
          });
        }
      );
    });
  }
};
