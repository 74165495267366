import { AxiosError } from "axios";
import { createApi } from "../../../utils/createApi";
import { ImmixAlertsTab } from "../ImmixAlertsTab";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import ErrorBoundary from "../../../../ErrorBoundary";
import { NavigateFunction } from "react-router-dom";

export const handleDeleteImmixAlert = (
  immixAlertId: number,
  immixAlerts: any,
  setImmixAlerts: any,
  setActiveTab: any,
  setAddImmixAlertDialogOpen: any,
  setSendingImmixTest: Dispatcher<boolean>,
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>,
  setImmixTestFailDialogOpen: Dispatcher<boolean>,
  setDeleteImmixAlertDialogOpen: Dispatcher<boolean>,
  setBackdropLoadingScreenOn: any,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi(``);

  const retryFunction = () => {
    handleDeleteImmixAlert(
      immixAlertId,
      immixAlerts,
      setImmixAlerts,
      setActiveTab,
      setAddImmixAlertDialogOpen,
      setSendingImmixTest,
      setImmixTestSuccessDialogOpen,
      setImmixTestFailDialogOpen,
      setDeleteImmixAlertDialogOpen,
      setBackdropLoadingScreenOn,
      navigate
    );
  };

  AxiosUI.delete(`/immix/${immixAlertId}/`).then(
    () => {
      const updatedImmixAlerts = immixAlerts.filter(
        (alert: any) => Number(alert.id) !== Number(immixAlertId)
      );
      setImmixAlerts(updatedImmixAlerts);
      setActiveTab(<></>);
      setActiveTab(
        <ErrorBoundary>
          <ImmixAlertsTab
            immixAlerts={updatedImmixAlerts}
            setAddImmixAlertDialogOpen={setAddImmixAlertDialogOpen}
            setSendingImmixTest={setSendingImmixTest}
            setImmixTestSuccessDialogOpen={setImmixTestSuccessDialogOpen}
            setImmixTestFailDialogOpen={setImmixTestFailDialogOpen}
            setDeleteImmixAlertDialogOpen={setDeleteImmixAlertDialogOpen}
            navigate={navigate}
          />
        </ErrorBoundary>
      );
      setBackdropLoadingScreenOn(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error deleting Immix Alert, please try again.");
        setBackdropLoadingScreenOn(false);
      });
    }
  );
};
