/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  SiteSchedule,
  SiteInfo,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { ScheduleGrid } from "./ScheduleGrid";
import { EditScheduleTimesDialog } from "./EditScheduleTimesDialog";
import { handleCreateInitialTimeSchedules } from "./SiteAboutPageUtils/handleCreateInitialTimeSchedules";
import { handleConvertSchedulesToBooleanArrays } from "./SiteAboutPageUtils/handleConvertSchedulesToBooleanArrays";
import { handleCreateDuskToDawn } from "./SiteAboutPageUtils/handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";
import { useNavigate } from "react-router-dom";
import { SchedulesFilterDropDown } from "./SchedulesFilterDropDown";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { SchedulesTabs } from "./SchedulesTabs";
import { handleScheduleTabChange } from "./SiteAboutPageUtils/handleScheduleTabChange";
import EditIcon from "@mui/icons-material/Edit";
import { EditActiveProductsDialog } from "./EditActiveProductsDialog";
import { EditScheduleNameDialog } from "./EditScheduleNameDialog";
import { handleFlexSchedulingToggle } from "./SiteAboutPageUtils/handleFlexSchedulingToggle";
import BackdropLoadingScreen from "../../../common/BackdropLoadingScreen";
import { handleAddSchedule } from "./SiteAboutPageUtils/handleAddSchedule";
import CancelIcon from "@mui/icons-material/Cancel";
import { DeleteScheduleDialog } from "./DeleteScheduleDialog";
import { handleCheckOverlappingSchedulesForSharedProducts } from "./SiteAboutPageUtils/handleCheckOverlappingSchedulesForSharedProducts";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const SchedulesTabEditMode = ({
  siteSchedules,
  siteInfo,
  setScheduleChangesMade,
  setBackdropLoadingScreenOn,
  setActiveTab,
  setEditMode,
  setSiteSchedules,
  setSiteInfo,
}: {
  siteSchedules: any;
  siteInfo: SiteInfo;
  setScheduleChangesMade: Dispatcher<boolean>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setActiveTab: Dispatcher<JSX.Element>;
  setEditMode: Dispatcher<boolean>;
  setSiteSchedules: Dispatcher<SiteSchedule[]>;
  setSiteInfo: Dispatcher<SiteInfo>;
}) => {
  const navigate = useNavigate();

  const [editModeTabSiteSchedules, setEditModeTabSiteSchedules] =
    React.useState(siteSchedules);

  const [schedulesIndex, setSchedulesIndex] = React.useState(
    Number(localStorage.schedulesIndex)
  );

  const [editTimeScheduleDialogOpen, setEditTimeScheduleDialogOpen] =
    React.useState(false);

  const [editActiveProductsDialogOpen, setEditActiveProductsDialogOpen] =
    React.useState(false);

  const [editScheduleNameDialogOpen, setEditScheduleNameDialogOpen] =
    React.useState(false);

  const [selectedDayInfo, setSelectedDayInfo] = React.useState(
    siteSchedules[schedulesIndex]
  );

  const [timeRanges, setTimeRanges] = React.useState(
    handleCreateInitialTimeSchedules(
      siteSchedules[schedulesIndex]?.schedule || []
    )
  );

  const [grid, setGrid] = React.useState(
    handleConvertSchedulesToBooleanArrays(
      siteSchedules[schedulesIndex]?.schedule || []
    )
  );

  const [duskToDawn, setDuskToDawn] = React.useState(
    handleCreateDuskToDawn(siteSchedules[schedulesIndex]?.schedule || [])
  );

  const [duskToDawnLocation, setDuskToDawnLocation] = React.useState(
    handleCreateDuskToDawnLocationPlusBuffer(
      siteSchedules[schedulesIndex]?.schedule || []
    )
  );

  const [matchingSchedules, setMatchingSchedules] = React.useState<number[][]>(
    []
  );

  const [scheudleGrid, setScheduleGrid] = React.useState(<></>);

  const [clickedSchedulesTab, setClickedSchedulesTab] = React.useState(
    localStorage.clickedSchedulesTab
  );

  const [visibleSchedules, setVisibleSchedules] = React.useState(
    JSON.parse(localStorage.visibleSchedules)
  );

  const [flexSchedulesOn, setFlexSchedulesOn] = React.useState(
    siteInfo.has_flex_schedule
  );

  const [deleteScheduleDialogOpen, setDeleteScheduleDialogOpen] =
    React.useState(false);

  const [
    schedulesBackdropLoadingScreenOn,
    setSchedulesBackdropLoadingScreenOn,
  ] = React.useState(false);

  const userInfo = JSON.parse(localStorage.userInfo);

  const permissions = userInfo.permission;

  const isSuperUser =
    userInfo.username.includes("@aegissystems") ||
    userInfo.username.includes("@actuate") ||
    userInfo.email.includes("@aegissystems") ||
    userInfo.email.includes("@actuate");

  const products = JSON.parse(localStorage.products);
  const productIds = new Map();

  products.forEach((product: any) => {
    productIds.set(product.id, product.name);
  });

  React.useEffect(() => {
handleCheckOverlappingSchedulesForSharedProducts(editModeTabSiteSchedules)
  }
    , [editModeTabSiteSchedules])

  React.useEffect(() => {
    setScheduleGrid(
      <ScheduleGrid
        editable={true}
        setSelectedDayInfo={setSelectedDayInfo}
        setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
        timeRanges={handleCreateInitialTimeSchedules(
          editModeTabSiteSchedules[schedulesIndex]?.schedule || []
        )}
        setTimeRanges={setTimeRanges}
        grid={handleConvertSchedulesToBooleanArrays(
          editModeTabSiteSchedules[schedulesIndex]?.schedule || []
        )}
        setGrid={setGrid}
        duskToDawn={handleCreateDuskToDawn(
          siteSchedules[schedulesIndex]?.schedule || []
        )}
        setDuskToDawn={setDuskToDawn}
        duskToDawnLocation={handleCreateDuskToDawnLocationPlusBuffer(
          siteSchedules[schedulesIndex]?.schedule || []
        )}
        setDuskToDawnLocation={setDuskToDawnLocation}
        setMatchingSchedules={setMatchingSchedules}
        setScheduleChangesMade={setScheduleChangesMade}
        setSiteSchedules={setSiteSchedules}
        setScheduleGrid={setScheduleGrid}
        setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
      />
    );
  }, []);

  return (
    <div>
      <BackdropLoadingScreen openState={schedulesBackdropLoadingScreenOn} />
      <div className="border-t border-gray-200"></div>
      {editTimeScheduleDialogOpen && (
        <EditScheduleTimesDialog
          siteSchedules={siteSchedules}
          siteInfo={siteInfo}
          editTimeScheduleDialogOpen={editTimeScheduleDialogOpen}
          setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
          matchingSchedules={matchingSchedules}
          selectedDayInfo={selectedDayInfo}
          timeRanges={timeRanges}
          duskToDawnLocation={duskToDawnLocation}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setActiveTab={setActiveTab}
          setEditMode={setEditMode}
          setSiteSchedules={setSiteSchedules}
          setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
          setSiteInfo={setSiteInfo}
          setTimeRanges={setTimeRanges}
          setGrid={setGrid}
          setMatchingSchedules={setMatchingSchedules}
          navigate={navigate}
        />
      )}
      {editActiveProductsDialogOpen && (
        <EditActiveProductsDialog
          siteSchedules={siteSchedules}
          editActiveProductsDialogOpen={editActiveProductsDialogOpen}
          setEditActiveProductsDialogOpen={setEditActiveProductsDialogOpen}
          setSiteSchedules={setSiteSchedules}
          setScheduleChangesMade={setScheduleChangesMade}
        />
      )}
      {editScheduleNameDialogOpen && (
        <EditScheduleNameDialog
          siteSchedules={siteSchedules}
          editScheduleNameDialogOpen={editScheduleNameDialogOpen}
          setEditScheduleNameDialogOpen={setEditScheduleNameDialogOpen}
          setSiteSchedules={setSiteSchedules}
          setScheduleChangesMade={setScheduleChangesMade}
        />
      )}
      {deleteScheduleDialogOpen && (
        <DeleteScheduleDialog
          deleteScheduleDialogOpen={deleteScheduleDialogOpen}
          setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          scheduleName={siteSchedules[schedulesIndex]?.display_name}
          scheduleId={siteSchedules[schedulesIndex]?.id}
          siteId={Number(siteInfo.id)}
          setSiteSchedules={setSiteSchedules}
          setSchedulesBackdropLoadingScreenOn={
            setSchedulesBackdropLoadingScreenOn
          }
          setVisibleSchedules={setVisibleSchedules}
          setSchedulesIndex={setSchedulesIndex}
          setScheduleChangesMade={setScheduleChangesMade}
          setClickedSchedulesTab={setClickedSchedulesTab}
          setActiveTab={setActiveTab}
          setEditMode={setEditMode}
          setSiteInfo={setSiteInfo}
          siteInfo={siteInfo}
          setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
          setScheduleGrid={setScheduleGrid}
          setSelectedDayInfo={setSelectedDayInfo}
          setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
          setTimeRanges={setTimeRanges}
          setGrid={setGrid}
          setDuskToDawn={setDuskToDawn}
          setDuskToDawnLocation={setDuskToDawnLocation}
          setMatchingSchedules={setMatchingSchedules}
          navigate={navigate}
        />
      )}
      <div
        className={`relative top-[0px] ${
          !flexSchedulesOn
            ? "h-[460]"
            : visibleSchedules.two
            ? "h-[560px]"
            : "h-[540px]"
        } bg-white overflow-scroll`}
      >
        <div className="flex">
          <p className="font-black font-mulish text-[20px] p-3 indent-4">
            Schedule Overview &nbsp;
            <Tooltip
              title={`Times shows are in ${siteInfo.timezone} time. Scheduled arming time ranges are highlighted in green.`}
              placement="top"
              sx={{ fontSize: "15px", fontFamily: "mulish" }}
            >
              <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
            </Tooltip>
          </p>
          {permissions.flex_schedule && (
            <div className="pl-[30px] pt-[10px]">
              <FormControlLabel
                sx={{ fontFamily: "Mulish" }}
                control={
                  <Switch
                    color="warning"
                    checked={flexSchedulesOn}
                    disabled={false}
                    onChange={(event) => {
                      setSchedulesBackdropLoadingScreenOn(true);
                      handleFlexSchedulingToggle(
                        event.target.checked,
                        Number(siteInfo.id),
                        setSiteSchedules,
                        setSchedulesBackdropLoadingScreenOn,
                        setEditModeTabSiteSchedules,
                        setVisibleSchedules,
                        setFlexSchedulesOn,
                        setSiteInfo,
                        setSchedulesIndex,
                        setClickedSchedulesTab,
                        setActiveTab,
                        siteInfo,
                        setScheduleChangesMade,
                        setDeleteScheduleDialogOpen,
                        setBackdropLoadingScreenOn,
                        setEditMode,
                        setScheduleGrid,
                        setSelectedDayInfo,
                        setEditTimeScheduleDialogOpen,
                        setTimeRanges,
                        setGrid,
                        setDuskToDawn,
                        setDuskToDawnLocation,
                        setMatchingSchedules,
                        navigate
                      );
                    }}
                  />
                }
                label="Flex Scheduling"
              />
            </div>
          )}

          <div className="relative left-[850px] top-[15px]">
            {!visibleSchedules.three &&
              flexSchedulesOn &&
              permissions.flex_schedule && (
                <OrangeTextButton
                  onClick={() => {
                    setSchedulesBackdropLoadingScreenOn(true);
                    handleAddSchedule(
                      Number(siteInfo.id),
                      setSiteSchedules,
                      setSchedulesBackdropLoadingScreenOn,
                      visibleSchedules,
                      setVisibleSchedules,
                      setSchedulesIndex,
                      setScheduleChangesMade,
                      setClickedSchedulesTab,
                      setActiveTab,
                      setEditMode,
                      setSiteInfo,
                      siteInfo,
                      setDeleteScheduleDialogOpen,
                      setBackdropLoadingScreenOn,
                      setEditModeTabSiteSchedules,
                      setScheduleGrid,
                      setSelectedDayInfo,
                      setEditTimeScheduleDialogOpen,
                      setTimeRanges,
                      setGrid,
                      setDuskToDawn,
                      setDuskToDawnLocation,
                      setMatchingSchedules,
                      navigate
                    );
                  }}
                  text="+ Add Schedule"
                  disabled={false}
                />
              )}
          </div>
        </div>
        <div className="pb-[10px]">
          {visibleSchedules.two &&
            flexSchedulesOn &&
            permissions.flex_schedule && (
              <SchedulesTabs
                clickedSchedulesTab={clickedSchedulesTab}
                setClickedSchedulesTab={setClickedSchedulesTab}
                siteSchedules={siteSchedules}
                visibleSchedules={visibleSchedules}
                setScheduleGrid={setScheduleGrid}
                setTimeRanges={setTimeRanges}
                setGrid={setGrid}
                setDuskToDawn={setDuskToDawn}
                setDuskToDawnLocation={setDuskToDawnLocation}
                setSelectedDayInfo={setSelectedDayInfo}
                setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
                setMatchingSchedules={setMatchingSchedules}
                setScheduleChangesMade={setScheduleChangesMade}
                setSiteSchedules={setSiteSchedules}
                editMode={true}
                setSchedulesIndex={setSchedulesIndex}
                setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
              />
            )}
        </div>
        {flexSchedulesOn && permissions.flex_schedule && (
          <div className="flex">
            <div className="pl-[30px] pb-[10px] overflow-scroll flex">
              <p className="font-black font-mulish text-[20px] flex">
                {siteSchedules[schedulesIndex]?.display_name
                  ? siteSchedules[schedulesIndex]?.display_name
                  : `Schedule ${schedulesIndex + 1}`}
                {isSuperUser && (
                  <div className="pl-[10px] pb-[px]">
                    <Tooltip
                      title={siteSchedules[schedulesIndex]?.log_name ? `${siteSchedules[schedulesIndex].log_name}` : ""}
                      placement="top"
                      sx={{ fontSize: "15px", fontFamily: "mulish" }}
                    >
                      <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                    </Tooltip>
                  </div>
                )}
              </p>
              <div className="relative top-[-5px]">
                <OrangeTextButton
                  onClick={() => {
                    setEditScheduleNameDialogOpen(true);
                  }}
                  text={
                    <Tooltip
                      title={"Edit Schedule Name"}
                      placement="top"
                      sx={{ fontSize: "20px", fontFamily: "mulish" }}
                    >
                      <EditIcon />
                    </Tooltip>
                  }
                  disabled={false}
                />
              </div>
              <div className="flex">
                <p className="font-black font-mulish text-[16px] pl-[30px] pt-[5px]">
                  {`Active Products: `}
                </p>
                <div className="pl-[6px] text-[16px] pt-[5px]">
                  {siteSchedules[schedulesIndex]?.product ? (
                    `${siteSchedules[schedulesIndex].product
                      .map((product: number) => {
                        return `${productIds.get(product)}`;
                      })
                      .join(", ")}`
                  ) : (
                    <></>
                  )}
                </div>
                <div className="relative top-[-5px]">
                  <OrangeTextButton
                    onClick={() => {
                      setEditActiveProductsDialogOpen(true);
                    }}
                    text={
                      <Tooltip
                        title={"Edit Active Products"}
                        placement="top"
                        sx={{ fontSize: "20px", fontFamily: "mulish" }}
                      >
                        <EditIcon />
                      </Tooltip>
                    }
                    disabled={false}
                  />
                </div>
                {siteSchedules.length > 1 && (
                  <div className="pl-[30px]">
                    <OrangeTextButton
                      onClick={() => setDeleteScheduleDialogOpen(true)}
                      text={
                        <>
                          <CancelIcon fontSize="small" />
                          &nbsp;Delete Schedule
                        </>
                      }
                      disabled={false}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="relative top-[0px] left-[15px] w-[1500px]">
          {scheudleGrid}
        </div>
      </div>
    </div>
  );
};
