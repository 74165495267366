import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../../common/TableHead";
import { TextField, Switch, TableHead, Autocomplete } from "@mui/material";
import { Dispatcher } from "./SiteAboutPageUtils/siteAboutInterfaces";

const capitalizeWords = (str: string) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

const lowerCaseWords = (str: string) => {
  return str.replace(/\b\w/g, (char) => char.toLowerCase());
};

export const CameraHealthTabEditMode = ({
  healthCheckData,
  setCameraHealthPatchObject,
  setCameraHealthChangesMade,
  siteInfo,
  setAddCameraHealthDialogOpen,
  setTurnOffHealthDialogOpen,
}: {
  healthCheckData: any;
  setCameraHealthPatchObject: any;
  setCameraHealthChangesMade: Dispatcher<boolean>;
  siteInfo: any;
  setAddCameraHealthDialogOpen: Dispatcher<boolean>;
  setTurnOffHealthDialogOpen: Dispatcher<boolean>;
}) => {
  const titlesArray = [
    "Camera Health Monitoring On",
    "Daily Reports",
    "Daily Report Emails",
    "",
  ];

  const titlesArray2 = [
    "Instant Notifications",
    "Instant Notification Emails",
    "Sensitivity",
    "Cadence",
  ];

  const sensitivityOptions = ["low", "medium", "high"];

  const cadenceOptions = ["120", "60", "30", "15"];

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          postion: "absolute",
          width: "screen",
          fontFamily: "Mulish",
        }}
      >
        {healthCheckData.deployment === 0 ? (
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                Camera Health Monitoring On
              </TableCell>
            </TableRow>
            <TableBody>
              <TableRow>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  <Switch
                    checked={healthCheckData.deployment === 3}
                    disabled={false}
                    color="warning"
                    onChange={() => {
                      setAddCameraHealthDialogOpen(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </TableHead>
        ) : (
          <>
            <>
              <TableHeadComponent titlesArray={titlesArray} />
              <TableBody>
                <TableRow>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    <Switch
                      checked={healthCheckData.deployment === 3}
                      disabled={false}
                      color="warning"
                      onChange={() => {
                        setTurnOffHealthDialogOpen(true);
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    {healthCheckData.report_emails_enabled ? (
                      <Switch
                        defaultChecked
                        disabled={false}
                        color="warning"
                        onChange={(event: any, value: any) => {
                          setCameraHealthPatchObject((previousState: any) => {
                            return {
                              ...previousState,
                              report_emails_enabled: value,
                            };
                          });
                          setCameraHealthChangesMade(true);
                        }}
                      />
                    ) : (
                      <Switch
                        disabled={false}
                        color="warning"
                        onChange={(event: any, value: any) => {
                          setCameraHealthPatchObject((previousState: any) => {
                            return {
                              ...previousState,
                              report_emails_enabled: value,
                            };
                          });
                          setCameraHealthChangesMade(true);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    <TextField
                      defaultValue={healthCheckData.report_emails}
                      variant="standard"
                      sx={{ width: 400 }}
                      type="string"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setCameraHealthPatchObject((previousState: any) => {
                          return {
                            ...previousState,
                            report_emails: event.target.value,
                          };
                        });
                        setCameraHealthChangesMade(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
            <>
              <TableHeadComponent titlesArray={titlesArray2} />
              <TableBody>
                <TableRow>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    {healthCheckData.alert_emails_enabled ? (
                      <Switch
                        defaultChecked
                        disabled={false}
                        color="warning"
                        onChange={(event: any, value: any) => {
                          setCameraHealthPatchObject((previousState: any) => {
                            return {
                              ...previousState,
                              alert_emails_enabled: value,
                            };
                          });
                          setCameraHealthChangesMade(true);
                        }}
                      />
                    ) : (
                      <Switch
                        disabled={false}
                        color="warning"
                        onChange={(event: any, value: any) => {
                          setCameraHealthPatchObject((previousState: any) => {
                            return {
                              ...previousState,
                              alert_emails_enabled: value,
                            };
                          });
                          setCameraHealthChangesMade(true);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    <TextField
                      defaultValue={healthCheckData.alert_emails}
                      variant="standard"
                      sx={{ width: 400 }}
                      type="string"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setCameraHealthPatchObject((previousState: any) => {
                          return {
                            ...previousState,
                            alert_emails: event.target.value,
                          };
                        });
                        setCameraHealthChangesMade(true);
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    <Autocomplete
                      disablePortal
                      options={sensitivityOptions.map((option: string) => {
                        return capitalizeWords(option);
                      })}
                      defaultValue={capitalizeWords(
                        healthCheckData.sensitivity_level
                      )}
                      onChange={(event: any, newInputValue: any) => {
                        setCameraHealthPatchObject((previousState: any) => {
                          return {
                            ...previousState,
                            sensitivity_level: lowerCaseWords(newInputValue),
                          };
                        });
                        setCameraHealthChangesMade(true);
                      }}
                      sx={{ width: 250 }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Sensitivity"
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    <Autocomplete
                      disablePortal
                      options={cadenceOptions}
                      defaultValue={healthCheckData.cadence}
                      onChange={(event: any, newInputValue: any) => {
                        setCameraHealthPatchObject((previousState: any) => {
                          return {
                            ...previousState,
                            cadence: newInputValue,
                          };
                        });
                        setCameraHealthChangesMade(true);
                      }}
                      sx={{ width: 250 }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Cadence"
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </>
          </>
        )}
      </Table>
    </TableContainer>
  );
};
