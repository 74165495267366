import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Dispatcher } from "./SiteAboutPageUtils/siteAboutInterfaces";
import { handleScheduleTabChange } from "./SiteAboutPageUtils/handleScheduleTabChange";
const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const SchedulesTabs = ({
  clickedSchedulesTab,
  setClickedSchedulesTab,
  siteSchedules,
  visibleSchedules,
  setScheduleGrid,
  setTimeRanges,
  setGrid,
  setDuskToDawn,
  setDuskToDawnLocation,
  setSelectedDayInfo,
  setEditTimeScheduleDialogOpen,
  setMatchingSchedules,
  setScheduleChangesMade,
  setSiteSchedules,
  editMode,
  setSchedulesIndex,
  setEditModeTabSiteSchedules,
}: {
  clickedSchedulesTab: string;
  setClickedSchedulesTab: Dispatcher<string>;
  siteSchedules: any;
  visibleSchedules: any;
  setScheduleGrid: Dispatcher<any>;
  setTimeRanges: Dispatcher<any>;
  setGrid: Dispatcher<any>;
  setDuskToDawn: Dispatcher<any>;
  setDuskToDawnLocation: Dispatcher<any>;
  setSelectedDayInfo: Dispatcher<any>;
  setEditTimeScheduleDialogOpen: Dispatcher<boolean>;
  setMatchingSchedules: Dispatcher<any>;
  setScheduleChangesMade: Dispatcher<boolean>;
  setSiteSchedules: Dispatcher<any>;
  editMode: boolean;
  setSchedulesIndex: Dispatcher<number>;
  setEditModeTabSiteSchedules: any;
}) => {
  return (
    <Box sx={{ maxWidth: { xs: 1320, sm: 1480 }, bgcolor: "background.paper" }}>
      <ThemeProvider theme={theme}>
        <Tabs
          value={clickedSchedulesTab}
          onChange={(event, newValue) => {
            setClickedSchedulesTab(newValue);
            let selectedIndex = 0;
            if (newValue === "one") {
              selectedIndex = 0;
              localStorage.setItem("clickedSchedulesTab", "one");
              localStorage.setItem("schedulesIndex", "0");
              setSchedulesIndex(0);
            } else if (newValue === "two") {
              selectedIndex = 1;
              localStorage.setItem("clickedSchedulesTab", "two");
              localStorage.setItem("schedulesIndex", "1");
              setSchedulesIndex(1);
            } else if (newValue === "three") {
              selectedIndex = 2;
              localStorage.setItem("clickedSchedulesTab", "three");
              localStorage.setItem("schedulesIndex", "2");
              setSchedulesIndex(2);
            }
            handleScheduleTabChange(
              selectedIndex,
              siteSchedules,
              setScheduleGrid,
              setTimeRanges,
              setGrid,
              setDuskToDawn,
              setDuskToDawnLocation,
              editMode,
              setSelectedDayInfo,
              setEditTimeScheduleDialogOpen,
              setMatchingSchedules,
              setScheduleChangesMade,
              setSiteSchedules,
              setEditModeTabSiteSchedules
            );
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          ``
          {visibleSchedules.one && (
            <Tab
              label={
                siteSchedules[0]
                  ? `${siteSchedules[0].display_name}`
                  : "Schedule 1"
              }
              value="one"
            />
          )}
          {visibleSchedules.two && (
            <Tab
              label={
                siteSchedules[1]
                  ? `${siteSchedules[1].display_name}`
                  : "Schedule 2"
              }
              value="two"
            />
          )}
          {visibleSchedules.three && (
            <Tab
              label={
                siteSchedules[2]
                  ? `${siteSchedules[2].display_name}`
                  : "Schedule 3"
              }
              value="three"
            />
          )}
        </Tabs>
      </ThemeProvider>
    </Box>
  );
};
