// import { assignMetricsToProducts } from "./assignMetricsToProducts";

// export const generateActiveProducts = (
//   streamsObject: any,
//   setActiveProducts: any,
//   setProductsData: any,
//   setBackupProductsData: any
// ) => {
//   const activeProductsToSet: any[] = [];
//   let count = 0;
//   for (const key in streamsObject) {
//     const streamsMetcis: any[] = streamsObject[key].stream_metrics;
//     streamsMetcis.forEach((object: any) => {
//       if (count === 0) {
//         activeProductsToSet.push(object);
//       } else {
//         activeProductsToSet.forEach((obj: any, index: any) => {
//           if (obj.label_name === object.label_name) {
//             if (object.active) {
//               activeProductsToSet[index] = object;
//             }
//           }
//         });
//       }
//     });
//     count += 1;
//   }
//   setActiveProducts(activeProductsToSet);
//   assignMetricsToProducts(
//     activeProductsToSet,
//     setProductsData,
//     setBackupProductsData
//   );
//   return activeProductsToSet;
// };

import { assignMetricsToProducts } from "./assignMetricsToProducts";

export const generateActiveProducts = (
  streamsObject: any,
  setActiveProducts: any,
  setProductsData: any,
  setBackupProductsData: any
) => {
  const productsMap = new Map();
  let streamsObjectToLoop: any = streamsObject;

  if (!streamsObject) {
    streamsObjectToLoop = {
      1: {
        stream_metrics: [
          {
            id: null,
            label_name: "intruder",
            display_name: "Intruder",
            active: false,
            option_id: null,
            options: [
              ["Low", 36],
              ["Medium", 37],
              ["Intermediate (Medium-High)", 64],
              ["High (more sensitive)", 38],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "vehicle",
            display_name: "Vehicle (grouped car, truck, bus)",
            active: false,
            option_id: null,
            options: [
              ["Low", 36],
              ["Medium", 37],
              ["Intermediate (Medium-High)", 64],
              ["High (more sensitive)", 38],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "bike",
            display_name: "Bike (grouped bicycle, motorcycle)",
            active: false,
            option_id: null,
            options: [
              ["Low", 36],
              ["Medium", 37],
              ["Intermediate (Medium-High)", 64],
              ["High (more sensitive)", 38],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "intruder_plus",
            display_name: "Intruder +",
            active: false,
            option_id: null,
            options: [
              ["Low", 58],
              ["Medium", 59],
              ["Intermediate (Medium-High)", 61],
              ["High (more sensitive)", 60],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "vehicle_plus",
            display_name: "Vehicle +",
            active: false,
            option_id: null,
            options: [
              ["Low", 58],
              ["Medium", 59],
              ["Intermediate (Medium-High)", 61],
              ["High (more sensitive)", 60],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "bike_plus",
            display_name: "Bike +",
            active: false,
            option_id: null,
            options: [
              ["Low", 58],
              ["Medium", 59],
              ["Intermediate (Medium-High)", 61],
              ["High (more sensitive)", 60],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "gun",
            display_name: "Gun",
            active: false,
            option_id: null,
            options: [
              ["Low", 32],
              ["Medium", 33],
              ["High (more sensitive)", 34],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "pistol",
            display_name: "Pistol",
            active: false,
            option_id: null,
            options: [
              ["Low", 32],
              ["Medium", 33],
              ["High (more sensitive)", 34],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "crowd",
            display_name: "Crowd",
            active: false,
            option_id: null,
            options: [
              ["Low", 27],
              ["Medium", 28],
              ["High (more sensitive)", 29],
            ],
            user_input_label: "Min Crowd Size",
            user_input_field: "min_crowd_size",
            user_input_value: null,
            display_distance: true,
            distance: null,
            distance_options: [
              ["Close", "0.5"],
              ["Normal", "1.0"],
              ["Far Apart", "2.0"],
            ],
          },
          {
            id: null,
            label_name: "loiterer",
            display_name: "Loitering",
            active: false,
            option_id: null,
            options: [
              ["Low", 40],
              ["Medium", 41],
              ["Intermediate (Medium-High)", 62],
              ["High (more sensitive)", 42],
            ],
            user_input_label: "Frame Thresh",
            user_input_field: "frame_thresh",
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "vehicle_loiterer",
            display_name: "Vehicle Loitering",
            active: false,
            option_id: null,
            options: [
              ["Low", 55],
              ["Medium", 56],
              ["Intermediate (Medium-High)", 63],
              ["High (more sensitive)", 57],
            ],
            user_input_label: "Frame Thresh",
            user_input_field: "frame_thresh",
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "people_flow",
            display_name: "People Flow",
            active: false,
            option_id: null,
            options: [],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "social_distancing",
            display_name: "Social Distancing",
            active: false,
            option_id: null,
            options: [
              ["Low", 49],
              ["Medium", 50],
              ["High (more sensitive)", 51],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: true,
            distance: null,
            distance_options: [
              ["Close", "0.5"],
              ["Normal", "1.0"],
              ["Far Apart", "2.0"],
            ],
          },
          {
            id: null,
            label_name: "fall_person",
            display_name: "Fall Person",
            active: false,
            option_id: null,
            options: [["Medium", 30]],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "mask",
            display_name: "Mask",
            active: false,
            option_id: null,
            options: [["Medium", 43]],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "mask_compliance",
            display_name: "Mask Compliance",
            active: false,
            option_id: null,
            options: [["Medium", 44]],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "no_mask",
            display_name: "No Mask",
            active: false,
            option_id: null,
            options: [["Medium", 44]],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "no_hard_hat",
            display_name: "No Hard Hat",
            active: false,
            option_id: null,
            options: [["Medium", 35]],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "left_object",
            display_name: "Left Object",
            active: false,
            option_id: null,
            options: [["Medium", 39]],
            user_input_label: "Frame Thresh",
            user_input_field: "frame_thresh",
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "package",
            display_name: "Package",
            active: false,
            option_id: null,
            options: [["Medium", 45]],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "amazon",
            display_name: "Amazon",
            active: false,
            option_id: null,
            options: [
              ["Low", 46],
              ["Medium", 47],
              ["High (more sensitive)", 48],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "dhl",
            display_name: "DHL",
            active: false,
            option_id: null,
            options: [
              ["Low", 46],
              ["Medium", 47],
              ["High (more sensitive)", 48],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "fedex",
            display_name: "Fedex",
            active: false,
            option_id: null,
            options: [
              ["Low", 46],
              ["Medium", 47],
              ["High (more sensitive)", 48],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "school_bus",
            display_name: "School Bus",
            active: false,
            option_id: null,
            options: [
              ["Low", 46],
              ["Medium", 47],
              ["High (more sensitive)", 48],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "ups",
            display_name: "UPS",
            active: false,
            option_id: null,
            options: [
              ["Low", 46],
              ["Medium", 47],
              ["High (more sensitive)", 48],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "usps",
            display_name: "USPS",
            active: false,
            option_id: null,
            options: [
              ["Low", 46],
              ["Medium", 47],
              ["High (more sensitive)", 48],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "envera",
            display_name: "Envera",
            active: false,
            option_id: null,
            options: [
              ["Low", 46],
              ["Medium", 47],
              ["High (more sensitive)", 48],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "sudden_scene_change",
            display_name: "Sudden Scene Change",
            active: false,
            option_id: null,
            options: [
              ["Low", 52],
              ["Medium", 53],
              ["High (more sensitive)", 54],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "fire",
            display_name: "Fire",
            active: false,
            option_id: null,
            options: [],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "smoke",
            display_name: "Smoke",
            active: false,
            option_id: null,
            options: [],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "fall",
            display_name: "Slip and Fall (old model)",
            active: false,
            option_id: null,
            options: [],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "non_ups",
            display_name: "Non-UPS",
            active: false,
            option_id: null,
            options: [],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
          {
            id: null,
            label_name: "fire_truck",
            display_name: "Fire Truck",
            active: false,
            option_id: null,
            options: [
              ["Low", 46],
              ["Medium", 47],
              ["High (more sensitive)", 48],
            ],
            user_input_label: null,
            user_input_field: null,
            user_input_value: null,
            display_distance: false,
            distance: null,
            distance_options: null,
          },
        ],
      },
    };
  }
  // Iterate over each stream
  for (const key in streamsObjectToLoop) {
    const streamMetrics: any[] = streamsObjectToLoop[key].stream_metrics;
    streamMetrics.forEach((metric: any) => {
      // If it's the first encounter or metric is active, set or update the product
      if (!productsMap.has(metric.label_name) || metric.active) {
        productsMap.set(metric.label_name, metric);
      }
    });
  }

  const activeProductsToSet = Array.from(productsMap.values());
  setActiveProducts(activeProductsToSet);
  assignMetricsToProducts(
    activeProductsToSet,
    setProductsData,
    setBackupProductsData
  );

  return activeProductsToSet;
};
