import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleDeleteImmixAlert } from "./EditCameraUtils/handleDeleteImmixAlert";

export const DeleteImmixAlertDialog = ({
  deleteImmixAlertDialogOpen,
  setDeleteImmixAlertDialogOpen,
  setBackdropLoadingScreenOn,
  immixAlerts,
  setImmixAlerts,
  setActiveTab,
  setAddImmixAlertDialogOpen,
  setSendingImmixTest,
  setImmixTestSuccessDialogOpen,
  setImmixTestFailDialogOpen,
  navigate,
}: {
  deleteImmixAlertDialogOpen: boolean;
  setDeleteImmixAlertDialogOpen: Dispatcher<boolean>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  immixAlerts: any;
  setImmixAlerts: Dispatcher<any>;
  setActiveTab: Dispatcher<any>;
  setAddImmixAlertDialogOpen: Dispatcher<boolean>;
  setSendingImmixTest: Dispatcher<boolean>;
  setImmixTestSuccessDialogOpen: Dispatcher<boolean>;
  setImmixTestFailDialogOpen: Dispatcher<boolean>;
  navigate: NavigateFunction;
}) => {
  const immixAlertId = localStorage.selectedImmixAlertId;

  return (
    <Dialog open={deleteImmixAlertDialogOpen}>
      <DialogTitle id="alert-dialog-title">
        {" Are you sure you want to delete this Alert?"}
      </DialogTitle>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setDeleteImmixAlertDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setBackdropLoadingScreenOn(true);
            handleDeleteImmixAlert(
              immixAlertId,
              immixAlerts,
              setImmixAlerts,
              setActiveTab,
              setAddImmixAlertDialogOpen,
              setSendingImmixTest,
              setImmixTestSuccessDialogOpen,
              setImmixTestFailDialogOpen,
              setDeleteImmixAlertDialogOpen,
              setBackdropLoadingScreenOn,
              navigate
            );
            setDeleteImmixAlertDialogOpen(false);
          }}
          text="Delete Alert"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
