import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  Legend,
} from "recharts";
import { CircularProgress } from "@mui/material";
import { colorsArray } from "./AnalyticsUtils/colorsArray";
import {
  LoadingData,
  ParentGroupsAlertData,
  ParentGroupsAlertDataLabel,
} from "./AnalyticsUtils/analyticsInterfaces";
import useWindowDimensions from "../../common/useWindowDimensions";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleSiteGroupClick } from "../Sites/SitesPage/SitesPageUtils/handleSiteGroupClick";
import { NavigateFunction } from "react-router-dom";
import { handleLevel1IndexClick } from "../Sites/SiteAboutPage/SiteAboutPageUtils/handleLevel1IndexClick";
import { getMax7Array } from "./AnalyticsUtils/getMax7Array";
import { getFullArray } from "./AnalyticsUtils/getFullArray";
import { ShowMoreButton } from "./ShowMoreButton";
import { createColor } from "./AnalyticsUtils/createColor";

export const AlertsByParentGroupsGraph = ({
  parentGroupsAlertsData,
  loadingData,
  setDisplayOnLeftBool,
  alertGraphName,
  setPageLoading,
  navigate,
  visibleArray,
  setVisibleArray,
  conversionArray,
  showMoreVisible,
  showMoreOpen,
  setShowMoreOpen,
  navBarCollapsed,
}: {
  parentGroupsAlertsData: ParentGroupsAlertData[];
  loadingData: LoadingData;
  setDisplayOnLeftBool: Dispatcher<boolean>;
  alertGraphName: string;
  setPageLoading: Dispatcher<boolean>;
  navigate: NavigateFunction;
  visibleArray: any[];
  setVisibleArray: Dispatcher<any[]>;
  conversionArray: any[];
  showMoreVisible: boolean;
  showMoreOpen: boolean;
  setShowMoreOpen: Dispatcher<boolean>;
  navBarCollapsed: boolean;
}) => {
  const [barChartHeight, setBarChartHeight] = React.useState(385);
  const [outerBoxHeight, setOuterBoxHeight] = React.useState(425);
  const [innerBoxHeight, setInnerBoxHeight] = React.useState(423);

  const optionIds = new Map();

  parentGroupsAlertsData.forEach((object: ParentGroupsAlertData) => {
    optionIds.set(object.name, object.id);
  });

  const labelNames: string[] = [];
  const labelDisplayNames: string[] = [];
  const labelsArray: { label: ParentGroupsAlertDataLabel }[] = [];
  let labelStringLength = 0;
  parentGroupsAlertsData.forEach((object: ParentGroupsAlertData) => {
    if (object.label && object.label.display_name) {
      if (!labelDisplayNames.includes(object.label.display_name)) {
        labelDisplayNames.push(object.label.display_name);
        labelStringLength += object.label.display_name.length;
      }
    }
    if (!labelsArray[0]) {
      labelNames.push(object.label.name);
      labelsArray.push({
        label: object.label,
      });
    }
    labelsArray.forEach(() => {
      if (!labelNames.includes(object.label.name)) {
        labelsArray.push({
          label: object.label,
        });
        labelNames.push(object.label.name);
      }
    });
  });

  let topLength = 36;

  let bottomLength = 56;

  if (labelStringLength > 47 && labelStringLength < 95) {
    topLength = 56;
    bottomLength = 76;
  }

  if (labelStringLength > 94) {
    topLength = 76;
    bottomLength = 96;
  }

  const max7Array = getMax7Array(conversionArray);

  const fullArray = getFullArray(conversionArray);

  const barChartHeightFull = conversionArray.length * 60;

  const outerBoxHeightFull = Math.floor(conversionArray.length * 66);

  const innerBoxHeightFull = Math.floor(conversionArray.length * 66 - 2);

  React.useEffect(() => {
    setDisplayOnLeftBool(false);
    setShowMoreOpen(false);
    setVisibleArray(max7Array);
    setBarChartHeight(385);
    setOuterBoxHeight(425);
    setInnerBoxHeight(423);
  }, [loadingData]);

  return (
    <div
      style={
        navBarCollapsed
          ? {
              position: "absolute",
              top: 50,
              left: 690,
              right: 1,
              height: outerBoxHeight,
              width: 656,
              backgroundColor: "#BDBDBD",
            }
          : {
              position: "absolute",
              top: 50,
              left: 650,
              right: 1,
              height: outerBoxHeight,
              width: 620,
              backgroundColor: "#BDBDBD",
            }
      }
    >
      <div
        style={{
          position: "absolute",
          top: 1,
          left: 1,
          right: 1,
          height: innerBoxHeight,
          background: "white",
        }}
      ></div>
      <p className="text-actuate-blue font-mulish text-[22px] font-bold absolute top-[8px] left-[40px]">
        {`Alerts by ${alertGraphName}`}
      </p>
      <p className="text-actuate-blue font-mulish text-[14px] absolute top-[36px] left-[40px]">
        {`Total alerts for each ${alertGraphName} during the selected dates`}
      </p>
      {showMoreVisible && (
        <div
          className={
            !navBarCollapsed
              ? "absolute top-[5px] left-[480px]"
              : "absolute top-[5px] left-[516px]"
          }
        >
          <ShowMoreButton
            showMoreOpen={showMoreOpen}
            setShowMoreOpen={setShowMoreOpen}
            setVisibleArray={setVisibleArray}
            fullArray={fullArray}
            max7Array={max7Array}
            setBarChartHeight={setBarChartHeight}
            barChartHeightFull={barChartHeightFull}
            setOuterBoxHeight={setOuterBoxHeight}
            outerBoxHeightFull={outerBoxHeightFull}
            setInnerBoxHeight={setInnerBoxHeight}
            innerBoxHeightFull={innerBoxHeightFull}
            setDisplayOnLeftBool={setDisplayOnLeftBool}
          />
        </div>
      )}

      {!loadingData.parentGroupsData && (
        <div className="absolute top-[60px] left-[0px]">
          <BarChart
            layout="vertical"
            width={!navBarCollapsed ? 620 : 656}
            height={barChartHeight}
            data={visibleArray}
            margin={{
              top: 5,
              right: 30,
              left: 50,
              bottom: 5,
            }}
            onClick={(event: any) => {
              if (alertGraphName === "Parent Group") {
                handleLevel1IndexClick(
                  optionIds.get(event.activePayload[0].payload.name),
                  navigate
                );
              }
              if (alertGraphName === "Site") {
                navigate(
                  `/sites/aboutsite/${optionIds.get(
                    event.activePayload[0].payload.name
                  )}`
                );
              }
              //  if (alertGraphName === "Camera"){
              //   getCameraGeneralInfo(optionIds.get(event.activePayload[0].payload.name), navigate)
              //  }
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" allowDecimals={false} />
            <YAxis dataKey="name" type="category" hide />
            {visibleArray[0] && <Tooltip />}
            {!showMoreOpen ? (
              <Legend height={bottomLength} />
            ) : (
              <Legend height={topLength} verticalAlign="top" />
            )}
            {labelsArray.map((object, index: number) => {
              if (index === 0) {
                return (
                  <Bar
                    cursor="pointer"
                    key={object.label.name}
                    dataKey={object.label.name}
                    stackId="a"
                    fill={createColor(object.label.name, object.label.color)}
                    name={
                      object.label.display_name.charAt(0).toUpperCase() +
                      object.label.display_name.slice(1)
                    }
                    // onClick={(event) =>{
                    //   console.log("event1", optionIds.get(event.name))
                    //   // if (alertGraphName === "Site"){
                    //   //   handleSiteGroupClick(setPageLoading, object.id, navigate)
                    //   // }
                    // }
                    // }
                  >
                    <LabelList
                      dataKey="abreviated_name"
                      position="left"
                      width={220}
                    />
                  </Bar>
                );
              } else {
                return (
                  <Bar
                    cursor="pointer"
                    key={object.label.name}
                    dataKey={object.label.name}
                    stackId="a"
                    fill={createColor(object.label.name, object.label.color)}
                    name={
                      object.label.display_name.charAt(0).toUpperCase() +
                      object.label.display_name.slice(1)
                    }
                    // onClick={(event) =>{
                    //   console.log("event2", optionIds.get(event.name))
                    //   // if (alertGraphName === "Site"){
                    //   //   handleSiteGroupClick(setPageLoading, object.id, navigate)
                    //   // }
                    // }
                    // }
                  ></Bar>
                );
              }
            })}
          </BarChart>
        </div>
      )}
      {loadingData.parentGroupsData && (
        <div className="absolute top-[170px] left-[230px]">
          <CircularProgress size={25} sx={{ position: "relative", left: 40 }} />
          <div className="font-mulish text-actuate-blue test-bold">
            Fetching Data...
          </div>
        </div>
      )}
    </div>
  );
};
