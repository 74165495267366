import React from "react";
import { createApi } from "../../../utils/createApi";
import { getNumbersAfterEditCamera } from "../../EditCamera/EditCamera";
import { getNumbersAfterSites } from "../../EditCamera/EditCamera";
import { AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { resizeImageIgnoreZones } from "./resizeImageIgnoreZones";
import { addIgnoreLabel } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/addIgnoreLabel";

export const getEditIgnoreZonesInfo = (
  setPageLoading: Dispatcher<boolean>,
  setBreadCrumbs: Dispatcher<any>,
  setVisibleIgnoreZones: Dispatcher<any>,
  setDimensions: Dispatcher<any>,
  setAdjustedDimensions: Dispatcher<any>
) => {
  const AxiosUI = createApi(``);

  const cameraId: any = getNumbersAfterEditCamera(location.href);

  const adjustDimensions = (dimensions: string) => {
    const [width, height] = dimensions.split(" x ").map(Number);
    if (!width || !height) return; // Exit if parsing fails

    const aspectRatio = height / width;
    const adjustedHeight = Math.round(720 * aspectRatio);

    setAdjustedDimensions({ x: 720, y: adjustedHeight });
  };

  AxiosUI.get(`/camera/${cameraId}/status/`).then((response: AxiosResponse) => {
    localStorage.setItem(
      "zwibblerDimensions",
      JSON.stringify(resizeImageIgnoreZones(response.data.frame_dimensions))
    );
    localStorage.setItem(
      "preview_url",
      JSON.stringify(response.data.preview_url)
    );
    setDimensions(resizeImageIgnoreZones(response.data.frame_dimensions));
    adjustDimensions(response.data.frame_dimensions);
    localStorage.setItem("currentCamera", response.data.camera_name);
    AxiosUI.get(`/camera/${cameraId}/ignore_zones/`).then(
      (response: AxiosResponse) => {
        setVisibleIgnoreZones(addIgnoreLabel(response.data));
        AxiosUI.get(
          `customer/${getNumbersAfterSites(location.href)}/about/`
        ).then((response: AxiosResponse) => {
          setBreadCrumbs(response.data.breadcrumbs);
          if (!localStorage.currentCamera) {
            AxiosUI.get(`/camera/${cameraId}/general_info/`).then(
              (response: AxiosResponse) => {
                localStorage.setItem(
                  "currentCamera",
                  response.data.camera_name
                );
                setPageLoading(false);
              }
            );
          } else {
            setPageLoading(false);
          }
        });
      }
    );
  });
};
