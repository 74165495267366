import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableHeadComponent } from "../../../common/TableHead";
import { Checkbox, FormControlLabel, Switch, TableHead } from "@mui/material";

const capitalizeWords = (str: string) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const CameraHealthTab = ({
  healthCheckData,
  siteInfo,
}: {
  healthCheckData: any;
  siteInfo: any;
}) => {
  const titlesArray = [
    "Camera Health Monitoring On",
    "Daily Reports",
    "Daily Report Emails",
    "",
  ];

  const titlesArray2 = [
    "Instant Notifications",
    "Instant Notification Emails",
    "Sensitivity",
    "Cadence",
  ];

  return (
    <>
      <TableContainer component={Paper} sx={{ width: "screen" }}>
        <Table
          sx={{
            postion: "absolute",
            width: "screen",
            fontFamily: "Mulish",
          }}
        >
          {healthCheckData.deployment === 0 ? (
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                  Camera Health Monitoring On
                </TableCell>
              </TableRow>
              <TableBody>
                <TableRow>
                  <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                    <Switch checked={false} disabled={true} color="warning" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </TableHead>
          ) : (
            <>
              <>
                <TableHeadComponent titlesArray={titlesArray} />
                <TableBody>
                  <TableRow>
                    <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                      <Switch
                        checked={healthCheckData.deployment === 3}
                        disabled={true}
                        color="warning"
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                      {healthCheckData.report_emails_enabled ? (
                        <Switch
                          defaultChecked
                          disabled={true}
                          color="warning"
                        />
                      ) : (
                        <Switch
                          checked={false}
                          disabled={true}
                          color="warning"
                        />
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                      {healthCheckData.report_emails}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </>
              <>
                <TableHeadComponent titlesArray={titlesArray2} />
                <TableBody>
                  <TableRow>
                    <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                      {healthCheckData.alert_emails_enabled ? (
                        <Switch
                          defaultChecked
                          disabled={true}
                          color="warning"
                        />
                      ) : (
                        <Switch
                          checked={false}
                          disabled={true}
                          color="warning"
                        />
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                      {healthCheckData.alert_emails}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                      {capitalizeWords(healthCheckData.sensitivity_level)}
                    </TableCell>
                    <TableCell align="left" sx={{ fontFamily: "Mulish" }}>
                      {healthCheckData.cadence}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </>
            </>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
