import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { getCamerasBySiteAlerts } from "./AlertsUtils/getCamerasBySiteAlerts";

export const SiteDropDownAlertsPage = ({
  userFilters,
  setUserFilters,
  siteOptions,
  setCameraOptions,
  setCameraDropDownValue,
  navigate,
  siteDropDownValue,
  setSiteDropDownValue,
  loadingSites,
  setLoadingCameras,
  navBarCollapsed,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
  siteOptions: any;
  setCameraOptions: Dispatcher<any>;
  setCameraDropDownValue: Dispatcher<string[]>;
  navigate: NavigateFunction;
  siteDropDownValue: string[];
  setSiteDropDownValue: Dispatcher<string[]>;
  loadingSites: boolean;
  setLoadingCameras: Dispatcher<boolean>;
  navBarCollapsed: boolean;
}) => {
  const siteIds = new Map();
  const siteNames = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });
  siteOptions.forEach((object: any) => {
    siteNames.set(object.id, object.name);
  });

  const parentAccounts = JSON.parse(localStorage.parentAccounts);

  const parentIds = new Map();
  parentAccounts.forEach((object: any) => {
    parentIds.set(object.name, object.id);
  });

  return (
    <div>
      <Autocomplete
        multiple
        limitTags={1}
        value={siteDropDownValue}
        disablePortal
        options={
          siteOptions[0]
            ? siteOptions.map((object: any) => {
                return object.name;
              })
            : []
        }
        loading={loadingSites}
        onChange={(event, value: any) => {
          if (value[0]) {
            const siteIdsArray: string[] = [];
            const siteValuesArray: string[] = [];
            value.forEach((element: string) => {
              siteIdsArray.push(siteIds.get(element));
              siteValuesArray.push(element);
            });
            setSiteDropDownValue(siteValuesArray);
            setCameraDropDownValue([]);
            getCamerasBySiteAlerts(
              siteIdsArray,
              navigate,
              setCameraOptions,
              setLoadingCameras
            );
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                site: siteIdsArray,
                camera: "",
              };
            });
          } else {
            setSiteDropDownValue([]);
            setCameraDropDownValue([]);
            setCameraOptions([]);
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                site: [],
                camera: [],
              };
            });
            setLoadingCameras(false);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Site"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingSites ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
            sx={!navBarCollapsed ? { width: 150 } : { width: 175 }}
          />
        )}
      />
    </div>
  );
};
