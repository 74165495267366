import React from "react";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderIcon from "@mui/icons-material/Folder";
import Collapse from "@mui/material/Collapse";
import { createNestedListItemButton } from "./SitesPageUtils/createNestedListItemButton";
import { createUnnestedListItemButton } from "./SitesPageUtils/createUnnestedListItemButton";
import { handleUnnestedLevelClick } from "./SitesPageUtils/handleUnnestedLevelClick";
import { getIndividualGroup } from "./SitesPageUtils/getIndividualGroup";
import { AddGroupOrSiteDropDownMenu } from "./AddGroupOrSiteDropDownMenu";
import ListItemText from "@mui/material/ListItemText";
import { CircularProgress } from "@mui/material";
import { handlePushGroupsWithChildrenToTop } from "./SitesPageUtils/handlePushGroupsWithChildrenToTop";
import { GroupSearchBar } from "./GroupsSearchBar";
import { CameraIcon } from "../../../common/CameraIcon";

export const SitesGroupList = ({
  groupTrees,
  selectedIndex,
  setPage,
  setCurrentTitle,
  setSelectedIndex,
  setRenderedRows,
  setPageLoading,
  navigate,
  setSiteBreadcrumbs,
  setAddGroupDialogOpen,
  permissions,
  navBarCollapsed,
  setSelected,
  searchBarValue,
  editedparentsGroupsList,
  setSearchBarValue,
  setEditedParentGroupList,
  setBackdropLoadingScreenOn,
  setSelectedGroupListName,
  setEditableGroupSelected,
}: {
  groupTrees: any;
  selectedIndex: any;
  setPage: any;
  setCurrentTitle: any;
  setSelectedIndex: any;
  setRenderedRows: any;
  setPageLoading: any;
  navigate: any;
  setSiteBreadcrumbs: any;
  setAddGroupDialogOpen: any;
  permissions: any;
  navBarCollapsed: boolean;
  setSelected: any;
  searchBarValue: any;
  editedparentsGroupsList: any;
  setSearchBarValue: any;
  setEditedParentGroupList: any;
  setBackdropLoadingScreenOn: any;
  setSelectedGroupListName: any;
  setEditableGroupSelected: any;
}) => {
  React.useEffect(() => {
    if (localStorage.level1Click === "true") {
      localStorage.setItem("getIndividualGroupId", localStorage.level1Id);
      getIndividualGroup(
        Number(localStorage.level1Id),
        setRenderedRows,
        setPageLoading,
        setSiteBreadcrumbs,
        setSelected,
        setEditableGroupSelected,
        setSelectedGroupListName,
        navigate
      );
      setSelectedIndex((previousState: any) => {
        return { ...previousState, level1: [Number(localStorage.level1Id)] };
      });
      localStorage.removeItem("level2Id");
      localStorage.removeItem("level3Id");
      localStorage.removeItem("level4Id");
      localStorage.removeItem("level5Id");
      localStorage.setItem("level1Click", "false");
    } else if (localStorage.level2Click === "true") {
      localStorage.setItem("getIndividualGroupId", localStorage.level2Id);
      getIndividualGroup(
        Number(localStorage.level2Id),
        setRenderedRows,
        setPageLoading,
        setSiteBreadcrumbs,
        setSelected,
        setEditableGroupSelected,
        setSelectedGroupListName,
        navigate
      );
      setSelectedIndex((previousState: any) => {
        return {
          ...previousState,
          level1: [Number(localStorage.level1Id)],
          level2: [Number(localStorage.level2Id)],
        };
      });
      localStorage.removeItem("level3Id");
      localStorage.removeItem("level4Id");
      localStorage.removeItem("level5Id");
      localStorage.setItem("level2Click", "false");
    } else if (localStorage.level3Click === "true") {
      localStorage.setItem("getIndividualGroupId", localStorage.level3Id);
      getIndividualGroup(
        Number(localStorage.level3Id),
        setRenderedRows,
        setPageLoading,
        setSiteBreadcrumbs,
        setSelected,
        setEditableGroupSelected,
        setSelectedGroupListName,
        navigate
      );
      setSelectedIndex((previousState: any) => {
        return {
          ...previousState,
          level1: [Number(localStorage.level1Id)],
          level2: [Number(localStorage.level2Id)],
          level3: [Number(localStorage.level3Id)],
        };
      });
      localStorage.removeItem("level4Id");
      localStorage.removeItem("level5Id");
      localStorage.setItem("level3Click", "false");
    } else if (localStorage.level4Click === "true") {
      localStorage.setItem("getIndividualGroupId", localStorage.level4Id);
      getIndividualGroup(
        Number(localStorage.level4Id),
        setRenderedRows,
        setPageLoading,
        setSiteBreadcrumbs,
        setSelected,
        setEditableGroupSelected,
        setSelectedGroupListName,
        navigate
      );
      setSelectedIndex((previousState: any) => {
        return {
          ...previousState,
          level1: [Number(localStorage.level1Id)],
          level2: [Number(localStorage.level2Id)],
          level3: [Number(localStorage.level3Id)],
          level4: [Number(localStorage.level4Id)],
        };
      });
      localStorage.removeItem("level5Id");
      localStorage.setItem("level4Click", "false");
    } else if (localStorage.level5Click === "true") {
      localStorage.setItem("getIndividualGroupId", localStorage.level5Id);
      getIndividualGroup(
        Number(localStorage.level5Id),
        setRenderedRows,
        setPageLoading,
        setSiteBreadcrumbs,
        setSelected,
        setEditableGroupSelected,
        setSelectedGroupListName,
        navigate
      );
      setSelectedIndex((previousState: any) => {
        return {
          ...previousState,
          level1: [Number(localStorage.level1Id)],
          level2: [Number(localStorage.level2Id)],
          level3: [Number(localStorage.level3Id)],
          level4: [Number(localStorage.level4Id)],
          level5: [Number(localStorage.level5Id)],
        };
      });
      localStorage.setItem("level5Click", "false");
    }
  }, []);

  return (
    <div>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            sx={{ fontSize: "18px", fontFamily: "Mulish" }}
            component="div"
            id="all-parent-group-subheader"
          >
            {searchBarValue ? (
              `Filtered Groups (${editedparentsGroupsList.length})`
            ) : (
              <>
                Parent Groups & Sites
                {permissions.add_cameras && (
                  <div className="relative left-[185px] top-[-48px] h-[1px]">
                    <AddGroupOrSiteDropDownMenu
                      navigate={navigate}
                      setAddGroupDialogOpen={setAddGroupDialogOpen}
                    />
                  </div>
                )}
              </>
            )}

            <div className="relative left-[-40px]">
              <GroupSearchBar
                setSearchBarValue={setSearchBarValue}
                setEditedParentGroupList={setEditedParentGroupList}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                searchBarValue={searchBarValue}
              />
            </div>
            {}
          </ListSubheader>
        }
      >
        {!groupTrees[0] ? (
          <div className="relative left-[55px] top-[15px]">
            <CircularProgress
              size={25}
              sx={{ position: "relative", left: 45 }}
            />
            <div className="font-mulish text-bold">Fetching groups...</div>
          </div>
        ) : searchBarValue ? (
          <>
            {editedparentsGroupsList.map((arrayLvl1Item: any) => {
              {
                if (arrayLvl1Item.parent_account) {
                  return (
                    <ListItemButton
                      key={arrayLvl1Item.id}
                      onClick={() => {
                        handleUnnestedLevelClick(
                          setPage,
                          setCurrentTitle,
                          arrayLvl1Item.name,
                          arrayLvl1Item.id,
                          setRenderedRows,
                          setPageLoading,
                          navigate,
                          setSiteBreadcrumbs,
                          setSelected,
                          false,
                          setSelectedGroupListName,
                          setEditableGroupSelected
                        );
                      }}
                    >
                      <ListItemIcon>
                        <FolderIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={arrayLvl1Item.name}
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontFamily: "Mulish",
                        }}
                      />
                    </ListItemButton>
                  );
                } else {
                  return (
                    <ListItemButton
                      key={arrayLvl1Item.id}
                      onClick={() => {
                        handleUnnestedLevelClick(
                          setPage,
                          setCurrentTitle,
                          arrayLvl1Item.name,
                          arrayLvl1Item.id,
                          setRenderedRows,
                          setPageLoading,
                          navigate,
                          setSiteBreadcrumbs,
                          setSelected,
                          true,
                          setSelectedGroupListName,
                          setEditableGroupSelected
                        );
                      }}
                    >
                      <div className="relative left-[-15px]">
                        <ListItemIcon>
                          <CameraIcon />
                        </ListItemIcon>
                      </div>
                      <div className="relative">
                        <ListItemText
                          primary={arrayLvl1Item.name}
                          primaryTypographyProps={{
                            fontSize: 14,
                            fontFamily: "Mulish",
                          }}
                        />
                      </div>
                    </ListItemButton>
                  );
                }
              }
            })}
          </>
        ) : (
          <>
            {handlePushGroupsWithChildrenToTop(groupTrees).map(
              (arrayLvl1Item: any) => {
                if (arrayLvl1Item.children[0]) {
                  return (
                    <div key={arrayLvl1Item.id}>
                      {createNestedListItemButton(
                        arrayLvl1Item,
                        selectedIndex,
                        setPage,
                        setCurrentTitle,
                        "level1",
                        setSelectedIndex,
                        setRenderedRows,
                        setPageLoading,
                        navigate,
                        undefined,
                        8,
                        setSiteBreadcrumbs,
                        navBarCollapsed,
                        setSelected,
                        setSelectedGroupListName,
                        setEditableGroupSelected
                      )}
                      <Collapse
                        in={
                          // arrayLvl1Item.id === selectedIndex.level1
                          selectedIndex.level1.includes(arrayLvl1Item.id)
                        }
                        timeout="auto"
                        unmountOnExit
                      >
                        {handlePushGroupsWithChildrenToTop(
                          arrayLvl1Item.children
                        ).map((arrayLvl2Item: any) => {
                          if (arrayLvl2Item.children[0]) {
                            return (
                              <div key={arrayLvl2Item.id}>
                                {createNestedListItemButton(
                                  arrayLvl2Item,
                                  selectedIndex,
                                  setPage,
                                  setCurrentTitle,
                                  "level2",
                                  setSelectedIndex,
                                  setRenderedRows,
                                  setPageLoading,
                                  navigate,
                                  5,
                                  7,
                                  setSiteBreadcrumbs,
                                  navBarCollapsed,
                                  setSelected,
                                  setSelectedGroupListName,
                                  setEditableGroupSelected
                                )}
                                <Collapse
                                  in={
                                    // arrayLvl2Item.id === selectedIndex.level2
                                    selectedIndex.level2.includes(
                                      arrayLvl2Item.id
                                    )
                                  }
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {handlePushGroupsWithChildrenToTop(
                                    arrayLvl2Item.children
                                  ).map((arrayLvl3Item: any) => {
                                    if (arrayLvl3Item.children[0]) {
                                      return (
                                        <div key={arrayLvl3Item.id}>
                                          {createNestedListItemButton(
                                            arrayLvl3Item,
                                            selectedIndex,
                                            setPage,
                                            setCurrentTitle,
                                            "level3",
                                            setSelectedIndex,
                                            setRenderedRows,
                                            setPageLoading,
                                            navigate,
                                            8,
                                            6,
                                            setSiteBreadcrumbs,
                                            navBarCollapsed,
                                            setSelected,
                                            setSelectedGroupListName,
                                            setEditableGroupSelected
                                          )}
                                          <Collapse
                                            in={
                                              // arrayLvl3Item.id ===
                                              // selectedIndex.level3
                                              selectedIndex.level3.includes(
                                                arrayLvl3Item.id
                                              )
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            {handlePushGroupsWithChildrenToTop(
                                              arrayLvl3Item.children
                                            ).map((arrayLvl4Item: any) => {
                                              if (arrayLvl4Item.children[0]) {
                                                return (
                                                  <div key={arrayLvl4Item.id}>
                                                    {createNestedListItemButton(
                                                      arrayLvl4Item,
                                                      selectedIndex,
                                                      setPage,
                                                      setCurrentTitle,
                                                      "level4",
                                                      setSelectedIndex,
                                                      setRenderedRows,
                                                      setPageLoading,
                                                      navigate,
                                                      11,
                                                      5,
                                                      setSiteBreadcrumbs,
                                                      navBarCollapsed,
                                                      setSelected,
                                                      setSelectedGroupListName,
                                                      setEditableGroupSelected
                                                    )}
                                                    <Collapse
                                                      in={
                                                        // arrayLvl4Item.id ===
                                                        // selectedIndex.level4
                                                        selectedIndex.level4.includes(
                                                          arrayLvl4Item.id
                                                        )
                                                      }
                                                      timeout="auto"
                                                      unmountOnExit
                                                    >
                                                      {handlePushGroupsWithChildrenToTop(
                                                        arrayLvl4Item.children
                                                      ).map(
                                                        (
                                                          arrayLvl5Item: any
                                                        ) => {
                                                          if (
                                                            arrayLvl5Item
                                                              .children[0]
                                                          ) {
                                                            return (
                                                              <div
                                                                key={
                                                                  arrayLvl5Item.id
                                                                }
                                                              >
                                                                {createNestedListItemButton(
                                                                  arrayLvl5Item,
                                                                  selectedIndex,
                                                                  setPage,
                                                                  setCurrentTitle,
                                                                  "level5",
                                                                  setSelectedIndex,
                                                                  setRenderedRows,
                                                                  setPageLoading,
                                                                  navigate,
                                                                  14,
                                                                  4,
                                                                  setSiteBreadcrumbs,
                                                                  navBarCollapsed,
                                                                  setSelected,
                                                                  setSelectedGroupListName,
                                                                  setEditableGroupSelected
                                                                )}
                                                                <Collapse
                                                                  in={
                                                                    // arrayLvl5Item.id ===
                                                                    // selectedIndex.level5
                                                                    selectedIndex.level5.includes(
                                                                      arrayLvl5Item.id
                                                                    )
                                                                  }
                                                                  timeout="auto"
                                                                  unmountOnExit
                                                                >
                                                                  {handlePushGroupsWithChildrenToTop(
                                                                    arrayLvl5Item.children
                                                                  ).map(
                                                                    (
                                                                      arrayLvl6Item: any
                                                                    ) => {
                                                                      if (
                                                                        arrayLvl6Item
                                                                          .children[0]
                                                                      ) {
                                                                        return (
                                                                          <div
                                                                            key={
                                                                              arrayLvl6Item.id
                                                                            }
                                                                          >
                                                                            {createNestedListItemButton(
                                                                              arrayLvl6Item,
                                                                              selectedIndex,
                                                                              setPage,
                                                                              setCurrentTitle,
                                                                              "level6",
                                                                              setSelectedIndex,
                                                                              setRenderedRows,
                                                                              setPageLoading,
                                                                              navigate,
                                                                              17,
                                                                              3,
                                                                              setSiteBreadcrumbs,
                                                                              navBarCollapsed,
                                                                              setSelected,
                                                                              setSelectedGroupListName,
                                                                              setEditableGroupSelected
                                                                            )}
                                                                          </div>
                                                                        );
                                                                      } else {
                                                                        return (
                                                                          <List
                                                                            component="div"
                                                                            key={
                                                                              arrayLvl6Item.id
                                                                            }
                                                                          >
                                                                            {createUnnestedListItemButton(
                                                                              arrayLvl6Item,
                                                                              setPage,
                                                                              setCurrentTitle,
                                                                              12,
                                                                              setRenderedRows,
                                                                              setPageLoading,
                                                                              navigate,
                                                                              8,
                                                                              setSiteBreadcrumbs,
                                                                              navBarCollapsed,
                                                                              setSelected,
                                                                              setSelectedGroupListName,
                                                                              setEditableGroupSelected
                                                                            )}
                                                                          </List>
                                                                        );
                                                                      }
                                                                    }
                                                                  )}
                                                                </Collapse>
                                                              </div>
                                                            );
                                                          } else {
                                                            return (
                                                              <List
                                                                component="div"
                                                                key={
                                                                  arrayLvl5Item.id
                                                                }
                                                              >
                                                                {createUnnestedListItemButton(
                                                                  arrayLvl5Item,
                                                                  setPage,
                                                                  setCurrentTitle,
                                                                  12,
                                                                  setRenderedRows,
                                                                  setPageLoading,
                                                                  navigate,
                                                                  8,
                                                                  setSiteBreadcrumbs,
                                                                  navBarCollapsed,
                                                                  setSelected,
                                                                  setSelectedGroupListName,
                                                                  setEditableGroupSelected
                                                                )}
                                                              </List>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </Collapse>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <List
                                                    component="div"
                                                    key={arrayLvl4Item.id}
                                                  >
                                                    {createUnnestedListItemButton(
                                                      arrayLvl4Item,
                                                      setPage,
                                                      setCurrentTitle,
                                                      9,
                                                      setRenderedRows,
                                                      setPageLoading,
                                                      navigate,
                                                      11,
                                                      setSiteBreadcrumbs,
                                                      navBarCollapsed,
                                                      setSelected,
                                                      setSelectedGroupListName,
                                                      setEditableGroupSelected
                                                    )}
                                                  </List>
                                                );
                                              }
                                            })}
                                          </Collapse>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <List
                                          component="div"
                                          key={arrayLvl3Item.id}
                                        >
                                          {createUnnestedListItemButton(
                                            arrayLvl3Item,
                                            setPage,
                                            setCurrentTitle,
                                            6,
                                            setRenderedRows,
                                            setPageLoading,
                                            navigate,
                                            6,
                                            setSiteBreadcrumbs,
                                            navBarCollapsed,
                                            setSelected,
                                            setSelectedGroupListName,
                                            setEditableGroupSelected
                                          )}
                                        </List>
                                      );
                                    }
                                  })}
                                </Collapse>
                              </div>
                            );
                          } else {
                            return (
                              <List component="div" key={arrayLvl2Item.id}>
                                {createUnnestedListItemButton(
                                  arrayLvl2Item,
                                  setPage,
                                  setCurrentTitle,
                                  3,
                                  setRenderedRows,
                                  setPageLoading,
                                  navigate,
                                  5,
                                  setSiteBreadcrumbs,
                                  navBarCollapsed,
                                  setSelected,
                                  setSelectedGroupListName,
                                  setEditableGroupSelected
                                )}
                              </List>
                            );
                          }
                        })}
                      </Collapse>
                    </div>
                  );
                } else {
                  if (arrayLvl1Item.parent_account) {
                    return (
                      <ListItemButton
                        key={arrayLvl1Item.id}
                        onClick={() => {
                          handleUnnestedLevelClick(
                            setPage,
                            setCurrentTitle,
                            arrayLvl1Item.name,
                            arrayLvl1Item.id,
                            setRenderedRows,
                            setPageLoading,
                            navigate,
                            setSiteBreadcrumbs,
                            setSelected,
                            false,
                            setSelectedGroupListName,
                            setEditableGroupSelected
                          );
                        }}
                      >
                        <ListItemIcon>
                          <FolderIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${arrayLvl1Item.name}`}
                          primaryTypographyProps={{
                            fontSize: 14,
                            fontFamily: "Mulish",
                          }}
                        />
                        {/* {arrayLvl1Item.name.length < 8 ? (
                  <>
                    {`${arrayLvl1Item.name} - ${arrayLvl1Item.children.length}`}
                    <VideocamOutlinedIcon fontSize="small" />
                  </>
                ) : (
                  <>
                    {`${arrayLvl1Item.name.slice(0, 7)}... - ${
                      arrayLvl1Item.children.length
                    }`}
                    <VideocamOutlinedIcon fontSize="small" />
                  </>
                )} */}
                      </ListItemButton>
                    );
                  } else {
                    return (
                      <List component="div" key={arrayLvl1Item.id}>
                        {createUnnestedListItemButton(
                          arrayLvl1Item,
                          setPage,
                          setCurrentTitle,
                          0,
                          setRenderedRows,
                          setPageLoading,
                          navigate,
                          4,
                          setSiteBreadcrumbs,
                          navBarCollapsed,
                          setSelected,
                          setSelectedGroupListName,
                          setEditableGroupSelected
                        )}
                      </List>
                    );
                  }
                }
              }
            )}
          </>
        )}
      </List>
    </div>
  );
};
