/* eslint-disable react-hooks/exhaustive-deps */
import { NavBar } from "../../../common/NavBar";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { SiteCamerasTable } from "./SiteCameras";
import { AboutTable } from "./AboutTable";
import { GeneralInfoTab } from "./GeneralInfoTab";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { TabsComponet } from "./TabsComponet";
import { CancelButton } from "./CancelButton";
import { SaveChangesButton } from "./SaveChangesButton";
import { EditInformationButton } from "./EditInformationButton";
import { AddAnotherScheduleButton } from "./AddAnotherScheduleButton";
import { BreadCrumbsComponet } from "../../../common/BreadCrumbsComponent";
import BackdropLoadingScreen from "../../../common/BackdropLoadingScreen";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { SyncSiteButton } from "./SyncSiteButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { createLinksArray } from "./SiteAboutPageUtils/createLinksArray";
import { handleSyncSite } from "./SiteAboutPageUtils/handleSyncSite";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { SiteAboutDialogs } from "./SiteAboutDialogs";
import { SiteAboutAlerts } from "./SiteAboutAlerts";
import useWindowDimensions from "../../../common/useWindowDimensions";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import { ConfigureMotionButton } from "./ConfigureMotionButton";
import {
  AlertState,
  SiteCameras,
  SiteSchedule,
  PatchObject,
  Dispatcher,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { handleGetAllData } from "./SiteAboutPageUtils/handleGetAllData";
import { handleSaveChangesButtonClick } from "./SiteAboutPageUtils/handleSaveChangesButtonClick";
import { Alert, Link } from "@mui/material";
import ErrorBoundary from "../../../../ErrorBoundary";
import NotesIcon from "@mui/icons-material/Notes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const getNumbersAfterAboutSite = (url: string) => {
  if (window.location.href.includes("aboutsitesecret")) {
    const match = url.match(/\/aboutsitesecret\/(\d+)/);
    if (match) {
      return match[1];
    } else {
      return "";
    }
  } else {
    const match = url.match(/\/aboutsite\/(\d+)/);
    if (match) {
      return match[1];
    } else {
      return "";
    }
  }
};

export const SiteAbout = ({
  permissions,
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  permissions: any;
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [clickedTab, setClickedTab] = React.useState("one");
  const [editMode, setEditMode] = React.useState(false);
  const [siteInfo, setSiteInfo] = React.useState<any>({});
  const [siteInfoBackup, setSiteInfoBackup] = React.useState<any>({});
  const [webhooksInfo, setWebhooksInfo] = React.useState<any>([]);
  const [activeTab, setActiveTab] = React.useState(<></>);
  const [vmsVariables, setVmsVariables] = React.useState<
    (string | string[] | undefined)[][]
  >([]);
  const [vmsVariablesBackup, setVmsVariablesBackup] = React.useState<
    (string | string[] | undefined)[][]
  >([]);
  const [patchObject, setPatchObject] = React.useState<PatchObject>({
    name: "",
  });
  const [schedulesTabActive, setSchedulesTabActive] = React.useState(false);
  const [siteSchedules, setSiteSchedules] = React.useState<SiteSchedule[]>([]);
  const [allSiteSchedules, setAllSiteSchedules] = React.useState<
    SiteSchedule[]
  >([]);
  const [webhooksPatchObject, setWebhooksPatchObject] = React.useState({
    customer: getNumbersAfterAboutSite(location.href),
    method: "GET",
    messsage_body: "",
  });
  const [webhooksChangesMade, setWebhooksChangesMade] = React.useState(false);

  const { width } = useWindowDimensions();

  const [alertState, setAlertState] = React.useState<AlertState>({
    changeSuccess: false,
    noCameraOrSchedule: false,
    noSchedule: false,
    activateSuccess: false,
    deactivateSuccess: false,
    disarmSuccess: false,
    armSuccess: false,
    armFail: false,
    syncSuccess: false,
    cameraDeleteSuccess: false,
    configureMotionSuccess: false,
    configureMotionFail: false,
    refreshCameraSuccess: false,
    refreshCameraFail: false,
    webhookChangesFail: false,
  });
  const [camerasNotDeployed, setCamerasNotDeployed] = React.useState(false);
  const [backdropLoadingScreenOn, setBackdropLoadingScreenOn] =
    React.useState(true);
  const [syncNeeded, setSyncNeeded] = React.useState<boolean>(false);
  const [deleteSiteDialogOpen, setDeleteSiteDialogOpen] = React.useState(false);
  const [deleteScheduleDialogOpen, setDeleteScheduleDialogOpen] =
    React.useState(false);
  const [siteInfoChangesMade, setSiteInfoChangesMade] = React.useState(false);
  const [scheduleChangesMade, setScheduleChangesMade] = React.useState(false);
  const [siteCameras, setSiteCameras] = React.useState<SiteCameras>(
    localStorage.siteCameras ? JSON.parse(localStorage.siteCameras) : []
  );
  const [disarmDialogOpen, setDisarmDialogOpen] = React.useState(false);
  const [armDialogOpen, setArmDialogOpen] = React.useState(false);
  const [rebootDialogOpen, setRebootDialogOpen] = React.useState(false);
  const [activateSiteDialogOpen, setActivateSiteDialogOpen] =
    React.useState(false);
  const [deactivateSiteDialogOpen, setDeactivateSiteDialogOpen] =
    React.useState(false);
  const [deativateInquiryDialogOpen, setDeativateInquiryDialogOpen] =
    React.useState(false);
  const [noAutoAddCamerasDialogOpen, setNoAutoAddCamerasDialogOpen] =
    React.useState(false);

  const [siteDeployed, setSiteDeployed] = React.useState(true);

  const [aiLinkSite, setAiLinkSite] = React.useState(false);

  const [toolTipTitle, setToolTipTitle] = React.useState("");

  const [configureMotion, setConfigureMotion] = React.useState(false);

  const deleteSiteText = (
    <>
      <CancelIcon fontSize="small" />
      &nbsp;DELETE Site
    </>
  );

  const seeAlertText = (
    <>
      <ErrorIcon fontSize="small" />
      &nbsp;See Alerts
    </>
  );

  const ActionLogsText = (
    <>
      <NotesIcon fontSize="small" />
      &nbsp;Action Logs
    </>
  );

  const ref = React.useRef<HTMLDivElement>(null);

  const [armedStatus, setArmedStatus] = React.useState(false);

  const [armButtonDisabled, setArmButtonDisabled] = React.useState(false);

  const [recordingServerData, setRecordingServerData] = React.useState({});

  const [recordingServerPatchObject, setRecordingServerPatchObject] =
    React.useState({});

  const [recordingServerChangesMade, setRecordingServerChangesMade] =
    React.useState(false);

  const [recordingServerVisible, setRecordingServerVisible] =
    React.useState(false);

  const [healthCheckData, setHealthCheckData] = React.useState({});

  const [cameraHealthPatchObject, setCameraHealthPatchObject] = React.useState(
    {}
  );

  const [cameraHealthChangesMade, setCameraHealthChangesMade] =
    React.useState(false);

  const [camerasRefreshing, setCamerasRefreshing] = React.useState(false);

  const [addCameraHealthDialogOpen, setAddCameraHealthDialogOpen] =
    React.useState(false);

  const [turnOffHealthDialogOpen, setTurnOffHealthDialogOpen] =
    React.useState(false);

  const [cameraCheckAlertOpen, setCameraCheckAlertOpen] = React.useState(
    localStorage.cameraCheckAlertOpen === "true"
  );

  const [deactivateSiteInsteadDialogOpen, setDeactivateSiteInsteadDialogOpen] =
    React.useState(false);

  const [
    activateSiteWithScheduleDialogOpen,
    setActivateSiteWithScheduleDialogOpen,
  ] = React.useState(false);

  const [siteSchedulesBackup, setSiteSchedulesBackup] = React.useState<any>([]);

  const [flexScheduleOnEditButtonClick, setFlexScheduleOnEditButtonClick] =
    React.useState(false);

  const [armSiteFlexScheduleDialogOpen, setArmSiteFlexScheduleDialogOpen] =
    React.useState(false);

  React.useEffect(() => {
    handleGetAllData(
      getNumbersAfterAboutSite(location.href),
      navigate,
      setSiteInfo,
      setSiteSchedules,
      setSiteCameras,
      setActiveTab,
      setDataLoaded,
      setBackdropLoadingScreenOn,
      setAllSiteSchedules,
      setVmsVariables,
      setNoAutoAddCamerasDialogOpen,
      setArmedStatus,
      setAiLinkSite,
      setSiteDeployed,
      setSyncNeeded,
      setAlertState,
      setCamerasNotDeployed,
      setToolTipTitle,
      setConfigureMotion,
      setPatchObject,
      setArmButtonDisabled,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerVisible,
      setHealthCheckData,
      setWebhooksInfo,
      setWebhooksPatchObject
    );
    localStorage.setItem("schedulesIndex", "0");
    localStorage.setItem("clickedSchedulesTab", "one");
    if (localStorage.cameraCheckAlertOpen === "true") {
      setTimeout(() => {
        setCameraCheckAlertOpen(false);
        localStorage.setItem("cameraCheckAlertOpen", "false");
      }, 10000);
    }
  }, []);

  const setScrollTop = (event: any) => {
    localStorage.setItem("siteAboutScrollTop", event.target.scrollTop);
  };

  React.useEffect(() => {
    if (ref) {
      setTimeout(() => {
        if (ref.current !== null) {
          ref.current.scrollTop = localStorage.siteAboutScrollTop;
        }
      }, 500);
    }
  }, [backdropLoadingScreenOn]);

  React.useEffect(() => {
    localStorage.setItem("navigatedFromSitesAboutPage", "true");
    localStorage.setItem(
      "allSiteSchedulesBackup",
      JSON.stringify(siteSchedules)
    );
  }, []);

  // React.useEffect(() => {
  //   if (localStorage.rebootSite === "true") {
  //     setRebootDialogOpen(true);
  //   }
  // }, []);

  //on Unmount
  // React.useEffect(
  //   () => () => {
  //   },
  //   []
  // )

  const navigate = useNavigate();

  const handleSiteBreadcrumbClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    localStorage.setItem("siteAboutScrollTop", "0");
    localStorage.setItem("expandedObjectSaved", "false");
    localStorage.setItem("navigatedFromSitesAboutPage", "true");
    localStorage.setItem("level0Click", "true");
    localStorage.setItem("sitesTablePage", "0");

    localStorage.setItem(
      "storedSelectedIndex",
      JSON.stringify({
        level1: [],
        level2: [],
        level3: [],
        level4: [],
        level5: [],
        level6: [],
      })
    );
    navigate("/sites");
  };

  return (
    <div>
      <div>
        {cameraCheckAlertOpen && (
          <Alert
            severity="info"
            onClose={() => {
              setCameraCheckAlertOpen(false);
              localStorage.setItem("cameraCheckAlertOpen", "false");
            }}
          >
            Camera check is running in the background. This may take a few
            minutes. You will be alerted when it is complete. Please do not
            close your window or refresh any page until the check has been
            completed.
          </Alert>
        )}
        <SiteAboutDialogs
          deleteScheduleDialogOpen={deleteScheduleDialogOpen}
          setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setAllSiteSchedules={setAllSiteSchedules}
          setSiteInfo={setSiteInfo}
          editMode={editMode}
          setActiveTab={setActiveTab}
          allSiteSchedules={allSiteSchedules}
          siteInfo={siteInfo}
          setScheduleChangesMade={setScheduleChangesMade}
          rebootDialogOpen={rebootDialogOpen}
          setRebootDialogOpen={setRebootDialogOpen}
          setAlertState={setAlertState}
          activateSiteDialogOpen={activateSiteDialogOpen}
          setActivateSiteDialogOpen={setActivateSiteDialogOpen}
          setPatchObject={setPatchObject}
          patchObject={patchObject}
          deactivateSiteDialogOpen={deactivateSiteDialogOpen}
          setDeactivateSiteDialogOpen={setDeactivateSiteDialogOpen}
          deativateInquiryDialogOpen={deativateInquiryDialogOpen}
          setDeativateInquiryDialogOpen={setDeativateInquiryDialogOpen}
          disarmDialogOpen={disarmDialogOpen}
          setDisarmDialogOpen={setDisarmDialogOpen}
          armDialogOpen={armDialogOpen}
          setArmDialogOpen={setArmDialogOpen}
          deleteSiteDialogOpen={deleteSiteDialogOpen}
          setDeleteSiteDialogOpen={setDeleteSiteDialogOpen}
          navigate={navigate}
          setSyncNeeded={setSyncNeeded}
          noAutoAddCamerasDialogOpen={noAutoAddCamerasDialogOpen}
          setNoAutoAddCamerasDialogOpen={setNoAutoAddCamerasDialogOpen}
          setArmedStatus={setArmedStatus}
          setArmButtonDisabled={setArmButtonDisabled}
          setEditMode={setEditMode}
          clickedTab={clickedTab}
          vmsVariables={vmsVariables}
          handleSaveChangesButtonClick={handleSaveChangesButtonClick}
          siteSchedules={siteSchedules}
          setSchedulesTabActive={setSchedulesTabActive}
          setClickedTab={setClickedTab}
          siteInfoChangesMade={siteInfoChangesMade}
          setSiteInfoChangesMade={setSiteInfoChangesMade}
          scheduleChangesMade={scheduleChangesMade}
          setSiteSchedules={setSiteSchedules}
          addCameraHealthDialogOpen={addCameraHealthDialogOpen}
          setAddCameraHealthDialogOpen={setAddCameraHealthDialogOpen}
          healthCheckData={healthCheckData}
          setCameraHealthPatchObject={setCameraHealthPatchObject}
          setCameraHealthChangesMade={setCameraHealthChangesMade}
          setHealthCheckData={setHealthCheckData}
          turnOffHealthDialogOpen={turnOffHealthDialogOpen}
          setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
          permissions={permissions}
          deactivateSiteInsteadDialogOpen={deactivateSiteInsteadDialogOpen}
          setDeactivateSiteInsteadDialogOpen={
            setDeactivateSiteInsteadDialogOpen
          }
          activateSiteWithScheduleDialogOpen={
            activateSiteWithScheduleDialogOpen
          }
          setActivateSiteWithScheduleDialogOpen={
            setActivateSiteWithScheduleDialogOpen
          }
          armSiteFlexScheduleDialogOpen={armSiteFlexScheduleDialogOpen}
          setArmSiteFlexScheduleDialogOpen={setArmSiteFlexScheduleDialogOpen}
          setToolTipTitle={setToolTipTitle}
        />
        <SiteAboutAlerts
          siteDeployed={siteDeployed}
          aiLinkSite={aiLinkSite}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setSyncNeeded={setSyncNeeded}
          setAlertState={setAlertState}
          siteInfo={siteInfo}
          setSiteInfo={setSiteInfo}
          setAllSiteSchedules={setAllSiteSchedules}
          setActiveTab={setActiveTab}
          vmsVariables={vmsVariables}
          setSiteDeployed={setSiteDeployed}
          setDeativateInquiryDialogOpen={setDeativateInquiryDialogOpen}
          setSiteCameras={setSiteCameras}
          camerasNotDeployed={camerasNotDeployed}
          setCamerasNotDeployed={setCamerasNotDeployed}
          navigate={navigate}
          setVmsVariables={setVmsVariables}
          alertState={alertState}
          setEditMode={setEditMode}
          setClickedTab={setClickedTab}
          setArmButtonDisabled={setArmButtonDisabled}
          setArmedStatus={setArmedStatus}
        />
        <BackdropLoadingScreen openState={backdropLoadingScreenOn} />
        {dataLoaded && (
          <div
            className={"bg-actuate-grey absolute w-full h-full overflow-scroll"}
            onScroll={setScrollTop}
            ref={ref}
          >
            <ErrorBoundary>
              <div
                className={
                  !navBarCollapsed
                    ? "absolute top-[15px] left-[150px] right-0 h-[59px] bg-white min-w-[1370px]"
                    : "absolute top-[15px] left-[75px] right-0 h-[59px] bg-white min-w-[1370px]"
                }
              >
                <BreadCrumbsComponet
                  linksArray={createLinksArray(siteInfo.breadcrumb_info)}
                  handleClick={handleSiteBreadcrumbClick}
                  styling="absolute top-[16px] left-[30px]"
                  breadcrumb_info={siteInfo.breadcrumb_info}
                />
              </div>
            </ErrorBoundary>
            <ErrorBoundary>
              <div
                className={
                  !navBarCollapsed
                    ? "absolute top-[87px] left-[150px] right-[1px] h-[58px] bg-white min-w-[1370px]"
                    : "absolute top-[87px] left-[75px] right-[1px] h-[58px] bg-white min-w-[1370px]"
                }
              >
                <p className="font-black font-mulish text-[20px] p-3 indent-4">
                  About
                </p>
                <FormGroup>
                  {!aiLinkSite && permissions.arm_disarm && (
                    <Tooltip
                      title={toolTipTitle}
                      placement="top"
                      sx={{ fontSize: "15px", fontFamily: "mulish" }}
                    >
                      <FormControlLabel
                        className="absolute right-[360px] top-[10px]"
                        control={
                          <Switch
                            checked={armedStatus}
                            color="warning"
                            disabled={armButtonDisabled || !siteInfo.active}
                            onChange={(event) => {
                              if (event.target.checked) {
                                if (siteInfo.has_flex_schedule) {
                                  setArmSiteFlexScheduleDialogOpen(true);
                                } else {
                                  setArmDialogOpen(true);
                                }
                              } else {
                                setDisarmDialogOpen(true);
                              }
                            }}
                          />
                        }
                        label="Armed"
                      />
                    </Tooltip>
                  )}

                  <div className={"absolute top-[11px] right-[655px]"}>
                    {/* <OrangeTextButton
                      onClick={() => {
                        navigate(
                          `/alerts?group=${siteInfo.breadcrumb_info[0].id}&site=${siteInfo.id}`
                        );
                      }}
                      text={seeAlertText}
                      disabled={false}
                    /> */}
                    <ThemeProvider theme={theme}>
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          navigate(
                            `/alerts?group=${siteInfo.breadcrumb_info[0].id}&site=${siteInfo.id}`
                          );
                        }}
                        disabled={false}
                        className="hover:bg-[#E67300]"
                      >
                        <Link
                          href={`/alerts?group=${siteInfo.breadcrumb_info[0].id}&site=${siteInfo.id}`}
                          onClick={(e) => {
                            e.preventDefault(); // Prevent default anchor navigation
                            navigate(
                              `/alerts?group=${siteInfo.breadcrumb_info[0].id}&site=${siteInfo.id}`
                            );
                          }}
                          sx={{
                            textDecoration: "none",
                            color: "inherit",
                            cursor: "pointer",
                            height: "100%",
                          }}
                        >
                          {seeAlertText}
                        </Link>
                      </Button>
                    </ThemeProvider>
                  </div>
                  <div className={"absolute top-[11px] right-[815px]"}>
                    <OrangeTextButton
                      onClick={() => {
                        navigate(`/actionlogs/${siteInfo.id}`);
                      }}
                      text={ActionLogsText}
                      disabled={false}
                    />
                  </div>
                  {permissions.arm_disarm && (
                    <FormControlLabel
                      className="absolute right-[505px] top-[10px]"
                      control={
                        <Switch
                          checked={siteInfo.active}
                          color="warning"
                          onChange={(event) => {
                            if (event.target.checked) {
                              setPatchObject((previousState: PatchObject) => {
                                return {
                                  ...previousState,
                                  active: true,
                                };
                              });
                              setActivateSiteDialogOpen(true);
                            } else {
                              setPatchObject((previousState: PatchObject) => {
                                return {
                                  ...previousState,
                                  active: false,
                                };
                              });
                              setDeactivateSiteDialogOpen(true);
                            }
                          }}
                        />
                      }
                      label="Active"
                    />
                  )}

                  <div className={"absolute top-[11px] right-52"}>
                    {permissions.delete_cameras && (
                      <OrangeTextButton
                        onClick={() => setDeleteSiteDialogOpen(true)}
                        text={deleteSiteText}
                        disabled={false}
                      />
                    )}
                  </div>
                  <div className={"absolute top-[10px] right-12"}>
                    <SyncSiteButton
                      syncNeeded={syncNeeded}
                      setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                      setSyncNeeded={setSyncNeeded}
                      setAlertState={setAlertState}
                      siteInfo={siteInfo}
                      setSiteInfo={setSiteInfo}
                      setAllSiteSchedules={setAllSiteSchedules}
                      setActiveTab={setActiveTab}
                      vmsVariables={vmsVariables}
                      setSiteDeployed={setSiteDeployed}
                      setDeativateInquiryDialogOpen={
                        setDeativateInquiryDialogOpen
                      }
                      setSiteCameras={setSiteCameras}
                      camerasNotDeployed={camerasNotDeployed}
                      setCamerasNotDeployed={setCamerasNotDeployed}
                      navigate={navigate}
                      setVmsVariables={setVmsVariables}
                      setClickedTab={setClickedTab}
                      setArmButtonDisabled={setArmButtonDisabled}
                      setArmedStatus={setArmedStatus}
                    />
                  </div>
                </FormGroup>
                <div className="absolute right-[1000px] top-[8px]">
                  {configureMotion && (
                    <ConfigureMotionButton
                      setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                      setAlertState={setAlertState}
                      navigate={navigate}
                    />
                  )}
                </div>
              </div>
            </ErrorBoundary>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[157px] left-[150px] right-[1px] min-w-[1370px]"
                  : "absolute top-[157px] left-[75px] right-[1px] min-w-[1370px]"
              }
            >
              <ErrorBoundary>
                <AboutTable siteInfo={siteInfo} />
              </ErrorBoundary>
            </div>
            <div></div>
            <ErrorBoundary>
              <div
                className={
                  !navBarCollapsed
                    ? "absolute top-[285px] left-[150px] right-[1px] h-[60px] min-w-[1370px] bg-white"
                    : "absolute top-[285px] left-[75px] right-[1px] h-[60px] min-w-[1370px] bg-white"
                }
              >
                <TabsComponet
                  clickedTab={clickedTab}
                  setClickedTab={setClickedTab}
                  editMode={editMode}
                  setActiveTab={setActiveTab}
                  setPatchObject={setPatchObject}
                  setSiteInfo={setSiteInfo}
                  vmsVariables={vmsVariables}
                  setVmsVariables={setVmsVariables}
                  siteSchedules={siteSchedules}
                  siteInfo={siteInfo}
                  setSiteSchedules={setSiteSchedules}
                  setSchedulesTabActive={setSchedulesTabActive}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setAlertState={setAlertState}
                  setSyncNeeded={setSyncNeeded}
                  setSiteInfoChangesMade={setSiteInfoChangesMade}
                  setScheduleChangesMade={setScheduleChangesMade}
                  setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                  recordingServerData={recordingServerData}
                  recordingServerPatchObject={recordingServerPatchObject}
                  setRecordingServerPatchObject={setRecordingServerPatchObject}
                  setRecordingServerChangesMade={setRecordingServerChangesMade}
                  recordingServerVisible={recordingServerVisible}
                  healthCheckData={healthCheckData}
                  setCameraHealthPatchObject={setCameraHealthPatchObject}
                  setCameraHealthChangesMade={setCameraHealthChangesMade}
                  setAddCameraHealthDialogOpen={setAddCameraHealthDialogOpen}
                  setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
                  webhooksInfo={webhooksInfo}
                  setWebhooksPatchObject={setWebhooksPatchObject}
                  setWebhooksChangesMade={setWebhooksChangesMade}
                  setEditMode={setEditMode}
                />
                <div className={"absolute top-[12px] right-[20px]"}>
                  {!editMode &&
                    (permissions.add_cameras || permissions.edit_cameras) && (
                      <EditInformationButton
                        clickedTab={clickedTab}
                        setEditMode={setEditMode}
                        setActiveTab={setActiveTab}
                        setPatchObject={setPatchObject}
                        setSiteInfo={setSiteInfo}
                        vmsVariables={vmsVariables}
                        setVmsVariables={setVmsVariables}
                        siteSchedules={siteSchedules}
                        siteInfo={siteInfo}
                        setSiteSchedules={setSiteSchedules}
                        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                        editMode={editMode}
                        setSchedulesTabActive={setSchedulesTabActive}
                        setClickedTab={setClickedTab}
                        setAlertState={setAlertState}
                        setSyncNeeded={setSyncNeeded}
                        setSiteInfoChangesMade={setSiteInfoChangesMade}
                        setScheduleChangesMade={setScheduleChangesMade}
                        setDeleteScheduleDialogOpen={
                          setDeleteScheduleDialogOpen
                        }
                        recordingServerData={recordingServerData}
                        recordingServerPatchObject={recordingServerPatchObject}
                        setRecordingServerPatchObject={
                          setRecordingServerPatchObject
                        }
                        setRecordingServerChangesMade={
                          setRecordingServerChangesMade
                        }
                        healthCheckData={healthCheckData}
                        setCameraHealthPatchObject={setCameraHealthPatchObject}
                        setCameraHealthChangesMade={setCameraHealthChangesMade}
                        setAddCameraHealthDialogOpen={
                          setAddCameraHealthDialogOpen
                        }
                        setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
                        webhooksInfo={webhooksInfo}
                        setWebhooksPatchObject={setWebhooksPatchObject}
                        setWebhooksChangesMade={setWebhooksChangesMade}
                        setSiteInfoBackup={setSiteInfoBackup}
                        setVmsVariablesBackup={setVmsVariablesBackup}
                        setSiteSchedulesBackup={setSiteSchedulesBackup}
                        setFlexScheduleOnEditButtonClick={
                          setFlexScheduleOnEditButtonClick
                        }
                      />
                    )}
                </div>
                <div
                  className={
                    width > 1300
                      ? "absolute top-[12px] right-[200px]"
                      : "absolute top-[12px] left-[747px]"
                  }
                >
                  {" "}
                  {schedulesTabActive &&
                    !editMode &&
                    siteInfo.integration_type_name !== "ailink" && (
                      <OrangeTextButton
                        onClick={() => {
                          navigate("/calendars");
                        }}
                        disabled={false}
                        text={
                          <>
                            <CalendarMonthIcon fontSize="small" />
                            &nbsp;View Calendars
                          </>
                        }
                      />
                    )}
                </div>

                <div
                  className={
                    width > 1300
                      ? "absolute top-[12px] right-[200px]"
                      : "absolute top-[12px] left-[747px]"
                  }
                >
                  {/* {schedulesTabActive && !editMode && (
                  <AddAnotherScheduleButton navigate={navigate} />
                )} */}
                </div>
                <div
                  className={
                    width > 1300
                      ? "absolute top-[12px] right-[20px]"
                      : "absolute top-[12px] left-[1008px]"
                  }
                >
                  {editMode && (
                    <SaveChangesButton
                      patchObject={patchObject}
                      setEditMode={setEditMode}
                      setSiteInfo={setSiteInfo}
                      setPatchObject={setPatchObject}
                      siteInfo={siteInfo}
                      clickedTab={clickedTab}
                      setActiveTab={setActiveTab}
                      vmsVariables={vmsVariables}
                      allSiteSchedules={allSiteSchedules}
                      siteSchedules={siteSchedules}
                      setAllSiteSchedules={setAllSiteSchedules}
                      setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                      setAlertState={setAlertState}
                      setSyncNeeded={setSyncNeeded}
                      editMode={editMode}
                      setSchedulesTabActive={setSchedulesTabActive}
                      setClickedTab={setClickedTab}
                      siteInfoChangesMade={siteInfoChangesMade}
                      setSiteInfoChangesMade={setSiteInfoChangesMade}
                      scheduleChangesMade={scheduleChangesMade}
                      setScheduleChangesMade={setScheduleChangesMade}
                      setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                      setSiteSchedules={setSiteSchedules}
                      setArmButtonDisabled={setArmButtonDisabled}
                      recordingServerChangesMade={recordingServerChangesMade}
                      recordingServerData={recordingServerData}
                      recordingServerPatchObject={recordingServerPatchObject}
                      setRecordingServerData={setRecordingServerData}
                      setRecordingServerPatchObject={
                        setRecordingServerPatchObject
                      }
                      setRecordingServerChangesMade={
                        setRecordingServerChangesMade
                      }
                      cameraHealthChangesMade={cameraHealthChangesMade}
                      healthCheckData={healthCheckData}
                      setHealthCheckData={setHealthCheckData}
                      cameraHealthPatchObject={cameraHealthPatchObject}
                      setCameraHealthPatchObject={setCameraHealthPatchObject}
                      setCameraHealthChangesMade={setCameraHealthChangesMade}
                      setArmedStatus={setArmedStatus}
                      webhooksChangesMade={webhooksChangesMade}
                      webhooksInfo={webhooksInfo}
                      setWebhooksInfo={setWebhooksInfo}
                      webhooksPatchObject={webhooksPatchObject}
                      setWebhooksPatchObject={setWebhooksPatchObject}
                      setWebhooksChangesMade={setWebhooksChangesMade}
                      setDeactivateSiteInsteadDialogOpen={
                        setDeactivateSiteInsteadDialogOpen
                      }
                      setActivateSiteWithScheduleDialogOpen={
                        setActivateSiteWithScheduleDialogOpen
                      }
                    />
                  )}
                </div>
                <div
                  className={
                    width > 1300
                      ? "absolute top-[12px] right-[200px]"
                      : "absolute top-[12px] left-[878px]"
                  }
                >
                  {editMode && (
                    <CancelButton
                      setEditMode={setEditMode}
                      clickedTab={clickedTab}
                      setActiveTab={setActiveTab}
                      vmsVariables={vmsVariables}
                      siteSchedules={siteSchedules}
                      siteInfo={siteInfo}
                      setDeleteScheduleDialogOpen={setDeleteScheduleDialogOpen}
                      recordingServerData={recordingServerData}
                      setRecordingServerPatchObject={
                        setRecordingServerPatchObject
                      }
                      healthCheckData={healthCheckData}
                      webhooksInfo={webhooksInfo}
                      siteInfoBackup={siteInfoBackup}
                      setSiteInfo={setSiteInfo}
                      vmsVariablesBackup={vmsVariablesBackup}
                      setVmsVariables={setVmsVariables}
                      flexScheduleOnEditButtonClick={
                        flexScheduleOnEditButtonClick
                      }
                      siteSchedulesBackup={siteSchedulesBackup}
                    />
                  )}
                </div>
              </div>
            </ErrorBoundary>
            <ErrorBoundary>
              <div
                className={
                  !navBarCollapsed
                    ? "absolute top-[342px] left-[150px] right-[1px] h-[17vh] min-w-[1370px]"
                    : "absolute top-[342px] left-[75px] right-[1px] h-[17vh] min-w-[1370px]"
                }
              >
                {activeTab}
                {!camerasRefreshing && (
                  <div className="pt-[15px]">
                    <SiteCamerasTable
                      setAlertState={setAlertState}
                      setSyncNeeded={setSyncNeeded}
                      setDeativateInquiryDialogOpen={
                        setDeativateInquiryDialogOpen
                      }
                      siteCameras={siteCameras}
                      setSiteCameras={setSiteCameras}
                      setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                      setNoAutoAddCamerasDialogOpen={
                        setNoAutoAddCamerasDialogOpen
                      }
                      siteInfo={siteInfo}
                      setSiteInfo={setSiteInfo}
                      setSiteSchedules={setSiteSchedules}
                      setActiveTab={setActiveTab}
                      setDataLoaded={setDataLoaded}
                      setAllSiteSchedules={setAllSiteSchedules}
                      setVmsVariables={setVmsVariables}
                      setArmedStatus={setArmedStatus}
                      setAiLinkSite={setAiLinkSite}
                      setSiteDeployed={setSiteDeployed}
                      setCamerasNotDeployed={setCamerasNotDeployed}
                      setToolTipTitle={setToolTipTitle}
                      setConfigureMotion={setConfigureMotion}
                      setPatchObject={setPatchObject}
                      setArmButtonDisabled={setArmButtonDisabled}
                      setRecordingServerData={setRecordingServerData}
                      setRecordingServerPatchObject={
                        setRecordingServerPatchObject
                      }
                      setRecordingServerVisible={setRecordingServerVisible}
                      setHealthCheckData={setHealthCheckData}
                      setCamerasRefreshing={setCamerasRefreshing}
                      permissions={permissions}
                      setWebhooksInfo={setWebhooksInfo}
                      setWebhooksPatchObject={setWebhooksPatchObject}
                      siteSchedules={siteSchedules}
                    />
                  </div>
                )}
              </div>
            </ErrorBoundary>
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
