import React from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { EnhancedTablePropsInvestigation } from "./InvestigationUtilities/investigationInterfaces";
import { visuallyHidden } from "@mui/utils";
import { AlertData, HeadCell } from "../Alerts/AlertsUtils/alertsInterfaces";

const headCells: readonly HeadCell[] = [
  {
    id: "site",
    numeric: false,
    label: "Site",
  },
  {
    id: "camera_name",
    numeric: false,
    label: "Camera Name",
  },
  {
    id: "utc_time",
    numeric: false,
    label: "Received (UTC)",
  },
  {
    id: "time",
    numeric: false,
    label: `Received (Local)`,
  },
  {
    id: "clip_result",
    numeric: false,
    label: "Clip Result",
  },
  {
    id: "file_id",
    numeric: true,
    label: "File ID",
  },
  // {
  //   id: "ground_truth",
  //   numeric: false,
  //   label: "Ground Truth",
  // },
];

export const EnhancedInvestigationTableHead = (
  props: EnhancedTablePropsInvestigation
) => {
  const { onRequestSort, orderBy, order } = props;
  const createSortHandler =
    (property: keyof AlertData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              width: 30,
            }}
            sx={{
              fontFamily: "mulish",
              fontWeight: 700,
              fontSize: 8.5,
              width: 150,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
