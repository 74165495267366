import React from "react";

export const handleDecreasePointsBy30 = (array: number[][][]): number[][][] => {
  return array.map((subArray) =>
    subArray.map((numberArray) => numberArray.map((num) => num - 30))
  );
};

export const handleIncrementPointsBy30 = (
  array: number[][][]
): number[][][] => {
  return array.map((subArray) =>
    subArray.map((numberArray) => numberArray.map((num) => num + 30))
  );
};
