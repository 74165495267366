import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const CameraDropDownAlertsPage = ({
  setUserFilters,
  cameraOptions,
  cameraDropDownValue,
  setCameraDropDownValue,
  siteOptions,
  loadingCameras,
  navBarCollapsed,
}: {
  setUserFilters: Dispatcher<any>;
  cameraOptions: any;
  cameraDropDownValue: string[];
  setCameraDropDownValue: Dispatcher<string[]>;
  siteOptions: any;
  loadingCameras: boolean;
  navBarCollapsed: boolean;
}) => {
  const cameraIds = new Map();
  const cameraNames = new Map();
  cameraOptions.forEach((object: any) => {
    cameraIds.set(object.camera_name, object.id);
  });
  cameraOptions.forEach((object: any) => {
    cameraNames.set(object.id, object.camera_name);
  });

  const siteIds = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  return (
    <div>
      <Autocomplete
        disablePortal
        multiple
        limitTags={1}
        value={cameraDropDownValue}
        options={
          cameraOptions[0]
            ? cameraOptions.map((object: any) => {
                return object.camera_name;
              })
            : []
        }
        loading={loadingCameras}
        onChange={(event, value: string[]) => {
          if (value[0]) {
            const cameraIdsArray: string[] = [];
            const cameraValuesArray: string[] = [];
            value.forEach((element: string) => {
              cameraIdsArray.push(cameraIds.get(element));
              cameraValuesArray.push(element);
            });
            setCameraDropDownValue(cameraValuesArray);
            setUserFilters((previousState: any) => {
              return { ...previousState, camera: cameraIdsArray };
            });
          } else {
            setCameraDropDownValue([]);
            setUserFilters((previousState: any) => {
              return {
                ...previousState,
                camera: [],
              };
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Camera"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingCameras ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
            sx={!navBarCollapsed ? { width: 150 } : { width: 175 }}
          />
        )}
      />
    </div>
  );
};
