import React from "react";
import { TrueThreatButton } from "./TrueThreatButton";
import { TrueNonThreatButton } from "./TrueNonThreatButton";
import { AlertData } from "./AlertsUtils/alertsInterfaces";
import { FalseButton } from "./FalseButton";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { handleCreateAlertTypeNoUnderscore } from "./AlertsTable";
import { EscalatedTrueThreatButton } from "./EscalatedTrueThreatButton";

export const AlertsIdentifierBox = ({
  selectedRow,
  alertData,
  setAlertData,
  setSelectedRow,
  selectdButtonsFilled,
  setSelectedButtonsFilled,
  navigate,
  setPageLoading,
  setButtonClicked,
  pageLoadedNumber,
  setPageLoadedNumber,
  setFetchingFilteredData,
  setSeeMoreURL,
  setMP4URL,
  userFilters,
  setThumbnailVideos,
  setDateOutsideRange,
  setPage,
  setVideoClipLoading,
  setAlertsToDisplay,
  setSelectedAlertCameraInfo,
  setIgnoreZonesSelectedAlert,
  setAlertsIndexOrder,
  setRefreshInterval,
  setCurrentName,
  setUserFilters,
  setRefreshingData,
  refreshInterval,
  cameraOptions,
  siteOptions,
  setRefreshingToken,
  refreshingToken,
  setAlertLabels,
  setPeriodHours,
  setQueryLimitHit,
  setForwardArrowDisabled,
  setBackArrowDisabled,
  setShowRefreshButton,
  setRowsSortable,
  setAlertsRefreshing,
}: {
  selectedRow: AlertData;
  alertData: AlertData[];
  setAlertData: Dispatcher<AlertData[]>;
  setSelectedRow: Dispatcher<AlertData>;
  selectdButtonsFilled: any;
  setSelectedButtonsFilled: any;
  navigate: NavigateFunction;
  setPageLoading: Dispatcher<boolean>;
  setButtonClicked: Dispatcher<boolean>;
  pageLoadedNumber: any;
  setPageLoadedNumber: Dispatcher<any>;
  setFetchingFilteredData: Dispatcher<boolean>;
  setSeeMoreURL: Dispatcher<string>;
  setMP4URL: Dispatcher<string>;
  userFilters: any;
  setThumbnailVideos: any;
  setDateOutsideRange: Dispatcher<boolean>;
  setPage: Dispatcher<number>;
  setVideoClipLoading: Dispatcher<boolean>;
  setAlertsToDisplay: Dispatcher<boolean>;
  setSelectedAlertCameraInfo: any;
  setIgnoreZonesSelectedAlert: any;
  setAlertsIndexOrder: any;
  setRefreshInterval: any;
  setCurrentName: any;
  setUserFilters: any;
  setRefreshingData: any;
  refreshInterval: number;
  cameraOptions: any;
  siteOptions: any;
  setRefreshingToken: Dispatcher<boolean>;
  refreshingToken: boolean;
  setAlertLabels: Dispatcher<string[]>;
  setPeriodHours: Dispatcher<number>;
  setQueryLimitHit: Dispatcher<boolean>;
  setForwardArrowDisabled: Dispatcher<boolean>;
  setBackArrowDisabled: Dispatcher<boolean>;
  setShowRefreshButton: Dispatcher<boolean>;
  setRowsSortable: Dispatcher<boolean>;
  setAlertsRefreshing: Dispatcher<boolean>;
}) => {
  return selectedRow.detection_type === "escalated_true_threat" ||
    selectedRow.detection_type === "true_threat" ? (
    <div className="w-[615px] h-[160px] rounded-lg  bg-white">
      <div className="relative left-[155px] top-[7px] w-[400px] text-actuate-blue font-mulish text-[16px]">
        {selectedRow.alert_labels
          ? `Was this alert a true ${handleCreateAlertTypeNoUnderscore(
              selectedRow.alert_labels
            )} detection?`
          : ""}
      </div>
      <div className="flex justify-between w-[580px] pl-[35px] pt-[15px]">
        <div>
          <EscalatedTrueThreatButton
            detection_type={selectedRow.detection_type}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
          />
        </div>
        <div>
          <TrueThreatButton
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
            pageLoadedNumber={pageLoadedNumber}
            setPageLoadedNumber={setPageLoadedNumber}
            setFetchingFilteredData={setFetchingFilteredData}
            setSeeMoreURL={setSeeMoreURL}
            setMP4URL={setMP4URL}
            userFilters={userFilters}
            setThumbnailVideos={setThumbnailVideos}
            setDateOutsideRange={setDateOutsideRange}
            setPage={setPage}
            setVideoClipLoading={setVideoClipLoading}
            setAlertsToDisplay={setAlertsToDisplay}
            setSelectedAlertCameraInfo={setSelectedAlertCameraInfo}
            setIgnoreZonesSelectedAlert={setIgnoreZonesSelectedAlert}
            setAlertsIndexOrder={setAlertsIndexOrder}
            setRefreshInterval={setRefreshInterval}
            setCurrentName={setCurrentName}
            setUserFilters={setUserFilters}
            setRefreshingData={setRefreshingData}
            refreshInterval={refreshInterval}
            cameraOptions={cameraOptions}
            siteOptions={siteOptions}
            setRefreshingToken={setRefreshingToken}
            refreshingToken={refreshingToken}
            setAlertLabels={setAlertLabels}
            setPeriodHours={setPeriodHours}
            setQueryLimitHit={setQueryLimitHit}
            setForwardArrowDisabled={setForwardArrowDisabled}
            setBackArrowDisabled={setBackArrowDisabled}
            setShowRefreshButton={setShowRefreshButton}
            setRowsSortable={setRowsSortable}
            setAlertsRefreshing={setAlertsRefreshing}
          />
        </div>
      </div>
      <div className="flex justify-between w-[580px] pl-[35px] pt-[15px]">
        <div>
          <TrueNonThreatButton
            detection_type={selectedRow.detection_type}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
          />
        </div>
        <div>
          <FalseButton
            detection_type={selectedRow.detection_type}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="w-[615px] h-[100px] rounded-lg  bg-white">
      <div className="relative left-[155px] top-[7px] w-[400px] text-actuate-blue font-mulish text-[16px]">
        {selectedRow.alert_labels
          ? `Was this alert a true ${handleCreateAlertTypeNoUnderscore(
              selectedRow.alert_labels
            )} detection?`
          : ""}
      </div>
      <div className="flex justify-between w-[600px] pl-[13px] pt-[15px]">
        <div>
          <TrueThreatButton
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
            pageLoadedNumber={pageLoadedNumber}
            setPageLoadedNumber={setPageLoadedNumber}
            setFetchingFilteredData={setFetchingFilteredData}
            setSeeMoreURL={setSeeMoreURL}
            setMP4URL={setMP4URL}
            userFilters={userFilters}
            setThumbnailVideos={setThumbnailVideos}
            setDateOutsideRange={setDateOutsideRange}
            setPage={setPage}
            setVideoClipLoading={setVideoClipLoading}
            setAlertsToDisplay={setAlertsToDisplay}
            setSelectedAlertCameraInfo={setSelectedAlertCameraInfo}
            setIgnoreZonesSelectedAlert={setIgnoreZonesSelectedAlert}
            setAlertsIndexOrder={setAlertsIndexOrder}
            setRefreshInterval={setRefreshInterval}
            setCurrentName={setCurrentName}
            setUserFilters={setUserFilters}
            setRefreshingData={setRefreshingData}
            refreshInterval={refreshInterval}
            cameraOptions={cameraOptions}
            siteOptions={siteOptions}
            setRefreshingToken={setRefreshingToken}
            refreshingToken={refreshingToken}
            setAlertLabels={setAlertLabels}
            setPeriodHours={setPeriodHours}
            setQueryLimitHit={setQueryLimitHit}
            setForwardArrowDisabled={setForwardArrowDisabled}
            setBackArrowDisabled={setBackArrowDisabled}
            setShowRefreshButton={setShowRefreshButton}
            setRowsSortable={setRowsSortable}
            setAlertsRefreshing={setAlertsRefreshing}
          />
        </div>
        <div>
          <TrueNonThreatButton
            detection_type={selectedRow.detection_type}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
          />
        </div>
        <div>
          <FalseButton
            detection_type={selectedRow.detection_type}
            alertData={alertData}
            setAlertData={setAlertData}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            selectdButtonsFilled={selectdButtonsFilled}
            setSelectedButtonsFilled={setSelectedButtonsFilled}
            navigate={navigate}
            setPageLoading={setPageLoading}
            setButtonClicked={setButtonClicked}
          />
        </div>
      </div>
    </div>
  );
};
