import React from "react";
import { Dispatcher } from "./SiteAboutPageUtils/siteAboutInterfaces";
import { Dialog } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export const EditScheduleNameDialog = ({
  siteSchedules,
  editScheduleNameDialogOpen,
  setEditScheduleNameDialogOpen,
  setSiteSchedules,
  setScheduleChangesMade,
}: {
  siteSchedules: any;
  editScheduleNameDialogOpen: boolean;
  setEditScheduleNameDialogOpen: Dispatcher<boolean>;
  setSiteSchedules: Dispatcher<any>;
  setScheduleChangesMade: Dispatcher<boolean>;
}) => {
  const schedulesIndex = Number(localStorage.schedulesIndex);

  const [scheduleName, setScheduleName] = React.useState(
    siteSchedules[schedulesIndex].display_name
  );

  return (
    <Dialog
      open={editScheduleNameDialogOpen}
      onClose={() => setEditScheduleNameDialogOpen(false)}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ background: "#001943" }}>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Schedule Name
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div>
          <div>
            <div className=" bg-white">
              <div>
                <div className="pt-[20px]">
                  <TextField
                    label="Schedule Name"
                    autoComplete="off"
                    value={scheduleName}
                    onChange={(newValue) => {
                      setScheduleName(newValue.target.value);
                    }}
                    disabled={false}
                    sx={{ width: 400 }}
                    variant="standard"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex pr-[20px] pt-[20px]" style={{ display: "flex" }}>
          <div style={{ marginLeft: "auto" }}>
            <OrangeTextButton
              onClick={() => {
                setEditScheduleNameDialogOpen(false);
              }}
              text="Cancel"
              disabled={false}
            />
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            <OrangeTextButton
              onClick={() => {
                const newSiteSchedules = siteSchedules;
                newSiteSchedules[schedulesIndex].display_name = scheduleName;
                setSiteSchedules(newSiteSchedules);
                setScheduleChangesMade(true);
                setEditScheduleNameDialogOpen(false);
              }}
              text="Ok"
              disabled={false}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};
