import React from "react";
import { createApi } from "../../../utils/createApi";
import { AxiosResponse } from "axios";

export const getAllCameras = (
  customerId: number,
  cameraId: number,
  setAllCamerasData: any,
  setCameraIndex: any
) => {
  const AxiosUI = createApi(``);

  let allCamerasData: any = [];

  const getAllCamerasAPICall = (pageNumber: number) => {
    AxiosUI.get(
      `camera/site/?customer__id=${customerId}&page=${pageNumber}`
    ).then((response: AxiosResponse) => {
      if (response.data.count) {
        if (pageNumber === 1) {
          allCamerasData = response.data.results;
        }
        if (pageNumber > 1) {
          allCamerasData.push(...response.data.results);
        }
        if (response.data.next) {
          getAllCamerasAPICall(pageNumber + 1);
        } else {
          allCamerasData.forEach((camera: any, index: number) => {
            if (camera.id === cameraId) {
              setCameraIndex(index);
            }
          });
          setAllCamerasData(allCamerasData);
        }
      }
    });
  };
  getAllCamerasAPICall(1);
};
