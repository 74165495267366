import React from "react";
import { GeneralInfoTab } from "../GeneralInfoTab";
import { VmsTab } from "../VmsTab";
import { SchedulesTab } from "../SchedulesTab";
import { SMTPTab } from "../SMTPTab";
import { RecordingServerTab } from "../RecordingServerTab";
import { CameraHealthTab } from "../CameraHealthTab";
import { WebhooksTab } from "../WebhooksTab";

export const handleCancelButtonClick = (
  setEditMode: any,
  clickedTab: any,
  setActiveTab: any,
  vmsVariables: any,
  siteSchedules: any,
  siteInfo: any,
  setDeleteScheduleDialogOpen: any,
  recordingServerData: any,
  setRecordingServerPatchObject: any,
  healthCheckData: any,
  webhooksInfo: any,
  siteInfoBackup: any,
  setSiteInfo: any,
  vmsVariablesBackup: any,
  setVmsVariables: any,
  flexScheduleOnEditButtonClick: boolean,
  siteSchedulesBackup: any
) => {
  const backupSiteInfo = siteInfoBackup;

  setSiteInfo(backupSiteInfo);
  setVmsVariables(vmsVariablesBackup);
  setEditMode(false);
  if (siteInfo.integration_type_name === "milestone") {
    setRecordingServerPatchObject(
      JSON.parse(localStorage.recordingServerBackup)
    );
  }

  let siteSchedulesBackupFinal;

  if (
    !flexScheduleOnEditButtonClick &&
    localStorage.flexSchedulesOn === "false"
  ) {
    if (localStorage.flexSchedulingChanged === "true") {
      siteSchedulesBackupFinal = JSON.parse(
        localStorage.backUpStandardSchedule
      );
    } else {
      siteSchedulesBackupFinal = siteSchedulesBackup;
    }
    backupSiteInfo.has_flex_schedule = false;
  }

  if (
    flexScheduleOnEditButtonClick &&
    localStorage.flexSchedulesOn === "true"
  ) {
    if (localStorage.flexSchedulingChanged === "true") {
      siteSchedulesBackupFinal = JSON.parse(localStorage.backUpFlexSchedule);
    } else {
      siteSchedulesBackupFinal = siteSchedulesBackup;
    }
    backupSiteInfo.has_flex_schedule = true;
  }

  if (
    !flexScheduleOnEditButtonClick &&
    localStorage.flexSchedulesOn === "true"
  ) {
    siteSchedulesBackupFinal = JSON.parse(localStorage.backUpFlexSchedule);
  }

  if (
    flexScheduleOnEditButtonClick &&
    localStorage.flexSchedulesOn === "false"
  ) {
    siteSchedulesBackupFinal = JSON.parse(localStorage.backUpStandardSchedule);
  }

  if (clickedTab === "one") {
    setActiveTab(
      <GeneralInfoTab
        siteInfo={backupSiteInfo}
        setSiteInfo={setSiteInfo}
        setActiveTab={setActiveTab}
      />
    );
  } else if (clickedTab === "two") {
    setActiveTab(
      <VmsTab vmsVariables={vmsVariablesBackup} siteInfo={backupSiteInfo} />
    );
  } else if (clickedTab === "three") {
    setActiveTab(
      <SchedulesTab
        siteSchedules={siteSchedulesBackupFinal}
        siteInfo={backupSiteInfo}
      />
    );
  } else if (clickedTab === "four") {
    setActiveTab(<SMTPTab />);
  } else if (clickedTab === "five") {
    setActiveTab(
      <RecordingServerTab
        recordingServerData={JSON.parse(localStorage.recordingServerBackup)}
      />
    );
  } else if (clickedTab === "six") {
    setActiveTab(
      <CameraHealthTab
        healthCheckData={healthCheckData}
        siteInfo={backupSiteInfo}
      />
    );
  } else if (clickedTab === "seven") {
    setActiveTab(<WebhooksTab webhooksInfo={webhooksInfo} />);
  }
};
