import React, { useState, useCallback } from "react";
import "./ScheduleGrid.css"; // Make sure the CSS file is imported
import { getTime } from "date-fns";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { handleConvertSchedulesToBooleanArrays } from "./SiteAboutPageUtils/handleConvertSchedulesToBooleanArrays";
import { handleCreateInitialTimeSchedules } from "./SiteAboutPageUtils/handleCreateInitialTimeSchedules";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { handleCreateDuskToDawn } from "./SiteAboutPageUtils/handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";
import { Button, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { locationsMatcher } from "../../../utils/locationsMatcher";
import { Dispatcher } from "./SiteAboutPageUtils/siteAboutInterfaces";
import { handleCreateMatchingSchedules } from "./handleCreateMatchingSchedules";
import { handleUpdateSiteSchedules } from "./SiteAboutPageUtils/handleUpdateSiteSchedules";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const hoursOfDay = Array.from(
  { length: 24 },
  (_, i) => `${i.toString().padStart(2, "0")}:00`
);

export const ScheduleGrid = ({
  editable,
  setSelectedDayInfo,
  setEditTimeScheduleDialogOpen,
  timeRanges,
  setTimeRanges,
  grid,
  setGrid,
  duskToDawn,
  setDuskToDawn,
  duskToDawnLocation,
  setDuskToDawnLocation,
  setMatchingSchedules,
  setScheduleChangesMade,
  setSiteSchedules,
  setScheduleGrid,
  setEditModeTabSiteSchedules,
}: {
  editable: boolean;
  setSelectedDayInfo: any;
  setEditTimeScheduleDialogOpen: Dispatcher<boolean>;
  timeRanges: string[];
  setTimeRanges: Dispatcher<string[]>;
  grid: boolean[][];
  setGrid: Dispatcher<boolean[][]>;
  duskToDawn: boolean[];
  setDuskToDawn: Dispatcher<boolean[]>;
  duskToDawnLocation: string[][];
  setDuskToDawnLocation: Dispatcher<string[][]>;
  setMatchingSchedules: Dispatcher<number[][]>;
  setScheduleChangesMade: Dispatcher<boolean>;
  setSiteSchedules: Dispatcher<any>;
  setScheduleGrid: any;
  setEditModeTabSiteSchedules: Dispatcher<any>;
}) => {
  const width = 96; // 24 hours * 4 (15 minutes each)
  const height = 7; // Days of the week

  // Initialize grid state
  const initialGrid = Array.from({ length: height }, () =>
    Array.from({ length: width }, () => false)
  );

  const [isMouseDown, setIsMouseDown] = useState(false);

  const [startTile, setStartTile] = useState<{ x: number; y: number } | null>(
    null
  );
  const [selectedTiles, setSelectedTiles] = useState<
    { x: number; y: number }[]
  >([]);

  const handleMouseDown = useCallback((x: number, y: number) => {
    setIsMouseDown(true);
    setStartTile({ x, y });
    setSelectedTiles([{ x, y }]);
  }, []);

  const handleMouseUp = useCallback(
    (x: number, y: number) => {
      if (startTile) {
        changeTileColors(startTile, { x, y });
      }
      setIsMouseDown(false);
      setStartTile(null);
      setSelectedTiles([]);
    },
    [startTile]
  );

  const handleMouseOverOrFocus = useCallback(
    (x: number, y: number) => {
      if (isMouseDown && startTile) {
        const newSelectedTiles = calculateSelectedTiles(startTile, { x, y });
        setSelectedTiles(newSelectedTiles);
      }
    },
    [isMouseDown, startTile]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent, x: number, y: number) => {
      if (event.key === "Enter" || event.key === " ") {
        changeTileColors({ x, y }, { x, y });
      }
    },
    []
  );

  const calculateSelectedTiles = (
    start: { x: number; y: number },
    end: { x: number; y: number }
  ) => {
    const selected = [];
    const xMin = Math.min(start.x, end.x);
    const xMax = Math.max(start.x, end.x);
    const yMin = Math.min(start.y, end.y);
    const yMax = Math.max(start.y, end.y);

    for (let y = yMin; y <= yMax; y++) {
      for (let x = xMin; x <= xMax; x++) {
        selected.push({ x, y });
      }
    }

    return selected;
  };

  const changeTileColors = (
    start: { x: number; y: number },
    end: { x: number; y: number }
  ) => {
    const newGrid = grid.map((row) => [...row]); // Creates a deep copy
    const selected = calculateSelectedTiles(start, end);

    selected.forEach(({ x, y }) => {
      newGrid[y][x] = !newGrid[y][x]; // Toggle the value
    });

    if (checkInvalidTimeRanges(newGrid[start.y])) {
      alert("Only one start and end date are supported.");
      return;
    }

    //update dawn-to-dusk to be false if any of the tiles in the row have changed
    const newDawnToDusk = [...duskToDawn];
    for (let i = start.y; i <= end.y; i++) {
      newDawnToDusk[i] = false;
    }
    setDuskToDawn(newDawnToDusk);

    //update dawn-to-dusk-location to be false if any of the tiles in the row have changed
    const newDawnToDuskLocation = [...duskToDawnLocation];
    for (let i = start.y; i <= end.y; i++) {
      newDawnToDuskLocation[i] = ["", ""];
    }
    setDuskToDawnLocation(newDawnToDuskLocation);

    // update the timeRanges for only rows that were changed
    const newTimeRanges = [...timeRanges];
    for (let i = start.y; i <= end.y; i++) {
      newTimeRanges[i] = getTimeRanges(newGrid[i]);
    }
    setTimeRanges(newTimeRanges);

    // check each array in the new grid to see if they match, create an array of arrays with each subarray containing all of the matching schedules index numbers, so for example if only the time ranges from 0 and 1 match, the array would look like [[0, 1], [2], [3], [4], [5], [6]] also each number can only appear once in the array of arrays
    const newMatchingSchedules: number[][] = [];
    for (let i = 0; i < newGrid.length; i++) {
      const matchingScheduleIndexes: number[] = [];
      for (let j = 0; j < newGrid.length; j++) {
        if (newGrid[i].toString() === newGrid[j].toString()) {
          matchingScheduleIndexes.push(j);
        }
      }
      newMatchingSchedules.push(matchingScheduleIndexes);
    }
    // check the newMatchingSchedules to see if any subarrays are the same, if they are, remove the duplicate subarray
    for (let i = 0; i < newMatchingSchedules.length; i++) {
      for (let j = i + 1; j < newMatchingSchedules.length; j++) {
        if (
          newMatchingSchedules[i].toString() ===
          newMatchingSchedules[j].toString()
        ) {
          newMatchingSchedules.splice(j, 1);
        }
      }
    }
    for (let i = 0; i < newMatchingSchedules.length; i++) {
      for (let j = i + 1; j < newMatchingSchedules.length; j++) {
        if (
          newMatchingSchedules[i].toString() ===
          newMatchingSchedules[j].toString()
        ) {
          newMatchingSchedules.splice(j, 1);
        }
      }
    }

    if (newMatchingSchedules.length === 2) {
      if (
        newMatchingSchedules[0].toString() ===
        newMatchingSchedules[1].toString()
      ) {
        newMatchingSchedules.pop();
      }
    }

    setMatchingSchedules(newMatchingSchedules);

    handleUpdateSiteSchedules(
      newTimeRanges,
      newDawnToDuskLocation,
      newMatchingSchedules,
      setSiteSchedules,
      setEditModeTabSiteSchedules
    );

    setScheduleChangesMade(true);

    setGrid(newGrid);

    if (setScheduleGrid !== null) {
      setScheduleGrid(
        <ScheduleGrid
          editable={true}
          setSelectedDayInfo={setSelectedDayInfo}
          setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
          timeRanges={newTimeRanges}
          setTimeRanges={setTimeRanges}
          grid={newGrid}
          setGrid={setGrid}
          duskToDawn={newDawnToDusk}
          setDuskToDawn={setDuskToDawn}
          duskToDawnLocation={newDawnToDuskLocation}
          setDuskToDawnLocation={setDuskToDawnLocation}
          setMatchingSchedules={setMatchingSchedules}
          setScheduleChangesMade={setScheduleChangesMade}
          setSiteSchedules={setSiteSchedules}
          setScheduleGrid={setScheduleGrid}
          setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
        />
      );
    }
  };

  function checkInvalidTimeRanges(selected: boolean[]): boolean {
    if (selected.length !== 96) {
      throw new Error(
        "The input array must contain exactly 96 boolean values."
      );
    }

    const ranges: number[][] = []; // Stores start and end indexes
    let startIndex: number | null = null;

    for (let i = 0; i < selected.length; i++) {
      if (selected[i] && startIndex === null) {
        startIndex = i;
      } else if (!selected[i] && startIndex !== null) {
        ranges.push([startIndex, i]);
        startIndex = null;
      }
    }

    // Handle case where selection goes until the end
    if (startIndex !== null) {
      ranges.push([startIndex, selected.length]);
    }

    // Return true if the invalid condition is met
    return (
      ranges.length > 2 ||
      (ranges.length === 2 && (ranges[0][0] !== 0 || ranges[1][1] !== 96))
    );
  }

  function getTimeRanges(selected: boolean[]): string {
    if (selected.length !== 96) {
      throw new Error(
        "The input array must contain exactly 96 boolean values."
      );
    }
    const ranges: string[] = [];
    let startIndex: number | null = null;

    for (let i = 0; i < selected.length; i++) {
      if (selected[i] && startIndex === null) {
        startIndex = i;
      } else if (!selected[i] && startIndex !== null) {
        ranges.push(`${formatTime(startIndex)} - ${formatTime(i)}`);
        startIndex = null;
      }
    }

    // Handle case where the selection goes until the end of the array
    if (startIndex !== null) {
      ranges.push(`${formatTime(startIndex)} - ${formatTime(selected.length)}`);
    }

    if (ranges[0] === "00:00 - 24:00") {
      return "Always On";
    } else if (ranges.length > 2) {
      return "Only one start and end date are supported.";
    } else if (
      ranges.length === 2 &&
      (ranges[0].substring(0, 5) !== "00:00" ||
        ranges[1].substring(8, 13) !== "24:00")
    ) {
      return "Only one start and end date are supported.";
    } else if (
      ranges.length === 2 &&
      // check the first five charcters of ranges[0] to see if they are 00:00
      ranges[0].substring(0, 5) === "00:00" &&
      // check the last five charcters of ranges[1] to see if they are 24:00
      ranges[1].substring(8, 13) === "24:00"
    ) {
      return `${ranges[1].substring(0, 5)} - ${ranges[0].substring(8, 13)}`;
    } else return ranges.join(", ");
  }

  function formatTime(index: number): string {
    const totalMinutes = index * 15;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${padZero(hours)}:${padZero(minutes)}`;
  }

  function padZero(num: number): string {
    return num.toString().padStart(2, "0");
  }

  React.useEffect(() => {
    setMatchingSchedules(handleCreateMatchingSchedules(grid));
  }, []);

  return (
    <div className="schedule-grid-container">
      <table className="schedule-grid-table">
        <thead>
          <tr>
            <th rowSpan={2}></th> {/* Empty corner cell */}
            <th colSpan={48} className="time-label">
              AM
            </th>
            <th colSpan={48} className="time-label">
              PM
            </th>
            <th className="time-range-label" rowSpan={2}>
              ARM START-ARM END
            </th>
            {editable && (
              <th className="edit-label" rowSpan={2}>
                EDIT
              </th>
            )}
          </tr>
          <tr>
            {hoursOfDay.map((hour, index) => (
              <th key={index} colSpan={4} className="hour-label">
                {hour}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {grid.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <th className="day-label">
                <Button
                  disabled
                  sx={{
                    "&.Mui-disabled": {
                      color: "black", // Text color
                    },
                  }}
                >
                  {daysOfWeek[rowIndex]}
                </Button>
              </th>
              {row.map((isActive, colIndex) => {
                const isSelected = selectedTiles.some(
                  (tile) => tile.x === colIndex && tile.y === rowIndex
                );

                return editable ? (
                  <td
                    key={`${rowIndex}-${colIndex}`}
                    className={`tile ${isActive ? "active" : ""} ${
                      isSelected ? "selected" : ""
                    }`}
                    onMouseDown={() => {
                      editable && handleMouseDown(colIndex, rowIndex);
                    }}
                    onMouseUp={() => {
                      editable && handleMouseUp(colIndex, rowIndex);
                    }}
                    onMouseOver={() => {
                      editable && handleMouseOverOrFocus(colIndex, rowIndex);
                    }}
                    onFocus={() => {
                      editable && handleMouseOverOrFocus(colIndex, rowIndex);
                    }}
                    onKeyDown={(e) => {
                      editable && handleKeyDown(e, colIndex, rowIndex);
                    }}
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="button"
                    aria-pressed={isActive}
                    tabIndex={0} // Make the cell focusable
                  />
                ) : (
                  <td
                    key={`${rowIndex}-${colIndex}`}
                    className={`inactive-tile ${isActive ? "active" : ""}`}
                  />
                );
              })}
              <th className="time-range-label">
                <Button
                  disabled
                  sx={{
                    "&.Mui-disabled": {
                      color: "black", // Text color
                    },
                  }}
                >
                  {timeRanges[rowIndex]}
                </Button>
              </th>
              {editable && (
                <th className="edit-label">
                  <OrangeTextButton
                    text={<EditIcon fontSize="small" />}
                    onClick={() => {
                      setSelectedDayInfo({
                        day: rowIndex,
                        timeRanges: timeRanges[rowIndex],
                        duskToDawn: duskToDawnLocation[rowIndex][0],
                        bufferTime: duskToDawnLocation[rowIndex][1],
                      });
                      setEditTimeScheduleDialogOpen(true);
                    }}
                    disabled={false}
                  />
                </th>
              )}

              {/* <th className="dusk-to-dawn-label">
                {
                  // getTimeRanges(grid[rowIndex])
                  !duskToDawn[rowIndex] ? (
                    <CancelIcon color="error" fontSize="small" />
                  ) : (
                    <Tooltip
                      title={`${locationsMatcher(
                        "Name",
                        duskToDawnLocation[rowIndex][0]
                      )} with ${duskToDawnLocation[rowIndex][1]} minute buffer`}
                      placement="top"
                    >
                      <CheckCircleIcon color="success" fontSize="small" />
                    </Tooltip>
                  )
                }
              </th> */}
            </tr>
          ))}
        </tbody>
      </table>
      {editable && (
        <div className="flex">
          <p className="pt-[13px] text-[14px]">
            {`To create a new schedule, click and drag over the desired arming
  time blocks. To manually edit your schedule times, click the `}
            <EditIcon fontSize="small" />
            {` icon next to Arm Start-Arm End column.`}
          </p>
          <div className="relative pl-[190px] pt-1">
            <OrangeTextButton
              text="Clear Table"
              onClick={() => {
                setGrid(initialGrid);
                if (setScheduleGrid !== null) {
                  setScheduleGrid(
                    <ScheduleGrid
                      editable={true}
                      setSelectedDayInfo={setSelectedDayInfo}
                      setEditTimeScheduleDialogOpen={
                        setEditTimeScheduleDialogOpen
                      }
                      timeRanges={Array.from({ length: height }, () => "")}
                      setTimeRanges={setTimeRanges}
                      grid={initialGrid}
                      setGrid={setGrid}
                      duskToDawn={Array.from({ length: height }, () => false)}
                      setDuskToDawn={setDuskToDawn}
                      duskToDawnLocation={Array.from({ length: height }, () => [
                        "",
                        "",
                      ])}
                      setDuskToDawnLocation={setDuskToDawnLocation}
                      setMatchingSchedules={setMatchingSchedules}
                      setScheduleChangesMade={setScheduleChangesMade}
                      setSiteSchedules={setSiteSchedules}
                      setScheduleGrid={setScheduleGrid}
                      setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
                    />
                  );
                }
                setScheduleChangesMade(true);
                setSiteSchedules((prev: any) => {
                  const newSchedules = [...prev];
                  newSchedules[Number(localStorage.schedulesIndex)].schedule = [
                    {
                      always_on: false,
                      buffer_time: 0,
                      customer: localStorage.siteId,
                      day_of_week: [0, 1, 2, 3, 4, 5, 6],
                      enabled: true,
                      end_time: null,
                      is_overide: false,
                      location_dusk_dawn: "",
                      start_time: null,
                    },
                  ];
                  return newSchedules;
                });
                setMatchingSchedules(
                  handleCreateMatchingSchedules(initialGrid)
                );
                setTimeRanges(Array.from({ length: height }, () => ""));
                setDuskToDawn(Array.from({ length: height }, () => false));
                setDuskToDawnLocation(
                  Array.from({ length: height }, () => ["", ""])
                );
              }}
              disabled={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};
