import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { handleClickedTabChange } from "./SiteAboutPageUtils/handleClickedTabChange";
import {
  AlertState,
  SiteInfo,
  PatchObject,
} from "./SiteAboutPageUtils/siteAboutInterfaces";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const TabsComponet = ({
  clickedTab,
  setClickedTab,
  editMode,
  setActiveTab,
  setPatchObject,
  setSiteInfo,
  vmsVariables,
  setVmsVariables,
  siteSchedules,
  siteInfo,
  setSiteSchedules,
  setSchedulesTabActive,
  setBackdropLoadingScreenOn,
  setAlertState,
  setSyncNeeded,
  setSiteInfoChangesMade,
  setScheduleChangesMade,
  setDeleteScheduleDialogOpen,
  recordingServerData,
  recordingServerPatchObject,
  setRecordingServerPatchObject,
  setRecordingServerChangesMade,
  recordingServerVisible,
  healthCheckData,
  setCameraHealthPatchObject,
  setCameraHealthChangesMade,
  setAddCameraHealthDialogOpen,
  setTurnOffHealthDialogOpen,
  webhooksInfo,
  setWebhooksPatchObject,
  setWebhooksChangesMade,
  setEditMode,
}: {
  clickedTab: string;
  setClickedTab: Dispatcher<string>;
  editMode: boolean;
  setActiveTab: Dispatcher<JSX.Element>;
  setPatchObject: Dispatcher<PatchObject>;
  setSiteInfo: Dispatcher<SiteInfo>;
  vmsVariables: (string | string[] | undefined)[][];
  setVmsVariables: Dispatcher<(string | string[] | undefined)[][]>;
  siteSchedules: any;
  siteInfo: SiteInfo;
  setSiteSchedules: Dispatcher<any[]>;
  setSchedulesTabActive: Dispatcher<boolean>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setAlertState: Dispatcher<AlertState>;
  setSyncNeeded: Dispatcher<boolean>;
  setSiteInfoChangesMade: Dispatcher<boolean>;
  setScheduleChangesMade: Dispatcher<boolean>;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
  recordingServerData: any;
  recordingServerPatchObject: any;
  setRecordingServerPatchObject: any;
  setRecordingServerChangesMade: any;
  recordingServerVisible: boolean;
  healthCheckData: any;
  setCameraHealthPatchObject: any;
  setCameraHealthChangesMade: Dispatcher<boolean>;
  setAddCameraHealthDialogOpen: Dispatcher<boolean>;
  setTurnOffHealthDialogOpen: Dispatcher<boolean>;
  webhooksInfo: any;
  setWebhooksPatchObject: any;
  setWebhooksChangesMade: any;
  setEditMode: any;
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <Tabs
          value={clickedTab}
          onChange={(event, newValue) =>
            handleClickedTabChange(
              event,
              newValue,
              setClickedTab,
              editMode,
              setActiveTab,
              setPatchObject,
              setSiteInfo,
              vmsVariables,
              setVmsVariables,
              siteSchedules,
              siteInfo,
              setSiteSchedules,
              setSchedulesTabActive,
              setBackdropLoadingScreenOn,
              setAlertState,
              setSyncNeeded,
              setSiteInfoChangesMade,
              setScheduleChangesMade,
              setDeleteScheduleDialogOpen,
              recordingServerData,
              recordingServerPatchObject,
              setRecordingServerPatchObject,
              setRecordingServerChangesMade,
              healthCheckData,
              setCameraHealthPatchObject,
              setCameraHealthChangesMade,
              setAddCameraHealthDialogOpen,
              setTurnOffHealthDialogOpen,
              webhooksInfo,
              setWebhooksPatchObject,
              setWebhooksChangesMade,
              setEditMode
            )
          }
          aria-label="tabs"
          color="primary"
        >
          <Tab
            value="one"
            label="General Info"
            sx={{ fontFamily: "Mulish", fontWeight: "Bold", color: "primary" }}
          />
          <Tab
            value="two"
            label="VMS"
            sx={{ fontFamily: "Mulish", fontWeight: "Bold", color: "primary" }}
          />
          {siteInfo.display_schedule && (
            <Tab
              value="three"
              label="Schedules"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {localStorage.SMTPSite === "true" && (
            <Tab
              value="four"
              label="SMTP Settings"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {recordingServerVisible && (
            <Tab
              value="five"
              label="Recording Servers"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {siteInfo.chm && (
            <Tab
              value="six"
              label="Camera Health"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
          {webhooksInfo[0] && (
            <Tab
              value="seven"
              label="Webhooks"
              sx={{
                fontFamily: "Mulish",
                fontWeight: "Bold",
                color: "primary",
              }}
            />
          )}
        </Tabs>
      </ThemeProvider>
    </Box>
  );
};
