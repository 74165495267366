import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "./siteAboutInterfaces";
import { SchedulesTabEditMode } from "../SchedulesTabEditMode";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { NavigateFunction } from "react-router-dom";
import { ScheduleGrid } from "../ScheduleGrid";
import { handleCreateInitialTimeSchedules } from "./handleCreateInitialTimeSchedules";
import { handleConvertSchedulesToBooleanArrays } from "./handleConvertSchedulesToBooleanArrays";
import { handleCreateDuskToDawn } from "./handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../../SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";

export const handleDeleteSchedule = (
  scheduleId: string,
  siteId: number,
  setSiteSchedules: Dispatcher<any>,
  setSchedulesBackdropLoadingScreenOn: Dispatcher<boolean>,
  setVisibleSchedules: Dispatcher<any>,
  setSchedulesIndex: Dispatcher<number>,
  setScheduleChangesMade: Dispatcher<boolean>,
  setClickedSchedulesTab: Dispatcher<string>,
  setActiveTab: Dispatcher<any>,
  setEditMode: Dispatcher<boolean>,
  setSiteInfo: Dispatcher<any>,
  siteInfo: any,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setEditModeTabSiteSchedules: Dispatcher<any>,
  setScheduleGrid: Dispatcher<any>,
  setSelectedDayInfo: Dispatcher<any>,
  setEditTimeScheduleDialogOpen: Dispatcher<boolean>,
  setTimeRanges: Dispatcher<any>,
  setGrid: Dispatcher<any>,
  setDuskToDawn: Dispatcher<any>,
  setDuskToDawnLocation: Dispatcher<any>,
  setMatchingSchedules: Dispatcher<any>,
  navigate: NavigateFunction
) => {
  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleDeleteSchedule(
      scheduleId,
      siteId,
      setSiteSchedules,
      setSchedulesBackdropLoadingScreenOn,
      setVisibleSchedules,
      setSchedulesIndex,
      setScheduleChangesMade,
      setClickedSchedulesTab,
      setActiveTab,
      setEditMode,
      setSiteInfo,
      siteInfo,
      setBackdropLoadingScreenOn,
      setEditModeTabSiteSchedules,
      setScheduleGrid,
      setSelectedDayInfo,
      setEditTimeScheduleDialogOpen,
      setTimeRanges,
      setGrid,
      setDuskToDawn,
      setDuskToDawnLocation,
      setMatchingSchedules,
      navigate
    );
  };

  AxiosUI.delete(`/flex_schedule/${scheduleId}/`).then(
    () => {
      let filteredSiteSchedules: any;

      setSiteSchedules((previousSiteSchedules: any) => {
        filteredSiteSchedules = previousSiteSchedules.filter(
          (schedule: any) => schedule.id !== scheduleId
        );
        return filteredSiteSchedules;
      });
      setEditModeTabSiteSchedules(filteredSiteSchedules);
      setSchedulesIndex(0);
      localStorage.setItem("schedulesIndex", "0");
      setClickedSchedulesTab("one");
      localStorage.setItem("clickedSchedulesTab", "one");

      if (filteredSiteSchedules.length === 1) {
        setVisibleSchedules({
          one: true,
          two: false,
          three: false,
        });
        localStorage.setItem(
          "visibleSchedules",
          JSON.stringify({
            one: true,
            two: false,
            three: false,
          })
        );
      }
      if (filteredSiteSchedules.length === 2) {
        setVisibleSchedules({
          one: true,
          two: true,
          three: false,
        });
        localStorage.setItem(
          "visibleSchedules",
          JSON.stringify({
            one: true,
            two: true,
            three: false,
          })
        );
      }
      setActiveTab(
        <SchedulesTabEditMode
          siteSchedules={filteredSiteSchedules}
          siteInfo={siteInfo}
          setScheduleChangesMade={setScheduleChangesMade}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setActiveTab={setActiveTab}
          setEditMode={setEditMode}
          setSiteSchedules={setSiteSchedules}
          setSiteInfo={setSiteInfo}
        />
      );
      setScheduleGrid(
        <ScheduleGrid
          editable={true}
          setSelectedDayInfo={setSelectedDayInfo}
          setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
          timeRanges={handleCreateInitialTimeSchedules(
            filteredSiteSchedules[0].schedule
          )}
          setTimeRanges={setTimeRanges}
          grid={handleConvertSchedulesToBooleanArrays(
            filteredSiteSchedules[0].schedule
          )}
          setGrid={setGrid}
          duskToDawn={handleCreateDuskToDawn(filteredSiteSchedules[0].schedule)}
          setDuskToDawn={setDuskToDawn}
          duskToDawnLocation={handleCreateDuskToDawnLocationPlusBuffer(
            filteredSiteSchedules[0].schedule
          )}
          setDuskToDawnLocation={setDuskToDawnLocation}
          setMatchingSchedules={setMatchingSchedules}
          setScheduleChangesMade={setScheduleChangesMade}
          setSiteSchedules={setSiteSchedules}
          setScheduleGrid={setScheduleGrid}
          setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
        />
      );

      setSchedulesBackdropLoadingScreenOn(false);

      // AxiosUI.get(`flex_schedule/by_customer/${siteId}/`).then(
      //   (response: AxiosResponse) => {
      //     localStorage.setItem("siteSchedule", JSON.stringify(response.data));
      //     setSiteSchedules(response.data);
      //     setEditModeTabSiteSchedules(response.data);
      //     setSchedulesIndex(0);
      //     localStorage.setItem("schedulesIndex", "0");
      //     setClickedSchedulesTab("one");
      //     localStorage.setItem("clickedSchedulesTab", "one");
      //     localStorage.setItem(
      //       "backUpFlexSchedule",
      //       JSON.stringify(response.data)
      //     );
      //     if (response.data.length === 1) {
      //       setVisibleSchedules({
      //         one: true,
      //         two: false,
      //         three: false,
      //       });
      //       localStorage.setItem(
      //         "visibleSchedules",
      //         JSON.stringify({
      //           one: true,
      //           two: false,
      //           three: false,
      //         })
      //       );
      //     }
      //     if (response.data.length === 2) {
      //       setVisibleSchedules({
      //         one: true,
      //         two: true,
      //         three: false,
      //       });
      //       localStorage.setItem(
      //         "visibleSchedules",
      //         JSON.stringify({
      //           one: true,
      //           two: true,
      //           three: false,
      //         })
      //       );
      //     }
      //     setActiveTab(
      //       <SchedulesTabEditMode
      //         siteSchedules={response.data}
      //         siteInfo={siteInfo}
      //         setScheduleChangesMade={setScheduleChangesMade}
      //         setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
      //         setActiveTab={setActiveTab}
      //         setEditMode={setEditMode}
      //         setSiteSchedules={setSiteSchedules}
      //         setSiteInfo={setSiteInfo}
      //       />
      //     );
      //     setScheduleGrid(
      //       <ScheduleGrid
      //         editable={true}
      //         setSelectedDayInfo={setSelectedDayInfo}
      //         setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
      //         timeRanges={handleCreateInitialTimeSchedules(
      //           response.data[0].schedule
      //         )}
      //         setTimeRanges={setTimeRanges}
      //         grid={handleConvertSchedulesToBooleanArrays(
      //           response.data[0].schedule
      //         )}
      //         setGrid={setGrid}
      //         duskToDawn={handleCreateDuskToDawn(response.data[0].schedule)}
      //         setDuskToDawn={setDuskToDawn}
      //         duskToDawnLocation={handleCreateDuskToDawnLocationPlusBuffer(
      //           response.data[0].schedule
      //         )}
      //         setDuskToDawnLocation={setDuskToDawnLocation}
      //         setMatchingSchedules={setMatchingSchedules}
      //         setScheduleChangesMade={setScheduleChangesMade}
      //         setSiteSchedules={setSiteSchedules}
      //         setScheduleGrid={setScheduleGrid}
      //         setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
      //       />
      //     );

      //     setSchedulesBackdropLoadingScreenOn(false);
      //   }
      // );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Problem deleting schedule.  Please try again.");
        setSchedulesBackdropLoadingScreenOn(false);
      });
    }
  );
};
