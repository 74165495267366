import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { handleDeleteSchedule } from "./SiteAboutPageUtils/handleDeleteSchedule";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export const DeleteScheduleDialog = ({
  deleteScheduleDialogOpen,
  setDeleteScheduleDialogOpen,
  setBackdropLoadingScreenOn,
  scheduleName,
  scheduleId,
  siteId,
  setSiteSchedules,
  setSchedulesBackdropLoadingScreenOn,
  setVisibleSchedules,
  setSchedulesIndex,
  setScheduleChangesMade,
  setClickedSchedulesTab,
  setActiveTab,
  setEditMode,
  setSiteInfo,
  siteInfo,
  setEditModeTabSiteSchedules,
  setScheduleGrid,
  setSelectedDayInfo,
  setEditTimeScheduleDialogOpen,
  setTimeRanges,
  setGrid,
  setDuskToDawn,
  setDuskToDawnLocation,
  setMatchingSchedules,
  navigate,
}: {
  deleteScheduleDialogOpen: boolean;
  setDeleteScheduleDialogOpen: Dispatcher<boolean>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  scheduleName: string;
  scheduleId: string;
  siteId: number;
  setSiteSchedules: Dispatcher<any>;
  setSchedulesBackdropLoadingScreenOn: Dispatcher<boolean>;
  setVisibleSchedules: Dispatcher<any>;
  setSchedulesIndex: Dispatcher<number>;
  setScheduleChangesMade: Dispatcher<boolean>;
  setClickedSchedulesTab: Dispatcher<string>;
  setActiveTab: Dispatcher<any>;
  setEditMode: Dispatcher<boolean>;
  setSiteInfo: Dispatcher<any>;
  siteInfo: any;
  setEditModeTabSiteSchedules: Dispatcher<any>;
  setScheduleGrid: Dispatcher<any>;
  setSelectedDayInfo: Dispatcher<any>;
  setEditTimeScheduleDialogOpen: Dispatcher<boolean>;
  setTimeRanges: Dispatcher<any>;
  setGrid: Dispatcher<any>;
  setDuskToDawn: Dispatcher<any>;
  setDuskToDawnLocation: Dispatcher<any>;
  setMatchingSchedules: Dispatcher<any>;
  navigate: NavigateFunction;
}) => {
  return (
    <Dialog
      open={deleteScheduleDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {` Are you sure you want to delete ${scheduleName} ?`}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Once deleted this action can&apos;t be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setDeleteScheduleDialogOpen(false);
          }}
          text="Cancel"
          disabled={false}
        />
        <OrangeTextButton
          onClick={() => {
            setDeleteScheduleDialogOpen(false);
            setSchedulesBackdropLoadingScreenOn(true);
            handleDeleteSchedule(
              scheduleId,
              siteId,
              setSiteSchedules,
              setSchedulesBackdropLoadingScreenOn,
              setVisibleSchedules,
              setSchedulesIndex,
              setScheduleChangesMade,
              setClickedSchedulesTab,
              setActiveTab,
              setEditMode,
              setSiteInfo,
              siteInfo,
              setBackdropLoadingScreenOn,
              setEditModeTabSiteSchedules,
              setScheduleGrid,
              setSelectedDayInfo,
              setEditTimeScheduleDialogOpen,
              setTimeRanges,
              setGrid,
              setDuskToDawn,
              setDuskToDawnLocation,
              setMatchingSchedules,
              navigate
            );
          }}
          text="Delete"
          disabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};
