import React from "react";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const assignMetricsToProducts = (
  activeProducts: any,
  setProductsData: any,
  setBackupProductsData: any
) => {
  const intruderMetrics: any = {
    // intruder: {},
    // bike: {},
    // vehicle: {},
  };

  const intruderPlusMetrics: any = {
    // intruder_plus: {},
    // bike_plus: {},
    // vehicle_plus: {},
  };

  const motionPlusMetrics: any = {};

  const gunMetrics: any = {
    // gun: {},
    // pistol: {},
  };

  const crowdMetrics: any = {
    // crowd: {},
  };

  const loiteringMetrics: any = {
    // loiterer: {},
    // vehicle_loiterer: {},
  };

  const fallPersonMetrics: any = {
    // fall_person: {},
  };

  const noHardHatMetrics: any = {
    // no_hard_hat: {},
  };

  const maskMetrics: any = {
    // mask: {},
    // mask_compliance: {},
    // no_mask: {},
  };

  const socialDistancingMetrics: any = {
    // social_distancing: {},
  };

  const leftObjectMetrics: any = {
    // left_object: {},
  };

  const packageMetrics: any = {
    // package: {},
  };

  const fireMetrics: any = {
    // fire: {},
    // smoke: {},
  };

  // const peopleFlowMetrics = {
  //   people_flow: {},
  // };

  const fallMetrics: any = {
    // fall: {},
  };

  const vehicleLogoIdMetrics: any = {
    // amazon: {},
    // dhl: {},
    // fedex: {},
    // school_bus: {},
    // ups: {},
    // usps: {},
    // envera: {},
    // fire_truck: {},
  };

  const nonUpsMetrics: any = {
    // non_ups: {},
  };

  activeProducts.forEach((product: any) => {
    if (product.label_name === "intruder") {
      intruderMetrics.intruder = product;
    }
    if (product.label_name === "vehicle") {
      intruderMetrics.vehicle = product;
    }
    if (product.label_name === "bike") {
      intruderMetrics.bike = product;
    }
    if (product.label_name === "intruder_plus") {
      intruderPlusMetrics.intruder_plus = product;
    }
    if (product.label_name === "vehicle_plus") {
      intruderPlusMetrics.vehicle_plus = product;
    }
    if (product.label_name === "bike_plus") {
      intruderPlusMetrics.bike_plus = product;
    }
    if (product.label_name === "intruder_motion") {
      motionPlusMetrics.intruder_motion = product;
    }
    if (product.label_name === "vehicle_motion") {
      motionPlusMetrics.vehicle_motion = product;
    }
    if (product.label_name === "bike_motion") {
      motionPlusMetrics.bike_motion = product;
    }
    if (product.label_name === "gun") {
      gunMetrics.gun = product;
    }
    if (product.label_name === "pistol") {
      gunMetrics.pistol = product;
    }
    if (product.label_name === "crowd") {
      crowdMetrics.crowd = product;
    }
    if (product.label_name === "loiterer") {
      loiteringMetrics.loiterer = product;
    }
    if (product.label_name === "vehicle_loiterer") {
      loiteringMetrics.vehicle_loiterer = product;
    }
    if (product.label_name === "fall_person") {
      fallPersonMetrics.fall_person = product;
    }
    if (product.label_name === "no_hard_hat") {
      noHardHatMetrics.no_hard_hat = product;
    }
    if (product.label_name === "mask") {
      maskMetrics.mask = product;
    }
    if (product.label_name === "mask_compliance") {
      maskMetrics.mask_compliance = product;
    }
    if (product.label_name === "no_mask") {
      maskMetrics.no_mask = product;
    }
    if (product.label_name === "social_distancing") {
      socialDistancingMetrics.social_distancing = product;
    }
    if (product.label_name === "left_object") {
      leftObjectMetrics.left_object = product;
    }
    if (product.label_name === "package") {
      packageMetrics.package = product;
    }
    if (product.label_name === "fire") {
      fireMetrics.fire = product;
    }
    if (product.label_name === "smoke") {
      fireMetrics.smoke = product;
    }
    if (product.label_name === "amazon") {
      vehicleLogoIdMetrics.amazon = product;
    }
    if (product.label_name === "dhl") {
      vehicleLogoIdMetrics.dhl = product;
    }
    if (product.label_name === "fedex") {
      vehicleLogoIdMetrics.fedex = product;
    }
    if (product.label_name === "school_bus") {
      vehicleLogoIdMetrics.school_bus = product;
    }
    if (product.label_name === "ups") {
      vehicleLogoIdMetrics.ups = product;
    }
    if (product.label_name === "usps") {
      vehicleLogoIdMetrics.usps = product;
    }
    if (product.label_name === "envera") {
      vehicleLogoIdMetrics.envera = product;
    }
    if (product.label_name === "fire_truck") {
      vehicleLogoIdMetrics.fire_truck = product;
    }
    if (product.label_name === "non_ups") {
      nonUpsMetrics.non_ups = product;
    }
    if (product.label_name === "fall") {
      fallMetrics.fall = product;
    }

    const updatedProductsData: any = [];

    if (Object.keys(intruderMetrics).length > 0) {
      updatedProductsData.push({
        name: "Intruder",
        label_name: "intruder",
        open: false,
        metrics: intruderMetrics,
      });
    }
    if (Object.keys(intruderPlusMetrics).length > 0) {
      updatedProductsData.push({
        name: "Intruder Plus",
        label_name: "intruderPlus",
        open: false,
        metrics: intruderPlusMetrics,
      });
    }
    if (Object.keys(motionPlusMetrics).length > 0) {
      updatedProductsData.push({
        name: "Motion Plus",
        label_name: "motionPlus",
        open: false,
        metrics: motionPlusMetrics,
      });
    }
    if (Object.keys(gunMetrics).length > 0) {
      updatedProductsData.push({
        name: "Gun",
        label_name: "gun",
        open: false,
        metrics: gunMetrics,
      });
    }
    if (Object.keys(crowdMetrics).length > 0) {
      updatedProductsData.push({
        name: "Crowd",
        label_name: "crowd",
        open: false,
        metrics: crowdMetrics,
      });
    }
    if (Object.keys(loiteringMetrics).length > 0) {
      updatedProductsData.push({
        name: "Loitering",
        label_name: "loitering",
        open: false,
        metrics: loiteringMetrics,
      });
    }
    if (Object.keys(fallPersonMetrics).length > 0) {
      updatedProductsData.push({
        name: "Fall Person",
        label_name: "fallPerson",
        open: false,
        metrics: fallPersonMetrics,
      });
    }
    if (Object.keys(noHardHatMetrics).length > 0) {
      updatedProductsData.push({
        name: "No Hard Hat",
        label_name: "noHardHat",
        open: false,
        metrics: noHardHatMetrics,
      });
    }
    if (Object.keys(maskMetrics).length > 0) {
      updatedProductsData.push({
        name: "Mask",
        label_name: "mask",
        open: false,
        metrics: maskMetrics,
      });
    }
    if (Object.keys(socialDistancingMetrics).length > 0) {
      updatedProductsData.push({
        name: "Social Distancing",
        label_name: "socialDistancing",
        open: false,
        metrics: socialDistancingMetrics,
      });
    }
    if (Object.keys(leftObjectMetrics).length > 0) {
      updatedProductsData.push({
        name: "Left Object",
        label_name: "leftObject",
        open: false,
        metrics: leftObjectMetrics,
      });
    }
    if (Object.keys(packageMetrics).length > 0) {
      updatedProductsData.push({
        name: "Package",
        label_name: "package",
        open: false,
        metrics: packageMetrics,
      });
    }
    if (Object.keys(fireMetrics).length > 0) {
      updatedProductsData.push({
        name: "Fire",
        label_name: "fire",
        open: false,
        metrics: fireMetrics,
      });
    }
    if (Object.keys(vehicleLogoIdMetrics).length > 0) {
      updatedProductsData.push({
        name: "Vehicle Logo ID",
        label_name: "vehicleLogoId",
        open: false,
        metrics: vehicleLogoIdMetrics,
      });
    }
    if (Object.keys(nonUpsMetrics).length > 0) {
      updatedProductsData.push({
        name: "Non-UPS",
        label_name: "nonUps",
        open: false,
        metrics: nonUpsMetrics,
      });
    }

    // const updatedProductsData = [
    //   {
    //     name: "Intruder",
    //     label_name: "intruder",
    //     open: false,
    //     metrics: intruderMetrics,
    //   },
    //   {
    //     name: "Intruder Plus",
    //     label_name: "intruderPlus",
    //     open: false,
    //     metrics: intruderPlusMetrics,
    //   },
    //   { name: "Gun", label_name: "gun", open: false, metrics: gunMetrics },
    //   {
    //     name: "Crowd",
    //     label_name: "crowd",
    //     open: false,
    //     metrics: crowdMetrics,
    //   },
    //   {
    //     name: "Loitering",
    //     label_name: "loitering",
    //     open: false,
    //     metrics: loiteringMetrics,
    //   },
    //   {
    //     name: "Fall Person",
    //     label_name: "fallPerson",
    //     open: false,
    //     metrics: fallPersonMetrics,
    //   },
    //   // {
    //   //   name: "Slip and Fall (old model)",
    //   //   label_name: "fall",
    //   //   open: false,
    //   //   metrics: {
    //   //     fall: fallMetrics,
    //   //   },
    //   // },
    //   {
    //     name: "No Hard Hat",
    //     label_name: "noHardHat",
    //     open: false,
    //     metrics: noHardHatMetrics,
    //   },
    //   { name: "Mask", label_name: "mask", open: false, metrics: maskMetrics },
    //   {
    //     name: "Social Distancing",
    //     label_name: "socialDistancing",
    //     open: false,
    //     metrics: socialDistancingMetrics,
    //   },
    //   {
    //     name: "Left Object",
    //     label_name: "leftObject",
    //     open: false,
    //     metrics: leftObjectMetrics,
    //   },
    //   {
    //     name: "Package",
    //     label_name: "package",
    //     open: false,
    //     metrics: packageMetrics,
    //   },
    //   { name: "Fire", label_name: "fire", open: false, metrics: fireMetrics },
    //   {
    //     name: "Vehicle Logo ID",
    //     label_name: "vehicleLogoId",
    //     open: false,
    //     metrics: vehicleLogoIdMetrics,
    //   },
    //   // {
    //   //   name: "People Flow",
    //   //   label_name: "peopleFlow",
    //   //   open: false,
    //   //   metrics: peopleFlowMetrics,
    //   // },
    //   {
    //     name: "Non-UPS",
    //     label_name: "nonUps",
    //     open: false,
    //     metrics: nonUpsMetrics,
    //   },
    // ];
    setBackupProductsData(updatedProductsData);
    setProductsData(updatedProductsData);
    localStorage.setItem(
      "backupProductsData",
      JSON.stringify(updatedProductsData)
    );
  });
};
