import React from "react";

export const handleRefreshButtonsFill = (
  detection_type: any,
  setSelectedButtonsFilled: any
) => {
  if (!detection_type) {
    setSelectedButtonsFilled({
      true_threat: true,
      true_non_threat: true,
      false_positive: true,
      escalated_true_threat: true,
    });
  }
  if (detection_type === "true_threat") {
    setSelectedButtonsFilled({
      true_threat: true,
      true_non_threat: false,
      false_positive: false,
      escalated_true_threat: false,
    });
  }
  if (detection_type === "true_non_threat") {
    setSelectedButtonsFilled({
      true_threat: false,
      true_non_threat: true,
      false_positive: false,
      escalated_true_threat: false,
    });
  }
  if (detection_type === "false_positive") {
    setSelectedButtonsFilled({
      true_threat: false,
      true_non_threat: false,
      false_positive: true,
      escalated_true_threat: false,
    });
  }
  if (detection_type === "escalated_true_threat") {
    setSelectedButtonsFilled({
      true_threat: false,
      true_non_threat: false,
      false_positive: false,
      escalated_true_threat: true,
    });
  }
};
