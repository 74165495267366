import { createMonitoringAPICall } from "../../../utils/createMonitoringAPICall";
import { AxiosError, AxiosResponse } from "axios";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { addSelectedAndDetectionTypeOnRefresh } from "./addSelectedAndDetectionTypeOnRefresh";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";
import { checkIfCurrentDate } from "./checkIfCurrentDate";
import { timeSlicer } from "../timeSlicer";
import { numberListToString } from "./getAlertsWithQuerry";

const isAlertsUrl = () => {
  const currentUrl = window.location.href;
  return currentUrl.includes("alerts");
};

export const getAlertsRefresh = (
  setAlertData: Dispatcher<any>,
  navigate: NavigateFunction,
  groupId: string,
  siteId: number[],
  cameraId: number[],
  startDate: string,
  endDate: string,
  startTime: Date,
  endTime: Date,
  filterType: string,
  setSelectedRow: any,
  setRefreshingData: Dispatcher<boolean>,
  setSelectedAlertCameraInfo: any,
  setIgnoreZonesSelectedAlert: any,
  setAlertsIndexOrder: any,
  refreshInterval: any,
  setRefreshInterval: any,
  pageLoadedNumber: number,
  setSeeMoreURL: any,
  setMP4URL: any,
  setVideoClipLoading: any,
  setSelectedButtonsFilled: any,
  setAlertsToDisplay: any,
  setCurrentName: any,
  setPage: any,
  setDateOutsideRange: any,
  setFetchingFilteredData: any,
  setPageLoading: any,
  setUserFilters: any,
  userFilters: any,
  cameraOptions: any,
  siteOptions: any,
  startTimeStamp: any,
  setRefreshingToken: Dispatcher<boolean>,
  refreshingToken: boolean,
  previousLength: number,
  setBackArrowDisabled: any,
  setForwardArrowDisabled: any,
  setShowRefreshButton: any,
  setAlertsRefreshing: Dispatcher<boolean>,
  triggeredFromDetectionLabeling: boolean
) => {
  const AxiosUI = createMonitoringAPICall(``);
  const retryFunction = () => {
    localStorage.tokenRefreshing === "false" &&
      getAlertsRefresh(
        setAlertData,
        navigate,
        groupId,
        siteId,
        cameraId,
        startDate,
        endDate,
        startTime,
        endTime,
        filterType,
        setSelectedRow,
        setRefreshingData,
        setSelectedAlertCameraInfo,
        setIgnoreZonesSelectedAlert,
        setAlertsIndexOrder,
        refreshInterval,
        setRefreshInterval,
        pageLoadedNumber,
        setSeeMoreURL,
        setMP4URL,
        setVideoClipLoading,
        setSelectedButtonsFilled,
        setAlertsToDisplay,
        setCurrentName,
        setPage,
        setDateOutsideRange,
        setFetchingFilteredData,
        setPageLoading,
        setUserFilters,
        userFilters,
        cameraOptions,
        siteOptions,
        startTimeStamp,
        setRefreshingToken,
        refreshingToken,
        previousLength,
        setBackArrowDisabled,
        setForwardArrowDisabled,
        setShowRefreshButton,
        setAlertsRefreshing,
        false
      );
  };

  let searchingPastDates = false;

  let searchParamsString = ``;

  if (startDate) {
    searchParamsString += `&start=${startDate}`;
  }

  if (endDate) {
    searchParamsString += `&end=${endDate}`;
    searchingPastDates = checkIfCurrentDate(endDate);
  }

  if (groupId) {
    if (groupId !== "undefined") {
      searchParamsString += `&group_id=${groupId}`;
    }
  }

  if (siteId[0]) {
    searchParamsString += `&customer_id=${numberListToString(siteId)}`;
  }

  if (cameraId) {
    searchParamsString += `&camera_id=${numberListToString(cameraId)}`;
  }

  if (startDate.toString() === endDate.toString() && startTime !== null) {
    searchParamsString += `&start_time=${timeSlicer(startTime)}`;
  }

  if (startDate.toString() === endDate.toString() && endTime !== null) {
    searchParamsString += `&end_time=${timeSlicer(endTime)}`;
  }

  localStorage.setItem(
    "queryString",
    JSON.stringify(`${filterType}${searchParamsString}`)
  );

  const areObjectsEqual = (
    obj1: Record<string, any>,
    obj2: Record<string, any>
  ): boolean => {
    for (const key in obj1) {
      if (obj1[key] !== null && obj2[key] !== null) {
        if (obj1[key].toString() !== obj2[key].toString()) {
          return false;
        }
      }
    }
    return true;
  };

  if (
    Number(pageLoadedNumber) === Number(localStorage.pageLoadedNumber) &&
    isAlertsUrl() &&
    !searchingPastDates &&
    areObjectsEqual(userFilters, JSON.parse(localStorage.latestAlertFilters)) &&
    location.href.includes("alerts")
  ) {
    setAlertsRefreshing(true);

    setTimeout(
      () => {
        AxiosUI.get(
          `alert/?window_filter=${filterType}${searchParamsString}`
        ).then(
          (response: AxiosResponse) => {
            let previousTimeStamps = startTimeStamp;
            if (!response.data.records[0]) {
              setAlertData([]);
              setAlertsToDisplay(false);
              setRefreshingData(false);
            }
            if (response.data.records[0]) {
              previousTimeStamps =
                response.data.records[0].approx_capture_timestamp;
              if (
                previousTimeStamps !== startTimeStamp ||
                (previousTimeStamps === startTimeStamp &&
                  response.data.records.length !== previousLength)
              ) {
                setRefreshingData(true);
                const newData = response.data;
                setAlertsToDisplay(true);
                if (
                  JSON.parse(localStorage.queryString) ===
                  `${filterType}${searchParamsString}`
                ) {
                  setAlertData(
                    addSelectedAndDetectionTypeOnRefresh(
                      newData.records,
                      setAlertsIndexOrder,
                      setSeeMoreURL,
                      setSelectedRow,
                      setSelectedAlertCameraInfo,
                      setIgnoreZonesSelectedAlert,
                      navigate,
                      setMP4URL,
                      setVideoClipLoading,
                      setSelectedButtonsFilled,
                      newData,
                      setAlertData,
                      refreshingToken,
                      setBackArrowDisabled,
                      setForwardArrowDisabled,
                      setShowRefreshButton
                    )
                  );
                }
                setRefreshingData(false);
              }
            }
            if (localStorage.tokenRefreshing === "false") {
              setTimeout(() => {
                getAlertsRefresh(
                  setAlertData,
                  navigate,
                  groupId,
                  siteId,
                  cameraId,
                  startDate,
                  endDate,
                  startTime,
                  endTime,
                  filterType,
                  setSelectedRow,
                  setRefreshingData,
                  setSelectedAlertCameraInfo,
                  setIgnoreZonesSelectedAlert,
                  setAlertsIndexOrder,
                  refreshInterval,
                  setRefreshInterval,
                  pageLoadedNumber,
                  setSeeMoreURL,
                  setMP4URL,
                  setVideoClipLoading,
                  setSelectedButtonsFilled,
                  setAlertsToDisplay,
                  setCurrentName,
                  setPage,
                  setDateOutsideRange,
                  setFetchingFilteredData,
                  setPageLoading,
                  setUserFilters,
                  userFilters,
                  cameraOptions,
                  siteOptions,
                  previousTimeStamps,
                  setRefreshingToken,
                  refreshingToken,
                  response.data.records.length,
                  setBackArrowDisabled,
                  setForwardArrowDisabled,
                  setShowRefreshButton,
                  setAlertsRefreshing,
                  false
                );
              }, refreshInterval);
            }
          },
          (reason: AxiosError) => {
            setRefreshingToken(true);
            universalAPIErrorHandler(
              reason,
              navigate,
              () => {
                retryFunction();
                setRefreshingToken(false);
              },
              () => {
                setRefreshingToken(false);
                setRefreshingData(false);
                if (localStorage.tokenRefreshing === "false") {
                  setTimeout(() => {
                    getAlertsRefresh(
                      setAlertData,
                      navigate,
                      groupId,
                      siteId,
                      cameraId,
                      startDate,
                      endDate,
                      startTime,
                      endTime,
                      filterType,
                      setSelectedRow,
                      setRefreshingData,
                      setSelectedAlertCameraInfo,
                      setIgnoreZonesSelectedAlert,
                      setAlertsIndexOrder,
                      refreshInterval,
                      setRefreshInterval,
                      pageLoadedNumber,
                      setSeeMoreURL,
                      setMP4URL,
                      setVideoClipLoading,
                      setSelectedButtonsFilled,
                      setAlertsToDisplay,
                      setCurrentName,
                      setPage,
                      setDateOutsideRange,
                      setFetchingFilteredData,
                      setPageLoading,
                      setUserFilters,
                      userFilters,
                      cameraOptions,
                      siteOptions,
                      startTimeStamp,
                      setRefreshingToken,
                      refreshingToken,
                      previousLength,
                      setBackArrowDisabled,
                      setForwardArrowDisabled,
                      setShowRefreshButton,
                      setAlertsRefreshing,
                      false
                    );
                  }, refreshInterval);
                }
              }
            );
          }
        );
      },
      triggeredFromDetectionLabeling ? 0 : refreshInterval
    );
  } else {
    setAlertsRefreshing(false);
  }
};
