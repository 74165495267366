import React from "react";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { setStartDate } from "./HealthMonitoringUtils/setStartDate";

export const StartDateDropDownHM = ({
  userFilters,
  setUserFilters,
}: {
  userFilters: any;
  setUserFilters: Dispatcher<any>;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Start Date"
        value={userFilters.start_date}
        onChange={(newValue) => {
          if (newValue !== null) {
            setStartDate(newValue, userFilters, setUserFilters);
          }
        }}
        renderInput={(params) => (
          <TextField sx={{ width: 150 }} helperText="*Required" {...params} />
        )}
      />
    </LocalizationProvider>
  );
};
