import React from "react";
import { createApi } from "./createApi";
import { NavigateFunction } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "./universalAPIErrorHandler";

export const handleSetPermissions = (
  navigate: NavigateFunction,
  setPermissions: any
) => {
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleSetPermissions(navigate, setPermissions);
  };

  AxiosUI.get(`user/info/`).then(
    (response: AxiosResponse) => {
      const responsePermissions = response.data.permission;

      // run a for loop on the responsePermissions and set every key to the value of the key

      for (const key in responsePermissions) {
        setPermissions((prev: any) => {
          return {
            ...prev,
            [key]: responsePermissions[key],
          };
        });
      }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {});
    }
  );
};
