import React, { Profiler, useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "./Components/utils/auth";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import packageJson from "../package.json";

let rootElement = document.getElementById("root");
const history = createBrowserHistory({ window });

// Function to detect OS and apply class to <body>
const detectOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.includes("win") || userAgent.includes("linux")) {
    document.body.classList.add("pc-scrollbars");
  } else {
    document.body.classList.remove("pc-scrollbars");
  }
};

// Wrapper component to run OS detection on mount
const Root = () => {
  useEffect(() => {
    detectOS();
  }, []);

  return (
    <AuthProvider>
      <HistoryRouter history={history}>
        <Profiler id="App" onRender={onRenderCallback}>
          <App packageJson={packageJson} />
        </Profiler>
      </HistoryRouter>
    </AuthProvider>
  );
};

const onRenderCallback = (
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
) => {};

ReactDOM.render(<Root />, rootElement);
