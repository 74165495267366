import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getAlertsWithQuerry } from "./AlertsUtils/getAlertsWithQuerry";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";
import { updateSearchParamValues } from "../../utils/updateSearchParamValues";

export const FilterMenu = ({
  userFilters,
  setFetchingFilteredData,
  setAlertData,
  navigate,
  setSelectedRow,
  setSeeMoreURL,
  setMP4URL,
  setUserFilters,
  setSelectedButtonsFilled,
  setThumbnailVideos,
  setDateOutsideRange,
  setPage,
  setVideoClipLoading,
  setAlertsToDisplay,
  setPageLoading,
  setSelectedAlertCameraInfo,
  setIgnoreZonesSelectedAlert,
  setAlertsIndexOrder,
  setRefreshInterval,
  currentName,
  setCurrentName,
  pageLoadedNumber,
  setPageLoadedNumber,
  setRefreshingData,
  cameraOptions,
  siteOptions,
  setRefreshingToken,
  refreshingToken,
  setAlertLabels,
  setPeriodHours,
  setQueryLimitHit,
  searchParams,
  setSearchParams,
  detectionType,
  refreshInterval,
  setOutcomeFilterType,
  setForwardArrowDisabled,
  setBackArrowDisabled,
  setShowRefreshButton,
  setRowsSortable,
  setAlertsRefreshing,
}: {
  userFilters: any;
  setFetchingFilteredData: Dispatcher<any>;
  setAlertData: Dispatcher<any>;
  navigate: NavigateFunction;
  setSelectedRow: Dispatcher<any[]>;
  setSeeMoreURL: Dispatcher<string>;
  setMP4URL: Dispatcher<string>;
  setUserFilters: Dispatcher<any>;
  setSelectedButtonsFilled: any;
  setThumbnailVideos: any;
  setDateOutsideRange: Dispatcher<boolean>;
  setPage: Dispatcher<number>;
  setVideoClipLoading: Dispatcher<boolean>;
  setAlertsToDisplay: Dispatcher<boolean>;
  setPageLoading: Dispatcher<boolean>;
  setSelectedAlertCameraInfo: any;
  setIgnoreZonesSelectedAlert: any;
  setAlertsIndexOrder: any;
  setRefreshInterval: any;
  currentName: string;
  setCurrentName: Dispatcher<string>;
  pageLoadedNumber: number;
  setPageLoadedNumber: Dispatcher<number>;
  setRefreshingData: any;
  cameraOptions: any;
  siteOptions: any;
  setRefreshingToken: any;
  refreshingToken: any;
  setAlertLabels: Dispatcher<string[]>;
  setPeriodHours: Dispatcher<number>;
  setQueryLimitHit: Dispatcher<boolean>;
  searchParams: any;
  setSearchParams: any;
  detectionType: string;
  refreshInterval: any;
  setOutcomeFilterType: Dispatcher<string>;
  setForwardArrowDisabled: Dispatcher<boolean>;
  setBackArrowDisabled: Dispatcher<boolean>;
  setShowRefreshButton: Dispatcher<boolean>;
  setRowsSortable: Dispatcher<boolean>;
  setAlertsRefreshing: Dispatcher<boolean>;
}) => {
  const optionsArrayArray = [
    { name: "Unresolved Alerts", value: "UNCLAIMED" },
    { name: "Resolved Alerts", value: "CLAIMED" },
    { name: "All Alerts", value: "ALL" },
  ];

  const optionMaps = new Map();

  optionsArrayArray.forEach((option: any) => {
    optionMaps.set(`${option.value}`, `${option.name}`);
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div>
        <div className="absolute text-[12px] top-[7.5px] w-[80px] left-[15px]">
          FILTER BY
        </div>
        <div className="absolute left-[72px] w-[200px]">
          <Button
            id="filter-button"
            aria-controls={open ? "filter-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{ fontSize: "12px" }}
            onClick={handleClick}
          >
            {currentName}
          </Button>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            //   anchorOrigin={{
            //     vertical: 'top',
            //     horizontal: 'left',
            //   }}
            //   transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'left',
            //   }}
            MenuListProps={{
              "aria-labelledby": "filter-button",
            }}
          >
            {optionsArrayArray.map((object: any) => {
              return (
                <MenuItem
                  key={object.value}
                  onClick={() => {
                    let newNumber = Number(pageLoadedNumber);
                    newNumber += 1;
                    setPageLoadedNumber(newNumber);
                    localStorage.setItem("pageLoadedNumber", `${newNumber}`);
                    localStorage.setItem(
                      "latestAlertFilters",
                      JSON.stringify(userFilters)
                    );
                    setOutcomeFilterType("All");
                    setFetchingFilteredData(true);
                    setCurrentName(object.name);
                    getAlertsWithQuerry(
                      setAlertData,
                      navigate,
                      setSelectedRow,
                      setFetchingFilteredData,
                      setSeeMoreURL,
                      setMP4URL,
                      userFilters.parent_group,
                      userFilters.site,
                      userFilters.camera,
                      yyyymmddGenerator(userFilters.start_date),
                      yyyymmddGenerator(userFilters.end_date),
                      userFilters.start_time,
                      userFilters.end_time,
                      object.value,
                      setSelectedButtonsFilled,
                      setThumbnailVideos,
                      setDateOutsideRange,
                      setPage,
                      setVideoClipLoading,
                      setAlertsToDisplay,
                      setPageLoading,
                      setSelectedAlertCameraInfo,
                      setIgnoreZonesSelectedAlert,
                      setAlertsIndexOrder,
                      setRefreshInterval,
                      setCurrentName,
                      setUserFilters,
                      setRefreshingData,
                      newNumber,
                      refreshInterval,
                      userFilters,
                      cameraOptions,
                      siteOptions,
                      setRefreshingToken,
                      refreshingToken,
                      setAlertLabels,
                      setPeriodHours,
                      setQueryLimitHit,
                      setForwardArrowDisabled,
                      setBackArrowDisabled,
                      setShowRefreshButton,
                      setRowsSortable,
                      setAlertsRefreshing
                    );
                    setUserFilters((previousState: any) => {
                      return {
                        ...previousState,
                        filter_type: object.value,
                      };
                    });
                    updateSearchParamValues(
                      { ...userFilters, filter_type: object.value },
                      searchParams,
                      setSearchParams,
                      detectionType
                    );
                  }}
                >
                  {object.name}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    </div>
  );
};
