import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../../utils/createApi";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { SiteSchedule } from "./siteAboutInterfaces";
import { SchedulesTab } from "../SchedulesTab";
import { temporaryUpdateScheduleData } from "./temporaryUpdateScheduleData";

export const handleActivateSite = async (
  setBackdropLoadingScreenOn: any,
  patchObject: any,
  setSiteInfo: any,
  setAlertState: any,
  setPatchObject: any,
  siteInfo: any,
  setSiteSchedules: any,
  setAllSiteSchedules: any,
  clickedTab: any,
  setActiveTab: any,
  setDeleteScheduleDialogOpen: any,
  navigate: any
) => {
  setBackdropLoadingScreenOn(true);
  const AxiosUI = createApi(``);
  const retryFunction = () => {
    handleActivateSite(
      setBackdropLoadingScreenOn,
      patchObject,
      setSiteInfo,
      setAlertState,
      setPatchObject,
      siteInfo,
      setSiteSchedules,
      setAllSiteSchedules,
      clickedTab,
      setActiveTab,
      setDeleteScheduleDialogOpen,
      navigate
    );
  };
  await AxiosUI.patch(`customer/${siteInfo.id}/about/`, { active: true }).then(
    () => {
      AxiosUI.get(`schedule/by_customer/${siteInfo.id}/`).then(
        (response: AxiosResponse) => {
          const temporaryUpdatedScheduleData = temporaryUpdateScheduleData(
            response.data
          );
          localStorage.setItem(
            "siteSchedule",
            JSON.stringify(temporaryUpdatedScheduleData)
          );
          setSiteSchedules(temporaryUpdatedScheduleData);
          localStorage.setItem(
            "allSiteSchedulesBackup",
            JSON.stringify(temporaryUpdatedScheduleData)
          );
          if (clickedTab === "three") {
            setActiveTab(<></>);
            setActiveTab(
              <SchedulesTab
                siteSchedules={temporaryUpdatedScheduleData}
                siteInfo={siteInfo}
              />
            );
          }
          setPatchObject({ name: siteInfo.name });
          setBackdropLoadingScreenOn(false);
          setAlertState((previousState: any) => {
            return {
              ...previousState,
              changeSuccess: false,
              activateSuccess: true,
              deactivateSuccess: false,
              disarmSuccess: false,
              armSuccess: false,
              syncSuccess: false,
              cameraDeleteSuccess: false,
              armFail: false,
              noCameraOrSchedule: false,
              noSchedule: false,
            };
          });
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            setPatchObject({ name: siteInfo.name });
            setBackdropLoadingScreenOn(false);
            setAlertState((previousState: any) => {
              return {
                ...previousState,
                changeSuccess: false,
                activateSuccess: true,
                deactivateSuccess: false,
                disarmSuccess: false,
                armSuccess: false,
                syncSuccess: false,
                cameraDeleteSuccess: false,
                armFail: false,
                noCameraOrSchedule: false,
                noSchedule: false,
              };
            });
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error activating site, please try again");
        setSiteInfo(JSON.parse(localStorage.siteInfo));
        setBackdropLoadingScreenOn(false);
        setPatchObject({ name: siteInfo.name });
      });
    }
  );
};
