import React from "react";

export const handleAlertButtonsFill = (
  row: any,
  setSelectedButtonsFilled: any
) => {
  if (!row.detection_type) {
    setSelectedButtonsFilled({
      true_threat: true,
      true_non_threat: true,
      false_positive: true,
      escalated_true_threat: true,
    });
  }
  if (row.detection_type === "true_threat") {
    setSelectedButtonsFilled({
      true_threat: true,
      true_non_threat: false,
      false_positive: false,
      escalated_true_threat: false,
    });
  }
  if (row.detection_type === "true_non_threat") {
    setSelectedButtonsFilled({
      true_threat: false,
      true_non_threat: true,
      false_positive: false,
      escalated_true_threat: false,
    });
  }
  if (row.detection_type === "false_positive") {
    setSelectedButtonsFilled({
      true_threat: false,
      true_non_threat: false,
      false_positive: true,
      escalated_true_threat: false,
    });
  }
  if (row.detection_type === "escalated_true_threat") {
    setSelectedButtonsFilled({
      true_threat: false,
      true_non_threat: false,
      false_positive: false,
      escalated_true_threat: true,
    });
  }
};
