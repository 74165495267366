import React from "react";

const colorsArray = [
  "#FF8400",
  "#01579B",
  "#D32F2F",
  "#2E7D32",
  "#8830FC",
  "#FFC208",
  "#AD1457",
  "#0288D1",
  "#019B8C",
  "#38019B",
  "#CCA409",
  "#019B4D",
  "#CC4809",
  "#9B0157",
  "#9B0152",
  "#01589B",
  "#C00030",
  "#90C000",
  "#E67700",
  "#0267B3",
  "#B92727",
  "#26802E",
  "#7A29E6",
  "#FFB700",
  "#9C124E",
  "#0273A8",
  "#018571",
  "#31018A",
  "#B28F07",
  "#018B44",
  "#B84307",
  "#8A014B",
  "#8A0146",
  "#014D8A",
  "#AA002B",
  "#7FAA00",
  "#D9480F",
  "#6001A8",
];

const labelsArray = [
  "intruder",
  "vehicle",
  "bike",
  "intruder_plus",
  "vehicle_plus",
  "bike_plus",
  "gun",
  "pistol",
  "crowd",
  "loiterer",
  "vehicle_loiterer",
  "people_flow",
  "social_distancing",
  "fall_person",
  "mask",
  "mask_compliance",
  "no_mask",
  "no_hard_hat",
  "left_object",
  "package",
  "amazon",
  "dhl",
  "fedex",
  "school_bus",
  "ups",
  "usps",
  "envera",
  "sudden_scene_change",
  "fire",
  "smoke",
  "car",
  "bicycle",
  "bus",
  "motorcycle",
  "truck",
  "fall",
  "fire_truck",
  "non_ups",
];

export const createColor = (label: string, colorString: string) => {
  switch (label) {
    case labelsArray[0]:
      return colorsArray[0];
    case labelsArray[1]:
      return colorsArray[1];
    case labelsArray[2]:
      return colorsArray[2];
    case labelsArray[3]:
      return colorsArray[3];
    case labelsArray[4]:
      return colorsArray[4];
    case labelsArray[5]:
      return colorsArray[5];
    case labelsArray[6]:
      return colorsArray[6];
    case labelsArray[7]:
      return colorsArray[7];
    case labelsArray[8]:
      return colorsArray[8];
    case labelsArray[9]:
      return colorsArray[9];
    case labelsArray[10]:
      return colorsArray[10];
    case labelsArray[11]:
      return colorsArray[11];
    case labelsArray[12]:
      return colorsArray[12];
    case labelsArray[13]:
      return colorsArray[13];
    case labelsArray[14]:
      return colorsArray[14];
    case labelsArray[15]:
      return colorsArray[15];
    case labelsArray[16]:
      return colorsArray[16];
    case labelsArray[17]:
      return colorsArray[17];
    case labelsArray[18]:
      return colorsArray[18];
    case labelsArray[19]:
      return colorsArray[19];
    case labelsArray[20]:
      return colorsArray[20];
    case labelsArray[21]:
      return colorsArray[21];
    case labelsArray[22]:
      return colorsArray[22];
    case labelsArray[23]:
      return colorsArray[23];
    case labelsArray[24]:
      return colorsArray[24];
    case labelsArray[25]:
      return colorsArray[25];
    case labelsArray[26]:
      return colorsArray[26];
    case labelsArray[27]:
      return colorsArray[27];
    case labelsArray[28]:
      return colorsArray[28];
    case labelsArray[29]:
      return colorsArray[29];
    case labelsArray[30]:
      return colorsArray[30];
    case labelsArray[31]:
      return colorsArray[31];
    case labelsArray[32]:
      return colorsArray[32];
    case labelsArray[33]:
      return colorsArray[33];
    case labelsArray[34]:
      return colorsArray[34];
    case labelsArray[35]:
      return colorsArray[35];
    case labelsArray[36]:
      return colorsArray[36];
    case labelsArray[37]:
      return colorsArray[37];
    case labelsArray[38]:
      return colorsArray[38];
    default:
      return colorString;
  }
};
