// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { getRefreshToken } from "../../../utils/getRefreshToken";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { SiteOptions } from "../../Analytics/AnalyticsUtils/analyticsInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getSiteByParentGroupsPlusAssignSiteDDValueArray = (
  id: string,
  navigate: NavigateFunction,
  setSiteOptions: Dispatcher<SiteOptions[]>,
  setLoadingSites: Dispatcher<boolean>,
  sitesArray: number[],
  setSiteDropDownValue: Dispatcher<string[]>
) => {
  setLoadingSites(true);
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getSiteByParentGroupsPlusAssignSiteDDValueArray(
      id,
      navigate,
      setSiteOptions,
      setLoadingSites,
      sitesArray,
      setSiteDropDownValue
    );
  };

  AxiosUI.get(`customer/names/${id}/`).then(
    (response: AxiosResponse) => {
      setSiteOptions(response.data);
      const siteIds = new Map();
      response.data.forEach((object: any) => {
        siteIds.set(object.name, object.id);
      });
      const siteNames = new Map();
      response.data.forEach((object: any) => {
        siteNames.set(object.id, object.name);
      });
      setSiteDropDownValue(
        sitesArray.map((site: number) => {
          return siteNames.get(site);
        })
      );
      setLoadingSites(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Error retrieving sites. Please try again");
        setLoadingSites(false);
      });
    }
  );
};
