import React from "react";

export const temporaryUpdateScheduleData = (oldData: any) => {
  const addCameraInfo = JSON.parse(localStorage.addCameraInfo);

  const returnData = [
    {
      display_name: "Schedule 1",
      id: 1,
      product: [...addCameraInfo.initial_data.products],
      schedule: oldData,
    },
    // {
    //   display_name: "Schedule 2",
    //   id: 2,
    //   product: [],
    //   schedule: [],
    // },
    // {
    //   display_name: "Schedule 3",
    //   id: 3,
    //   product: [],
    //   schedule: [],
    // },
  ];

  return returnData;
};
