import React from "react";
import { Check } from "@mui/icons-material";
import { AlertData } from "./AlertsUtils/alertsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { createMonitoringAPICall } from "../../utils/createMonitoringAPICall";
import { NavigateFunction } from "react-router-dom";
import { AxiosError } from "axios";
import { getCurrentDateTime } from "../../utils/getCurrentDateAndTime";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";
// import { handleAlertRefreshFromDetectionLabeling } from "./AlertsUtils/handleAlertRefreshFromDetectionLabeling";

const handleTrueThreatClick = (
  alertData: AlertData[],
  setAlertData: Dispatcher<AlertData[]>,
  selectedRow: AlertData,
  setSelectedRow: Dispatcher<AlertData>,
  navigate: NavigateFunction,
  setSelectedButtonsFilled: Dispatcher<any>,
  setPageLoading: Dispatcher<boolean>,
  setButtonClicked: Dispatcher<boolean>,
  pageLoadedNumber: string,
  setPageLoadedNumber: Dispatcher<number>,
  setFetchingFilteredData: Dispatcher<boolean>,
  setSeeMoreURL: Dispatcher<string>,
  setMP4URL: Dispatcher<string>,
  userFilters: any,
  setThumbnailVideos: Dispatcher<any>,
  setDateOutsideRange: Dispatcher<boolean>,
  setPage: Dispatcher<number>,
  setVideoClipLoading: Dispatcher<boolean>,
  setAlertsToDisplay: Dispatcher<AlertData[]>,
  setSelectedAlertCameraInfo: Dispatcher<any>,
  setIgnoreZonesSelectedAlert: Dispatcher<any>,
  setAlertsIndexOrder: Dispatcher<any>,
  setRefreshInterval: Dispatcher<number>,
  setCurrentName: Dispatcher<string>,
  setUserFilters: Dispatcher<any>,
  setRefreshingData: Dispatcher<boolean>,
  refreshInterval: number,
  cameraOptions: any,
  siteOptions: any,
  setRefreshingToken: Dispatcher<boolean>,
  refreshingToken: boolean,
  setAlertLabels: Dispatcher<any>,
  setPeriodHours: Dispatcher<number>,
  setQueryLimitHit: Dispatcher<boolean>,
  setForwardArrowDisabled: Dispatcher<boolean>,
  setBackArrowDisabled: Dispatcher<boolean>,
  setShowRefreshButton: Dispatcher<boolean>,
  setRowsSortable: Dispatcher<boolean>,
  setAlertsRefreshing: Dispatcher<boolean>
) => {
  const AxiosUI = createMonitoringAPICall(``);
  const retryFunction = () => {
    handleTrueThreatClick(
      alertData,
      setAlertData,
      selectedRow,
      setSelectedRow,
      navigate,
      setSelectedButtonsFilled,
      setPageLoading,
      setButtonClicked,
      pageLoadedNumber,
      setPageLoadedNumber,
      setFetchingFilteredData,
      setSeeMoreURL,
      setMP4URL,
      userFilters,
      setThumbnailVideos,
      setDateOutsideRange,
      setPage,
      setVideoClipLoading,
      setAlertsToDisplay,
      setSelectedAlertCameraInfo,
      setIgnoreZonesSelectedAlert,
      setAlertsIndexOrder,
      setRefreshInterval,
      setCurrentName,
      setUserFilters,
      setRefreshingData,
      refreshInterval,
      cameraOptions,
      siteOptions,
      setRefreshingToken,
      refreshingToken,
      setAlertLabels,
      setPeriodHours,
      setQueryLimitHit,
      setForwardArrowDisabled,
      setBackArrowDisabled,
      setShowRefreshButton,
      setRowsSortable,
      setAlertsRefreshing
    );
  };
  const postedAlert = {
    approx_capture_timestamp: selectedRow.approx_capture_timestamp,
    window_id: selectedRow.window_id,
    alert_outcome: 1,
    threat_level: 1,
    notes: selectedRow.unresolved_notes,
  };

  AxiosUI.post(`alert/outcome/`, postedAlert).then(
    () => {
      setSelectedButtonsFilled(() => {
        return {
          true_threat: true,
          true_non_threat: false,
          false_positive: false,
          escalated_true_threat: false,
        };
      });
      // handleAlertRefreshFromDetectionLabeling(
      //   pageLoadedNumber,
      //   setPageLoadedNumber,
      //   setAlertData,
      //   navigate,
      //   setSelectedRow,
      //   setFetchingFilteredData,
      //   setSeeMoreURL,
      //   setMP4URL,
      //   userFilters,
      //   userFilters.filter_type,
      //   setSelectedButtonsFilled,
      //   setThumbnailVideos,
      //   setDateOutsideRange,
      //   setPage,
      //   setVideoClipLoading,
      //   setAlertsToDisplay,
      //   setPageLoading,
      //   setSelectedAlertCameraInfo,
      //   setIgnoreZonesSelectedAlert,
      //   setAlertsIndexOrder,
      //   setRefreshInterval,
      //   setCurrentName,
      //   setUserFilters,
      //   setRefreshingData,
      //   refreshInterval,
      //   cameraOptions,
      //   siteOptions,
      //   setRefreshingToken,
      //   refreshingToken,
      //   setAlertLabels,
      //   setPeriodHours,
      //   setQueryLimitHit,
      //   setForwardArrowDisabled,
      //   setBackArrowDisabled,
      //   setShowRefreshButton,
      //   setRowsSortable,
      //   setAlertsRefreshing
      // );
      const newSelectedRow = selectedRow;
      const newAlertData = alertData;
      newAlertData.forEach((object: AlertData, index: number) => {
        if (
          newSelectedRow.approx_capture_timestamp ===
          object.approx_capture_timestamp
        ) {
          newAlertData[index].detection_type = "true_threat";
          newAlertData[index].seen = true;
          newAlertData[index].outcome_number = 1;
          newAlertData[index].notes = selectedRow.unresolved_notes;
        }
      });
      setAlertData(newAlertData);
      newSelectedRow.detection_type = "true_threat";
      newSelectedRow.seen = true;
      newSelectedRow.updated = getCurrentDateTime();
      newSelectedRow.user = localStorage.username;
      localStorage.setItem("selectedRowAlerts", JSON.stringify(newSelectedRow));
      setSelectedRow(newSelectedRow);
      setButtonClicked(false);
      // if (refreshInterval > 2000) {
      // setTimeout(() => {

      // }, 1000);
      // }
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setButtonClicked(false);
        alert("Error saving alert type, please try again");
        // eslint-disable-next-line no-console
        console.log(reason);
      });
    }
  );
};

export const TrueThreatButton = (props: any) => {
  const {
    selectdButtonsFilled,
    setSelectedButtonsFilled,
    alertData,
    setAlertData,
    selectedRow,
    setSelectedRow,
    navigate,
    setPageLoading,
    setButtonClicked,
    pageLoadedNumber,
    setPageLoadedNumber,
    setFetchingFilteredData,
    setSeeMoreURL,
    setMP4URL,
    userFilters,
    setThumbnailVideos,
    setDateOutsideRange,
    setPage,
    setVideoClipLoading,
    setAlertsToDisplay,
    setSelectedAlertCameraInfo,
    setIgnoreZonesSelectedAlert,
    setAlertsIndexOrder,
    setRefreshInterval,
    setCurrentName,
    setUserFilters,
    setRefreshingData,
    refreshInterval,
    cameraOptions,
    siteOptions,
    setRefreshingToken,
    refreshingToken,
    setAlertLabels,
    setPeriodHours,
    setQueryLimitHit,
    setForwardArrowDisabled,
    setBackArrowDisabled,
    setShowRefreshButton,
    setRowsSortable,
    setAlertsRefreshing,
  } = props;

  const buttonWidth =
    selectedRow.detection_type === "escalated_true_threat" ||
    selectedRow.detection_type === "true_threat"
      ? "240"
      : "170";

  const filledButton = (
    <button
      className={`shadow-button bg-[#2E7D32] border-2	border-[#2E7D32] rounded font-mulish text-white text-base p-1.5 w-[${buttonWidth}px]`}
      onClick={() => {
        setButtonClicked(true);
        handleTrueThreatClick(
          alertData,
          setAlertData,
          selectedRow,
          setSelectedRow,
          navigate,
          setSelectedButtonsFilled,
          setPageLoading,
          setButtonClicked,
          pageLoadedNumber,
          setPageLoadedNumber,
          setFetchingFilteredData,
          setSeeMoreURL,
          setMP4URL,
          userFilters,
          setThumbnailVideos,
          setDateOutsideRange,
          setPage,
          setVideoClipLoading,
          setAlertsToDisplay,
          setSelectedAlertCameraInfo,
          setIgnoreZonesSelectedAlert,
          setAlertsIndexOrder,
          setRefreshInterval,
          setCurrentName,
          setUserFilters,
          setRefreshingData,
          refreshInterval,
          cameraOptions,
          siteOptions,
          setRefreshingToken,
          refreshingToken,
          setAlertLabels,
          setPeriodHours,
          setQueryLimitHit,
          setForwardArrowDisabled,
          setBackArrowDisabled,
          setShowRefreshButton,
          setRowsSortable,
          setAlertsRefreshing
        );
      }}
      disabled={false}
    >
      <Check /> True Threat
    </button>
  );

  const unfilledButton = (
    <button
      className={`shadow-button bg-white border-2	border-[#2E7D32] rounded font-mulish text-[#2E7D32] text-base p-1.5 w-[${buttonWidth}px]`}
      onClick={() => {
        setButtonClicked(true);
        setFetchingFilteredData(true);
        handleTrueThreatClick(
          alertData,
          setAlertData,
          selectedRow,
          setSelectedRow,
          navigate,
          setSelectedButtonsFilled,
          setPageLoading,
          setButtonClicked,
          pageLoadedNumber,
          setPageLoadedNumber,
          setFetchingFilteredData,
          setSeeMoreURL,
          setMP4URL,
          userFilters,
          setThumbnailVideos,
          setDateOutsideRange,
          setPage,
          setVideoClipLoading,
          setAlertsToDisplay,
          setSelectedAlertCameraInfo,
          setIgnoreZonesSelectedAlert,
          setAlertsIndexOrder,
          setRefreshInterval,
          setCurrentName,
          setUserFilters,
          setRefreshingData,
          refreshInterval,
          cameraOptions,
          siteOptions,
          setRefreshingToken,
          refreshingToken,
          setAlertLabels,
          setPeriodHours,
          setQueryLimitHit,
          setForwardArrowDisabled,
          setBackArrowDisabled,
          setShowRefreshButton,
          setRowsSortable,
          setAlertsRefreshing
        );
      }}
      disabled={false}
    >
      <Check /> True Threat
    </button>
  );

  return selectdButtonsFilled.true_threat ? filledButton : unfilledButton;
};
