import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { CircularProgress } from "@mui/material";

export const FalsePositiveReductionGraph = ({
  falsePostiveData,
  noFPData,
}: {
  falsePostiveData: any;
  noFPData: boolean;
}) => {
  return (
    <div className="absolute top-[65px] left-[735px] right-[1px] h-[370px] w-[610px] bg-white">
      <p className="text-actuate-blue font-mulish text-[26px] font-bold absolute top-[15px] left-[40px]">
        False Positive Reduction
      </p>
      <p className="text-actuate-blue font-mulish text-[16px] absolute top-[15px] left-[500px]">
        Last 5 Days
      </p>
      {/* <p className="text-[#757575] font-mulish text-[16px] absolute top-[200px] left-[-80px] -rotate-90">
        Motion Counts vs Detections
      </p> */}

      {!falsePostiveData && !noFPData && (
        <div className="absolute top-[170px] left-[290px]">
          <CircularProgress size={25} sx={{ position: "relative", left: 40 }} />
          <div className="font-mulish text-actuate-blue test-bold">
            Fetching Data...
          </div>
        </div>
      )}
      {!falsePostiveData && noFPData && (
        <div className="absolute top-[190px] left-[280px]">
          <div className="font-mulish text-actuate-blue test-bold">
            No Data to Display.
          </div>
        </div>
      )}
      <div className="absolute top-[70px] left-[10px]">
        <BarChart
          width={600}
          height={280}
          data={falsePostiveData}
          margin={{
            top: 5,
            right: 20,
            left: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis allowDecimals={false} />
          {falsePostiveData[0] && <Tooltip />}
          <Legend verticalAlign="top" height={36} />
          <Bar
            dataKey="detections"
            stackId="a"
            fill="#283E6D"
            name="Detections"
          />
          <Bar
            dataKey="motion_counts"
            stackId="b"
            fill="#FF8400"
            name="Motion Counts"
          />
        </BarChart>
      </div>
    </div>
  );
};
