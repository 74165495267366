/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import * as React from "react";
import { NavBar } from "../../common/NavBar";
import { IgnoreZonesBreadCrumbs } from "./IgnoreZonesBreadCrumbs";
import { createIgnoreZonesLinksArray } from "./EditIgnoreZonesUtils/createIgnoreZonesLinksArray";
import { useNavigate } from "react-router-dom";
import { pointsCreator } from "./EditIgnoreZonesUtils/pointsCreatorLarger";
import ZwibblerComponent from "./ZwibblerComponet";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { ActuateButton } from "../../common/ActuateButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { handleSubmitIgnoreZones } from "./EditIgnoreZonesUtils/handleSubmitIgnoreZones";
import * as LinesIcon from "../../../../assets/LinesIcon.png";
import * as CornerBoxes from "../../../../assets/CornerBoxes.png";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { getEditIgnoreZonesInfo } from "./EditIgnoreZonesUtils/getEditIgnoreZonesInfo";
import { pointsCreatorIgnoreZones } from "./EditIgnoreZonesUtils/pointCreatorIgnoreZones";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { Tooltip } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getAllCameras } from "../EditCamera/EditCameraUtils/getAllCameras";
import {
  getNumbersAfterEditCamera,
  getNumbersAfterSites,
} from "../EditCamera/EditCamera";
import { resetEditIgnoreZonesState } from "./EditIgnoreZonesUtils/resetEditIgnoreZonesState";
import { PopOutEditor } from "./PopOutEditor";
import IgnoreZoneBuilder from "../../common/IgnoreZoneBuilder";

export const EditIgnoreZones = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [breadcrumbs, setBreadCrumbs] = React.useState([]);

  const [visibleIgnoreZones, setVisibleIgnoreZones] = React.useState([]);

  const [ignorezoneEditorVisible, setIgnorezoneEditorVisible] =
    React.useState(false);

  const [selectedIgnoreZone, setSelectedIgnoreZone] = React.useState("");

  const [editsMade, setEditsMade] = React.useState(false);

  const [pageLoading, setPageLoading] = React.useState(true);

  const [largerMode, setLargerMode] = React.useState(false);

  // const [dimensions, setDimensions] = React.useState({
  //   width: 640,
  //   height: 360,
  // });

  const [adjustedDimensions, setAdjustedDimensions] = React.useState({
    x: 720,
    y: 576,
  });

  const [dimensions, setDimensions] = React.useState<{
    width: number;
    height: number;
  } | null>(null);

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;

    // setDimensions(resizeImage(naturalWidth, naturalHeight));
  };

  const navigate = useNavigate();

  const siteId = Number(getNumbersAfterSites(location.href));

  const handleSitesBreadcrumbClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    localStorage.setItem("level0Click", "false");
    localStorage.setItem("navigatedFromSitesAboutPage", "false");
    navigate("/sites");
  };

  const handleSingularSiteBreadcrumbClick = () => {
    navigate(`/sites/aboutsite/${siteId}`);
  };

  const handleIgnoreZoneClick = (object: any) => {
    setIgnorezoneEditorVisible(true);
    localStorage.setItem("ignoreZoneColor", object.line_color);
    localStorage.setItem("ignoreZones", JSON.stringify(object.coordinates));
  };

  const transformCoordinates = (coords: number[][][]): number[][][] => {
    return coords.map((group) =>
      group.map(([x, y]) => [
        x * adjustedDimensions.x,
        y * adjustedDimensions.y,
      ])
    );
  };

  const [allCamerasData, setAllCamerasData] = React.useState<any[]>([]);

  const [cameraIndex, setCameraIndex] = React.useState(0);

  const [popOutEditorDialogOpen, setPopOutEditorDialogOpen] =
    React.useState(false);

  const [iZBuilder, setIZBuilder] = React.useState(<></>);

  const [siteSchedules, setSiteSchedules] = React.useState([]);

  React.useEffect(() => {
    getEditIgnoreZonesInfo(
      setPageLoading,
      setBreadCrumbs,
      setVisibleIgnoreZones,
      setDimensions,
      setAdjustedDimensions
    );
    getAllCameras(
      Number(getNumbersAfterSites(location.href)),
      Number(getNumbersAfterEditCamera(location.href)),
      setAllCamerasData,
      setCameraIndex
    );
    localStorage.setItem("siteId", getNumbersAfterSites(location.href));
    localStorage.setItem(
      "currentCameraId",
      getNumbersAfterEditCamera(location.href)
    );
  }, []);

  React.useEffect(() => {
    if (ignorezoneEditorVisible) {
      setIZBuilder(
        <IgnoreZoneBuilder
          shapeColor={localStorage.ignoreZoneColor}
          height={adjustedDimensions.y}
          width={adjustedDimensions.x}
          imageURL={JSON.parse(localStorage.preview_url)}
          shapesArray={transformCoordinates(
            JSON.parse(localStorage.ignoreZones)
          )}
          setIgnorezoneEditorVisible={setIgnorezoneEditorVisible}
          setEditsMade={setEditsMade}
          visibleIgnoreZones={visibleIgnoreZones}
          selectedIgnoreZone={selectedIgnoreZone}
          setVisibleIgnoreZones={setVisibleIgnoreZones}
          largerMode={largerMode}
          setLargerMode={setLargerMode}
          dimensions={dimensions}
          setIZBuilder={setIZBuilder}
          allIgnoreZonesShown={false}
          siteSchedules={siteSchedules}
        />
      );
    } else {
      setIZBuilder(<></>);
    }
  }, [ignorezoneEditorVisible]);

  return (
    <div>
      <BackdropLoadingScreen openState={pageLoading} />
      <PopOutEditor
        popOutEditorDialogOpen={popOutEditorDialogOpen}
        setPopOutEditorDialogOpen={setPopOutEditorDialogOpen}
      />
      <div className="bg-actuate-grey absolute w-full h-full overflow-scroll">
        {!pageLoading && allCamerasData[0] && (
          <div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[15px] left-[150px] right-[1px] h-[59px] bg-white"
                  : "absolute top-[15px] left-[75px] right-[1px] h-[59px] bg-white"
              }
            >
              <IgnoreZonesBreadCrumbs
                linksArray={createIgnoreZonesLinksArray(breadcrumbs)}
                handleSitesBreadcrumbClick={handleSitesBreadcrumbClick}
                handleSingularSiteBreadcrumbClick={
                  handleSingularSiteBreadcrumbClick
                }
                styling="absolute top-[15px] left-[30px]"
              />
            </div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[87px] left-[150px] right-[1px] h-[58px] bg-white"
                  : "absolute top-[87px] left-[75px] right-[1px] h-[58px] bg-white"
              }
            >
              <p className="font-black font-mulish text-[20px] p-3 indent-4">
                {localStorage.currentCamera}
              </p>
            </div>
            <div className="absolute top-[97px] right-[100px]">
              {!ignorezoneEditorVisible && (
                <OrangeTextButton
                  onClick={() => {
                    handleSubmitIgnoreZones(
                      visibleIgnoreZones,
                      navigate,
                      setPageLoading
                    );
                  }}
                  text="Submit Changes"
                  disabled={!editsMade}
                />
              )}
            </div>
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[145px] left-[150px] right-[1px] h-full bg-white"
                  : "absolute top-[145px] left-[75px] right-[1px] h-full bg-white"
              }
            >
              {!ignorezoneEditorVisible && allCamerasData.length > 1 && (
                <div className="pt-[200px] w-[40px]">
                  <Tooltip
                    title={`Previous Camera: ${
                      cameraIndex === 0
                        ? allCamerasData[allCamerasData.length - 1].camera_name
                        : allCamerasData[cameraIndex - 1].camera_name
                    }`}
                    placement="top"
                    sx={{ fontSize: "12px", fontFamily: "mulish" }}
                  >
                    <div>
                      <OrangeTextButton
                        onClick={() => {
                          localStorage.setItem(
                            "currentCamera",
                            cameraIndex === 0
                              ? allCamerasData[allCamerasData.length - 1]
                                  .camera_name
                              : allCamerasData[cameraIndex - 1].camera_name
                          );
                          resetEditIgnoreZonesState(
                            setBreadCrumbs,
                            setVisibleIgnoreZones,
                            setIgnorezoneEditorVisible,
                            setSelectedIgnoreZone,
                            setEditsMade,
                            setPageLoading,
                            setDimensions,
                            setAllCamerasData,
                            setCameraIndex
                          );
                          if (cameraIndex === 0) {
                            navigate(
                              `/sites/${siteId}/editcamera/${
                                allCamerasData[allCamerasData.length - 1].id
                              }/editignorezones`
                            );
                            getEditIgnoreZonesInfo(
                              setPageLoading,
                              setBreadCrumbs,
                              setVisibleIgnoreZones,
                              setDimensions,
                              setAdjustedDimensions
                            );
                            getAllCameras(
                              Number(getNumbersAfterSites(location.href)),
                              Number(getNumbersAfterEditCamera(location.href)),
                              setAllCamerasData,
                              setCameraIndex
                            );
                          } else {
                            navigate(
                              `/sites/${siteId}/editcamera/${
                                allCamerasData[cameraIndex - 1].id
                              }/editignorezones`
                            );
                            getEditIgnoreZonesInfo(
                              setPageLoading,
                              setBreadCrumbs,
                              setVisibleIgnoreZones,
                              setDimensions,
                              setAdjustedDimensions
                            );
                            getAllCameras(
                              Number(getNumbersAfterSites(location.href)),
                              Number(getNumbersAfterEditCamera(location.href)),
                              setAllCamerasData,
                              setCameraIndex
                            );
                          }
                        }}
                        text={
                          <ArrowBackIosIcon fontSize="large" id="backButton" />
                        }
                        disabled={allCamerasData.length < 2}
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
              {!ignorezoneEditorVisible && allCamerasData.length > 1 && (
                <div className="absolute top-[200px] left-[1250px]">
                  <Tooltip
                    title={`Next Camera: ${
                      cameraIndex + 1 === allCamerasData.length
                        ? allCamerasData[0].camera_name
                        : allCamerasData[cameraIndex + 1].camera_name
                    }`}
                    placement="top"
                    sx={{ fontSize: "12px", fontFamily: "mulish" }}
                  >
                    <div>
                      <OrangeTextButton
                        onClick={() => {
                          localStorage.setItem(
                            "currentCamera",
                            cameraIndex + 1 === allCamerasData.length
                              ? allCamerasData[0].camera_name
                              : allCamerasData[cameraIndex + 1].camera_name
                          );
                          resetEditIgnoreZonesState(
                            setBreadCrumbs,
                            setVisibleIgnoreZones,
                            setIgnorezoneEditorVisible,
                            setSelectedIgnoreZone,
                            setEditsMade,
                            setPageLoading,
                            setDimensions,
                            setAllCamerasData,
                            setCameraIndex
                          );
                          if (cameraIndex + 1 === allCamerasData.length) {
                            navigate(
                              `/sites/${siteId}/editcamera/${allCamerasData[0].id}/editignorezones`
                            );
                            getEditIgnoreZonesInfo(
                              setPageLoading,
                              setBreadCrumbs,
                              setVisibleIgnoreZones,
                              setDimensions,
                              setAdjustedDimensions
                            );
                            getAllCameras(
                              Number(getNumbersAfterSites(location.href)),
                              Number(getNumbersAfterEditCamera(location.href)),
                              setAllCamerasData,
                              setCameraIndex
                            );
                          } else {
                            navigate(
                              `/sites/${siteId}/editcamera/${
                                allCamerasData[cameraIndex + 1].id
                              }/editignorezones`
                            );
                            getEditIgnoreZonesInfo(
                              setPageLoading,
                              setBreadCrumbs,
                              setVisibleIgnoreZones,
                              setDimensions,
                              setAdjustedDimensions
                            );
                            getAllCameras(
                              Number(getNumbersAfterSites(location.href)),
                              Number(getNumbersAfterEditCamera(location.href)),
                              setAllCamerasData,
                              setCameraIndex
                            );
                          }
                        }}
                        text={
                          <ArrowForwardIosIcon
                            fontSize="large"
                            id="backButton"
                          />
                        }
                        disabled={allCamerasData.length < 2}
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
              {!ignorezoneEditorVisible && (
                <div>
                  <div
                    className={
                      !navBarCollapsed
                        ? "overflow-auto absolute left-[855px] top-[75px] min-h-[360px] w-[305px]"
                        : "overflow-auto absolute left-[855px] top-[75px] min-h-[360px] w-[305px]"
                    }
                  >
                    <div className="absolute left-[0px] top-[0px] w-[275px]">
                      {visibleIgnoreZones.map((object: any) => {
                        return (
                          <div
                            key={object.label}
                            role="button"
                            onClick={() => {
                              setSelectedIgnoreZone(object.label);
                              handleIgnoreZoneClick(object);
                              setLargerMode(false);
                            }}
                          >
                            <div
                              key={object.label}
                              style={{
                                height: 25,
                                width: 6,
                                background: `${object.line_color}`,
                              }}
                              className="absolute"
                            ></div>
                            <p className="font-mulish text-[16px] indent-5">
                              {object.label_display_name}
                            </p>
                            &nbsp;
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {!pageLoading && !ignorezoneEditorVisible && (
                <div className="absolute top-[53px] left-[70px]">
                  <img
                    src={JSON.parse(localStorage.preview_url)}
                    style={{
                      width: `${adjustedDimensions.x}px`,
                      height: `${adjustedDimensions.y}px`,
                    }}
                    onLoad={handleImageLoad}
                    alt="TestCameraPhoto"
                  />
                  {!ignorezoneEditorVisible &&
                    visibleIgnoreZones.map((object: any) => {
                      return (
                        <div className="absolute top-[0px]" key={object.label}>
                          {object.coordinates[0] &&
                            object.coordinates.map((array: any, index: any) => {
                              return (
                                <svg
                                  className="absolute top-[0px]"
                                  key={index}
                                  style={{
                                    width: `${adjustedDimensions.x}px`,
                                    height: `${adjustedDimensions.y}px`,
                                  }}
                                >
                                  {dimensions && (
                                    <polygon
                                      points={pointsCreator(
                                        object.coordinates[index],
                                        adjustedDimensions.y,
                                        adjustedDimensions.x
                                      )}
                                      stroke={object.line_color}
                                      strokeWidth="5"
                                      fill={object.line_color}
                                      fillOpacity="50%"
                                    />
                                  )}
                                </svg>
                              );
                            })}
                        </div>
                      );
                    })}
                </div>
              )}

              <div
                className="player absolute top-[0px] left-[67px] w-[775px] h-[200px]"
                role="button"
                tabIndex={0}
              >
                {
                  // <ZwibblerComponent />
                  // <IgnoreZoneBuilder
                  //   shapeColor={localStorage.ignoreZoneColor}
                  //   height={adjustedDimensions.y}
                  //   width={adjustedDimensions.x}
                  //   imageURL={JSON.parse(localStorage.preview_url)}
                  //   shapesArray={transformCoordinates(
                  //     JSON.parse(localStorage.ignoreZones)
                  //   )}
                  //   setIgnorezoneEditorVisible={setIgnorezoneEditorVisible}
                  //   setEditsMade={setEditsMade}
                  //   visibleIgnoreZones={visibleIgnoreZones}
                  //   selectedIgnoreZone={selectedIgnoreZone}
                  //   setVisibleIgnoreZones={setVisibleIgnoreZones}
                  //   largerMode={largerMode}
                  //   setLargerMode={setLargerMode}
                  //   dimensions={dimensions}
                  // />
                  iZBuilder
                }
              </div>

              {/* <div className="absolute top-[7px] left-[150px] right-[1px] h-[170px] w-[640px] bg-white"></div> */}
            </div>
            {!ignorezoneEditorVisible && (
              <div
                className={
                  !navBarCollapsed
                    ? "absolute top-[167px] left-[980px] w-[420px] font-mulish text-[18px] text-actuate-blue"
                    : "absolute top-[167px] left-[905px] w-[420px] font-mulish text-[18px] text-actuate-blue"
                }
              >
                &nbsp; Click the Ignore Zone below you&apos;d like to edit.
              </div>
            )}
            {ignorezoneEditorVisible && !largerMode && (
              <div
                className={
                  !navBarCollapsed
                    ? "absolute top-[172px] left-[1020px] font-mulish text-[20px] text-actuate-blue min-w-[470px] pr-[40px]"
                    : "absolute top-[172px] left-[945px] font-mulish text-[20px] text-actuate-blue min-w-[500px] pr-[40px]"
                }
              >
                <p className="font-bold text-[17px]">INSTRUCTIONS:</p>
                <br></br>
                <p className="font-normal text-[17px] ">
                  To begin creating a shape click anywhere on the image to start
                  adding points.
                </p>
                <br></br>
                <p className="font-normal text-[17px]">
                  To finish creating a shape, click on the first point created.
                </p>
                <br></br>
                <p className="font-normal text-[17px]">
                  To modify the shape click and drag the points to the desired
                  location.
                </p>
                <br></br>
                <p className="font-normal text-[17px]">
                  To add additional points to the shape, click on the line
                  between two points.
                </p>
                <br></br>
                <p className="font-normal text-[17px]">
                  To create an additional shape, click anywhere on the image to
                  start adding points.
                </p>
                <br></br>
                <p className="font-normal text-[17px]">
                  To delete a shape hover anywhere over the shape and click to
                  select. Then press the delete key on your keyboard.
                </p>
                <br></br>
                <p className="font-normal text-[17px]">
                  To delete a single point double click on the point. This
                  action will only work if the remaining shape will have at
                  least 3 points.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};
