import React from "react";
import * as ActuateLogo from "../../../../assets/AcuateLogo.png";

export const RoboMladden = () => {
  return (
    <div className="bg-actuate-white absolute w-full h-full overflow-scroll min-w-[1200px]">
      <div className="h-[100px] w-full bg-actuate-blue flex flex-col items-center justify-center relative">
        <div className="absolute top-[20px] right-[20px] md:top-[20px] md:right-[20px]">
          {" "}
          {/* Maintain position for larger screens */}
          <img className="w-[100px]" src={ActuateLogo} alt="actuate logo" />
        </div>
        <p className="font-black font-mulish text-[50px] text-white text-center">
          Video Demo
        </p>
      </div>
      <div className="flex w-full">
        {/* First Column */}
        <div className="w-1/2 flex flex-col">
          <div className="mt-[20px]">
            <p className="pl-[20px] text-actuate-blue font-mulish text-[25px] font-bold">
              Resolution
            </p>
          </div>
          <div className="mt-[200px]">
            <p className="pl-[20px] text-actuate-blue font-mulish text-[25px] font-bold">
              Camera Type
            </p>
          </div>
          <div className="mt-[200px]">
            <p className="pl-[20px] text-actuate-blue font-mulish text-[25px] font-bold">
              Intruder Type
            </p>
          </div>
        </div>

        {/* Second Column */}
        <div className="w-1/2">
          <div className="h-[250px] w-[500px] bg-actuate-grey mt-[40px] flex items-center justify-center">
            <div className="font-mulish text-black text-[25px]">
              Drag Video Here
            </div>
          </div>
          <div className="mt-[20px] ml-[215px]">
            <button
              className={
                "shadow-button bg-actuate-orange rounded font-mulish text-white text-base	p-1.5 hover:bg-[#E67300]"
              }
            >
              Analyze
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
