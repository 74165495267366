import React from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { AlertData } from "./AlertsUtils/alertsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { createMonitoringAPICall } from "../../utils/createMonitoringAPICall";
import { NavigateFunction } from "react-router-dom";
import { AxiosError } from "axios";
import { getCurrentDateTime } from "../../utils/getCurrentDateAndTime";
import { universalAPIErrorHandler } from "../../utils/universalAPIErrorHandler";

const handleEscalatedTrueThreatClick = (
  alertData: AlertData[],
  setAlertData: Dispatcher<AlertData[]>,
  selectedRow: AlertData,
  setSelectedRow: Dispatcher<AlertData>,
  navigate: NavigateFunction,
  setSelectedButtonsFilled: Dispatcher<any>,
  setPageLoading: Dispatcher<boolean>,
  setButtonClicked: Dispatcher<boolean>
) => {
  const AxiosUI = createMonitoringAPICall(``);
  const retryFunction = () => {
    handleEscalatedTrueThreatClick(
      alertData,
      setAlertData,
      selectedRow,
      setSelectedRow,
      navigate,
      setSelectedButtonsFilled,
      setPageLoading,
      setButtonClicked
    );
  };
  const postedAlert = {
    approx_capture_timestamp: selectedRow.approx_capture_timestamp,
    window_id: selectedRow.window_id,
    alert_outcome: 4,
    threat_level: 4,
    notes: selectedRow.unresolved_notes,
  };

  AxiosUI.post(`alert/outcome/`, postedAlert).then(
    () => {
      setSelectedButtonsFilled(() => {
        return {
          true_threat: false,
          true_non_threat: false,
          false_positive: false,
          escalated_true_threat: true,
        };
      });
      const newSelectedRow = selectedRow;
      const newAlertData = alertData;
      newAlertData.forEach((object: AlertData, index: number) => {
        if (
          newSelectedRow.approx_capture_timestamp ===
          object.approx_capture_timestamp
        ) {
          newAlertData[index].detection_type = "escalated_true_threat";
          newAlertData[index].seen = true;
          newAlertData[index].outcome_number = 4;
          newAlertData[index].notes = selectedRow.unresolved_notes;
        }
      });
      setAlertData(newAlertData);
      newSelectedRow.detection_type = "escalated_true_threat";
      newSelectedRow.seen = true;
      newSelectedRow.updated = getCurrentDateTime();
      newSelectedRow.user = localStorage.username;
      localStorage.setItem("selectedRowAlerts", JSON.stringify(newSelectedRow));
      setSelectedRow(newSelectedRow);
      setButtonClicked(false);
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setButtonClicked(false);
        alert("Error saving alert type, please try again");
        // eslint-disable-next-line no-console
        console.log(reason);
      });
    }
  );
};

export const EscalatedTrueThreatButton = (props: any) => {
  const {
    selectdButtonsFilled,
    setSelectedButtonsFilled,
    alertData,
    setAlertData,
    selectedRow,
    setSelectedRow,
    navigate,
    setPageLoading,
    setButtonClicked,
  } = props;

  const filledButton = (
    <button
      className="shadow-button bg-[#7d2e72] border-2	border-[#7d2e72] rounded font-mulish text-white text-base p-1.5 w-[240px]"
      onClick={() => {
        setButtonClicked(true);
        handleEscalatedTrueThreatClick(
          alertData,
          setAlertData,
          selectedRow,
          setSelectedRow,
          navigate,
          setSelectedButtonsFilled,
          setPageLoading,
          setButtonClicked
        );
      }}
      disabled={false}
    >
      <PriorityHighIcon /> Escalated True Threat
    </button>
  );

  const unfilledButton = (
    <button
      className="shadow-button bg-white border-2	border-[#7d2e72] rounded font-mulish text-[#7d2e72] text-base p-1.5 w-[240px]"
      onClick={() => {
        setButtonClicked(true);
        handleEscalatedTrueThreatClick(
          alertData,
          setAlertData,
          selectedRow,
          setSelectedRow,
          navigate,
          setSelectedButtonsFilled,
          setPageLoading,
          setButtonClicked
        );
      }}
      disabled={false}
    >
      <PriorityHighIcon /> Escalated True Threat
    </button>
  );

  return selectdButtonsFilled.escalated_true_threat
    ? filledButton
    : unfilledButton;
};
