import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { AlertData, Order } from "./alertsInterfaces";

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  let thisOrderBy = orderBy;
  if (orderBy === "utc_time") {
    thisOrderBy = "clip_header_received" as Key;
  }
  if (orderBy === "time") {
    thisOrderBy = "clip_received_local" as Key;
  }
  if (orderBy === "site") {
    thisOrderBy = "customer_name" as Key;
  }
  if (orderBy === "formatted_date") {
    thisOrderBy = "approx_capture_timestamp" as Key;
  }

  return order === "desc"
    ? (a, b) => descendingComparator(a, b, thisOrderBy)
    : (a, b) => -descendingComparator(a, b, thisOrderBy);
}

export function stableSort<T>(
  array: any[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const isSelected = (name: string, selected: string[]) =>
  selected.indexOf(name) !== -1;

export const handleSelectAllClick = (
  event: React.ChangeEvent<HTMLInputElement>,
  alertData: AlertData[],
  setAlertData: Dispatcher<AlertData[]>
) => {
  const newAlertData: AlertData[] = [];
  if (event.target.checked) {
    alertData.forEach((alert: any) => {
      const newAlert = alert;
      newAlert.checked = true;
      newAlertData.push(newAlert);
    });
  } else {
    alertData.forEach((alert: any) => {
      const newAlert = alert;
      newAlert.checked = false;
      newAlertData.push(newAlert);
    });
  }
  setAlertData(newAlertData);
};

export const handleSingleCheckBoxClick = (
  event: any,
  alertData: AlertData[],
  selectedTimeStamp: string,
  setAlertData: Dispatcher<AlertData[]>
) => {
  const updatedAlertData: any[] = [];
  alertData.forEach((alert) => {
    const newAlert = alert;
    if (alert.approx_capture_timestamp === selectedTimeStamp) {
      newAlert.checked = event.target.checked;
    }
    updatedAlertData.push(newAlert);
  });
  setAlertData(updatedAlertData);
};
