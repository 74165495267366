import React from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { UserFilters } from "./AnalyticsUtils/analyticsInterfaces";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const CameraDropDown = ({
  userFilters,
  setUserFilters,
  cameraOptions,
  cameraDropDownValue,
  setCameraDropDownValue,
  loadingCameras,
}: {
  userFilters: UserFilters;
  setUserFilters: Dispatcher<any>;
  cameraOptions: any;
  cameraDropDownValue: any;
  setCameraDropDownValue: Dispatcher<any[]>;
  loadingCameras: boolean;
}) => {
  const cameraIds = new Map();
  cameraOptions.forEach((object: any) => {
    cameraIds.set(object.camera_name, object.id);
  });

  return (
    <div key="camera_dropdown">
      <Autocomplete
        disablePortal
        multiple
        limitTags={1}
        value={cameraDropDownValue}
        options={
          cameraOptions[0]
            ? cameraOptions.map((object: any) => {
                return object.camera_name;
              })
            : []
        }
        loading={loadingCameras}
        onChange={(event, value: string[]) => {
          if (value !== null) {
            const cameraIdsArray: string[] = [];
            const cameraValuesArray: string[] = [];
            value.forEach((element: string) => {
              cameraIdsArray.push(cameraIds.get(element));
              cameraValuesArray.push(element);
            });
            if (localStorage.filtersSavedClicked === "true") {
              localStorage.setItem(
                "cameraDropDownValues",
                JSON.stringify(cameraValuesArray)
              );
              localStorage.setItem(
                "defaultUserFilters",
                JSON.stringify({
                  parent_group: userFilters.parent_group,
                  site: userFilters.site,
                  camera: cameraIdsArray,
                  start_date: userFilters.start_date,
                  end_date: userFilters.end_date,
                })
              );
            }
            setCameraDropDownValue(cameraValuesArray);

            setUserFilters((previousState: any) => {
              return { ...previousState, camera: cameraIdsArray };
            });
          }
        }}
        sx={{ width: 285 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cameras"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingCameras ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoComplete="off"
          />
        )}
      />
    </div>
  );
};
