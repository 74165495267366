import React from "react";
import { Dispatcher } from "./SiteAboutPageUtils/siteAboutInterfaces";
import { Dialog } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Autocomplete, TextField } from "@mui/material";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { handleCheckOverlappingSchedulesForSharedProducts } from "./SiteAboutPageUtils/handleCheckOverlappingSchedulesForSharedProducts";

export const EditActiveProductsDialog = ({
  siteSchedules,
  editActiveProductsDialogOpen,
  setEditActiveProductsDialogOpen,
  setSiteSchedules,
  setScheduleChangesMade,
}: {
  siteSchedules: any;
  editActiveProductsDialogOpen: boolean;
  setEditActiveProductsDialogOpen: Dispatcher<boolean>;
  setSiteSchedules: Dispatcher<any>;
  setScheduleChangesMade: Dispatcher<boolean>;
}) => {
  const schedulesIndex = Number(localStorage.schedulesIndex);

  const [selectedProducts, setSelectedProducts] = React.useState([]);

  const products = JSON.parse(localStorage.products);
  const productIds = new Map();
  const productNames = new Map();

  products.forEach((element: any) => {
    productNames.set(`${element.name}`, element.id);
    productIds.set(element.id, `${element.name}`);
  });

  const productOptions = JSON.parse(localStorage.selectedProducts).map(
    (number: any) => productIds.get(number)
  );

  React.useEffect(() => {
    setSelectedProducts(
      siteSchedules[schedulesIndex].product.map((id: any) => productIds.get(id))
    );
  }, []);

  return (
    <Dialog
      open={editActiveProductsDialogOpen}
      onClose={() => setEditActiveProductsDialogOpen(false)}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ background: "#001943" }}>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Edit Active Products
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div>
          <div className="h-[200px]">
            <div className=" bg-white">
              <div>
                <div className="pt-[20px] pb-[30px]">
                  <Autocomplete
                    multiple
                    value={selectedProducts}
                    disablePortal
                    options={productOptions}
                    onChange={(event, value: any) => {
                      setSelectedProducts(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product(s)"
                        autoComplete="off"
                        sx={{ width: 400 }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex pr-[20px] pt-[20px]" style={{ display: "flex" }}>
          <div style={{ marginLeft: "auto" }}>
            <OrangeTextButton
              onClick={() => {
                setEditActiveProductsDialogOpen(false);
              }}
              text="Cancel"
              disabled={false}
            />
          </div>
          &nbsp;&nbsp;&nbsp;
          <div>
            <OrangeTextButton
              onClick={() => {
                const newSiteSchedules = siteSchedules;
                newSiteSchedules[schedulesIndex].product = selectedProducts.map(
                  (name: any) => productNames.get(name)
                );
                setSiteSchedules(newSiteSchedules);
                setScheduleChangesMade(true);
                setEditActiveProductsDialogOpen(false);
                handleCheckOverlappingSchedulesForSharedProducts(newSiteSchedules);
              }}
              text="Ok"
              disabled={false}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};
