import React from "react";
import { CircularProgress } from "@mui/material";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { sortHighToLow } from "./HealthMonitoringUtils/sortHighToLow";
import { SortingMenu } from "./SortingMenu";
import { LineChartTest } from "./LineChartTest";
import RefreshIcon from "@mui/icons-material/Refresh";
import { BandwidthBarGraph } from "./BandwidthBarGraph";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { handleRetryBandwidthDataFetch } from "./HealthMonitoringUtils/handleRetryBandwidthDataFetch";

export const BandwidthGraph = ({
  bandwidthData,
  loadingBandwidthData,
  navBarCollapsed,
  bandwidthLoadingError,
  setLoadingBandwidthData,
  setBandwidthLoadingError,
  filters,
  setBandwidthData,
  navigate,
}: {
  bandwidthData: any;
  loadingBandwidthData: boolean;
  navBarCollapsed: boolean;
  bandwidthLoadingError: boolean;
  setLoadingBandwidthData: Dispatcher<boolean>;
  setBandwidthLoadingError: Dispatcher<boolean>;
  filters: any;
  setBandwidthData: any;
  navigate: any;
}) => {
  const retryText = (
    <>
      <RefreshIcon fontSize="small" />
      &nbsp;Retry
    </>
  );

  return (
    <div
      className={
        !navBarCollapsed
          ? "absolute top-[50px] left-[20px] right-[1px] h-[425px] w-[610px] bg-[#BDBDBD]"
          : "absolute top-[50px] left-[58px] right-[1px] h-[425px] w-[647px] bg-[#BDBDBD]"
      }
    >
      <div
        className={
          !navBarCollapsed
            ? "absolute top-[1px] left-[1px] right-[1px] h-[423px] w-[608px] bg-white"
            : "absolute top-[1px] left-[1px] right-[1px] h-[423px] w-[645px] bg-white"
        }
      >
        <p className="text-actuate-blue font-mulish text-[22px] font-bold absolute top-[8px] left-[30px]">
          Bandwidth by Site
        </p>
        <p className="text-actuate-blue font-mulish text-[14px] absolute top-[40px] left-[31px]">
          Bandwidth used in GB over selected dates.
        </p>
        <div
          className={
            !navBarCollapsed
              ? "absolute top-[25px] left-[10px] h-[380px] w-[590px] overflow-scroll scrollbar-hide"
              : "absolute top-[25px] left-[10px] h-[380px] w-[617px] overflow-scroll scrollbar-hide"
          }
        >
          {/* <LineChartTest /> */}
          {!loadingBandwidthData && !bandwidthLoadingError && (
            <BandwidthBarGraph bandwidthData={bandwidthData} />
          )}
          {!loadingBandwidthData && bandwidthLoadingError && (
            <div className="absolute top-[170px] left-[200px]">
              <div className="font-mulish text-actuate-blue test-bold text-[20px]">
                Error fetching data.
              </div>
              <div className="pl-[35px]">
                <OrangeTextButton
                  onClick={() => {
                    setLoadingBandwidthData(true);
                    setBandwidthLoadingError(false);
                    handleRetryBandwidthDataFetch(
                      filters,
                      setBandwidthData,
                      setLoadingBandwidthData,
                      setBandwidthLoadingError,
                      navigate
                    );
                  }}
                  text={retryText}
                  disabled={false}
                />
              </div>
            </div>
          )}
          {loadingBandwidthData && (
            <div className="absolute top-[170px] left-[230px]">
              <CircularProgress
                size={25}
                sx={{ position: "relative", left: 40 }}
              />
              <div className="font-mulish text-actuate-blue test-bold">
                Fetching Data...
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
