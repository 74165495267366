import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { GeneralInfoTab } from "../GeneralInfoTab";
import { VmsTab } from "../VmsTab";
import { SchedulesTab } from "../SchedulesTab";
import { submitScheduleChanges } from "./submitScheduleChanges";
import { getUpdatedSitesPageInfo } from "../../../AddSite/getUpdatedSitesPageInfo";
import { checkArmedStatus } from "./checkArmedStatus";
import handlePatchRecordingServerData from "./handlePatchRecordingServerData";
import { SMTPTab } from "../SMTPTab";
import { RecordingServerTab } from "../RecordingServerTab";
import { handlePatchCameraHealthData } from "./handlePatchCameraHealthData";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { handlePatchWebhooksData } from "./handlePatchWebhooksData";
import { CameraHealthTab } from "../CameraHealthTab";
import { WebhooksTab } from "../WebhooksTab";
import { getNumbersAfterAboutSite } from "../SiteAbout";

export const handleSaveChangesButtonClick = async (
  patchObject: any,
  setEditMode: any,
  setSiteInfo: any,
  setPatchObject: any,
  siteInfo: any,
  clickedTab: any,
  setActiveTab: any,
  vmsVariables: any,
  siteSchedules: any,
  setAllSiteSchedules: any,
  setBackdropLoadingScreenOn: any,
  setAlertState: any,
  setSyncNeeded: any,
  editMode: any,
  setSchedulesTabActive: any,
  setClickedTab: any,
  siteInfoChangesMade: any,
  setSiteInfoChangesMade: any,
  scheduleChangesMade: any,
  setScheduleChangesMade: any,
  setDeleteScheduleDialogOpen: any,
  navigate: any,
  setSiteSchedules: any,
  setArmButtonDisabled: any,
  recordingServerChangesMade: any,
  recordingServerData: any,
  recordingServerPatchObject: any,
  setRecordingServerData: any,
  setRecordingServerPatchObject: any,
  setRecordingServerChangesMade: any,
  cameraHealthChangesMade: any,
  healthCheckData: any,
  setHealthCheckData: any,
  cameraHealthPatchObject: any,
  setCameraHealthPatchObject: any,
  setCameraHealthChangesMade: any,
  setArmedStatus: any,
  webhooksChangesMade: boolean,
  webhooksInfo: any,
  setWebhooksInfo: any,
  webhooksPatchObject: any,
  setWebhooksPatchObject: any,
  setWebhooksChangesMade: any,
  setDeactivateSiteInsteadDialogOpen: any,
  setActivateSiteWithScheduleDialogOpen: any
) => {
  const AxiosUI = createApi(``);
  setBackdropLoadingScreenOn(true);
  const retryFunction = () => {
    handleSaveChangesButtonClick(
      patchObject,
      setEditMode,
      setSiteInfo,
      setPatchObject,
      siteInfo,
      clickedTab,
      setActiveTab,
      vmsVariables,
      siteSchedules,
      setAllSiteSchedules,
      setBackdropLoadingScreenOn,
      setAlertState,
      setSyncNeeded,
      editMode,
      setSchedulesTabActive,
      setClickedTab,
      siteInfoChangesMade,
      setSiteInfoChangesMade,
      scheduleChangesMade,
      setScheduleChangesMade,
      setDeleteScheduleDialogOpen,
      navigate,
      setSiteSchedules,
      setArmButtonDisabled,
      recordingServerChangesMade,
      recordingServerData,
      recordingServerPatchObject,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerChangesMade,
      cameraHealthChangesMade,
      healthCheckData,
      setHealthCheckData,
      cameraHealthPatchObject,
      setCameraHealthPatchObject,
      setCameraHealthChangesMade,
      setArmedStatus,
      webhooksChangesMade,
      webhooksInfo,
      setWebhooksInfo,
      webhooksPatchObject,
      setWebhooksPatchObject,
      setWebhooksChangesMade,
      setDeactivateSiteInsteadDialogOpen,
      setActivateSiteWithScheduleDialogOpen
    );
  };
  if (siteInfoChangesMade) {
    await AxiosUI.patch(
      `customer/${getNumbersAfterAboutSite(location.href)}/about/`,
      patchObject
    ).then(
      () => {
        setSiteInfoChangesMade(false);
        setPatchObject({ name: siteInfo.name });
        setAlertState((previousState: any) => {
          return {
            ...previousState,
            changeSuccess: true,
            activateSuccess: false,
            deactivateSuccess: false,
            disarmSuccess: false,
            armSuccess: false,
            syncSuccess: false,
            cameraDeleteSuccess: false,
          };
        });
        setEditMode(false);
        setSyncNeeded(true);
        setBackdropLoadingScreenOn(false);
        if (localStorage.groupNameChange === "true") {
          localStorage.setItem("groupNameChange", "false");
          AxiosUI.get(
            `customer/${getNumbersAfterAboutSite(location.href)}/about/`
          ).then(
            (response: AxiosResponse) => {
              setSiteInfo(response.data);
              checkArmedStatus(
                response.data,
                setArmButtonDisabled,
                navigate,
                setSiteInfo,
                setArmedStatus
              );
              localStorage.setItem("siteInfo", JSON.stringify(response.data));
            },
            (reason: AxiosError) => {
              universalAPIErrorHandler(reason, navigate, retryFunction, () => {
                if (clickedTab === "one") {
                  setActiveTab(
                    <GeneralInfoTab
                      siteInfo={siteInfo}
                      setSiteInfo={setSiteInfo}
                      setActiveTab={setActiveTab}
                    />
                  );
                } else if (clickedTab === "two") {
                  setActiveTab(
                    <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
                  );
                } else if (clickedTab === "three") {
                  setActiveTab(
                    <SchedulesTab
                      siteSchedules={siteSchedules}
                      siteInfo={siteInfo}
                    />
                  );
                }
              });
            }
          );
          getUpdatedSitesPageInfo();
        }
        if (clickedTab === "one") {
          setActiveTab(
            <GeneralInfoTab
              siteInfo={siteInfo}
              setSiteInfo={setSiteInfo}
              setActiveTab={setActiveTab}
            />
          );
        } else if (clickedTab === "two") {
          setActiveTab(
            <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
          );
        } else if (clickedTab === "three") {
          setActiveTab(
            <SchedulesTab siteSchedules={siteSchedules} siteInfo={siteInfo} />
          );
        } else if (clickedTab === "five") {
          setActiveTab(
            <RecordingServerTab recordingServerData={recordingServerData} />
          );
        }
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setSiteInfo(JSON.parse(localStorage.siteInfo));
          setEditMode(false);
          setBackdropLoadingScreenOn(false);
          if (clickedTab === "one") {
            setActiveTab(
              <GeneralInfoTab
                siteInfo={siteInfo}
                setSiteInfo={setSiteInfo}
                setActiveTab={setActiveTab}
              />
            );
          } else if (clickedTab === "two") {
            setActiveTab(
              <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
            );
          } else if (clickedTab === "three") {
            setActiveTab(
              <SchedulesTab siteSchedules={siteSchedules} siteInfo={siteInfo} />
            );
          }
        });
      }
    );
  }
  if (scheduleChangesMade) {
    await submitScheduleChanges(
      siteSchedules,
      setAllSiteSchedules,
      setEditMode,
      setBackdropLoadingScreenOn,
      clickedTab,
      setActiveTab,
      siteInfo,
      vmsVariables,
      setDeleteScheduleDialogOpen,
      navigate,
      patchObject,
      setSiteInfo,
      setPatchObject,
      setAlertState,
      setSyncNeeded,
      editMode,
      setSchedulesTabActive,
      setClickedTab,
      siteInfoChangesMade,
      setSiteInfoChangesMade,
      scheduleChangesMade,
      setScheduleChangesMade,
      setSiteSchedules,
      setArmedStatus,
      setDeactivateSiteInsteadDialogOpen,
      setArmButtonDisabled,
      recordingServerChangesMade,
      recordingServerData,
      recordingServerPatchObject,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerChangesMade,
      cameraHealthChangesMade,
      healthCheckData,
      setHealthCheckData,
      cameraHealthPatchObject,
      setCameraHealthPatchObject,
      setCameraHealthChangesMade,
      webhooksChangesMade,
      webhooksInfo,
      setWebhooksInfo,
      webhooksPatchObject,
      setWebhooksPatchObject,
      setWebhooksChangesMade,
      setActivateSiteWithScheduleDialogOpen
    );
    if (localStorage.scheduleChangeError !== "true") {
      // let getScheduleFail = false;
      // await AxiosUI.get(
      //   `schedule/by_customer/${getNumbersAfterAboutSite(location.href)}/`
      // ).then(
      //   (response: AxiosResponse) => {
      //     getScheduleFail = false;
      //     setScheduleChangesMade(false);
      //     localStorage.setItem("siteSchedules", JSON.stringify(response.data));
      //     localStorage.setItem("siteSchedule", JSON.stringify(response.data));
      //     setSiteSchedules(response.data);
      //     setAllSiteSchedules(response.data);
      //     localStorage.setItem(
      //       "allSiteSchedulesBackup",
      //       JSON.stringify(response.data)
      //     );
      //     setEditMode(false);
      //     setBackdropLoadingScreenOn(false);
      //     setAlertState((previousState: any) => {
      //       return {
      //         ...previousState,
      //         changeSuccess: false,
      //         activateSuccess: false,
      //         deactivateSuccess: false,
      //         disarmSuccess: false,
      //         armSuccess: false,
      //         syncSuccess: false,
      //         cameraDeleteSuccess: false,
      //         armFail: false,
      //       };
      //     });
      //     if (clickedTab === "one") {
      //       setActiveTab(
      //         <GeneralInfoTab
      //           siteInfo={siteInfo}
      //           setSiteInfo={setSiteInfo}
      //           setActiveTab={setActiveTab}
      //         />
      //       );
      //     } else if (clickedTab === "two") {
      //       setActiveTab(
      //         <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
      //       );
      //     } else if (clickedTab === "three") {
      //       if (localStorage.scheduleSubmitError === "true") {
      //         localStorage.removeItem("scheduleSubmitError");
      //         setActiveTab(
      //           <SchedulesTab
      //             siteSchedules={siteSchedules}
      //             siteInfo={siteInfo}
      //           />
      //         );
      //       } else {
      //         setActiveTab(
      //           <SchedulesTab
      //             siteSchedules={siteSchedules}
      //             siteInfo={siteInfo}
      //           />
      //         );
      //       }
      //     }
      //   },
      //   (reason: AxiosError) => {
      //     getScheduleFail = true;
      //     universalAPIErrorHandler(reason, navigate, retryFunction, () => {
      //       setSiteInfo(JSON.parse(localStorage.siteInfo));
      //       setEditMode(false);
      //       if (clickedTab === "one") {
      //         setActiveTab(
      //           <GeneralInfoTab
      //             siteInfo={siteInfo}
      //             setSiteInfo={setSiteInfo}
      //             setActiveTab={setActiveTab}
      //           />
      //         );
      //       } else if (clickedTab === "two") {
      //         setActiveTab(
      //           <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
      //         );
      //       } else if (clickedTab === "three") {
      //         setActiveTab(
      //           <SchedulesTab
      //             siteSchedules={siteSchedules}
      //             siteInfo={siteInfo}
      //           />
      //         );
      //       }
      //     });
      //   }
      // );
      // !getScheduleFail &&
      // await AxiosUI.get(
      //   `customer/${getNumbersAfterAboutSite(location.href)}/about/`
      // ).then(
      //   (response: AxiosResponse) => {
      //     setSiteInfo(response.data);
      //     checkArmedStatus(
      //       response.data,
      //       setArmButtonDisabled,
      //       navigate,
      //       setSiteInfo,
      //       setArmedStatus
      //     );
      //     setBackdropLoadingScreenOn(false);
      //   },
      //   (reason: AxiosError) => {
      //     universalAPIErrorHandler(reason, navigate, retryFunction, () => {
      //       if (clickedTab === "one") {
      //         setActiveTab(
      //           <GeneralInfoTab
      //             siteInfo={siteInfo}
      //             setSiteInfo={setSiteInfo}
      //             setActiveTab={setActiveTab}
      //           />
      //         );
      //       } else if (clickedTab === "two") {
      //         setActiveTab(
      //           <VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />
      //         );
      //       } else if (clickedTab === "three") {
      //         setActiveTab(
      //           <SchedulesTab
      //             siteSchedules={siteSchedules}
      //             siteInfo={siteInfo}
      //           />
      //         );
      //       }
      //     });
      //   }
      // );
    }
  }
  if (recordingServerChangesMade) {
    handlePatchRecordingServerData(
      recordingServerData,
      recordingServerPatchObject,
      setRecordingServerData,
      setRecordingServerPatchObject,
      setRecordingServerChangesMade,
      setBackdropLoadingScreenOn,
      setEditMode,
      setActiveTab,
      setAlertState,
      navigate
    );
  }
  if (cameraHealthChangesMade) {
    handlePatchCameraHealthData(
      healthCheckData,
      setHealthCheckData,
      cameraHealthPatchObject,
      setCameraHealthPatchObject,
      setCameraHealthChangesMade,
      setActiveTab,
      setAlertState,
      setBackdropLoadingScreenOn,
      setEditMode,
      siteInfo,
      navigate
    );
  }
  if (webhooksChangesMade) {
    handlePatchWebhooksData(
      webhooksInfo,
      setWebhooksInfo,
      webhooksPatchObject,
      setWebhooksPatchObject,
      setWebhooksChangesMade,
      setActiveTab,
      setAlertState,
      setBackdropLoadingScreenOn,
      setEditMode,
      navigate
    );
  }
  if (
    !siteInfoChangesMade &&
    !scheduleChangesMade &&
    !recordingServerChangesMade &&
    !cameraHealthChangesMade &&
    !webhooksChangesMade
  ) {
    setBackdropLoadingScreenOn(false);
    if (clickedTab === "one") {
      setActiveTab(
        <GeneralInfoTab
          siteInfo={siteInfo}
          setSiteInfo={setSiteInfo}
          setActiveTab={setActiveTab}
        />
      );
    } else if (clickedTab === "two") {
      setActiveTab(<VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />);
    } else if (clickedTab === "three") {
      setEditMode(false);
      setActiveTab(
        <SchedulesTab siteSchedules={siteSchedules} siteInfo={siteInfo} />
      );
    } else if (clickedTab === "four") {
      setActiveTab(<SMTPTab />);
    } else if (clickedTab === "five") {
      setActiveTab(
        <RecordingServerTab recordingServerData={recordingServerData} />
      );
    } else if (clickedTab === "six") {
      setActiveTab(
        <CameraHealthTab
          healthCheckData={healthCheckData}
          siteInfo={siteInfo}
        />
      );
    } else if (clickedTab === "seven") {
      setActiveTab(<WebhooksTab webhooksInfo={webhooksInfo} />);
    }
  }
};
