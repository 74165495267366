import React from "react";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import { ArrowDownward } from "@mui/icons-material";

export const DownloadButton = ({ downloadURL }: { downloadURL: string }) => {
  return (
    <OrangeTextButton
      onClick=""
      text={
        <>
          <ArrowDownward />
          &nbsp;
          <a href={downloadURL} download>
            Download
          </a>
        </>
      }
      disabled={!downloadURL}
    />
  );
};
