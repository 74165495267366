import React from "react";
import { GeneralInfoTab } from "../GeneralInfoTab";
import { GeneralInfoTabEditMode } from "../GeneralInfoTabEditMode";
import { VmsTab } from "../VmsTab";
import { VmsTabEditMode } from "../VmsTabEditMode";
import { SchedulesTab } from "../SchedulesTab";
import { SchedulesTabEditMode } from "../SchedulesTabEditMode";
import { SMTPTab } from "../SMTPTab";
import { RecordingServerTab } from "../RecordingServerTab";
import { RecordingServerTabEditMode } from "../RecordingServerTabEditMode";
import { CameraHealthTab } from "../CameraHealthTab";
import { CameraHealthTabEditMode } from "../CameraHealthTabEditMode";
import { Dispatcher } from "./siteAboutInterfaces";
import { WebhooksTab } from "../WebhooksTab";
import WebhooksTabEditMode from "../WebhooksTabEditMode";

export const handleClickedTabChange = (
  event: any,
  newValue: any,
  setClickedTab: any,
  editMode: any,
  setActiveTab: any,
  setPatchObject: any,
  setSiteInfo: any,
  vmsVariables: any,
  setVmsVariables: any,
  siteSchedules: any,
  siteInfo: any,
  setSiteSchedules: any,
  setSchedulesTabActive: any,
  setBackdropLoadingScreenOn: any,
  setAlertState: any,
  setSyncNeeded: any,
  setSiteInfoChangesMade: any,
  setScheduleChangesMade: any,
  setDeleteScheduleDialogOpen: any,
  recordingServerData: any,
  recordingServerPatchObject: any,
  setRecordingServerPatchObject: any,
  setRecordingServerChangesMade: any,
  healthCheckData: any,
  setCameraHealthPatchObject: any,
  setCameraHealthChangesMade: Dispatcher<boolean>,
  setAddCameraHealthDialogOpen: Dispatcher<boolean>,
  setTurnOffHealthDialogOpen: Dispatcher<boolean>,
  webhooksInfo: any,
  setWebhooksPatchObject: any,
  setWebhooksChangesMade: any,
  setEditMode: any
) => {
  setClickedTab(newValue);
  if (newValue === "one" && !editMode) {
    setActiveTab(
      <GeneralInfoTab
        siteInfo={siteInfo}
        setSiteInfo={setSiteInfo}
        setActiveTab={setActiveTab}
      />
    );
    setSchedulesTabActive(false);
  } else if (newValue === "one" && editMode) {
    setActiveTab(
      <GeneralInfoTabEditMode
        setPatchObject={setPatchObject}
        setSiteInfo={setSiteInfo}
        siteInfo={siteInfo}
        setSiteInfoChangesMade={setSiteInfoChangesMade}
      />
    );
    setSchedulesTabActive(false);
  } else if (newValue === "two" && !editMode) {
    setActiveTab(<VmsTab vmsVariables={vmsVariables} siteInfo={siteInfo} />);
    setSchedulesTabActive(false);
  } else if (newValue === "two" && editMode) {
    setActiveTab(
      <VmsTabEditMode
        vmsVariables={vmsVariables}
        setVmsVariables={setVmsVariables}
        setPatchObject={setPatchObject}
        setSiteInfo={setSiteInfo}
        siteInfo={siteInfo}
        setSiteInfoChangesMade={setSiteInfoChangesMade}
      />
    );
    setSchedulesTabActive(false);
  } else if (newValue === "three" && !editMode) {
    setActiveTab(
      <SchedulesTab siteSchedules={siteSchedules} siteInfo={siteInfo} />
    );
    setSchedulesTabActive(true);
  } else if (newValue === "three" && editMode) {
    setActiveTab(
      <SchedulesTabEditMode
        siteSchedules={siteSchedules}
        siteInfo={siteInfo}
        setScheduleChangesMade={setScheduleChangesMade}
        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
        setActiveTab={setActiveTab}
        setEditMode={setEditMode}
        setSiteSchedules={setSiteSchedules}
        setSiteInfo={setSiteInfo}
      />
    );
    setSchedulesTabActive(true);
  } else if (newValue === "four") {
    setActiveTab(<SMTPTab />);
    setSchedulesTabActive(false);
  } else if (newValue === "five" && !editMode) {
    setActiveTab(
      <RecordingServerTab recordingServerData={recordingServerData} />
    );
    setSchedulesTabActive(false);
  } else if (newValue === "five" && editMode) {
    setActiveTab(
      <RecordingServerTabEditMode
        recordingServerData={recordingServerData}
        recordingServerPatchObject={recordingServerPatchObject}
        setRecordingServerPatchObject={setRecordingServerPatchObject}
        setRecordingServerChangesMade={setRecordingServerChangesMade}
      />
    );
    setSchedulesTabActive(false);
  } else if (newValue === "six" && !editMode) {
    setActiveTab(
      <CameraHealthTab healthCheckData={healthCheckData} siteInfo={siteInfo} />
    );
    setSchedulesTabActive(false);
  } else if (newValue === "six" && editMode) {
    setActiveTab(
      <CameraHealthTabEditMode
        healthCheckData={healthCheckData}
        setCameraHealthPatchObject={setCameraHealthPatchObject}
        setCameraHealthChangesMade={setCameraHealthChangesMade}
        siteInfo={siteInfo}
        setAddCameraHealthDialogOpen={setAddCameraHealthDialogOpen}
        setTurnOffHealthDialogOpen={setTurnOffHealthDialogOpen}
      />
    );
    setSchedulesTabActive(false);
  } else if (newValue === "seven" && !editMode) {
    setActiveTab(<WebhooksTab webhooksInfo={webhooksInfo} />);
    setSchedulesTabActive(false);
  } else if (newValue === "seven" && editMode) {
    setActiveTab(
      <WebhooksTabEditMode
        webhooksInfo={webhooksInfo}
        setWebhooksPatchObject={setWebhooksPatchObject}
        setWebhooksChangesMade={setWebhooksChangesMade}
      />
    );
    setSchedulesTabActive(false);
  }
};
