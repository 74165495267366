import React from "react";
import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { Dispatcher } from "./siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { SchedulesTabEditMode } from "../SchedulesTabEditMode";
import { ScheduleGrid } from "../ScheduleGrid";
import { handleCreateInitialTimeSchedules } from "./handleCreateInitialTimeSchedules";
import { handleConvertSchedulesToBooleanArrays } from "./handleConvertSchedulesToBooleanArrays";
import { handleCreateDuskToDawn } from "./handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../../SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";
import { temporaryUpdateScheduleData } from "./temporaryUpdateScheduleData";

export const handleFlexSchedulingToggle = (
  enabled: boolean,
  siteId: number,
  setSiteSchedules: React.Dispatch<React.SetStateAction<any[]>>,
  setSchedulesBackdropLoadingScreenOn: Dispatcher<boolean>,
  setEditModeTabSiteSchedules: Dispatcher<any>,
  setVisibleSchedules: Dispatcher<any>,
  setFlexSchedulesOn: Dispatcher<boolean>,
  setSiteInfo: Dispatcher<any>,
  setSchedulesIndex: Dispatcher<number>,
  setClickedSchedulesTab: Dispatcher<string>,
  setActiveTab: Dispatcher<JSX.Element>,
  siteInfo: any,
  setScheduleChangesMade: Dispatcher<boolean>,
  setDeleteScheduleDialogOpen: Dispatcher<boolean>,
  setBackdropLoadingScreenOn: Dispatcher<boolean>,
  setEditMode: Dispatcher<boolean>,
  setScheduleGrid: Dispatcher<any>,
  setSelectedDayInfo: Dispatcher<any>,
  setEditTimeScheduleDialogOpen: Dispatcher<boolean>,
  setTimeRanges: Dispatcher<any>,
  setGrid: Dispatcher<any>,
  setDuskToDawn: Dispatcher<any>,
  setDuskToDawnLocation: Dispatcher<any>,
  setMatchingSchedules: Dispatcher<any>,
  navigate: NavigateFunction
) => {
  localStorage.setItem("flexSchedulingChanged", "true");

  const AxiosUI = createApi("");

  const retryFunction = () => {
    handleFlexSchedulingToggle(
      enabled,
      siteId,
      setSiteSchedules,
      setSchedulesBackdropLoadingScreenOn,
      setEditModeTabSiteSchedules,
      setVisibleSchedules,
      setFlexSchedulesOn,
      setSiteInfo,
      setSchedulesIndex,
      setClickedSchedulesTab,
      setActiveTab,
      siteInfo,
      setScheduleChangesMade,
      setDeleteScheduleDialogOpen,
      setBackdropLoadingScreenOn,
      setEditMode,
      setScheduleGrid,
      setSelectedDayInfo,
      setEditTimeScheduleDialogOpen,
      setTimeRanges,
      setGrid,
      setDuskToDawn,
      setDuskToDawnLocation,
      setMatchingSchedules,
      navigate
    );
  };

  if (enabled) {
    AxiosUI.post(`/flex_schedule/enable/${siteId}/`).then(
      (response: AxiosResponse) => {
        setSiteSchedules(response.data);
        setEditModeTabSiteSchedules(response.data);
        localStorage.setItem(
          "backUpFlexSchedule",
          JSON.stringify(response.data)
        );
        if (response.data.length === 1) {
          setVisibleSchedules({
            one: true,
            two: false,
            three: false,
          });
          localStorage.setItem(
            "visibleSchedules",
            JSON.stringify({
              one: true,
              two: false,
              three: false,
            })
          );
        }
        if (response.data.length === 2) {
          setVisibleSchedules({
            one: true,
            two: true,
            three: false,
          });
          localStorage.setItem(
            "visibleSchedules",
            JSON.stringify({
              one: true,
              two: true,
              three: false,
            })
          );
        }
        if (response.data.length === 3) {
          setVisibleSchedules({
            one: true,
            two: true,
            three: true,
          });
          localStorage.setItem(
            "visibleSchedules",
            JSON.stringify({
              one: true,
              two: true,
              three: true,
            })
          );
        }
        setFlexSchedulesOn(true);
        localStorage.setItem("flexSchedulesOn", "true");
        setSiteInfo((prev: any) => {
          return {
            ...prev,
            has_flex_schedule: true,
          };
        });
        setSchedulesIndex(0);
        setClickedSchedulesTab("one");
        localStorage.setItem("clickedSchedulesTab", "one");
        localStorage.setItem("schedulesIndex", "0");
        setActiveTab(
          <SchedulesTabEditMode
            siteSchedules={response.data}
            siteInfo={siteInfo}
            setScheduleChangesMade={setScheduleChangesMade}
            setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
            setActiveTab={setActiveTab}
            setEditMode={setEditMode}
            setSiteSchedules={setSiteSchedules}
            setSiteInfo={setSiteInfo}
          />
        );
        setScheduleGrid(
          <ScheduleGrid
            editable={true}
            setSelectedDayInfo={setSelectedDayInfo}
            setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
            timeRanges={handleCreateInitialTimeSchedules(
              response.data[0].schedule
            )}
            setTimeRanges={setTimeRanges}
            grid={handleConvertSchedulesToBooleanArrays(
              response.data[0].schedule
            )}
            setGrid={setGrid}
            duskToDawn={handleCreateDuskToDawn(response.data[0].schedule)}
            setDuskToDawn={setDuskToDawn}
            duskToDawnLocation={handleCreateDuskToDawnLocationPlusBuffer(
              response.data[0].schedule
            )}
            setDuskToDawnLocation={setDuskToDawnLocation}
            setMatchingSchedules={setMatchingSchedules}
            setScheduleChangesMade={setScheduleChangesMade}
            setSiteSchedules={setSiteSchedules}
            setScheduleGrid={setScheduleGrid}
            setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
          />
        );
        setSchedulesBackdropLoadingScreenOn(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Problem enabling flex scheduling.");
          setFlexSchedulesOn(false);
          localStorage.setItem("flexSchedulesOn", "false");
          setSiteInfo((prev: any) => {
            return {
              ...prev,
              has_flex_schedule: false,
            };
          });
          setSchedulesBackdropLoadingScreenOn(false);
        });
      }
    );
  } else {
    AxiosUI.post(`/flex_schedule/disable/${siteId}/`).then(
      () => {
        setFlexSchedulesOn(false);
        localStorage.setItem("flexSchedulesOn", "false");
        localStorage.setItem(
          "visibleSchedules",
          JSON.stringify({
            one: true,
            two: false,
            three: false,
          })
        );
        AxiosUI.get(`schedule/by_customer/${siteId}/`).then(
          (response: AxiosResponse) => {
            localStorage.setItem("flexSchedulesOn", "false");
            const temporaryUpdatedScheduleData = temporaryUpdateScheduleData(
              response.data
            );
            localStorage.setItem(
              "visibleSchedules",
              JSON.stringify({
                one: true,
                two: false,
                three: false,
              })
            );
            localStorage.setItem(
              "siteSchedule",
              JSON.stringify(temporaryUpdatedScheduleData)
            );
            setSiteSchedules(temporaryUpdatedScheduleData);
            localStorage.setItem(
              "backUpStandardSchedule",
              JSON.stringify(temporaryUpdatedScheduleData)
            );
            setSiteInfo((prev: any) => {
              return {
                ...prev,
                has_flex_schedule: false,
              };
            });
            setSchedulesIndex(0);
            setClickedSchedulesTab("one");
            localStorage.setItem("clickedSchedulesTab", "one");
            localStorage.setItem("schedulesIndex", "0");
            setEditModeTabSiteSchedules(temporaryUpdatedScheduleData);
            setActiveTab(
              <SchedulesTabEditMode
                siteSchedules={temporaryUpdatedScheduleData}
                siteInfo={siteInfo}
                setScheduleChangesMade={setScheduleChangesMade}
                setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                setActiveTab={setActiveTab}
                setEditMode={setEditMode}
                setSiteSchedules={setSiteSchedules}
                setSiteInfo={setSiteInfo}
              />
            );
            setScheduleGrid(
              <ScheduleGrid
                editable={true}
                setSelectedDayInfo={setSelectedDayInfo}
                setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
                timeRanges={handleCreateInitialTimeSchedules(
                  temporaryUpdatedScheduleData[0].schedule
                )}
                setTimeRanges={setTimeRanges}
                grid={handleConvertSchedulesToBooleanArrays(
                  temporaryUpdatedScheduleData[0].schedule
                )}
                setGrid={setGrid}
                duskToDawn={handleCreateDuskToDawn(
                  temporaryUpdatedScheduleData[0].schedule
                )}
                setDuskToDawn={setDuskToDawn}
                duskToDawnLocation={handleCreateDuskToDawnLocationPlusBuffer(
                  temporaryUpdatedScheduleData[0].schedule
                )}
                setDuskToDawnLocation={setDuskToDawnLocation}
                setMatchingSchedules={setMatchingSchedules}
                setScheduleChangesMade={setScheduleChangesMade}
                setSiteSchedules={setSiteSchedules}
                setScheduleGrid={setScheduleGrid}
                setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
              />
            );
            setSchedulesBackdropLoadingScreenOn(false);
          },
          (reason: AxiosError) => {
            universalAPIErrorHandler(reason, navigate, retryFunction, () => {
              const emptyScheduleData = [
                {
                  display_name: "Schedule 1",
                  id: 1,
                  product: [],
                  schedule: [],
                },
              ];

              localStorage.setItem(
                "backUpStandardSchedule",
                JSON.stringify(emptyScheduleData)
              );
              setSiteInfo((prev: any) => {
                return {
                  ...prev,
                  has_flex_schedule: false,
                };
              });
              setActiveTab(
                <SchedulesTabEditMode
                  siteSchedules={emptyScheduleData}
                  siteInfo={siteInfo}
                  setScheduleChangesMade={setScheduleChangesMade}
                  setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
                  setActiveTab={setActiveTab}
                  setEditMode={setEditMode}
                  setSiteSchedules={setSiteSchedules}
                  setSiteInfo={setSiteInfo}
                />
              );
              setScheduleGrid(
                <ScheduleGrid
                  editable={true}
                  setSelectedDayInfo={setSelectedDayInfo}
                  setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
                  timeRanges={handleCreateInitialTimeSchedules(
                    emptyScheduleData[0].schedule
                  )}
                  setTimeRanges={setTimeRanges}
                  grid={handleConvertSchedulesToBooleanArrays(
                    emptyScheduleData[0].schedule
                  )}
                  setGrid={setGrid}
                  duskToDawn={handleCreateDuskToDawn(
                    emptyScheduleData[0].schedule
                  )}
                  setDuskToDawn={setDuskToDawn}
                  duskToDawnLocation={handleCreateDuskToDawnLocationPlusBuffer(
                    emptyScheduleData[0].schedule
                  )}
                  setDuskToDawnLocation={setDuskToDawnLocation}
                  setMatchingSchedules={setMatchingSchedules}
                  setScheduleChangesMade={setScheduleChangesMade}
                  setSiteSchedules={setSiteSchedules}
                  setScheduleGrid={setScheduleGrid}
                  setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
                />
              );
              setSchedulesBackdropLoadingScreenOn(false);
              setSiteSchedules([
                {
                  display_name: "Schedule 1",
                  id: 1,
                  product: [],
                  schedule: [],
                },
              ]);
              setSchedulesIndex(0);
              setClickedSchedulesTab("one");
              localStorage.setItem("clickedSchedulesTab", "one");
              localStorage.setItem("schedulesIndex", "0");
              localStorage.setItem(
                "visibleSchedules",
                JSON.stringify({
                  one: true,
                  two: false,
                  three: false,
                })
              );
              setEditModeTabSiteSchedules([
                {
                  display_name: "Schedule 1",
                  id: 1,
                  product: [],
                  schedule: [],
                },
              ]);
            });
          }
        );
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Problem disabling flex scheduling.");
          setFlexSchedulesOn(true);
          localStorage.setItem("flexSchedulesOn", "true");
          setSiteInfo((prev: any) => {
            return {
              ...prev,
              has_flex_schedule: true,
            };
          });
          setSchedulesBackdropLoadingScreenOn(false);
        });
      }
    );
  }
};
