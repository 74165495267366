/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { NoCameras } from "./NoCamera";
import { CamerasTable } from "./CamerasTable";
import { DeleteCameraCheckDialog } from "./DeleteCameraCheckDialog";
import Checkbox from "@mui/material/Checkbox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import { checkIfAllCamerasDecativated } from "./SiteAboutPageUtils/checkIfAllCamerasDecativated";
import {
  AlertState,
  SiteCameras,
  SiteCamerasResult,
} from "./SiteAboutPageUtils/siteAboutInterfaces";
import { OrangeTextButton } from "../../../common/OrangeTextButton";

type Dispatcher<S> = React.Dispatch<React.SetStateAction<S>>;

export interface EnhancedTableProps {
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  allExpanded: boolean;
  setAllExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, allExpanded, setAllExpanded } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox color="primary" onChange={onSelectAllClick} />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            fontFamily: "Mulish",
            fontWeight: "Bold",
            fontSize: "15px",
            minWidth: 150,
          }}
        >
          Camera Name &nbsp;
          <Tooltip
            title="Hover over camera name to see sample image from camera feed."
            placement="top"
            sx={{ fontSize: "15px", fontFamily: "mulish" }}
          >
            <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            fontFamily: "Mulish",
            fontWeight: "Bold",
            fontSize: "15px",
            minWidth: 150,
          }}
        >
          Active &nbsp;
          <Tooltip
            title="A green checkmark indicates a camera is running. A red X indicates it is not."
            placement="top"
            sx={{ fontSize: "15px", fontFamily: "mulish" }}
          >
            <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            fontFamily: "Mulish",
            fontWeight: "Bold",
            fontSize: "15px",
            minWidth: 150,
          }}
        >
          Synced &nbsp;
          <Tooltip
            title="An indication of whether the camera has been edited since its last deployment. A green checkmark will show if the most current version of the camera is running and a red x will show if the changes to the camera have not been synced - in which case it should be re-synced"
            placement="top"
            sx={{ fontSize: "15px", fontFamily: "mulish" }}
          >
            <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            fontFamily: "Mulish",
            fontWeight: "Bold",
            fontSize: "15px",
            minWidth: 150,
          }}
        >
          Use&nbsp;Motion
        </TableCell>
        <TableCell
          align="left"
          sx={{
            fontFamily: "Mulish",
            fontWeight: "Bold",
            fontSize: "15px",
            minWidth: 150,
          }}
        >
          Ignore&nbsp;Zones &nbsp;
          <Tooltip
            title="A green checkmark indicates the site has active ignore zones. A red X indicates no ignore zones have been created."
            placement="top"
            sx={{ fontSize: "15px", fontFamily: "mulish" }}
          >
            <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            fontFamily: "Mulish",
            fontWeight: "Bold",
            fontSize: "15px",
            minWidth: 150,
          }}
        >
          Health &nbsp;
          <Tooltip
            title="A green checkmark indicates a camera is running without issue. A red X indicates there is an issue(s) with the camera. Hovering over the X will display the issue(s)."
            placement="top"
            sx={{ fontSize: "15px", fontFamily: "mulish" }}
          >
            <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
          </Tooltip>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            fontFamily: "Mulish",
            fontWeight: "Bold",
            fontSize: "15px",
            minWidth: 150,
          }}
        >
          {allExpanded ? (
            <OrangeTextButton
              onClick={() => {
                setAllExpanded(false);
              }}
              text="+ Expand All"
              disabled={false}
            />
          ) : (
            <OrangeTextButton
              onClick={() => {
                setAllExpanded(true);
              }}
              text="- Collapse All"
              disabled={false}
            />
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export const SiteCamerasTable = ({
  setAlertState,
  setSyncNeeded,
  setDeativateInquiryDialogOpen,
  siteCameras,
  setSiteCameras,
  setBackdropLoadingScreenOn,
  setNoAutoAddCamerasDialogOpen,
  siteInfo,
  setSiteInfo,
  setSiteSchedules,
  setActiveTab,
  setDataLoaded,
  setAllSiteSchedules,
  setVmsVariables,
  setArmedStatus,
  setAiLinkSite,
  setSiteDeployed,
  setCamerasNotDeployed,
  setToolTipTitle,
  setConfigureMotion,
  setPatchObject,
  setArmButtonDisabled,
  setRecordingServerData,
  setRecordingServerPatchObject,
  setRecordingServerVisible,
  setHealthCheckData,
  setCamerasRefreshing,
  permissions,
  setWebhooksInfo,
  setWebhooksPatchObject,
  siteSchedules,
}: {
  setAlertState: Dispatcher<AlertState>;
  setSyncNeeded: Dispatcher<boolean>;
  setDeativateInquiryDialogOpen: Dispatcher<boolean>;
  siteCameras: SiteCameras;
  setSiteCameras: Dispatcher<SiteCameras>;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setNoAutoAddCamerasDialogOpen: Dispatcher<boolean>;
  siteInfo: any;
  setSiteInfo: any;
  setSiteSchedules: any;
  setActiveTab: any;
  setDataLoaded: any;
  setAllSiteSchedules: any;
  setVmsVariables: any;
  setArmedStatus: any;
  setAiLinkSite: any;
  setSiteDeployed: any;
  setCamerasNotDeployed: any;
  setToolTipTitle: any;
  setConfigureMotion: any;
  setPatchObject: any;
  setArmButtonDisabled: any;
  setRecordingServerData: any;
  setRecordingServerPatchObject: any;
  setRecordingServerVisible: Dispatcher<boolean>;
  setHealthCheckData: any;
  setCamerasRefreshing: Dispatcher<boolean>;
  permissions: any;
  setWebhooksInfo: any;
  setWebhooksPatchObject: any;
  siteSchedules: any;
}) => {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = siteCameras.results.map(
        (obj: SiteCamerasResult) => obj.id
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    const siteCamerasTablePagePreferences = JSON.parse(
      localStorage.siteCamerasTablePagePreferences
    );
    for (const key in siteCamerasTablePagePreferences) {
      if (Number(key) === Number(siteInfo.id)) {
        setRowsPerPage(siteCamerasTablePagePreferences[key].rowsPerPage);
        setPage(siteCamerasTablePagePreferences[key].page);
      }
    }
  }, []);

  React.useEffect(() => {
    if (JSON.parse(localStorage.siteCameras)) {
      setSiteCameras(JSON.parse(localStorage.siteCameras));
      // checkIfAllCamerasDecativated(
      //   JSON.parse(localStorage.siteCameras),
      //   setDeativateInquiryDialogOpen
      // );
    } else {
      if (Boolean(siteInfo.active) === true) {
        setAlertState((previousState: AlertState) => {
          return { ...previousState, noCameraOrSchedule: true };
        });
      }
    }
  }, []);

  const handleSingleCheckboxClick = (
    event: React.MouseEvent<unknown>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  return (
    <>
      <div>
        {deleteDialogOpen && (
          <DeleteCameraCheckDialog
            selected={selected}
            deleteDialogOpen={deleteDialogOpen}
            setDeleteDialogOpen={setDeleteDialogOpen}
            setSiteCameras={setSiteCameras}
            setAlertState={setAlertState}
            setSelected={setSelected}
            setSyncNeeded={setSyncNeeded}
            setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          />
        )}
      </div>
      {siteCameras.results[0] && (
        <CamerasTable
          navigate={navigate}
          EnhancedTableHead={EnhancedTableHead}
          siteCameras={siteCameras}
          page={page}
          rowsPerPage={rowsPerPage}
          isSelected={isSelected}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          handleSelectAllClick={handleSelectAllClick}
          handleSingleCheckboxClick={handleSingleCheckboxClick}
          selected={selected}
          setSiteCameras={setSiteCameras}
          setDeleteDialogOpen={setDeleteDialogOpen}
          setAlertState={setAlertState}
          setSelected={setSelected}
          setSyncNeeded={setSyncNeeded}
          setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
          setNoAutoAddCamerasDialogOpen={setNoAutoAddCamerasDialogOpen}
          siteInfo={siteInfo}
          setSiteInfo={setSiteInfo}
          setSiteSchedules={setSiteSchedules}
          setActiveTab={setActiveTab}
          setDataLoaded={setDataLoaded}
          setAllSiteSchedules={setAllSiteSchedules}
          setVmsVariables={setVmsVariables}
          setArmedStatus={setArmedStatus}
          setAiLinkSite={setAiLinkSite}
          setSiteDeployed={setSiteDeployed}
          setCamerasNotDeployed={setCamerasNotDeployed}
          setToolTipTitle={setToolTipTitle}
          setConfigureMotion={setConfigureMotion}
          setPatchObject={setPatchObject}
          setArmButtonDisabled={setArmButtonDisabled}
          setRecordingServerData={setRecordingServerData}
          setRecordingServerPatchObject={setRecordingServerPatchObject}
          setRecordingServerVisible={setRecordingServerVisible}
          setHealthCheckData={setHealthCheckData}
          setCamerasRefreshing={setCamerasRefreshing}
          permissions={permissions}
          setWebhooksInfo={setWebhooksInfo}
          setWebhooksPatchObject={setWebhooksPatchObject}
          siteSchedules={siteSchedules}
        />
      )}
      {!siteCameras.results[0] && (
        <NoCameras
          navigate={navigate}
          setNoAutoAddCamerasDialogOpen={setNoAutoAddCamerasDialogOpen}
        />
      )}
    </>
  );
};
