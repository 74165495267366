import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";

export const handleActivateCamera = async (
  submittedObject: any,
  navigate: any,
  setSiteCameras: any,
  setAlertState: any,
  setSelected: any,
  setSyncNeeded: any,
  setBackdropLoadingScreenOn: any
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    handleActivateCamera(
      submittedObject,
      navigate,
      setSiteCameras,
      setAlertState,
      setSelected,
      setSyncNeeded,
      setBackdropLoadingScreenOn
    );
  };
  let cameraActivateFail = false;

  await AxiosUI.post(`camera/action/activate/`, submittedObject).then(
    () => {
      cameraActivateFail = false;
    },
    (reason: AxiosError) => {
      cameraActivateFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Camera activation failed, please try again.");
        setBackdropLoadingScreenOn(false);
      });
    }
  );
  !cameraActivateFail &&
    (await AxiosUI.get(
      `camera/site/?customer__id=${getNumbersAfterAboutSite(location.href)}`
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
        setSiteCameras(response.data);
        setAlertState((previousState: any) => {
          return {
            ...previousState,
            changeSuccess: true,
            activateSuccess: false,
            deactivateSuccess: false,
            disarmSuccess: false,
            armSuccess: false,
            syncSuccess: false,
            cameraDeleteSuccess: false,
          };
        });
        setSelected([]);
        setSyncNeeded(true);
        setBackdropLoadingScreenOn(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setBackdropLoadingScreenOn(false);
        });
      }
    ));
};
