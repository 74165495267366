import React from "react";
import { SortingMenu } from "./SortingMenu";
import { sortHighToLow } from "./HealthMonitoringUtils/sortHighToLow";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  Cell,
} from "recharts";
import { CircularProgress } from "@mui/material";
import { handleSingleCameraGraphClick } from "./HealthMonitoringUtils/handleSingleCameraGraphClick";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const CameraStatsGraph = ({
  cameraStatsData,
  loadingCameraStatsData,
  alertsData,
  setActiveTable,
  setAlertStatusDialogOpen,
  setSelectedRow,
  setSelectedRowIndex,
  setFectchingAllData,
  setAlertsData,
  setUnhealthyCamsData,
  unhealthyCamsData,
  clickedTab,
  setSortedData,
  activeName,
  setActiveName,
  singleCellActivated,
  setSingleCellActivated,
  setFilteredDataLengths,
  setUpdatingStatus,
  showSiteStats,
  filters,
  alertFilter,
  setSceneChangeOutcomesDialogOpen,
  setBackgroundFrame,
  setDetectedFrame,
  setSelectedRows,
  selectedRows,
  setErrorsOverviewData,
  setCameraStatsData,
  setLoadingCameraStatsData,
  setBandwidthData,
  setLoadingBandwidthData,
  setShowSiteStats,
  cameraDropDownValue,
  navBarCollapsed,
  userFilters,
  sceneChangeValueString,
  setBulkSceneChangeOutcomesDialogOpen,
  navigate,
}: {
  cameraStatsData: any;
  loadingCameraStatsData: boolean;
  alertsData: any;
  setActiveTable: any;
  setAlertStatusDialogOpen: any;
  setSelectedRow: any;
  setSelectedRowIndex: any;
  setFectchingAllData: any;
  setAlertsData: any;
  setUnhealthyCamsData: any;
  unhealthyCamsData: any;
  clickedTab: any;
  setSortedData: any;
  activeName: string;
  setActiveName: Dispatcher<string>;
  singleCellActivated: boolean;
  setSingleCellActivated: Dispatcher<boolean>;
  setFilteredDataLengths: any;
  setUpdatingStatus: any;
  showSiteStats: boolean;
  filters: any;
  alertFilter: any;
  setSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  setBackgroundFrame: Dispatcher<string>;
  setDetectedFrame: Dispatcher<string>;
  setSelectedRows: any;
  selectedRows: any;
  setErrorsOverviewData: any;
  setCameraStatsData: any;
  setLoadingCameraStatsData: any;
  setBandwidthData: any;
  setLoadingBandwidthData: any;
  setShowSiteStats: any;
  cameraDropDownValue: any;
  navBarCollapsed: boolean;
  userFilters: any;
  sceneChangeValueString: string;
  setBulkSceneChangeOutcomesDialogOpen: Dispatcher<boolean>;
  navigate: any;
}) => {
  const [currentBandwidthSort, setCurrentBandwidthSort] =
    React.useState(`Volume (High to Low)`);

  const [sortedCameraStatsData, setSortedCameraStatsData] =
    React.useState(cameraStatsData);

  let calculatedWidth = 1160;

  if (navBarCollapsed) {
    calculatedWidth = 1235;
  }

  if (cameraStatsData.length > 8) {
    calculatedWidth = cameraStatsData.length * 145;
  }

  React.useEffect(() => {
    setSortedCameraStatsData(sortHighToLow(sortedCameraStatsData, "total"));
  }, []);

  return (
    <div
      className={
        !navBarCollapsed
          ? "absolute top-[50px] left-[20px] right-[1px] h-[475px] w-[1250px] bg-[#BDBDBD]"
          : "absolute top-[50px] left-[20px] right-[1px] h-[475px] w-[1325px] bg-[#BDBDBD]"
      }
    >
      <div
        className={
          !navBarCollapsed
            ? "absolute top-[1px] left-[1px] right-[1px] h-[473px] w-[1248px] bg-white"
            : "absolute top-[1px] left-[1px] right-[1px] h-[473px] w-[1323px] bg-white"
        }
      >
        <p className="text-actuate-blue font-mulish text-[22px] font-bold absolute top-[8px] left-[30px]">
          {showSiteStats ? `Site Stats` : `Camera Stats`}
        </p>
        <p className="text-actuate-blue font-mulish text-[14px] absolute top-[36px] left-[31px]">
          {showSiteStats
            ? `Total of each error and warning type per site during selected dates.`
            : `Total of each error and warning type per camera during selected dates.`}
        </p>
        <div
          className={
            !navBarCollapsed
              ? "absolute top-[12px] left-[1040px]"
              : "absolute top-[12px] left-[1115px]"
          }
        >
          <SortingMenu
            currentSort={currentBandwidthSort}
            setCurrentSort={setCurrentBandwidthSort}
            setSortedData={setSortedCameraStatsData}
            volumeSortString={`total`}
            nameSortString={`name`}
            data={cameraStatsData}
          />
        </div>
        {loadingCameraStatsData && (
          <div className="absolute top-[75px] left-[10px] h-[390px] w-[1230px] overflow-scroll scrollbar-hide">
            <CircularProgress
              size={25}
              sx={{ position: "relative", left: 40 }}
            />
            <div className="font-mulish text-actuate-blue test-bold">
              Fetching Data...
            </div>
          </div>
        )}
        {!loadingCameraStatsData &&
          (sortedCameraStatsData.length === 0 ? (
            <p className="text-actuate-blue font-mulish text-[22px]  absolute top-[230px] left-[500px]">
              No Data Available.
            </p>
          ) : (
            <div
              className={
                !navBarCollapsed
                  ? "absolute top-[75px] left-[10px] h-[390px] w-[1230px] overflow-scroll scrollbar-hide"
                  : "absolute top-[75px] left-[10px] h-[390px] w-[1305px] overflow-scroll scrollbar-hide"
              }
              id="stats_bar"
            >
              <BarChart
                id="stats_bar"
                width={calculatedWidth}
                height={380}
                data={sortedCameraStatsData}
                barSize={120}
                margin={{
                  top: 5,
                  right: 0,
                  left: 18,
                  bottom: 35,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" type="category" hide />
                <YAxis
                  allowDecimals={false}
                  label={{
                    value: "Total Volume",
                    angle: -90,
                    position: "insideLeft",
                    offset: -10,
                  }}
                />
                <Legend verticalAlign="top" align="left" height={50} />
                <Tooltip />
                {(userFilters.metrics.includes("Connectivity") ||
                  userFilters.metrics.length === 0) && (
                  <Bar
                    id="stats_bar"
                    cursor="pointer"
                    dataKey="connectivity"
                    fill={"#53B2AD"}
                    stackId="a"
                    name="Connectivity"
                    onClick={(event: any, eventIndex: any) => {
                      sortedCameraStatsData.map((entry: any, index: any) => {
                        if (eventIndex === index) {
                          if (
                            singleCellActivated &&
                            activeName !== entry.name
                          ) {
                            setSingleCellActivated(true);
                          }
                          if (
                            singleCellActivated &&
                            activeName === entry.name
                          ) {
                            setSingleCellActivated(false);
                          }
                          if (!singleCellActivated) {
                            setSingleCellActivated(true);
                          }
                          setActiveName(entry.name);
                          setActiveTable(<></>);
                          setTimeout(() => {
                            handleSingleCameraGraphClick(
                              alertsData,
                              entry.name,
                              !singleCellActivated,
                              setActiveTable,
                              setAlertStatusDialogOpen,
                              setSelectedRow,
                              setSelectedRowIndex,
                              setFectchingAllData,
                              setAlertsData,
                              setUnhealthyCamsData,
                              unhealthyCamsData,
                              clickedTab,
                              setSortedData,
                              setFilteredDataLengths,
                              setUpdatingStatus,
                              activeName,
                              entry.name,
                              showSiteStats,
                              filters,
                              alertFilter,
                              setSceneChangeOutcomesDialogOpen,
                              setBackgroundFrame,
                              setDetectedFrame,
                              setSelectedRows,
                              selectedRows,
                              setErrorsOverviewData,
                              setCameraStatsData,
                              setLoadingCameraStatsData,
                              setBandwidthData,
                              setLoadingBandwidthData,
                              setShowSiteStats,
                              cameraDropDownValue,
                              sceneChangeValueString,
                              setBulkSceneChangeOutcomesDialogOpen,
                              navigate
                            );
                          }, 1);
                        }
                      });
                    }}
                  >
                    {singleCellActivated &&
                      sortedCameraStatsData.map((entry: any, index: any) => (
                        <Cell
                          cursor="pointer"
                          fill={
                            entry.name === activeName ? "#53B2AD" : "#888888"
                          }
                          key={`cell-${index}`}
                        ></Cell>
                      ))}

                    <LabelList dataKey="display_name" position="bottom" />
                  </Bar>
                )}
                {(userFilters.metrics.includes("Scene Change") ||
                  userFilters.metrics.length === 0) && (
                  <Bar
                    id="stats_bar"
                    cursor="pointer"
                    dataKey="scene_change"
                    fill="#E68B37"
                    stackId="a"
                    name="Scene Change"
                    onClick={(event: any, eventIndex: any) => {
                      sortedCameraStatsData.map((entry: any, index: any) => {
                        if (eventIndex === index) {
                          setActiveName(entry.name);
                          setActiveTable(<></>);
                          setTimeout(() => {
                            setSingleCellActivated(!singleCellActivated);
                            handleSingleCameraGraphClick(
                              alertsData,
                              entry.name,
                              !singleCellActivated,
                              setActiveTable,
                              setAlertStatusDialogOpen,
                              setSelectedRow,
                              setSelectedRowIndex,
                              setFectchingAllData,
                              setAlertsData,
                              setUnhealthyCamsData,
                              unhealthyCamsData,
                              clickedTab,
                              setSortedData,
                              setFilteredDataLengths,
                              setUpdatingStatus,
                              activeName,
                              entry.name,
                              showSiteStats,
                              filters,
                              alertFilter,
                              setSceneChangeOutcomesDialogOpen,
                              setBackgroundFrame,
                              setDetectedFrame,
                              setSelectedRows,
                              selectedRows,
                              setErrorsOverviewData,
                              setCameraStatsData,
                              setLoadingCameraStatsData,
                              setBandwidthData,
                              setLoadingBandwidthData,
                              setShowSiteStats,
                              cameraDropDownValue,
                              sceneChangeValueString,
                              setBulkSceneChangeOutcomesDialogOpen,
                              navigate
                            );
                          }, 1);
                        }
                      });
                    }}
                  >
                    {singleCellActivated &&
                      sortedCameraStatsData.map((entry: any, index: any) => (
                        <Cell
                          cursor="pointer"
                          fill={
                            entry.name === activeName ? "#E68B37" : "#888888"
                          }
                          key={`cell-${index}`}
                        />
                      ))}
                    {/* <LabelList dataKey="display_name" position="bottom" /> */}
                  </Bar>
                )}
                {(userFilters.metrics.includes("Recording Status") ||
                  userFilters.metrics.length) === 0 && (
                  <Bar
                    cursor="pointer"
                    dataKey="recording_status"
                    fill="#CD4A81"
                    stackId="a"
                    name="Recording Status"
                    onClick={(event: any, eventIndex: any) => {
                      sortedCameraStatsData.map((entry: any, index: any) => {
                        if (eventIndex === index) {
                          setActiveName(entry.name);
                          setActiveTable(<></>);
                          setTimeout(() => {
                            setSingleCellActivated(!singleCellActivated);
                            handleSingleCameraGraphClick(
                              alertsData,
                              entry.name,
                              !singleCellActivated,
                              setActiveTable,
                              setAlertStatusDialogOpen,
                              setSelectedRow,
                              setSelectedRowIndex,
                              setFectchingAllData,
                              setAlertsData,
                              setUnhealthyCamsData,
                              unhealthyCamsData,
                              clickedTab,
                              setSortedData,
                              setFilteredDataLengths,
                              setUpdatingStatus,
                              activeName,
                              entry.name,
                              showSiteStats,
                              filters,
                              alertFilter,
                              setSceneChangeOutcomesDialogOpen,
                              setBackgroundFrame,
                              setDetectedFrame,
                              setSelectedRows,
                              selectedRows,
                              setErrorsOverviewData,
                              setCameraStatsData,
                              setLoadingCameraStatsData,
                              setBandwidthData,
                              setLoadingBandwidthData,
                              setShowSiteStats,
                              cameraDropDownValue,
                              sceneChangeValueString,
                              setBulkSceneChangeOutcomesDialogOpen,
                              navigate
                            );
                          }, 1);
                        }
                      });
                    }}
                  >
                    {singleCellActivated &&
                      sortedCameraStatsData.map((entry: any, index: any) => (
                        <Cell
                          cursor="pointer"
                          fill={
                            entry.name === activeName ? "#CD4A81" : "#888888"
                          }
                          key={`cell-${index}`}
                        />
                      ))}
                  </Bar>
                )}
                {(userFilters.metrics.includes("Motion Status") ||
                  userFilters.metrics.length === 0) && (
                  <Bar
                    id="stats_bar"
                    cursor="pointer"
                    dataKey="motion_status"
                    fill="#7F83F3"
                    stackId="a"
                    name="Motion Status"
                    onClick={(event: any, eventIndex: any) => {
                      sortedCameraStatsData.map((entry: any, index: any) => {
                        if (eventIndex === index) {
                          setActiveName(entry.name);
                          setActiveTable(<></>);
                          setTimeout(() => {
                            setSingleCellActivated(!singleCellActivated);
                            handleSingleCameraGraphClick(
                              alertsData,
                              entry.name,
                              !singleCellActivated,
                              setActiveTable,
                              setAlertStatusDialogOpen,
                              setSelectedRow,
                              setSelectedRowIndex,
                              setFectchingAllData,
                              setAlertsData,
                              setUnhealthyCamsData,
                              unhealthyCamsData,
                              clickedTab,
                              setSortedData,
                              setFilteredDataLengths,
                              setUpdatingStatus,
                              activeName,
                              entry.name,
                              showSiteStats,
                              filters,
                              alertFilter,
                              setSceneChangeOutcomesDialogOpen,
                              setBackgroundFrame,
                              setDetectedFrame,
                              setSelectedRows,
                              selectedRows,
                              setErrorsOverviewData,
                              setCameraStatsData,
                              setLoadingCameraStatsData,
                              setBandwidthData,
                              setLoadingBandwidthData,
                              setShowSiteStats,
                              cameraDropDownValue,
                              sceneChangeValueString,
                              setBulkSceneChangeOutcomesDialogOpen,
                              navigate
                            );
                          }, 1);
                        }
                      });
                    }}
                  >
                    {singleCellActivated &&
                      sortedCameraStatsData.map((entry: any, index: any) => (
                        <Cell
                          cursor="pointer"
                          fill={
                            entry.name === activeName ? "#7F83F3" : "#888888"
                          }
                          key={`cell-${index}`}
                        />
                      ))}
                    {/* <LabelList dataKey="display_name" position="bottom" /> */}
                  </Bar>
                )}
                {(userFilters.metrics.includes("Image Quality") ||
                  userFilters.metrics.length === 0) && (
                  <Bar
                    id="stats_bar"
                    cursor="pointer"
                    dataKey="image_quality"
                    fill="#8EDE79"
                    stackId="a"
                    name="Image Quality"
                    onClick={(event: any, eventIndex: any) => {
                      sortedCameraStatsData.map((entry: any, index: any) => {
                        if (eventIndex === index) {
                          setActiveName(entry.name);
                          setActiveTable(<></>);
                          setTimeout(() => {
                            setSingleCellActivated(!singleCellActivated);
                            handleSingleCameraGraphClick(
                              alertsData,
                              entry.name,
                              !singleCellActivated,
                              setActiveTable,
                              setAlertStatusDialogOpen,
                              setSelectedRow,
                              setSelectedRowIndex,
                              setFectchingAllData,
                              setAlertsData,
                              setUnhealthyCamsData,
                              unhealthyCamsData,
                              clickedTab,
                              setSortedData,
                              setFilteredDataLengths,
                              setUpdatingStatus,
                              activeName,
                              entry.name,
                              showSiteStats,
                              filters,
                              alertFilter,
                              setSceneChangeOutcomesDialogOpen,
                              setBackgroundFrame,
                              setDetectedFrame,
                              setSelectedRows,
                              selectedRows,
                              setErrorsOverviewData,
                              setCameraStatsData,
                              setLoadingCameraStatsData,
                              setBandwidthData,
                              setLoadingBandwidthData,
                              setShowSiteStats,
                              cameraDropDownValue,
                              sceneChangeValueString,
                              setBulkSceneChangeOutcomesDialogOpen,
                              navigate
                            );
                          }, 1);
                        }
                      });
                    }}
                  >
                    {singleCellActivated &&
                      sortedCameraStatsData.map((entry: any, index: any) => (
                        <Cell
                          cursor="pointer"
                          fill={
                            entry.name === activeName ? "#8EDE79" : "#888888"
                          }
                          key={`cell-${index}`}
                        />
                      ))}
                    {/* <LabelList dataKey="display_name" position="bottom" /> */}
                  </Bar>
                )}
                {(userFilters.metrics.includes("Stream Quality") ||
                  userFilters.metrics.length === 0) && (
                  <Bar
                    id="stats_bar"
                    cursor="pointer"
                    dataKey="stream_quality"
                    fill="#39B1EA"
                    stackId="a"
                    name="Stream Quality"
                    onClick={(event: any, eventIndex: any) => {
                      sortedCameraStatsData.map((entry: any, index: any) => {
                        if (eventIndex === index) {
                          setActiveName(entry.name);
                          setActiveTable(<></>);
                          setTimeout(() => {
                            setSingleCellActivated(!singleCellActivated);
                            handleSingleCameraGraphClick(
                              alertsData,
                              entry.name,
                              !singleCellActivated,
                              setActiveTable,
                              setAlertStatusDialogOpen,
                              setSelectedRow,
                              setSelectedRowIndex,
                              setFectchingAllData,
                              setAlertsData,
                              setUnhealthyCamsData,
                              unhealthyCamsData,
                              clickedTab,
                              setSortedData,
                              setFilteredDataLengths,
                              setUpdatingStatus,
                              activeName,
                              entry.name,
                              showSiteStats,
                              filters,
                              alertFilter,
                              setSceneChangeOutcomesDialogOpen,
                              setBackgroundFrame,
                              setDetectedFrame,
                              setSelectedRows,
                              selectedRows,
                              setErrorsOverviewData,
                              setCameraStatsData,
                              setLoadingCameraStatsData,
                              setBandwidthData,
                              setLoadingBandwidthData,
                              setShowSiteStats,
                              cameraDropDownValue,
                              sceneChangeValueString,
                              setBulkSceneChangeOutcomesDialogOpen,
                              navigate
                            );
                          }, 1);
                        }
                      });
                    }}
                  >
                    {singleCellActivated &&
                      sortedCameraStatsData.map((entry: any, index: any) => (
                        <Cell
                          cursor="pointer"
                          fill={
                            entry.name === activeName ? "#39B1EA" : "#888888"
                          }
                          key={`cell-${index}`}
                        />
                      ))}
                    {/* <LabelList dataKey="display_name" position="bottom" /> */}
                  </Bar>
                )}

                {/* <Bar
                  cursor="pointer"
                  dataKey="license_info"
                  fill="#6A2BCA"
                  stackId="a"
                  name="License Info"
                  onClick={() => {}}
                > */}
                {/* <LabelList dataKey="display_name" position="bottom" /> */}
                {/* </Bar> */}
              </BarChart>
            </div>
          ))}
      </div>
    </div>
  );
};
