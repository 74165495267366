// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { createApi } from "../../../utils/createApi";
import { Dispatcher } from "../../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { NavigateFunction } from "react-router-dom";
import { CameraOptions } from "../../Analytics/AnalyticsUtils/analyticsInterfaces";
import { universalAPIErrorHandler } from "../../../utils/universalAPIErrorHandler";

export const getCamerasBySiteAlerts = (
  idArray: string[],
  navigate: NavigateFunction,
  setCameraOptions: Dispatcher<CameraOptions[]>,
  setLoadingCameras: Dispatcher<boolean>
) => {
  setLoadingCameras(true);
  const AxiosUI = createApi("");
  const retryFunction = () => {
    getCamerasBySiteAlerts(
      idArray,
      navigate,
      setCameraOptions,
      setLoadingCameras
    );
  };

  let idString = "";

  if (idArray.length === 1) {
    idString = `${idArray[0]}`;
  }
  if (idArray.length > 1) {
    idString = `${idArray[0]}`;
    idArray.forEach((id: string, index: number) => {
      if (index > 0) {
        idString += `,${id}`;
      }
    });
  }

  let allSiteCameras: any;

  const getSiteCameras = (pageNumber: number) => {
    AxiosUI.get(`camera/site/?customers=${idString}&page=${pageNumber}`).then(
      (response: AxiosResponse) => {
        if (response.data.count > 0) {
          if (pageNumber === 1) {
            allSiteCameras = response.data.results;
          }
          if (pageNumber > 1) {
            allSiteCameras.push(...response.data.results);
          }
          if (response.data.next) {
            getSiteCameras(pageNumber + 1);
          } else {
            setCameraOptions(allSiteCameras);
            setLoadingCameras(false);
          }
        }
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          alert("Error retrieving sites. Please try again");
          setLoadingCameras(false);
        });
      }
    );
  };

  idArray.length === 0 ? setCameraOptions([]) : getSiteCameras(1);
};
