import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { checkArmedStatus } from "./checkArmedStatus";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";

export const handleArmSiteFlexSchedules = (
  setBackdropLoadingScreenOn: any,
  setSiteInfo: any,
  setAlertState: any,
  setSyncNeeded: any,
  setArmedStatus: any,
  navigate: any,
  setArmButtonDisabled: any,
  selectedSchedulesArrays: {
    ids: number[];
    names: string[];
  },
  setToolTipTitle: any
) => {
  const AxiosUI = createApi(``);
  setBackdropLoadingScreenOn(true);

  const retryFunction = () => {
    handleArmSiteFlexSchedules(
      setBackdropLoadingScreenOn,
      setSiteInfo,
      setAlertState,
      setSyncNeeded,
      setArmedStatus,
      navigate,
      setArmButtonDisabled,
      selectedSchedulesArrays,
      setToolTipTitle
    );
  };

  AxiosUI.post(`flex_schedule/action/arm/`, {
    flex_schedule_ids: selectedSchedulesArrays.ids,
  }).then(
    () => {
      AxiosUI.get(
        `customer/${getNumbersAfterAboutSite(location.href)}/about/`
      ).then(
        (response: AxiosResponse) => {
          checkArmedStatus(
            response.data,
            setArmButtonDisabled,
            navigate,
            setSiteInfo,
            setArmedStatus
          );
          setSiteInfo(response.data);
          if (response.data.settings_deployed !== true) {
            setSyncNeeded(true);
          }
          alert(
            `The following schedules have been successfully armed: ${selectedSchedulesArrays.names.join(
              ", "
            )}`
          );
          setArmedStatus(true);
          setBackdropLoadingScreenOn(false);
        },
        (reason: AxiosError) => {
          universalAPIErrorHandler(reason, navigate, retryFunction, () => {
            {
              setAlertState((previousState: any) => {
                return {
                  ...previousState,
                  changeSuccess: false,
                  activateSuccess: false,
                  deactivateSuccess: false,
                  disarmSuccess: false,
                  armSuccess: false,
                  syncSuccess: false,
                  cameraDeleteSuccess: false,
                  armFail: true,
                };
              });
            }
            setBackdropLoadingScreenOn(false);
            // eslint-disable-next-line no-console
            console.log(reason);
          });
        }
      );
    },
    (reason: AxiosError) => {
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        setBackdropLoadingScreenOn(false);
        setSiteInfo((previousState: any) => {
          return { ...previousState, armed: false };
        });
      });
    }
  );
};
