export const pointsCreator = (array: any[], height: number, width: number) => {
  let returnString = ``;
  array.forEach((array: number[]) => {
    returnString += `${array[0] * width},${array[1] * height} `;
  });
  return returnString;
};

export const pointsCreatorPlus30 = (
  array: any[],
  height: number,
  width: number
) => {
  let returnString = ``;
  array.forEach((array: number[]) => {
    returnString += `${array[0] * width + 30},${array[1] * height + 30} `;
  });
  return returnString;
};
