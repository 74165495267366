import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";

export const OutcomeTypeFilter = ({
  outcomeFilterType,
  setOutcomeFilterType,
  setPage,
}: {
  outcomeFilterType: string;
  setOutcomeFilterType: Dispatcher<string>;
  setPage: Dispatcher<number>;
}) => {
  const outcomesArray = [
    { name: "All", value: "All" },
    { name: "True Threat", value: "true_threat" },
    { name: "Escalated True Threat", value: "escalated_true_threat" },
    { name: "True Non-Threat", value: "true_non_threat" },
    { name: "False", value: "false_positive" },
  ];

  const optionMaps = new Map();

  outcomesArray.forEach((option: any) => {
    optionMaps.set(`${option.value}`, `${option.name}`);
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div>
        <div className="absolute text-[12px] top-[7.5px] w-[80px] left-[250px]">
          OUTCOME
        </div>
        <div className="absolute left-[312px] w-[400px] text-left">
          <Button
            id="filter-button"
            aria-controls={open ? "filter-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{ fontSize: "12px" }}
            onClick={handleClick}
          >
            {optionMaps.get(outcomeFilterType)}
          </Button>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            //   anchorOrigin={{
            //     vertical: 'top',
            //     horizontal: 'left',
            //   }}
            //   transformOrigin={{
            //     vertical: 'top',
            //     horizontal: 'left',
            //   }}
            MenuListProps={{
              "aria-labelledby": "filter-button",
            }}
          >
            {outcomesArray.map((object: any) => {
              return (
                <MenuItem
                  key={object.value}
                  onClick={() => {
                    setOutcomeFilterType(object.value);
                    setPage(0);
                    handleClose();
                  }}
                >
                  {object.name}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    </div>
  );
};
