import React, { useState, useEffect } from "react";
import { PolygonDrawer } from "../pages/EditIgnoreZones/TestPolygonDrawer/PolygonDrawer";
import { OrangeTextButton } from "./OrangeTextButton";
import { ActuateButton } from "./ActuateButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Dispatcher } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import Checkbox from "@mui/material/Checkbox";
import {
  pointsCreator,
  pointsCreatorPlus30,
} from "../pages/EditIgnoreZones/EditIgnoreZonesUtils/pointsCreatorLarger";
import { addIgnoreLabel } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/addIgnoreLabel";
import { handleIncrementPointsBy30 } from "../pages/EditIgnoreZones/EditIgnoreZonesUtils/handleDecreasePointsBy30";
// import { SchedulesTabs } from "../pages/Sites/SiteAboutPage/SchedulesTabs";

interface IgnoreZoneBuilderProps {
  shapeColor: string;
  shapesArray?: number[][][];
  height: number;
  width: number;
  imageURL: string;
  setIgnorezoneEditorVisible: Dispatcher<boolean>;
  setEditsMade: Dispatcher<boolean>;
  visibleIgnoreZones: any;
  selectedIgnoreZone: string;
  setVisibleIgnoreZones: Dispatcher<any>;
  largerMode: boolean;
  setLargerMode: Dispatcher<boolean>;
  dimensions?: any;
  setIZBuilder: Dispatcher<JSX.Element>;
  allIgnoreZonesShown: boolean;
  siteSchedules: any;
}

const IgnoreZoneBuilder: React.FC<IgnoreZoneBuilderProps> = ({
  shapeColor,
  shapesArray = [],
  height,
  width,
  imageURL,
  setIgnorezoneEditorVisible,
  setEditsMade,
  visibleIgnoreZones,
  selectedIgnoreZone,
  setVisibleIgnoreZones,
  largerMode,
  setLargerMode,
  dimensions,
  setIZBuilder,
  allIgnoreZonesShown,
  siteSchedules,
}) => {
  const [shapes, setShapes] = useState<number[][][]>(shapesArray || []);
  const [selectedShapeIndex, setSelectedShapeIndex] = useState<number | null>(
    null
  );
  const [polygonDrawer, setPolygonDrawer] = React.useState(<></>);

  const [imageHeight, setImageHeight] = React.useState(height);
  const [imageWidth, setImageWidth] = React.useState(width);

  const [iZpolygonPoints, setIZPolygonPoints] = useState<number[][]>([]);

  const [showAllIgnoreZones, setShowAllIgnoreZones] =
    useState<boolean>(allIgnoreZonesShown);

  const [clickedSchedulesTab, setClickedSchedulesTab] = useState<string>("one");

  const shapesSizeIncreaser = (shapes: number[][][]) => {
    // Increase the size of the shapes by 1.5
    return shapes.map((shape) => {
      return shape.map((point) => {
        return point.map((coord) => coord * 1.5);
      });
    });
  };

  const polygonPointsShapesIncreaser = (polygonPoints: number[][]) => {
    // Increase the size of the shapes by 1.5
    setIZPolygonPoints(
      polygonPoints.map((point) => {
        return point.map((coord) => coord * 1.5);
      })
    );
    return polygonPoints.map((point) => {
      return point.map((coord) => coord * 1.5);
    });
  };

  const polygonPointsShapesDecreaser = (polygonPoints: number[][]) => {
    setIZPolygonPoints(
      polygonPoints.map((point) => {
        return point.map((coord) => coord / 1.5);
      })
    );
    return polygonPoints.map((point) => {
      return point.map((coord) => coord / 1.5);
    });
  };

  const apiPointsCreator = (points: number[][][]) => {
    return points.map((polygon) =>
      polygon.map(([x, y]) => [x / width, y / height])
    );
  };

  // const apiPointsCreatorLarger = (points: number[][][]) => {
  //   return points.map((polygon) =>
  //     polygon.map(([x, y]) => [x / 1.5 / width, y / 1.5 / height])
  //   );
  // };

  const shapesSizeDecreaser = (shapes: number[][][]) => {
    // Decrease the size of the shapes by 1.5
    return shapes.map((shape) => {
      return shape.map((point) => {
        return point.map((coord) => coord / 1.5);
      });
    });
  };

  useEffect(() => {
    if (shapesArray && shapesArray.length > 0) {
      setShapes(shapesArray);
    }
  }, [shapesArray]);

  React.useEffect(() => {
    setPolygonDrawer(
      <PolygonDrawer
        initialPoints={[]}
        shapes={handleIncrementPointsBy30(shapes)}
        shapeColor={shapeColor}
        setShapes={setShapes}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        setIZPolygonPoints={setIZPolygonPoints}
      />
    );
  }, []);

  const handleMakeLarger = () => {
    setPolygonDrawer(<></>);
    setIZBuilder(<></>);
    setLargerMode(true);
    setTimeout(() => {
      setIZBuilder(
        <IgnoreZoneBuilder
          shapeColor={localStorage.ignoreZoneColor}
          height={imageHeight * 1.5}
          width={imageWidth * 1.5}
          imageURL={JSON.parse(localStorage.preview_url)}
          shapesArray={shapesSizeIncreaser([
            ...shapes,
            iZpolygonPoints.map((numArray: number[]) =>
              numArray.map((num) => num - 30)
            ),
          ])}
          setIgnorezoneEditorVisible={setIgnorezoneEditorVisible}
          setEditsMade={setEditsMade}
          visibleIgnoreZones={visibleIgnoreZones}
          selectedIgnoreZone={selectedIgnoreZone}
          setVisibleIgnoreZones={setVisibleIgnoreZones}
          largerMode={true}
          setLargerMode={setLargerMode}
          dimensions={dimensions}
          setIZBuilder={setIZBuilder}
          allIgnoreZonesShown={showAllIgnoreZones}
          siteSchedules={siteSchedules}
        />
      );
    }, 100);
  };

  const handleMakeSmaller = () => {
    setPolygonDrawer(<></>);
    setIZBuilder(<></>);
    setLargerMode(false);
    setTimeout(() => {
      setIZBuilder(
        <IgnoreZoneBuilder
          shapeColor={localStorage.ignoreZoneColor}
          height={imageHeight / 1.5}
          width={imageWidth / 1.5}
          imageURL={JSON.parse(localStorage.preview_url)}
          shapesArray={shapesSizeDecreaser([
            ...shapes,
            iZpolygonPoints.map((numArray: number[]) =>
              numArray.map((num) => num - 30)
            ),
          ])}
          setIgnorezoneEditorVisible={setIgnorezoneEditorVisible}
          setEditsMade={setEditsMade}
          visibleIgnoreZones={visibleIgnoreZones}
          selectedIgnoreZone={selectedIgnoreZone}
          setVisibleIgnoreZones={setVisibleIgnoreZones}
          largerMode={false}
          setLargerMode={setLargerMode}
          dimensions={dimensions}
          setIZBuilder={setIZBuilder}
          allIgnoreZonesShown={showAllIgnoreZones}
          siteSchedules={siteSchedules}
        />
      );
    }, 100);
  };

  const handleClearAll = () => {
    setPolygonDrawer(<></>);
    setShapes([]);
    setSelectedShapeIndex(null);
    setIZPolygonPoints([]);
    setIZBuilder(<></>);
    setTimeout(() => {
      setIZBuilder(
        <IgnoreZoneBuilder
          shapeColor={localStorage.ignoreZoneColor}
          height={imageHeight}
          width={imageWidth}
          imageURL={JSON.parse(localStorage.preview_url)}
          shapesArray={[]}
          setIgnorezoneEditorVisible={setIgnorezoneEditorVisible}
          setEditsMade={setEditsMade}
          visibleIgnoreZones={visibleIgnoreZones}
          selectedIgnoreZone={selectedIgnoreZone}
          setVisibleIgnoreZones={setVisibleIgnoreZones}
          largerMode={largerMode}
          setLargerMode={setLargerMode}
          dimensions={dimensions}
          setIZBuilder={setIZBuilder}
          allIgnoreZonesShown={showAllIgnoreZones}
          siteSchedules={siteSchedules}
        />
      );
    }, 1);
  };

  const handleCancel = () => {
    setIgnorezoneEditorVisible(false);
  };

  return (
    <div>
      <div className="font-mulish">
        <FormControlLabel
          sx={{ fontFamily: "Mulish" }}
          control={
            <Switch
              color="warning"
              defaultChecked={largerMode}
              disabled={false}
              onChange={(event) => {
                if (event.target.checked) {
                  handleMakeLarger();
                  setLargerMode(true);
                } else {
                  handleMakeSmaller();
                  setLargerMode(false);
                }
              }}
            />
          }
          label="Larger Mode"
        />
        <FormControlLabel
          sx={{
            fontFamily: "Mulish",
            color: "#001943",
          }}
          label="Show All Ignore Zones"
          control={
            <Checkbox
              checked={showAllIgnoreZones}
              color="primary"
              onChange={(event: any, result: any) => {
                if (result === true) {
                  setShowAllIgnoreZones(true);
                } else {
                  setShowAllIgnoreZones(false);
                }
              }}
            />
          }
        />
      </div>
      <div style={{ marginBottom: "10px" }}></div>
      {showAllIgnoreZones && (
        <div className="absolute top-[60px] left-[10px]">
          {visibleIgnoreZones
            .filter((object: any) => object.line_color !== shapeColor)
            .map((object: any) => {
              return (
                <div className="absolute top-[0px]" key={object.label}>
                  {object.coordinates[0] &&
                    object.coordinates.map((array: any, index: any) => {
                      return (
                        <svg
                          className="absolute top-[0px]"
                          key={index}
                          style={{
                            width: `${imageWidth + 30}px`,
                            height: `${imageHeight + 30}px`,
                          }}
                        >
                          {dimensions && (
                            <polygon
                              points={pointsCreatorPlus30(
                                object.coordinates[index],
                                imageHeight,
                                imageWidth
                              )}
                              stroke={object.line_color}
                              strokeWidth="5"
                              fill={object.line_color}
                              fillOpacity="50%"
                            />
                          )}
                        </svg>
                      );
                    })}
                </div>
              );
            })}
        </div>
      )}
      <div
        style={{
          padding: "8px", // Adjust padding as needed
          borderRadius: "4px", // Rounded corners
          backgroundColor: "white", // Optional, for visible padding area
          display: "inline-block", // Ensures the padding wraps tightly around the svg
        }}
      >
        <div className="absolute">{polygonDrawer}</div>

        <div className="pt-[30px] pl-[30px]">
          <svg
            width={imageWidth}
            height={imageHeight}
            style={{
              backgroundImage: `url(${imageURL})`,
              backgroundSize: `${imageWidth}px ${imageHeight}px`,
            }}
          >
            {shapes.map((shape, i) => (
              <polygon
                key={i}
                points={shape.map((point) => point.join(",")).join(" ")}
                fill={i === selectedShapeIndex ? shapeColor : `${shapeColor}40`}
                stroke={shapeColor}
                strokeWidth={2}
              />
            ))}
          </svg>
        </div>

        <div className="pt-[40px] pl-[20px]">
          <OrangeTextButton
            onClick={handleClearAll}
            text="Clear All"
            disabled={false}
          />
        </div>
        {showAllIgnoreZones && (
          <div className="absolute pt-[20px] flex w-[1200px] overflow-scroll">
            {visibleIgnoreZones.map((object: any) => {
              return (
                <div
                  key={object.label}
                  className="pr-[35px] cursor-default min-w-[100px]"
                >
                  <div
                    key={object.label}
                    style={{
                      height: 25,
                      width: 6,
                      background: `${object.line_color}`,
                      cursor: "default",
                    }}
                    className="absolute cursor-default"
                  ></div>
                  <p className="font-mulish text-[14px] indent-5 cursor-default">
                    {object.label_display_name}
                  </p>
                  &nbsp;
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className={`absolute top-[0px] right-[200px]`}>
        <OrangeTextButton
          onClick={handleCancel}
          text="Cancel"
          disabled={false}
        />
      </div>
      <div className={`absolute top-[0px] right-[40px]`}>
        <ActuateButton
          onClick={() => {
            setIgnorezoneEditorVisible(false);
            setEditsMade(true);
            const updatedVisibleIgnoreZones: any = [];
            // create a new variable called updatedIZPolygonPoints that takes in iZpolygonPoints and decreases the points by 30
            const updatedIZPolygonPoints = iZpolygonPoints.map(
              (array: number[]) => {
                return array.map((num) => num - 30);
              }
            );
            const updatedShapes = [...shapes, updatedIZPolygonPoints];

            const shapesObject = apiPointsCreator(updatedShapes);
            visibleIgnoreZones.forEach((object: any) => {
              if (object.label === selectedIgnoreZone && shapesObject) {
                const updatedCoordinates: any = [];

                for (const key in shapesObject) {
                  updatedCoordinates.push(shapesObject[key]);
                }
                object.coordinates = updatedCoordinates;
                updatedVisibleIgnoreZones.push(object);
              } else if (object.label !== selectedIgnoreZone && shapesObject) {
                updatedVisibleIgnoreZones.push(object);
              }
            });

            setVisibleIgnoreZones(addIgnoreLabel(updatedVisibleIgnoreZones));
          }}
          text="Save Edits"
          disabled={false}
        />
      </div>
    </div>
  );
};

export default IgnoreZoneBuilder;
