import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { OrangeTextButton } from "../../../common/OrangeTextButton";
import { NavigateFunction } from "react-router-dom";
import { Dispatcher } from "./SiteAboutPageUtils/siteAboutInterfaces";
import { TextField } from "@mui/material";
import { handleSubmitCameraHealthData } from "./SiteAboutPageUtils/handleSubmitCameraHealthData";

const AddCameraHealthDialog = ({
  addCameraHealthDialogOpen,
  setAddCameraHealthDialogOpen,
  setSiteInfo,
  setActiveTab,
  healthCheckData,
  setCameraHealthPatchObject,
  setCameraHealthChangesMade,
  siteInfo,
  setBackdropLoadingScreenOn,
  setHealthCheckData,
  setEditMode,
  setTurnOffHealthDialogOpen,
  permissions,
  navigate,
}: {
  addCameraHealthDialogOpen: boolean;
  setAddCameraHealthDialogOpen: Dispatcher<boolean>;
  setSiteInfo: any;
  setActiveTab: any;
  healthCheckData: any;
  setCameraHealthPatchObject: any;
  setCameraHealthChangesMade: any;
  siteInfo: any;
  setBackdropLoadingScreenOn: Dispatcher<boolean>;
  setHealthCheckData: any;
  setEditMode: Dispatcher<boolean>;
  setTurnOffHealthDialogOpen: Dispatcher<boolean>;
  permissions: any;
  navigate: NavigateFunction;
}) => {
  const [step2, setStep2] = React.useState(false);

  const [submitObject, setSubmitObject] = React.useState(healthCheckData);

  const userInfo = JSON.parse(localStorage.userInfo);

  React.useEffect(() => {
    if (userInfo.permission.chm) {
      setStep2(true);
    }
  }, []);

  return (
    <Dialog
      open={addCameraHealthDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Add Camera Health Monitoring"}
      </DialogTitle>
      {!step2 ? (
        <DialogContent>
          <DialogContentText>
            Actuate&apos;s Camera Health Monitoring product is now available.
          </DialogContentText>
          <br></br>
          <DialogContentText>
            If interested, please contact your account manager at am@actuate.ai
            to learn more and to access the product.
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <TextField
            multiline
            defaultValue={healthCheckData.alert_emails}
            onChange={(event: any) => {
              setSubmitObject((previousState: any) => {
                return { ...previousState, alert_emails: event.target.value };
              });
            }}
            label="Alert Emails"
            sx={{ width: 500 }}
            helperText="Emails to receive health check notifications, separated by comma."
            rows={3}
          />
          <br></br>
          <br></br>

          <TextField
            multiline
            defaultValue={healthCheckData.report_emails}
            onChange={(event: any) => {
              setSubmitObject((previousState: any) => {
                return { ...previousState, report_emails: event.target.value };
              });
            }}
            label="Report Emails"
            sx={{ width: 500 }}
            helperText="Emails to receive health check reports, separated by comma."
            rows={3}
          />
        </DialogContent>
      )}
      <DialogActions>
        <OrangeTextButton
          onClick={() => {
            setAddCameraHealthDialogOpen(false);
          }}
          text={step2 ? "Cancel" : "OK"}
          disabled={false}
        />
        {step2 && (
          <OrangeTextButton
            onClick={() => {
              setBackdropLoadingScreenOn(true);
              setAddCameraHealthDialogOpen(false);
              handleSubmitCameraHealthData(
                healthCheckData,
                setHealthCheckData,
                submitObject,
                setCameraHealthPatchObject,
                setCameraHealthChangesMade,
                setActiveTab,
                setBackdropLoadingScreenOn,
                siteInfo,
                setAddCameraHealthDialogOpen,
                addCameraHealthDialogOpen,
                setSiteInfo,
                setEditMode,
                setTurnOffHealthDialogOpen,
                navigate
              );
            }}
            text="Submit"
            disabled={false}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddCameraHealthDialog;
