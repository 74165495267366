import { Switch, Tooltip } from "@mui/material";
import React from "react";
import { handleProductMasterSwitchEvent } from "./handleProductMasterSwitchEvent";

export const createIntruderToggle = (
  selectedGroups: any,
  setSelectedGroups: any,
  productIndex: number,
  setLocalProductsData: any,
  setProductsData: any,
  setPatchObject: any,
  setGeneralInfoChangesMade: any
) => {
  if (selectedGroups.intruder.disabled) {
    if (selectedGroups.motionPlus.selected) {
      return (
        <Tooltip
          title={`Intruder can not be run with either Intruder Plus or Motion plus enabled. To enable Intruder, please toggle off both Intruder Plus and Motion Plus.`}
          placement="top"
        >
          <div>
            <Switch
              checked={selectedGroups.intruder.selected}
              color="warning"
              disabled={true}
            />
          </div>
        </Tooltip>
      );
    } else
      return (
        <Tooltip
          title={`Intruder Plus is already enabled. To enable Intruder, please toggle off Intruder Plus.`}
          placement="top"
        >
          <div>
            <Switch
              checked={selectedGroups.intruder.selected}
              color="warning"
              disabled={true}
            />
          </div>
        </Tooltip>
      );
  } else {
    if (selectedGroups.motionPlus.selected) {
      if (selectedGroups.intruderPlus.selected) {
        return (
          <Tooltip
            title={`Intruder can not be run with either Intruder Plus or Motion plus enabled. To enable Intruder, please toggle off both Intruder Plus and Motion Plus.`}
            placement="top"
          >
            <div>
              <Switch
                checked={selectedGroups.intruder.selected}
                color="warning"
                disabled={true}
              />
            </div>
          </Tooltip>
        );
      } else
        return (
          <Tooltip
            title={`Intruder and Motion Plus can not run together. To enable Intruder, please toggle off Motion Plus.`}
            placement="top"
          >
            <div>
              <Switch
                checked={selectedGroups.intruder.selected}
                color="warning"
                disabled={true}
              />
            </div>
          </Tooltip>
        );
    } else if (selectedGroups.intruderPlus.selected) {
      return (
        <Tooltip
          title={`Intruder Plus is already enabled. To enable Intruder, please toggle off Intruder Plus.`}
          placement="top"
        >
          <div>
            <Switch
              checked={selectedGroups.intruder.selected}
              color="warning"
              disabled={true}
            />
          </div>
        </Tooltip>
      );
    } else
      return (
        <Switch
          checked={selectedGroups.intruder.selected}
          color="warning"
          disabled={false}
          onChange={(event) => {
            if (!event.target.checked) {
              setSelectedGroups((previousState: any) => {
                return {
                  ...previousState,
                  intruder: {
                    selected: false,
                    disabled: previousState.intruder.disabled,
                    intruder: false,
                    bike: false,
                    vehicle: false,
                  },
                  intruderPlus: {
                    selected: previousState.intruderPlus.selected,
                    disabled: selectedGroups.intruderPlus.selected,
                    intruder_plus: previousState.intruderPlus.intruder_plus,
                    bike_plus: previousState.intruderPlus.bike_plus,
                    vehicle_plus: previousState.intruderPlus.vehicle_plus,
                  },
                };
              });
            }
            if (event.target.checked) {
              setSelectedGroups((previousState: any) => {
                return {
                  ...previousState,
                  intruder: {
                    selected: true,
                    disabled: previousState.intruder.disabled,
                    intruder: true,
                    bike: true,
                    vehicle: true,
                  },
                  intruderPlus: {
                    selected: previousState.intruderPlus.selected,
                    disabled: !selectedGroups.intruderPlus.selected,
                    intruder_plus: previousState.intruderPlus.intruder_plus,
                    bike_plus: previousState.intruderPlus.bike_plus,
                    vehicle_plus: previousState.intruderPlus.vehicle_plus,
                  },
                };
              });
            }
            handleProductMasterSwitchEvent(
              productIndex,
              setLocalProductsData,
              setProductsData,
              setPatchObject,
              event
            );
            setGeneralInfoChangesMade(true);
          }}
        />
      );
  }
};
