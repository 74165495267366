type Schedule = {
    day_of_week: (string | number)[];
    start_time: string | null;
    end_time: string | null;
};

type ProductSchedule = {
    id: number;
    product: number[];
    schedule: Schedule[];
};

const isValidTime = (time: string | null): boolean => {
    return time !== null && time !== ":00" && !time.includes("-");
};

const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(":".slice(0, 2)).map(Number);
    return hours * 60 + minutes;
};

const isOverlapping = (startA: string, endA: string, startB: string, endB: string): boolean => {
    const startAMin = timeToMinutes(startA);
    let endAMin = timeToMinutes(endA);
    const startBMin = timeToMinutes(startB);
    let endBMin = timeToMinutes(endB);

    // Handle overnight schedules
    if (endAMin < startAMin) endAMin += 1440;
    if (endBMin < startBMin) endBMin += 1440;

    return !(endAMin <= startBMin || endBMin <= startAMin);
};

export const handleCheckOverlappingSchedulesForSharedProducts = (data: ProductSchedule[]): boolean => {
    const productMap = new Map<number, ProductSchedule[]>();

    // Group schedules by product
    data.forEach((entry) => {
        entry.product.forEach((prod) => {
            if (!productMap.has(prod)) {
                productMap.set(prod, []);
            }
            productMap.get(prod)!.push(entry);
        });
    });

    // Check for overlapping schedules within each product group
    for (const [_, entries] of productMap) {
        if (entries.length < 2) continue;

        for (let i = 0; i < entries.length; i++) {
            for (let j = i + 1; j < entries.length; j++) {
                for (const scheduleA of entries[i].schedule) {
                    for (const scheduleB of entries[j].schedule) {
                        if (!isValidTime(scheduleA.start_time) || !isValidTime(scheduleA.end_time) ||
                            !isValidTime(scheduleB.start_time) || !isValidTime(scheduleB.end_time)) {
                            continue;
                        }

                        // Normalize day_of_week values to strings for uniform comparison
                        const daysA = scheduleA.day_of_week.map(String);
                        const daysB = scheduleB.day_of_week.map(String);

                        if (daysA.some(day => daysB.includes(day))) {
                            if (isOverlapping(scheduleA.start_time!, scheduleA.end_time!, scheduleB.start_time!, scheduleB.end_time!)) {
                                alert("There are overlapping schedules for shared products. Please check the schedule times and active products for each schedule.")
                                return true;
                            }
                        }
                    }
                }
            }
        }
    }

    return false;
};
