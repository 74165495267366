import React from "react";

export const findNewScheduleIndex = (siteSchedules: any, responseData: any) => {
  //check each schedule in response data to see which is the new schedule
  //this will be the schedule whose id is not one of the ids in siteSchedules
  let newScheduleIndex = 0;
  for (let i = 0; i < responseData.length; i++) {
    if (
      !siteSchedules.some((schedule: any) => schedule.id === responseData[i].id)
    ) {
      newScheduleIndex = i;
      break;
    }
  }

  return newScheduleIndex;
};
