import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tooltip from "@mui/material/Tooltip";
import { handleLevel1IndexClick } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/handleLevel1IndexClick";
import { handleLevel2IndexClick } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/handleLevel2IndexClick";
import { handleLevel3IndexClick } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/handleLevel3IndexClick";
import { handleLevel4IndexClick } from "../pages/Sites/SiteAboutPage/SiteAboutPageUtils/handleLevel4IndexClick";
import { useNavigate } from "react-router-dom";
import { nameSlicer } from "./formikContainer/nameSlicer";

export const BreadCrumbsComponet = ({
  linksArray,
  handleClick,
  styling,
  breadcrumb_info,
}: {
  linksArray: any;
  handleClick: any;
  styling: any;
  breadcrumb_info: any;
}) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    localStorage.setItem("parentGroupId", breadcrumb_info[0].id);
  }, []);

  return (
    <Stack spacing={2}>
      <div className={styling}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="medium" />}
          aria-label="breadcrumb"
        >
          {linksArray.map((object: any, index: any) => {
            if (index === 0) {
              return (
                <Tooltip title={object.name} placement="top" key={index}>
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="/sites"
                    sx={{
                      fontSize: "17px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={handleClick}
                  >
                    {nameSlicer(object.name)}
                  </Link>
                </Tooltip>
              );
            } else if (index === 1 && linksArray.length > 2) {
              return (
                <Tooltip title={object.name} placement="top" key={index}>
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "17px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      handleLevel1IndexClick(breadcrumb_info[0].id, navigate);
                    }}
                  >
                    {nameSlicer(object.name)}
                  </Link>
                </Tooltip>
              );
            } else if (index === 2 && linksArray.length > 3) {
              return (
                <Tooltip title={object.name} placement="top" key={index}>
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "17px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      handleLevel2IndexClick(breadcrumb_info, navigate);
                    }}
                  >
                    {nameSlicer(object.name)}
                  </Link>
                </Tooltip>
              );
            } else if (index === 3 && linksArray.length > 4) {
              return (
                <Tooltip title={object.name} placement="top" key={index}>
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "17px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      handleLevel3IndexClick(breadcrumb_info, navigate);
                    }}
                  >
                    {nameSlicer(object.name)}
                  </Link>
                </Tooltip>
              );
            } else if (index === 4 && linksArray.length > 5) {
              return (
                <Tooltip title={object.name} placement="top" key={index}>
                  <Link
                    underline="hover"
                    key={index}
                    color="inherit"
                    href="#"
                    sx={{
                      fontSize: "17px",
                      fontFamily: "Mulish",
                      fontWeight: "bold",
                    }}
                    variant="h5"
                    onClick={() => {
                      handleLevel4IndexClick(breadcrumb_info, navigate);
                    }}
                  >
                    {nameSlicer(object.name)}
                  </Link>
                </Tooltip>
              );
            } else
              return (
                <Typography
                  key={index}
                  color="text.primary"
                  fontSize="17px"
                  fontFamily="Mulish"
                  fontWeight="bold"
                >
                  {object.name}
                </Typography>
              );
          })}
        </Breadcrumbs>
      </div>
    </Stack>
  );
};
