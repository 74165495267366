import React from "react";
import { ScheduleGrid } from "../ScheduleGrid";
import { handleCreateInitialTimeSchedules } from "./handleCreateInitialTimeSchedules";
import { handleConvertSchedulesToBooleanArrays } from "./handleConvertSchedulesToBooleanArrays";
import { handleCreateDuskToDawn } from "./handleCreateDuskToDawn";
import { handleCreateDuskToDawnLocationPlusBuffer } from "../../SitesPage/SitesPageUtils/handleCreateDuskToDawnLocationPlusBuffer";

export const handleScheduleTabChange = (
  selectedIndex: number,
  allSchedules: any,
  setScheduleGrid: any,
  setTimeRanges: any,
  setGrid: any,
  setDuskToDawn: any,
  setDuskToDawnLocation: any,
  editMode: boolean,
  setSelectedDayInfo: any,
  setEditTimeScheduleDialogOpen: any,
  setMatchingSchedules: any,
  setScheduleChangesMade: any,
  setSiteSchedules: any,
  setEditModeTabSiteSchedules: any
) => {
  editMode
    ? setScheduleGrid(
        <ScheduleGrid
          editable={true}
          setSelectedDayInfo={setSelectedDayInfo}
          setEditTimeScheduleDialogOpen={setEditTimeScheduleDialogOpen}
          timeRanges={handleCreateInitialTimeSchedules(
            allSchedules[selectedIndex].schedule
          )}
          setTimeRanges={setTimeRanges}
          grid={handleConvertSchedulesToBooleanArrays(
            allSchedules[selectedIndex].schedule
          )}
          setGrid={setGrid}
          duskToDawn={handleCreateDuskToDawn(
            allSchedules[selectedIndex].schedule
          )}
          setDuskToDawn={setDuskToDawn}
          duskToDawnLocation={handleCreateDuskToDawnLocationPlusBuffer(
            allSchedules[selectedIndex].schedule
          )}
          setDuskToDawnLocation={setDuskToDawnLocation}
          setMatchingSchedules={setMatchingSchedules}
          setScheduleChangesMade={setScheduleChangesMade}
          setSiteSchedules={setSiteSchedules}
          setScheduleGrid={setScheduleGrid}
          setEditModeTabSiteSchedules={setEditModeTabSiteSchedules}
        />
      )
    : setScheduleGrid(
        <ScheduleGrid
          editable={false}
          setSelectedDayInfo={() => {}}
          setEditTimeScheduleDialogOpen={() => {}}
          timeRanges={handleCreateInitialTimeSchedules(
            allSchedules[selectedIndex].schedule
          )}
          setTimeRanges={setTimeRanges}
          grid={handleConvertSchedulesToBooleanArrays(
            allSchedules[selectedIndex].schedule
          )}
          setGrid={setGrid}
          duskToDawn={handleCreateDuskToDawn(
            allSchedules[selectedIndex].schedule
          )}
          setDuskToDawn={setDuskToDawn}
          duskToDawnLocation={handleCreateDuskToDawnLocationPlusBuffer(
            allSchedules[selectedIndex].schedule
          )}
          setDuskToDawnLocation={setDuskToDawnLocation}
          setMatchingSchedules={() => {}}
          setScheduleChangesMade={() => {}}
          setSiteSchedules={() => {}}
          setScheduleGrid={() => {}}
          setEditModeTabSiteSchedules={() => {}}
        />
      );
};
