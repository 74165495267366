import * as React from "react";
import { SitesGroupList } from "./SitesGroupList";
import { FilteredSitesGroupList } from "./FilteredSitesGroupList";
import TableContainer from "@mui/material/TableContainer";
import { SiteHierarchyBreadcrumbs } from "../../../common/SiteHierarchyBreadcrumbs";
import useWindowDimensions from "../../../common/useWindowDimensions";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SitesGroupTableSortable } from "./SitesGroupTableSortable";
import { addOptimalSettingsNumber } from "./SitesPageUtils/addOptimalSettingsNumber";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

export const SitesScreen = ({
  searchBarValue,
  currentTitle,
  pageLoading,
  renderedRows,
  page,
  rowsPerPage,
  setPageLoading,
  navigate,
  groupTrees,
  selectedIndex,
  setPage,
  setCurrentTitle,
  setSelectedIndex,
  setRenderedRows,
  editedparentsGroupsList,
  setRowsPerPage,
  setSiteBreadcrumbs,
  siteBreadcrumbs,
  setAddGroupDialogOpen,
  navBarCollapsed,
  permissions,
  selected,
  setSelected,
  setSiteScreenAlerts,
  setSiteScreenLoading,
  setSearchBarValue,
  setEditedParentGroupList,
  setBackdropLoadingScreenOn,
  editableGroupSelected,
  setEditableGroupSelected,
  selectedGroupListName,
  setSelectedGroupListName,
  setGroupTrees,
  getIndividualGroup,
}: {
  searchBarValue: any;
  currentTitle: any;
  pageLoading: boolean;
  renderedRows: any;
  page: number;
  rowsPerPage: number;
  setPageLoading: any;
  navigate: any;
  groupTrees: any;
  selectedIndex: any;
  setPage: any;
  setCurrentTitle: any;
  setSelectedIndex: any;
  setRenderedRows: any;
  editedparentsGroupsList: any;
  setRowsPerPage: any;
  siteBreadcrumbs: any;
  setSiteBreadcrumbs: any;
  setAddGroupDialogOpen: any;
  navBarCollapsed: boolean;
  permissions: any;
  selected: any;
  setSelected: any;
  setSiteScreenAlerts: any;
  setSiteScreenLoading: any;
  setSearchBarValue: any;
  setEditedParentGroupList: any;
  setBackdropLoadingScreenOn: any;
  editableGroupSelected: any;
  setEditableGroupSelected: any;
  selectedGroupListName: any;
  setSelectedGroupListName: any;
  setGroupTrees: any;
  getIndividualGroup: any;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const { height } = useWindowDimensions();

  const idScrollHeightMap = new Map();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = renderedRows.map((obj: any) => obj.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSingleCheckboxClick = (
    event: React.MouseEvent<unknown>,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const loadingDiv = (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -translate-x-[-140px]">
      <ThemeProvider theme={theme}>
        <div>
          <CircularProgress color="primary" />
        </div>
      </ThemeProvider>
    </div>
  );

  const siteGroupList = React.useMemo(
    () => (
      <SitesGroupList
        groupTrees={groupTrees}
        selectedIndex={selectedIndex}
        setPage={setPage}
        setCurrentTitle={setCurrentTitle}
        setSelectedIndex={setSelectedIndex}
        setRenderedRows={setRenderedRows}
        setPageLoading={setPageLoading}
        navigate={navigate}
        setSiteBreadcrumbs={setSiteBreadcrumbs}
        setAddGroupDialogOpen={setAddGroupDialogOpen}
        permissions={permissions}
        navBarCollapsed={navBarCollapsed}
        setSelected={setSelected}
        searchBarValue={searchBarValue}
        editedparentsGroupsList={editedparentsGroupsList}
        setSearchBarValue={setSearchBarValue}
        setEditedParentGroupList={setEditedParentGroupList}
        setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
        setSelectedGroupListName={setSelectedGroupListName}
        setEditableGroupSelected={setEditableGroupSelected}
      />
    ),
    [
      selectedIndex,
      groupTrees,
      navBarCollapsed,
      searchBarValue,
      editedparentsGroupsList,
    ]
  );

  const updatedRenderedRows = addOptimalSettingsNumber(renderedRows);

  React.useEffect(() => {
    let currentTop = 0;
    groupTrees.forEach((item: any) => {
      if (item.parent_account || item.children[0]) {
        idScrollHeightMap.set(item.id, currentTop);
        currentTop += 45;
      } else if (!item.parent_account) {
        idScrollHeightMap.set(item.id, currentTop);
        currentTop += 88.5;
      }
    });
    if (ref && ref.current !== null && localStorage.parentGroupId) {
      ref.current.scrollTop = idScrollHeightMap.get(
        Number(localStorage.parentGroupId)
      );
    }
  }, []);

  return (
    <div>
      <div>
        <TableContainer
          ref={ref}
          sx={
            navBarCollapsed
              ? {
                  position: "absolute",
                  left: 75,
                  top: 77,
                  height: height - 107,
                  width: "325px",
                  bgcolor: "background.paper",
                }
              : {
                  position: "absolute",
                  left: 150,
                  top: 77,
                  height: height - 107,
                  width: "250px",
                  bgcolor: "background.paper",
                }
          }
        >
          {siteGroupList}
        </TableContainer>
      </div>
      {!pageLoading && (
        <div>
          <SitesGroupTableSortable
            renderedRows={updatedRenderedRows}
            page={page}
            rowsPerPage={rowsPerPage}
            setPageLoading={setPageLoading}
            navigate={navigate}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            handleSelectAllClick={handleSelectAllClick}
            handleSingleCheckboxClick={handleSingleCheckboxClick}
            isSelected={isSelected}
            selected={selected}
            setSelected={setSelected}
            setSiteBreadcrumbs={setSiteBreadcrumbs}
            setSiteScreenAlerts={setSiteScreenAlerts}
            setRenderedRows={setRenderedRows}
            setSiteScreenLoading={setSiteScreenLoading}
            editableGroupSelected={editableGroupSelected}
            selectedGroupListName={selectedGroupListName}
            setBackdropLoadingScreenOn={setBackdropLoadingScreenOn}
            setSelectedGroupListName={setSelectedGroupListName}
            groupTrees={groupTrees}
            setGroupTrees={setGroupTrees}
            siteBreadcrumbs={siteBreadcrumbs}
            setSelectedIndex={setSelectedIndex}
            getIndividualGroup={getIndividualGroup}
            setEditableGroupSelected={setEditableGroupSelected}
          />
        </div>
      )}
      {pageLoading ? loadingDiv : ""}
    </div>
  );
};
