/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import { NavBar } from "../../common/NavBar";
import { AlertsTable, setDetectionTypeLower } from "./AlertsTable";
import { AlertData } from "./AlertsUtils/alertsInterfaces";
import { AlertsIdentifierBox } from "./AlertsIdentifierBox";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAlerts } from "./AlertsUtils/getAlerts";
import BackdropLoadingScreen from "../../common/BackdropLoadingScreen";
import { OrangeTextButton } from "../../common/OrangeTextButton";
import useWindowDimensions from "../../common/useWindowDimensions";
import { NotesDialog } from "./NotesDialog";
import { Alert, Dialog, DialogContent, Tooltip } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { handleForwardButtonClick } from "./AlertsUtils/handleForwardButtonClick";
import { handleBackButtonClick } from "./AlertsUtils/handleBackButtonClick";
import { createSiteHyperlink } from "./AlertsUtils/createSiteHyperlink";
import { createCameraHyperlink } from "./AlertsUtils/createCameraHyperlink";
import ErrorBoundary from "../../../ErrorBoundary";
import { Dispatcher } from "../Sites/SiteAboutPage/SiteAboutPageUtils/siteAboutInterfaces";
import { handleRowSelected } from "./AlertsUtils/handleRowSelected";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ActiveIgnoreZonesWindow } from "./ActiveIgnoreZonesWindow";
import CameraInfoTable from "./CameraInfoTable";
import { saveAs } from "file-saver";
import { updateSearchParamValues } from "../../utils/updateSearchParamValues";
import { getSitesByParentGroupAlerts } from "./AlertsUtils/getSitesByParentGroupAlerts";
import { getCamerasBySiteAlerts } from "./AlertsUtils/getCamerasBySiteAlerts";
import { getParentAccounts } from "../../utils/getParentAccounts";
import { FiltersAlerts } from "./FiltersAlerts";
import { convertHHMMTime } from "./AlertsUtils/convertHHMMTime";
import { handleCreateFilterTypeName } from "./AlertsUtils/handleCreateFilterTypeName";
import { getIgnoreZoneDialogInfo } from "./AlertsUtils/getIgnoreZoneDialogInfo";
import { getAlertsDownloadURL } from "./AlertsUtils/getAlertsDownloadURL";
import { yyyymmddGenerator } from "../Analytics/AnalyticsUtils/yyyymmddGenerator";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { checkForRedirectAndFetchMP4 } from "./AlertsUtils/checkForRedirectAndFetchMP4";
import { parse } from "path";
import { getSitesByParentGroup } from "../Analytics/AnalyticsUtils/getSitesByParentGroup";
import { getSiteByParentGroupsPlusAssignSiteDDValueArray } from "./AlertsUtils/getSiteByParentGroupsPlusAssignSiteDDValueArray";
import { getCamerasBySitesPlusAssignCameraDDValueArray } from "./AlertsUtils/getCamerasBySitesPlusAssignCameraDDValueArray";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8400",
    },
  },
});

const parseNumberList = (str: string): number[] => str.split(",").map(Number);

const AlertsMirror = ({
  navBarCollapsed,
  setNavBarCollapsed,
}: {
  navBarCollapsed: boolean;
  setNavBarCollapsed: Dispatcher<boolean>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const group = searchParams.get("group");
  const site = searchParams.get("site");
  const camera = searchParams.get("camera");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const start_time = searchParams.get("start_time");
  const end_time = searchParams.get("end_time");
  const filter_type = searchParams.get("filter_type");
  const detection_type = searchParams.get("detection_type");

  const [userFilters, setUserFilters] = React.useState<any>({
    parent_group: group,
    site: site ? parseNumberList(site) : [],
    camera: camera ? parseNumberList(camera) : [],
    start_date: start_date ? new Date(start_date) : null,
    end_date: end_date ? new Date(end_date) : null,
    start_time: start_time ? convertHHMMTime(start_time) : null,
    end_time: end_time ? convertHHMMTime(end_time) : null,
    filter_type: filter_type ? filter_type : "UNCLAIMED",
  });

  React.useEffect(() => {
    if (searchParams.size > 0) {
      localStorage.setItem("latestAlertFilters", JSON.stringify(userFilters));
      updateSearchParamValues(
        userFilters,
        searchParams,
        setSearchParams,
        detectionType
      );
    }
  }, []);

  const [alertData, setAlertData] = React.useState<AlertData[]>([]);

  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = React.useState(false);

  const [selected, setSelected] = React.useState<string[]>([]);

  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const [page, setPage] = React.useState(0);

  const [selectdButtonsFilled, setSelectedButtonsFilled] = React.useState({
    true_threat: true,
    true_non_threat: true,
    false_positive: true,
    escalated_true_threat: true,
  });

  React.useEffect(() => {
    // if(
    //   end_date && !isCurrentDate(end_date)
    // ) {
    //   setRowsSortable(true);
    // }

    getParentAccounts(
      setParentAccountsLoading,
      setParentAccounts,
      setParentIds,
      setParentNames,
      navigate
    );

    if (group) {
      setLoadingSites(true);
      getSiteByParentGroupsPlusAssignSiteDDValueArray(
        group,
        navigate,
        setSiteOptions,
        setLoadingSites,
        site ? parseNumberList(site) : [],
        setSiteDropDownValue
      );
    }
    if (site) {
      getCamerasBySitesPlusAssignCameraDDValueArray(
        [site],
        navigate,
        setCameraOptions,
        setLoadingCameras,
        setCameraDropDownValue,
        camera ? parseNumberList(camera) : []
      );
    }
  }, []);

  const [refreshingToken, setRefreshingToken] = React.useState(false);

  const [buttonClicked, setButtonClicked] = React.useState(false);

  const [fetchingFilteredData, setFetchingFilteredData] = React.useState(false);

  const [seeMoreURL, setSeeMoreURL] = React.useState<any>("");

  const [mP4URL, setMP4URL] = React.useState<any>("");

  const [thumbnailVideos, setThumbnailVideos] = React.useState<any>({});

  const [dateOutsideRange, setDateOutsideRange] = React.useState(false);

  const [ignoreZoneDialogOpen, setIgnoreZoneDialogOpen] = React.useState(false);

  const [siteOptions, setSiteOptions] = React.useState<any[]>([]);

  const [cameraOptions, setCameraOptions] = React.useState<any[]>([]);

  const [notesDialogOpen, setNotesDialogOpen] = React.useState(false);

  const [noteText, setNoteText] = React.useState("");

  const [videoClipLoading, setVideoClipLoading] = React.useState(true);

  const [alertsToDisplay, setAlertsToDisplay] = React.useState(false);

  const [refreshingData, setRefreshingData] = React.useState(false);

  const { width } = useWindowDimensions();

  const [alertIndex, setAlertIndex] = React.useState(0);

  const [timeFiltersDisabled, setTimeFiltersDisabled] = React.useState(true);

  const [loadingSites, setLoadingSites] = React.useState(group ? true : false);

  const [loadingCameras, setLoadingCameras] = React.useState(
    site ? true : false
  );

  const [selectedAlertCameraInfo, setSelectedAlertCameraInfo] = React.useState(
    {}
  );

  const [ignoreZonesSelectedAlert, setIgnoreZonesSelectedAlert] =
    React.useState([]);

  const [alertsIndexOrder, setAlertsIndexOrder] = React.useState([]);

  const [refreshInterval, setRefreshInterval] = React.useState(
    JSON.parse(localStorage.refresh_interval)
  );

  const [currentName, setCurrentName] = React.useState(
    filter_type ? handleCreateFilterTypeName(filter_type) : "Unresolved Alerts"
  );

  const [pageLoadedNumber, setPageLoadedNumber] = React.useState(0);

  const [parentAccounts, setParentAccounts] = React.useState([]);

  const [parentAccountsLoading, setParentAccountsLoading] =
    React.useState(true);

  const [parentIds, setParentIds] = React.useState(new Map());

  const [parentNames, setParentNames] = React.useState(new Map());

  const [warningIssued, setWarningIssued] = React.useState(false);

  const [alertLabels, setAlertLabels] = React.useState<string[]>(["All"]);

  const [detectionType, setDetectionType] = React.useState(
    detection_type ? detection_type : "All"
  );

  const [outcomeFilterType, setOutcomeFilterType] = React.useState("All");

  const [periodHours, setPeriodHours] = React.useState(2);

  const [queryLimitHit, setQueryLimitHit] = React.useState(false);

  const [filtersApplied, setFiltersApplied] = React.useState(false);

  const [loadingIgnoreZonesInfo, setLoadingIgnoreZonesInfo] =
    React.useState(false);

  const [alertsDownloadURL, setAlertsDownloadURL] = React.useState("");

  const [forwardArrowDisabled, setForwardArrowDisabled] = React.useState(false);

  const [backArrowDisabled, setBackArrowDisabled] = React.useState(true);

  const [showRefreshButton, setShowRefreshButton] = React.useState(false);

  const [rowsSortable, setRowsSortable] = React.useState(true);

  const [alertsRefreshing, setAlertsRefreshing] = React.useState(false);

  const refreshText = (
    <>
      <RefreshIcon fontSize="small" />
      &nbsp;Try Refresh
    </>
  );

  const parsedSiteList = site ? parseNumberList(site) : [];
  const parseSiteListNames: string[] = [];

  const cameraIds = new Map();
  cameraOptions.forEach((object: any) => {
    cameraIds.set(object.camera_name, object.id);
  });

  const siteIds = new Map();
  siteOptions.forEach((object: any) => {
    siteIds.set(object.name, object.id);
  });

  const siteNames = new Map();
  parsedSiteList.forEach((object: any) => {
    siteNames.set(object.id, object.name);
  });

  const [siteDropDownValue, setSiteDropDownValue] = React.useState<string[]>(
    []
  );

  const [cameraDropDownValue, setCameraDropDownValue] = React.useState<
    string[]
  >([]);

  const ref = React.useRef<HTMLDivElement>(null);

  const setScrollTop = (event: any) => {
    localStorage.setItem("aletsScrollTop", event.target.scrollTop);
  };

  React.useEffect(() => {
    if (ref) {
      if (ref.current !== null) {
        ref.current.scrollTop = localStorage.aletsScrollTop;
      }
    }
  }, [alertData]);

  const scrollToTop = () => {
    if (ref) {
      if (ref.current !== null) {
        ref.current.scrollTop = 0;
      }
    }
  };

  React.useEffect(() => {
    if (searchParams.size > 0) {
      localStorage.setItem("pageLoadedNumber", "0");
      setPageLoadedNumber(0);
      setPageLoading(true);
      if (start_date && end_date) {
        if (start_date === end_date) {
          setTimeFiltersDisabled(false);
        } else {
          setTimeFiltersDisabled(true);
        }
      }
      getAlerts(
        setAlertData,
        navigate,
        setSelectedRow,
        setPageLoading,
        setSeeMoreURL,
        setMP4URL,
        userFilters,
        setThumbnailVideos,
        setDateOutsideRange,
        setVideoClipLoading,
        setAlertsToDisplay,
        setSelectedAlertCameraInfo,
        setIgnoreZonesSelectedAlert,
        setAlertsIndexOrder,
        setRefreshInterval,
        setFetchingFilteredData,
        setCurrentName,
        setPage,
        setSelectedButtonsFilled,
        setUserFilters,
        cameraOptions,
        siteOptions,
        setRefreshingData,
        0,
        refreshInterval,
        setParentAccountsLoading,
        setParentAccounts,
        setParentIds,
        setParentNames,
        setRefreshingToken,
        refreshingToken,
        setAlertLabels,
        setPeriodHours,
        setFiltersApplied,
        setForwardArrowDisabled,
        setBackArrowDisabled,
        setShowRefreshButton,
        setRowsSortable,
        setAlertsRefreshing
      );
      getAlertsDownloadURL(
        setAlertsDownloadURL,
        userFilters.parent_group,
        userFilters.site,
        userFilters.camera,
        yyyymmddGenerator(userFilters.start_date),
        yyyymmddGenerator(userFilters.end_date),
        userFilters.start_time,
        userFilters.end_time
      );
    }
  }, []);

  React.useEffect(() => {
    //create filtered data
    let filteredData = alertData;
    if (detectionType === "All" && outcomeFilterType !== "All") {
      filteredData = alertData.filter(
        (alert) => alert.detection_type === outcomeFilterType
      );
    } else if (detectionType !== "All" && outcomeFilterType === "All") {
      filteredData = alertData.filter(
        (row: any) => row.alert_labels === setDetectionTypeLower(detectionType)
      );
    } else if (detectionType !== "All" && outcomeFilterType !== "All") {
      filteredData = alertData.filter(
        (row: any) =>
          row.alert_labels === setDetectionTypeLower(detectionType) &&
          row.detection_type === outcomeFilterType
      );
    }
    //check if selectedRow is in the filtered data using the approximate_time_stamp
    if (selectedRow) {
      const selectedRowInFilteredData = filteredData.find(
        (row) =>
          row.approx_capture_timestamp === selectedRow.approx_capture_timestamp
      );

      //if not, set selectedRow to the first element of the filtered data
      if (!selectedRowInFilteredData) {
        setSelectedRow(filteredData[0]);
        handleRowSelected(
          filteredData[0],
          alertData,
          setAlertData,
          setSelectedButtonsFilled,
          setSeeMoreURL,
          navigate,
          setMP4URL,
          setVideoClipLoading,
          setSelectedRow,
          setSelectedAlertCameraInfo,
          setIgnoreZonesSelectedAlert,
          refreshingToken,
          filteredData,
          setBackArrowDisabled,
          setForwardArrowDisabled,
          setShowRefreshButton
        );
        setBackArrowDisabled(true);
        setForwardArrowDisabled(false);
      } else {
        const selectedRowIndex = filteredData.findIndex(
          (row) =>
            row.approx_capture_timestamp ===
            selectedRow.approx_capture_timestamp
        );
        //if it is the first element, disable the back arrow
        if (selectedRowIndex === 0) {
          setBackArrowDisabled(true);
          setForwardArrowDisabled(false);
        }
        //if it is the last element, disable the forward arrow
        else if (selectedRowIndex === filteredData.length - 1) {
          setForwardArrowDisabled(true);
          setBackArrowDisabled(false);
        }
        //if it is neither, enable both arrows
        else {
          setBackArrowDisabled(false);
          setForwardArrowDisabled(false);
        }
      }
    }
  }, [alertData, detectionType, outcomeFilterType]);

  return (
    <div>
      {selectedRow && (
        <NotesDialog
          notesDialogOpen={notesDialogOpen}
          setNotesDialogOpen={setNotesDialogOpen}
          selectedRow={selectedRow}
          noteText={noteText}
          setNoteText={setNoteText}
          alertData={alertData}
          setAlertData={setAlertData}
          setSelectedRow={setSelectedRow}
          navigate={navigate}
        />
      )}
      <Dialog open={ignoreZoneDialogOpen}>
        <IconButton
          aria-label="close"
          onClick={() => {
            setIgnoreZoneDialogOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ActiveIgnoreZonesWindow
            selectedAlertCameraInfo={selectedAlertCameraInfo}
            ignoreZonesSelectedAlert={ignoreZonesSelectedAlert}
            loadingIgnoreZonesInfo={loadingIgnoreZonesInfo}
          />
        </DialogContent>
      </Dialog>
      <BackdropLoadingScreen openState={fetchingFilteredData} />
      <BackdropLoadingScreen openState={buttonClicked} />
      <BackdropLoadingScreen openState={pageLoading} />
      {dateOutsideRange && (
        <Alert
          severity="warning"
          onClose={() => {
            setDateOutsideRange(false);
          }}
        >
          Alerts with dates outside of selected range are displayed.
        </Alert>
      )}
      {queryLimitHit && (
        <Alert
          severity="warning"
          onClose={() => {
            setQueryLimitHit(false);
          }}
        >
          <p className="font-mulish font-bold text-[16px]">
            The selected parent group is too large. Only the 100 most recent
            alerts are displayed. Please select a specific site to view the
            accurate number of alerts.
          </p>
        </Alert>
      )}
      <div
        className="bg-actuate-grey absolute w-screen h-screen overflow-scroll"
        onScroll={setScrollTop}
        ref={ref}
      >
        <div
          className={
            !navBarCollapsed
              ? "font-mulish w-full min-w-[1200px]"
              : "font-mulish w-full min-w-[1200px]"
          }
        >
          <div className={!navBarCollapsed ? "ml-[150px]" : "ml-[75px]"}>
            <FiltersAlerts
              navBarCollapsed={navBarCollapsed}
              userFilters={userFilters}
              setUserFilters={setUserFilters}
              navigate={navigate}
              setSiteOptions={setSiteOptions}
              setSiteDropDownValue={setSiteDropDownValue}
              setCameraOptions={setCameraOptions}
              setCameraDropDownValue={setCameraDropDownValue}
              setLoadingSites={setLoadingSites}
              setRefreshInterval={setRefreshInterval}
              parentAccounts={parentAccounts}
              parentIds={parentIds}
              parentAccountsLoading={parentAccountsLoading}
              siteOptions={siteOptions}
              siteDropDownValue={siteDropDownValue}
              loadingSites={loadingSites}
              setLoadingCameras={setLoadingCameras}
              cameraOptions={cameraOptions}
              cameraDropDownValue={cameraDropDownValue}
              loadingCameras={loadingCameras}
              setFetchingFilteredData={setFetchingFilteredData}
              setAlertData={setAlertData}
              setSelectedRow={setSelectedRow}
              setSeeMoreURL={setSeeMoreURL}
              setMP4URL={setMP4URL}
              setSelectedButtonsFilled={setSelectedButtonsFilled}
              setThumbnailVideos={setThumbnailVideos}
              setDateOutsideRange={setDateOutsideRange}
              setPage={setPage}
              setVideoClipLoading={setVideoClipLoading}
              setAlertsToDisplay={setAlertsToDisplay}
              setPageLoading={setPageLoading}
              setTimeFiltersDisabled={setTimeFiltersDisabled}
              warningIssued={warningIssued}
              setWarningIssued={setWarningIssued}
              timeFiltersDisabled={timeFiltersDisabled}
              setFiltersApplied={setFiltersApplied}
              pageLoadedNumber={pageLoadedNumber}
              setPageLoadedNumber={setPageLoadedNumber}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              detectionType={detectionType}
              setSelectedAlertCameraInfo={setSelectedAlertCameraInfo}
              setIgnoreZonesSelectedAlert={setIgnoreZonesSelectedAlert}
              setAlertsIndexOrder={setAlertsIndexOrder}
              setCurrentName={setCurrentName}
              setRefreshingData={setRefreshingData}
              setRefreshingToken={setRefreshingToken}
              refreshingToken={refreshingToken}
              setAlertLabels={setAlertLabels}
              setPeriodHours={setPeriodHours}
              setQueryLimitHit={setQueryLimitHit}
              setAlertsDownloadURL={setAlertsDownloadURL}
              refreshInterval={refreshInterval}
              setOutcomeFilterType={setOutcomeFilterType}
              setForwardArrowDisabled={setForwardArrowDisabled}
              setBackArrowDisabled={setBackArrowDisabled}
              setShowRefreshButton={setShowRefreshButton}
              setRowsSortable={setRowsSortable}
              setAlertsRefreshing={setAlertsRefreshing}
            />
          </div>
          {/* <div>
              <svg width={width}>
                <line
                  x1="0"
                  y1="0"
                  x2="100%"
                  y2="0"
                  style={{ stroke: `#BDBDBD`, strokeWidth: 2 }}
                />
              </svg>
          </div> */}
          <div
            className={
              !navBarCollapsed
                ? "flex ml-[150px] w-[1450px]"
                : "flex ml-[75px] w-[1375px]"
            }
          >
            <div className="w-[650px]">
              {seeMoreURL && alertsToDisplay && selectedRow && (
                <div className="pt-[10px] pl-[20px]">
                  <p>
                    {selectedRow.display_name
                      ? createSiteHyperlink(
                          selectedRow.display_name,
                          selectedRow.customer_pk
                        )
                      : createSiteHyperlink(
                          selectedRow.customer_name,
                          selectedRow.customer_pk
                        )}{" "}
                    |{" "}
                    {createCameraHyperlink(
                      selectedRow.camera_name,
                      selectedRow.customer_pk,
                      selectedRow.camera_pk
                    )}
                  </p>
                </div>
              )}
              {!pageLoading && filtersApplied && (
                <div>
                  <div className="pt-[10px] w-[655px]">
                    {!fetchingFilteredData && !refreshingData && (
                      <div>
                        <AlertsTable
                          selected={selected}
                          alertData={alertData}
                          setAlertData={setAlertData}
                          setSelected={setSelected}
                          setSelectedRow={setSelectedRow}
                          setSelectedButtonsFilled={setSelectedButtonsFilled}
                          setSeeMoreURL={setSeeMoreURL}
                          navigate={navigate}
                          setMP4URL={setMP4URL}
                          page={page}
                          setPage={setPage}
                          userFilters={userFilters}
                          setFetchingFilteredData={setFetchingFilteredData}
                          setUserFilters={setUserFilters}
                          setThumbnailVideos={setThumbnailVideos}
                          setDateOutsideRange={setDateOutsideRange}
                          setVideoClipLoading={setVideoClipLoading}
                          setAlertsToDisplay={setAlertsToDisplay}
                          setPageLoading={setPageLoading}
                          setSelectedAlertCameraInfo={
                            setSelectedAlertCameraInfo
                          }
                          setIgnoreZonesSelectedAlert={
                            setIgnoreZonesSelectedAlert
                          }
                          setAlertsIndexOrder={setAlertsIndexOrder}
                          setRefreshInterval={setRefreshInterval}
                          currentName={currentName}
                          setCurrentName={setCurrentName}
                          pageLoadedNumber={pageLoadedNumber}
                          setPageLoadedNumber={setPageLoadedNumber}
                          setRefreshingData={setRefreshingData}
                          cameraOptions={cameraOptions}
                          siteOptions={siteOptions}
                          scrollToTop={scrollToTop}
                          refreshingToken={refreshingToken}
                          setRefreshingToken={setRefreshingToken}
                          setAlertLabels={setAlertLabels}
                          alertLabels={alertLabels}
                          detectionType={detectionType}
                          setDetectionType={setDetectionType}
                          setPeriodHours={setPeriodHours}
                          setQueryLimitHit={setQueryLimitHit}
                          searchParams={searchParams}
                          setSearchParams={setSearchParams}
                          alertsDownloadURL={alertsDownloadURL}
                          refreshInterval={refreshInterval}
                          outcomeFilterType={outcomeFilterType}
                          setOutcomeFilterType={setOutcomeFilterType}
                          setBackArrowDisabled={setBackArrowDisabled}
                          setForwardArrowDisabled={setForwardArrowDisabled}
                          setShowRefreshButton={setShowRefreshButton}
                          setRowsSortable={setRowsSortable}
                          rowsSortable={rowsSortable}
                          setAlertsRefreshing={setAlertsRefreshing}
                          alertsRefreshing={alertsRefreshing}
                        />
                        {alertsToDisplay &&
                          !userFilters.start_date &&
                          !userFilters.end_date &&
                          (alertData.length === 100 ? (
                            <p className="pt-[10px] pb-[10px] pl-[105px] font-mulish">
                              {periodHours === 1
                                ? `Displaying ${
                                    detectionType === "All" ? 100 : ""
                                  } most recent alerts from the past hour.`
                                : `Displaying ${
                                    detectionType === "All" ? 100 : ""
                                  } most recent alerts from the past ${periodHours} hours.`}
                            </p>
                          ) : (
                            <p className="pt-[10px] pb-[10px] pl-[170px] font-mulish">
                              {periodHours === 1
                                ? `Displaying alerts from the past hour.`
                                : `Displaying alerts from the past ${periodHours} hours.`}
                            </p>
                          ))}
                      </div>
                    )}
                    {refreshingData && (
                      <div className="relative">
                        <ThemeProvider theme={theme}>
                          <div className="relative left-[620px]">
                            <CircularProgress color="primary" />
                          </div>
                        </ThemeProvider>
                        <br></br>
                        <p className="relative left-[560px] font-mulish text-[20px]">
                          Refreshing Alerts...
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div>
              {seeMoreURL && alertsToDisplay && selectedRow && (
                <div>
                  <div className="pl-[520px]">
                    <OrangeTextButton
                      onClick={() => window.open(`${seeMoreURL}`, "_blank")}
                      disabled={false}
                      text="SEE ALERT"
                    />
                  </div>
                  <div className="pl-[10px] mt-[10px]">
                    {videoClipLoading && (
                      <div className="pl-[100px] pt-[50px] text-center">
                        <ThemeProvider theme={theme}>
                          <div>
                            <CircularProgress color="primary" />
                          </div>
                        </ThemeProvider>
                        <br></br>
                        <p className="font-mulish text-[20px]">
                          Video Loading...
                        </p>
                      </div>
                    )}
                    {!videoClipLoading && !mP4URL && (
                      <div className="pl-[95px] pt-[50px] text-[20px] font-mulish text-center">
                        <p>Video clip not available.</p>
                        <p>
                          If recent, video will be viewable when alarm closes.
                        </p>
                        <OrangeTextButton
                          onClick={() => {
                            handleRowSelected(
                              JSON.parse(localStorage.selectedRowAlerts),
                              alertData,
                              setAlertData,
                              setSelectedButtonsFilled,
                              setSeeMoreURL,
                              navigate,
                              setMP4URL,
                              setVideoClipLoading,
                              setSelectedRow,
                              setSelectedAlertCameraInfo,
                              setIgnoreZonesSelectedAlert,
                              refreshingToken,
                              alertData,
                              setBackArrowDisabled,
                              setForwardArrowDisabled,
                              setShowRefreshButton
                            );
                            // getAlertMP4(
                            //   selectedRow,
                            //   navigate,
                            //   setMP4URL,
                            //   setVideoClipLoading
                            // );
                          }}
                          text={refreshText}
                          disabled={false}
                        />
                      </div>
                    )}
                    {mP4URL && !videoClipLoading && (
                      <div className="flex items-center justify-between w-[680px]">
                        <div className="flex-none" id="arrow_back">
                          <OrangeTextButton
                            onClick={() => {
                              setRefreshingData(true);
                              handleBackButtonClick(
                                selectedRow,
                                setAlertIndex,
                                setSelectedRow,
                                alertData,
                                navigate,
                                setMP4URL,
                                setVideoClipLoading,
                                setSelectedButtonsFilled,
                                setSeeMoreURL,
                                setAlertData,
                                setRefreshingData,
                                setSelectedAlertCameraInfo,
                                setIgnoreZonesSelectedAlert,
                                alertsIndexOrder,
                                detectionType,
                                outcomeFilterType,
                                setBackArrowDisabled,
                                setForwardArrowDisabled,
                                setShowRefreshButton
                              );
                            }}
                            text={
                              <ArrowBackIosIcon
                                fontSize="large"
                                id="backButton"
                              />
                            }
                            disabled={
                              backArrowDisabled
                              // selectedRow.index === alertsIndexOrder[0]
                            }
                          />
                        </div>
                        <div className="flex-auto" id="video">
                          <video width="540" controls autoPlay loop>
                            <source src={mP4URL} type="video/mp4" />
                          </video>
                          <div className="flex pt-[5px]">
                            <div>
                              <OrangeTextButton
                                onClick={() => saveAs(mP4URL, "video.mp4")}
                                text="Download Video"
                                disabled={false}
                              />
                            </div>
                            {showRefreshButton && (
                              <div className="pl-[240px] flex">
                                <OrangeTextButton
                                  onClick={() => {
                                    setVideoClipLoading(true);
                                    checkForRedirectAndFetchMP4(
                                      selectedRow,
                                      navigate,
                                      setMP4URL,
                                      setVideoClipLoading,
                                      setShowRefreshButton
                                    );
                                  }}
                                  text={
                                    <>
                                      <RefreshIcon fontSize="small" />
                                      &nbsp;Refresh Video
                                    </>
                                  }
                                  disabled={false}
                                />
                                <Tooltip
                                  title={
                                    "If this button is visible the full clip may not have been ready. Click to refresh and check for full clip."
                                  }
                                  placement="right"
                                  sx={{
                                    fontSize: "15px",
                                    fontFamily: "mulish",
                                  }}
                                >
                                  <InfoOutlinedIcon fontSize="small"></InfoOutlinedIcon>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex-none" id="arrow_forward">
                          <OrangeTextButton
                            onClick={() => {
                              setRefreshingData(true);
                              handleForwardButtonClick(
                                selectedRow,
                                setAlertIndex,
                                setSelectedRow,
                                alertData,
                                navigate,
                                setMP4URL,
                                setVideoClipLoading,
                                setSelectedButtonsFilled,
                                setSeeMoreURL,
                                setAlertData,
                                setRefreshingData,
                                setSelectedAlertCameraInfo,
                                setIgnoreZonesSelectedAlert,
                                alertsIndexOrder,
                                detectionType,
                                outcomeFilterType,
                                setForwardArrowDisabled,
                                setBackArrowDisabled,
                                setShowRefreshButton
                              );
                            }}
                            text={
                              <ArrowForwardIosIcon
                                fontSize="large"
                                id="forwardButton"
                              />
                            }
                            disabled={
                              forwardArrowDisabled
                              // selectedRow.index ===
                              // alertsIndexOrder[alertsIndexOrder.length - 1]
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="pt-[10px] pl-[33px]">
                {mP4URL && !videoClipLoading && (
                  <ErrorBoundary>
                    <AlertsIdentifierBox
                      selectedRow={selectedRow}
                      alertData={alertData}
                      setAlertData={setAlertData}
                      setSelectedRow={setSelectedRow}
                      selectdButtonsFilled={selectdButtonsFilled}
                      setSelectedButtonsFilled={setSelectedButtonsFilled}
                      navigate={navigate}
                      setPageLoading={setPageLoading}
                      setButtonClicked={setButtonClicked}
                      pageLoadedNumber={pageLoadedNumber}
                      setPageLoadedNumber={setPageLoadedNumber}
                      setFetchingFilteredData={setFetchingFilteredData}
                      setSeeMoreURL={setSeeMoreURL}
                      setMP4URL={setMP4URL}
                      userFilters={userFilters}
                      setThumbnailVideos={setThumbnailVideos}
                      setDateOutsideRange={setDateOutsideRange}
                      setPage={setPage}
                      setVideoClipLoading={setVideoClipLoading}
                      setAlertsToDisplay={setAlertsToDisplay}
                      setSelectedAlertCameraInfo={setSelectedAlertCameraInfo}
                      setIgnoreZonesSelectedAlert={setIgnoreZonesSelectedAlert}
                      setAlertsIndexOrder={setAlertsIndexOrder}
                      setRefreshInterval={setRefreshInterval}
                      setCurrentName={setCurrentName}
                      setUserFilters={setUserFilters}
                      setRefreshingData={setRefreshingData}
                      refreshInterval={refreshInterval}
                      cameraOptions={cameraOptions}
                      siteOptions={siteOptions}
                      setRefreshingToken={setRefreshingToken}
                      refreshingToken={refreshingToken}
                      setAlertLabels={setAlertLabels}
                      setPeriodHours={setPeriodHours}
                      setQueryLimitHit={setQueryLimitHit}
                      setForwardArrowDisabled={setForwardArrowDisabled}
                      setBackArrowDisabled={setBackArrowDisabled}
                      setShowRefreshButton={setShowRefreshButton}
                      setRowsSortable={setRowsSortable}
                      setAlertsRefreshing={setAlertsRefreshing}
                    />
                  </ErrorBoundary>
                )}
              </div>
              <div>
                {alertsToDisplay &&
                  seeMoreURL &&
                  !pageLoading &&
                  mP4URL &&
                  !videoClipLoading && (
                    <div className="pl-[30px] pt-[3px]">
                      <Tooltip
                        title={
                          <ActiveIgnoreZonesWindow
                            selectedAlertCameraInfo={selectedAlertCameraInfo}
                            ignoreZonesSelectedAlert={ignoreZonesSelectedAlert}
                            loadingIgnoreZonesInfo={loadingIgnoreZonesInfo}
                          />
                        }
                        placement="top-start"
                      >
                        <OrangeTextButton
                          text="View Active Ignore Zones"
                          disabled={false}
                          onClick={() => {
                            setLoadingIgnoreZonesInfo(true);
                            getIgnoreZoneDialogInfo(
                              selectedRow,
                              setSelectedAlertCameraInfo,
                              setIgnoreZonesSelectedAlert,
                              setLoadingIgnoreZonesInfo,
                              navigate
                            );
                            setIgnoreZoneDialogOpen(true);
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
              </div>
              <div className="pl-[25px]">
                {alertsToDisplay &&
                  seeMoreURL &&
                  !pageLoading &&
                  mP4URL &&
                  !videoClipLoading && (
                    <CameraInfoTable
                      selectedRow={selectedRow}
                      setNoteText={setNoteText}
                      setNotesDialogOpen={setNotesDialogOpen}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="justify-start">
        <NavBar
          navBarCollapsed={navBarCollapsed}
          setNavBarCollapsed={setNavBarCollapsed}
          props={{}}
        />
      </div>
    </div>
  );
};

export default React.memo(AlertsMirror);
