import { createApi } from "../../../../utils/createApi";
import { AxiosError, AxiosResponse } from "axios";
import { universalAPIErrorHandler } from "../../../../utils/universalAPIErrorHandler";
import { getNumbersAfterAboutSite } from "../SiteAbout";
import { SiteCamerasResult, SiteInfo } from "./siteAboutInterfaces";

export const handleDeactivateCamera = async (
  submittedObject: any,
  navigate: any,
  setSiteCameras: any,
  setAlertState: any,
  setSelected: any,
  setSyncNeeded: any,
  setBackdropLoadingScreenOn: any,
  setSiteInfo: any
) => {
  const AxiosUI = createApi("");
  const retryFunction = () => {
    handleDeactivateCamera(
      submittedObject,
      navigate,
      setSiteCameras,
      setAlertState,
      setSelected,
      setSyncNeeded,
      setBackdropLoadingScreenOn,
      setSiteInfo
    );
  };
  let cameraDeactivateFail = false;

  await AxiosUI.post(`camera/action/deactivate/`, submittedObject).then(
    (response: AxiosResponse) => {
      // eslint-disable-next-line no-console
      console.log(response);
      cameraDeactivateFail = false;
    },
    (reason: AxiosError) => {
      cameraDeactivateFail = true;
      universalAPIErrorHandler(reason, navigate, retryFunction, () => {
        alert("Camera deactivation failed, please try again.");
        setBackdropLoadingScreenOn(false);
      });
    }
  );
  !cameraDeactivateFail &&
    (await AxiosUI.get(
      `camera/site/?customer__id=${getNumbersAfterAboutSite(location.href)}`
    ).then(
      (response: AxiosResponse) => {
        localStorage.setItem("siteCameras", JSON.stringify(response.data));
        setSiteCameras(response.data);
        let allFalse = true;
        response.data.results.forEach((item: SiteCamerasResult) => {
          if (item.active) {
            allFalse = false;
          }
        });
        if (allFalse) {
          setSiteInfo((previousState: SiteInfo) => {
            return {
              ...previousState,
              active: false,
            };
          });
        }
        setAlertState((previousState: any) => {
          return {
            ...previousState,
            changeSuccess: true,
            activateSuccess: false,
            deactivateSuccess: false,
            disarmSuccess: false,
            armSuccess: false,
            syncSuccess: false,
            cameraDeleteSuccess: false,
          };
        });
        setSelected([]);
        setSyncNeeded(true);
        setBackdropLoadingScreenOn(false);
      },
      (reason: AxiosError) => {
        universalAPIErrorHandler(reason, navigate, retryFunction, () => {
          setBackdropLoadingScreenOn(false);
        });
      }
    ));
};
